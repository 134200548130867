import React from "react";

import Typography from "@material-ui/core/Typography";

import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

export class KarmingDataProtection extends React.Component {

    render() {
        return (
            <Container>
                <Paper padding>
                    <Typography
                        variant="body1"
                        align="justify"
                        component="div">
                        <Typography
                            paragraph
                            variant="h5"
                            align="center">
                            Introduktion till dataskydd
                        </Typography>
                        <i>
                            Här kan du hitta information om dataskydd, verktyg samt nyttiga länkar kring
                            dataskyddsfrågor. Vi
                            på Karming har anpassat och förberett juridik som gör att du ska kunna komma igång direkt
                            med att
                            erbjuda dina kunder Tjänsten.

                        </i>

                        <div>
                            <Typography variant="h6">
                                Introduktion till dataskydd
                            </Typography>
                            <p>
                                Från och med 25 maj 2018 gäller en ny gemensam lagstiftning inom EU som reglerar hur
                                bland annat
                                företag får behandla personuppgifter. Den ersätter Personuppgiftslagen som gällt i
                                Sverige. Här har
                                vi samlat lite vanliga frågor och svar kring just dataskydd och vår tjänst. Vi på
                                Karming har anpassat
                                och förberett juridik som gör att du ska kunna komma igång direkt med att erbjuda dina
                                kunder
                                Tjänsten.
                            </p>
                        </div>
                        <div>
                            <Typography variant="h6">
                                Vad är GDPR?
                            </Typography>
                            <p>
                                GDPR är en förkortning av det engelska namnet på den nya EU –förordningen (General Data
                                Protection Regulation) och heter på svenska Dataskyddsförordningen.
                            </p>
                        </div>
                        <div>
                            <Typography variant="h6">
                                Vad räknas som Personuppgifter?
                            </Typography>
                            <p>
                                Som Personuppgifter räknas all slags information som direkt eller indirekt kan hänföras
                                till en fysisk
                                person som är i livet. Det kan, förutom namn och personnummer, vara e-post,
                                telefonnummer, men
                                även bilder och elektroniska identiteter.
                            </p>
                        </div>
                        <div>
                            <Typography variant="h6">
                                Får jag samla in personuppgifter?
                            </Typography>
                            <p>
                                Ja, man får samla in personuppgifter, men det är viktigt att man har koll på vilka
                                uppgifter man
                                samlar in, i vilket syfte man samlar in dem och hur de hanteras.
                            </p>
                        </div>
                        <div>
                            <Typography variant="h6">
                                Vad innebär det att jag är Personuppgiftsansvarig?
                            </Typography>
                            <p>
                                Du är redan idag ansvarig för de personuppgifter du har i din verksamhet, t.ex.
                                anställda, register
                                över leverantörer o.s.v.
                            </p>
                            <p>
                                I anknytning till vår tjänst innebär det att du äger och ansvarar för dina
                                Personuppgifter medan vi på
                                Karming AB agerar som ditt Personuppgiftsbiträde och hanterar och lagrar uppgifterna åt
                                dig.
                            </p>
                        </div>
                        <div>
                            <Typography variant="h6">
                                Vilka personuppgifter samlar jag in?
                            </Typography>
                            <p>
                                Du samlar troligtvis in många personuppgifter redan idag och är redan
                                personuppgiftsansvarig för
                                dem. Det kan röra sig om anställda, leverantörsregister, e-postlistor etc. Det är
                                viktigt att ha koll på
                                vilka uppgifter man idag faktiskt samlar in och hanterar – och om man har tillstånd att
                                göra det.
                            </p>
                            <p>
                                I Karmings tjänster behandlas de personuppgifter som medlemmen lägger in i
                                Lojalitetstjänsten när
                                de använder den eller som de lämnar över telefon eller via e-mail, till exempel namn,
                                e-postadress,
                                adress, telefonnummer, födelsedatum, social mediaprofil, IP-nummer, enhets-id,
                                registrerade köp
                                och preferenser samt aktiviteter utförda som medlem. Vi hämtar även in uppgifter från
                                offentliga
                                och privata register för att hålla medlemmars kontaktinformation uppdaterad.
                            </p>
                        </div>
                        <div>
                            <Typography variant="h6">
                                Varför finns det ingen ruta kunden ska klicka i för att godkänna Personuppgiftspolicyn?
                            </Typography>
                            <p>
                                Personuppgifter kan samlas in med olika rättslig grund beroende på olika situationer.
                                Ibland måste
                                man få ett samtycke av den person vars uppgifter man samlar in för att spara
                                uppgifterna. Då finns
                                det oftast en ruta eller liknande i anslutning till Personuppgiftspolicyn som måste
                                klickas i för att
                                bekräfta att man samtycker till innehållet i Policyn. I andra fall sparas
                                personuppgifter för att en
                                tjänst ska kunna fungera som den är ämnad, något som kallas berättigat intresse. Det kan
                                också vara
                                frågan om att personuppgifter måste sparas för att uppfylla andra lagkrav, t.ex. inom
                                bank och
                                betalningar. I anslutning till denna tjänst används berättigat intresse som grund för
                                att
                                personuppgifterna samlas in.
                            </p>
                        </div>
                    </Typography>
                </Paper>
            </Container>
        )
    }

}
