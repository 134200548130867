import React from 'react';
import PropTypes from "prop-types";

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {getUniqueObjectProperties} from "../../../../common/utils/data-utils";

const useStyles = makeStyles(theme => ({
    textRemoved: {
        textDecoration: 'line-through'
    },
    textGreen: {
        color: theme.palette.green.dark
    }
}));

export const CampaignsList = props => {
    const {purchase, array, index} = props;
    const classes = useStyles();

    const previousCampaigns = array[0] && array[index - 1]
        ? array[index - 1].campaigns
        : array[0];
    const addedCampaigns = array[index - 1] && purchase.campaigns.filter(currCampaign =>
            previousCampaigns.every(prevCampaign => prevCampaign.id !== currCampaign.id));
    const removedCampaigns = array[index - 1] && previousCampaigns && previousCampaigns.filter(currCampaign =>
            purchase.campaigns.every(prevCampaign => prevCampaign.id !== currCampaign.id));

    const recentCampaigns = previousCampaigns && [...previousCampaigns, ...purchase.campaigns];
    const campaigns = getUniqueObjectProperties(recentCampaigns);

    return (
        <React.Fragment>
            {campaigns && campaigns.length > 0 && campaigns.map((campaign, campaignIndex) => {
                    const isNewCampaign = addedCampaigns && addedCampaigns.some(addedCampaign =>
                        addedCampaign.id === campaign.id);
                    const isRemovedCampaign = removedCampaigns && removedCampaigns.some(removedCampaign =>
                        removedCampaign.id === campaign.id);

                    return (
                        <Typography
                            key={campaignIndex}
                            variant="subtitle2"
                            color={isRemovedCampaign && 'error' || 'inherit'}
                            className={
                                isRemovedCampaign && classes.textRemoved
                                || isNewCampaign && classes.textGreen
                                || undefined
                            }>
                            {campaign && `"${campaign.name}" (id: ${campaign.id})`}
                        </Typography>
                    )
                })}
        </React.Fragment>
    );
};

CampaignsList.propTypes = {
    purchase: PropTypes.object.isRequired,
    array: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired
};
