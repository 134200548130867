import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {KarmingDataProcessingAgreement} from "./KarmingDataProcessingAgreement";

import Spinner from "../../../../common/components/common/Spinner";
import {fetchUserDetails} from "../../../../common/actions/user-actions";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

class KarmingDataProcessingAgreementContainer extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object
    };

    render() {
        const {app, user} = this.props;

        if (!user) {
            return <Spinner app={app}/>
        }

        return (
            <Container>
                <Paper padding>
                    <KarmingDataProcessingAgreement company={user.company}/>
                </Paper>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.profile
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchUserDetails}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(KarmingDataProcessingAgreementContainer);
