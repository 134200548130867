import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types"

import CommercialChainForm from "./CommercialChainForm";
import Spinner from "../../../../../common/components/common/Spinner";
import Container from "../../../../../common/components/common/Container";
import Paper from "../../../../../common/components/common/Paper";
import {prepareFormData} from "../../../../utils/FormHelper";

import {
    createCommercialChain,
    fetchCommercialChain,
    updateCommercialChain
} from "../../../../actions/merchant-chain-actions";
import {S3_IMAGES_URL} from "../../../../../common/config/karming-links";

export const LOGO_FORM_DATA_NAME = "logo";
export const LOGO_FIELD_NAME = "logoExtension";
export const APP_ICON_DATA_NAME = "appIcon";
export const APP_ICON_FIELD_NAME = "appIconExtension";
export const APP_ICON_TEMPLATE = S3_IMAGES_URL + '/templates/PWA/icon512.png';

class CommercialChain extends React.Component {

    static propTypes = {
        commercialChain: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        app: PropTypes.object,
        fetchCommercialChain: PropTypes.func,
        updateCommercialChain: PropTypes.func,
        createCommercialChain: PropTypes.func
    };

    componentDidMount() {
        if (this.isUpdate()) {
            this.props.fetchCommercialChain(this.props.match.params.id)
        }
    }

    isUpdate() {
        return this.props.match.params.id != null;
    }

    onSubmit(values) {
        const body = prepareFormData(values);
        if (this.isUpdate()) {
            this.props.updateCommercialChain(this.props.match.params.id, body)
        } else {
            this.props.createCommercialChain(body, this.props.history)
        }
    }

    render() {
        const {app, commercialChain} = this.props;

        if (this.isUpdate() && !commercialChain) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <CommercialChainForm
                        enableReinitialize={true}
                        initialValues={this.isUpdate() ? commercialChain : {appIconPath: APP_ICON_TEMPLATE}}
                        isUpdate={this.isUpdate()}
                        onSubmit={this.onSubmit.bind(this)}/>
                </Paper>
            </Container>
        )
    }

}

function mapStateToProps(state) {
    return {
        //FIXME should not change current
        commercialChain: state.chains.current,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCommercialChain, updateCommercialChain, createCommercialChain}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CommercialChain);
