import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import {RequiredInfo} from "../../../../common/components/common/form/FormUtils";
import BannerBuilder, {bannerRequired} from "../../banner/BannerBuilder";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

class CampaignFormPage2 extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        initialValues: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func
    };

    render() {
        const {handleSubmit, previousPage, initialValues, title, isUpdate} = this.props;

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <form onSubmit={handleSubmit} autoComplete="on">
                        <Typography
                            variant="h5"
                            paragraph>
                            {title}
                        </Typography>

                        <Typography variant="subtitle1">
                            <React.Fragment>
                                &nbsp;
                                <FormattedMessage
                                    id="banner"
                                    defaultMessage="banner"/>
                                &nbsp;*
                            </React.Fragment>
                        </Typography>

                        <Field
                            name="banner"
                            component={BannerBuilder}
                            currentImageFile={this.getBannerPath(initialValues)}
                            type="blob"
                            onNext={handleSubmit}
                            onPrevious={previousPage}
                            isUpdate={isUpdate}
                            validate={[bannerRequired]}/>

                        <RequiredInfo/>
                    </form>
                </Paper>
            </Container>
        );
    }

    getBannerPath(campaign) {
        if (campaign) {
            return campaign.bannerPath
        }
    }

}

export default reduxForm({
    form: "campaignForm",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CampaignFormPage2);
