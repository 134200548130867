import React from "react";
import CircularProgressbar from "react-circular-progressbar";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Check from '@material-ui/icons/Check';

import ReadCheckbox from "../../../../common/components/common/ReadCheckbox";
import Button from "../../../../common/components/common/Button";
import FirstPosDialog from "./FirstPosDialog";
import StartGuideLink from "./StartGuideLink";

import {completeGuide} from "../../../actions/merchant-actions";
import {setStartGuideVisibility} from "../../../../common/actions/utils-actions";

import {primaryColor} from "../../../../common/utils/color-const";

const DEFAULT_START_GUIDE_STATUS = {
    hasAtLeastOneChain: false,
    welcomeOfferDefined: false,
    smsServiceActivated: false,
    membershipConditionsPublished: false,
    privacyPolicyPublished: false
};

const styles = theme => ({
    wrapper: {
        marginTop: theme.spacing(1),
        paddingRight: '3%',
        textAlign: 'center'
    },
    checkboxesWrapper: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        margin: theme.spacing(2)
    },
    subtitle: {
        marginLeft: theme.spacing(4)
    },
    progressCircle: {
        width: 150,
        margin: theme.spacing(1),
    },
    button: {
        width: '60%'
    }
});

class StartGuide extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        company: PropTypes.object,
        chain: PropTypes.object,
        completeGuide: PropTypes.func,
        handleAppOpenDialog: PropTypes.func,
        setStartGuideVisibility: PropTypes.func
    };

    render() {
        const {classes, company, chain} = this.props;

        const startGuideStatus = company && company.startGuideStatus
            ? Object.assign(company.startGuideStatus, {hasAtLeastOneChain: !!chain})
            : DEFAULT_START_GUIDE_STATUS;

        return (
            <div className={classes.wrapper}>
                <Typography variant="h5">
                    <FormattedMessage
                        id="startGuide"
                        defaultMessage="Start guide"/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="start-guide.CompleteStepsText"
                        defaultMessage="Complete the steps below and start rocking!"/>
                </Typography>
                {this.renderProgressBar(startGuideStatus)}

                <div className={classes.checkboxesWrapper}>
                    <ReadCheckbox
                        label={this.renderSetupChainLabel(startGuideStatus)}
                        checked={startGuideStatus.hasAtLeastOneChain}/>

                    <ReadCheckbox
                        label={this.renderDefineWelcomeOffer(startGuideStatus)}
                        checked={startGuideStatus.welcomeOfferDefined}/>
                    <Typography
                        variant="caption"
                        className={classes.subtitle}>
                        <FormattedMessage
                            id="optional"
                            defaultMessage="Optional"/>
                    </Typography>

                    <ReadCheckbox
                        label={this.renderActivateSMS(startGuideStatus)}
                        checked={startGuideStatus.smsServiceActivated}/>
                    <Typography
                        variant="caption"
                        className={classes.subtitle}>
                        <FormattedMessage
                            id="optional"
                            defaultMessage="Optional"/>
                    </Typography>

                    <ReadCheckbox
                        label={this.renderSetMembershipConditions(startGuideStatus)}
                        checked={startGuideStatus.membershipConditionsPublished}/>
                    <Typography
                        variant="caption"
                        className={classes.subtitle}>
                        <FormattedMessage
                            id="start-guide.WeHavePreparedText"
                            defaultMessage="We have prepared, to make it easy for you!"/>
                    </Typography>

                    <ReadCheckbox
                        label={this.renderSetPrivacyPolicy(startGuideStatus)}
                        checked={startGuideStatus.privacyPolicyPublished}/>
                </div>

                <Button
                    icon={<Check/>}
                    className={classes.button}
                    label={<FormattedMessage
                        id="complete"
                        defaultMessage="Complete"/>}
                    onClick={this.completeGuide.bind(this)}
                    disabled={!startGuideStatus.hasAtLeastOneChain
                    || !startGuideStatus.membershipConditionsPublished
                    || !startGuideStatus.privacyPolicyPublished}/>
            </div>
        )
    }

    completeGuide() {
        this.props.completeGuide();
        this.props.handleAppOpenDialog(
            <FormattedMessage
                id="start-guide.greatStuffText"
                defaultMessage="Great stuff! You did it. Now You are ready to conquer the world!"/>
        )
    }

    renderSetupChainLabel() {
        return (
            <FirstPosDialog
                moveToCreatePointOfSale={this.selectStepInStartGuide.bind(this, "/poschain/create")}
                moveToAddCommercialChain={this.selectStepInStartGuide.bind(this, "/chains/create")}/>
        )
    }

    renderDefineWelcomeOffer(startGuideStatus) {
        const pathname = "/attract";
        return (
            <StartGuideLink
                disabled={!startGuideStatus.hasAtLeastOneChain}
                onClick={startGuideStatus.hasAtLeastOneChain && this.selectStepInStartGuide.bind(this, pathname)}>
                <FormattedMessage
                    id="start-guide.defineYourWelcomeOffer"
                    defaultMessage="Define your welcome offer"/>
            </StartGuideLink>
        )
    }

    renderActivateSMS(startGuideStatus) {
        const pathname = "/sms";
        return (
            <StartGuideLink
                disabled={!startGuideStatus.hasAtLeastOneChain}
                onClick={startGuideStatus.hasAtLeastOneChain && this.selectStepInStartGuide.bind(this, pathname)}>
                <FormattedMessage
                    id="activateSms"
                    defaultMessage="Activate SMS"/>
            </StartGuideLink>
        )
    }

    renderSetMembershipConditions(startGuideStatus) {
        const pathname = "/chain/membership/terms";
        return (
            <StartGuideLink
                disabled={!startGuideStatus.hasAtLeastOneChain}
                onClick={startGuideStatus.hasAtLeastOneChain && this.selectStepInStartGuide.bind(this, pathname)}>
                <FormattedMessage
                    id="setMembershipConditions"
                    defaultMessage="Set Membership Conditions"/>
            </StartGuideLink>
        )
    }

    renderSetPrivacyPolicy(startGuideStatus) {
        const pathname = "/chain/membership/privacy";
        return (
            <StartGuideLink
                disabled={!startGuideStatus.hasAtLeastOneChain}
                onClick={startGuideStatus.hasAtLeastOneChain && this.selectStepInStartGuide.bind(this, pathname)}>
                <FormattedMessage
                    id="setPrivacyPolicy"
                    defaultMessage="Set Privacy Policy"/>
            </StartGuideLink>
        )
    }

    selectStepInStartGuide(pathname) {
        this.props.setStartGuideVisibility(false);
        this.props.history.push(pathname)
    }

    renderProgressBar(startGuideStatus) {
        const {classes} = this.props;
        const progress = this.calculateReadyPercentage(startGuideStatus);
        if (progress > 0) {
            return (
                <CircularProgressbar
                    className={classes.progressCircle}
                    percentage={progress}
                    styles={{
                        path: {stroke: primaryColor},
                        text: {
                            dominantBaseline: 'middle',
                            textAnchor: 'middle'
                        }
                    }}/>
            )
        }
    }

    calculateReadyPercentage(startGuideStatus) {
        let percentage = 0;

        if (startGuideStatus.hasAtLeastOneChain) {
            percentage += 20
        }
        if (startGuideStatus.welcomeOfferDefined) {
            percentage += 20
        }
        if (startGuideStatus.smsServiceActivated) {
            percentage += 20
        }
        if (startGuideStatus.membershipConditionsPublished) {
            percentage += 20
        }
        if (startGuideStatus.privacyPolicyPublished) {
            percentage += 20
        }
        return percentage;
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({completeGuide, setStartGuideVisibility}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(StartGuide));
