import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import MerchantDetailsForm from "../../../common/components/common/users/MerchantDetailsForm";
import Spinner from "../../../common/components/common/Spinner";
import MerchantAccessForm from "./MerchantAccessForm";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {fetchCommercialChains} from "../../actions/merchant-chain-actions";
import {changeMerchantAccess, fetchMerchant, removeMerchant} from "../../actions/merchant-actions";

const styles = theme => ({
    title: {
        marginTop: theme.spacing(1)
    }
});

class MerchantEdit extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        chains: PropTypes.array,
        merchant: PropTypes.object,
        history: PropTypes.object,
        auth: PropTypes.object,
        user: PropTypes.object,
        app: PropTypes.object,
        match: PropTypes.object,
        fetchMerchant: PropTypes.func,
        fetchCommercialChains: PropTypes.func,
        removeMerchant: PropTypes.func,
        changeMerchantAccess: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchMerchant(this.props.match.params.id);
        this.props.fetchCommercialChains();
    }

    removeMerchantAndRedirectToUsers(merchantId, history) {
        this.props.removeMerchant(merchantId, history);
    }

    render() {
        const {classes, merchant, app, history, user, auth, chains, changeMerchantAccess} = this.props;

        if (!merchant || !chains || merchant.id !== Number(this.props.match.params.id)) {
            return <Spinner app={app}/>
        }
        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <MerchantDetailsForm
                        isAdmin={false}
                        removeMerchant={this.removeMerchantAndRedirectToUsers.bind(this, merchant.id, history)}
                        initialValues={merchant}/>

                    <Typography
                        variant="h5"
                        className={classes.title}>
                        <FormattedMessage
                            id="userRole"
                            defaultMessage="User role"/>
                    </Typography>

                    <MerchantAccessForm
                        enableReinitialize
                        user={user.profile}
                        userRole={auth && auth.role}
                        initialValues={{
                            controlLocalAdmin: merchant.controlLocalAdmin,
                            merchantId: merchant.id,
                            pointsOfSale: merchant.pointsOfSaleIds,
                            role: merchant.authority
                        }}
                        chains={chains}
                        onSubmit={changeMerchantAccess.bind(this)}/>
                </Paper>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        merchant: state.merchant.merchant,
        chains: state.chains.commercialChains,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchMerchant,
        removeMerchant,
        fetchCommercialChains,
        changeMerchantAccess,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MerchantEdit));
