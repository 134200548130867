import React from "react";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {KARMING_REGISTERFORTECKNING_TEMPLATE} from "../../../../common/config/karming-links";

export class KarmingRecordList extends React.Component {

    render() {
        return (
            <Container>
                <Paper padding>
                    <Typography
                        variant="body1"
                        align="justify"
                        component="div">
                        <Typography
                            paragraph
                            variant="h5"
                            align="center">
                            Registerförteckning
                        </Typography>

                        <div>
                            <Typography variant="h6">
                                Introduktion
                            </Typography>
                            <p>
                                Personuppgiftsansvariga är skyldiga att föra ett register eller en förteckning över
                                behandlingar av
                                personuppgifter. Undantag finns för företag och organisationer som har färre än 250
                                anställda om
                                alla dessa kriterier är uppfyllda: 1) de uppgifter som samlas in inte kommer att medföra
                                en risk för
                                de registrerades rättigheter och friheter. 2) uppgifterna är tillfälliga 3) Uppgifterna
                                omfattar inte
                                känsliga personuppgifter enligt artikel 9 eller personuppgifter om lagöverträdelser
                                enligt artikel 10. I
                                så fall är man inte skyldiga att föra register om personuppgiftsbehandlingen.
                            </p>
                            <p>
                                I annat fall bör registret skapas skriftligen och finnas digitalt tillgängligt. Man bör
                                även se till att
                                registret är uppdaterat. Om Datainspektionen begär ska registret kunna göras
                                tillgänglig. Exakt vad
                                registret ska innehålla beskrivs i artikel 30 i den nya dataskyddsförordningen som
                                gäller från 25 maj
                                2018.
                            </p>
                        </div>
                        <div>
                            <Typography variant="h6">
                                Detta bör finnas med
                            </Typography>
                            <p>
                                På datainspektionens hemsida har de samlat följande checklista för
                                personuppgiftsansvariga:
                            </p>
                            <ul>
                                <li>
                                    Namn och kontaktuppgifter för den personuppgiftsansvarige, den
                                    personuppgiftsansvariges
                                    företrädare samt dataskyddsombudet.
                                </li>
                                <li>
                                    Ändamålen med behandlingen.
                                </li>
                                <li>
                                    En beskrivning av kategorierna av registrerade och kategorierna av personuppgifter.
                                </li>
                                <li>
                                    De kategorier av mottagare till vilka personuppgifterna har lämnats eller ska lämnas
                                    ut.
                                </li>
                                <li>
                                    I tillämpliga fall, överföringar av personuppgifter till ett tredjeland eller en
                                    internationell
                                    organisation.
                                </li>
                                <li>
                                    Om möjligt, de förutsedda tidsfristerna för radering av de olika kategorierna av
                                    uppgifter.
                                </li>
                                <li>
                                    Om möjligt, en allmän beskrivning av tekniska och organisatoriska säkerhetsåtgärder.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <Typography variant="h6">
                                Förslag på mall
                            </Typography>
                            <p>
                                Vi har tagit fram ett förslag på förifylld mall som ni kan välja att utgå ifrån och
                                anpassa efter er
                                verksamhet. Mallen hittar ni på länken nedan.
                            </p>

                            <a
                                href={KARMING_REGISTERFORTECKNING_TEMPLATE}
                                target="_blank"
                                rel="noopener noreferrer">
                                <FormattedMessage
                                    id="getTemplate"
                                    defaultMessage="Get Template"/>
                            </a>
                        </div>
                    </Typography>
                </Paper>
            </Container>
        )
    }

}
