import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import RenderTextField from "../../../../common/components/common/RenderTextField";
import {RequiredInfo} from "../../../../common/components/common/form/FormUtils";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {actionButtonLabelValidation, URLValidation} from "../../../../common/utils/karming-validators";

const validate = values => {
    return Object.assign(
        actionButtonLabelValidation(
            {name: "actionButtonLabel", value: values.actionButtonLabel},
            {name: "actionButtonTarget", value: values.actionButtonTarget}
        ),
        URLValidation({name: "actionButtonTarget", value: values.actionButtonTarget})
    )
};

class CampaignFormPage4 extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func
    };

    render() {
        const {handleSubmit, previousPage, title} = this.props;

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <form onSubmit={handleSubmit} autoComplete="on">
                        <Typography
                            variant="h5"
                            paragraph>
                            {title}
                        </Typography>

                        <Typography
                            variant="subtitle1"
                            paragraph>
                            <FormattedMessage
                                id="provideEcrIdForCoupon"
                                defaultMessage="Provide a EcrId for this coupon - so this coupon could be used with ECR automatic purchases"/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="youCanSkip"
                                defaultMessage="You can skip this as well as change later."/>
                        </Typography>

                        <Field
                            name="campaignEcrId"
                            hintText={<FormattedMessage
                                id="campaignEcrId"
                                defaultMessage="Campaign Ecr ID"/>}
                            component={RenderTextField}
                            type="text"/>

                        <Field
                            name="couponDetails.valueType"
                            hintText={<FormattedMessage
                                id="valueType"
                                defaultMessage="Value Type">
                            </FormattedMessage>}
                            component={RenderTextField}
                            type="text"/>

                        <Field
                            name="couponDetails.cashierDescription"
                            hintText={<FormattedMessage
                                id="cashierDescription"
                                defaultMessage="Cashier Descriptions">
                            </FormattedMessage>}
                            component={RenderTextField}
                            type="text"/>

                        <Field
                            name="couponDetails.value"
                            hintText={<FormattedMessage
                                id="value"
                                defaultMessage="Value">
                            </FormattedMessage>}
                            component={RenderTextField}
                            type="text"/>

                        <Field
                            name="couponDetails.validArticles"
                            hintText={<FormattedMessage
                                id="validArticles"
                                defaultMessage="Valid Articles">
                            </FormattedMessage>}
                            component={RenderTextField}
                            type="text"/>

                        <Field
                            name="couponDetails.validArticleCategory"
                            hintText={<FormattedMessage
                                id="validArticleCategory"
                                defaultMessage="Valid Article Category">
                            </FormattedMessage>}
                            component={RenderTextField}
                            type="text"/>

                        <Field
                            name="couponDetails.claimedBy"
                            hintText={<FormattedMessage
                                id="claimedBy"
                                defaultMessage="Claimed By">
                            </FormattedMessage>}
                            component={RenderTextField}
                            type="text"/>

                        <Field
                            name="couponDetails.minimumSpend"
                            hintText={<FormattedMessage
                                id="minimumSpend"
                                defaultMessage="Minimum Spend">
                            </FormattedMessage>}
                            component={RenderTextField}
                            type="number"/>

                        <Field
                            name="couponDetails.minimumItems"
                            hintText={<FormattedMessage
                                id="minimumItems"
                                defaultMessage="Minimum Items">
                            </FormattedMessage>}
                            component={RenderTextField}
                            type="number"/>

                        <Button
                            label={<FormattedMessage
                                id="previous"
                                defaultMessage="Previous"/>}
                            color="secondary"
                            onClick={previousPage}/>

                        <Button
                            label={<FormattedMessage
                                id="next"
                                defaultMessage="Next"/>}
                            type="submit"/>

                        <RequiredInfo/>
                    </form>
                </Paper>
            </Container>
        );
    }
}

export default reduxForm({
    form: "campaignForm",
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(CampaignFormPage4);
