import React from "react";
import {Field, FieldArray, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Save from '@material-ui/icons/Save';

import RenderTextField from "../../../../common/components/common/RenderTextField";
import Button from "../../../../common/components/common/Button";
import {required, RequiredInfo} from "../../../../common/components/common/form/FormUtils";
import OpeningHoursFields from "../../../../common/components/common/form/OpeningHoursFields";
import RenderDropzone from "../../../../common/components/common/form/ReactDropzone";
import {LOGO_FIELD_NAME} from "./chains/CommercialChain";

import {
    emailValidation,
    locationPhoneValidation,
    smsSenderNameValidation,
    subdomainValidation
} from "../../../../common/utils/karming-validators";
import {LANGUAGES, SWEDISH} from "../../../../common/utils/enums/languages";
import RenderSelect from "../../../../common/components/common/RenderSelect";
import MenuItem from "@material-ui/core/MenuItem";

const validate = values => {
    return Object.assign(
        emailValidation({name: "email", value: values.email}),
        locationPhoneValidation({name: "phone", value: values.phone}),
        subdomainValidation({name: "subdomain", value: values.subdomain}),
        smsSenderNameValidation({name: "smsSenderName", value: values.smsSenderName})
    )
};

const SUBDOMAIN_FIELD_NAME = 'subdomain';

const styles = theme => ({
    subtitle: {
        marginTop: theme.spacing(2)
    },
    websiteWrapper: {
        position: 'relative',
        display: 'flex',
        "& > :first-child": {
            width: '50%',
            marginRight: theme.spacing(1),
        },
        "& > :last-child": {
            marginTop: theme.spacing(4.8)
        }
    },
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1),
        }
    },
    imageDropzone: {
        verticalAlign: 'center',
        [theme.breakpoints.up("md")]: {
            width: '50%',
            margin: '0 auto'
        }
    },
});

class SinglePosChainForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        submitting: PropTypes.bool,
        change: PropTypes.func,
        handleSubmit: PropTypes.func
    };

    render() {
        const {classes, handleSubmit, submitting} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="h5">
                    <FormattedMessage
                        id="pointOfSale"
                        defaultMessage="Point of Sale"/>
                </Typography>


                <Field
                    name="commercialName"
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="commercialName"
                                defaultMessage="Commercial name"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    component={RenderTextField}
                    type="text"
                    onChange={(field, value) => {
                        this.props.change(SUBDOMAIN_FIELD_NAME, value.toLowerCase().replace(/[^A-Z0-9]/ig, ""))
                    }}
                    validate={[required]}/>

                <div className={classes.websiteWrapper}>
                    <Field
                        name="subdomain"
                        component={RenderTextField}
                        type="text"
                        hintText={
                            <React.Fragment>
                                <FormattedMessage
                                    id={SUBDOMAIN_FIELD_NAME}
                                    defaultMessage="Subdomain"/>
                                &nbsp;*
                            </React.Fragment>
                        }
                        validate={[required]}/>
                    <Typography variant="body1">
                        .kundklubb.online
                    </Typography>
                </div>

                <Typography
                    variant="subtitle1"
                    className={classes.subtitle}
                    component="div">
                    <FormattedMessage
                        id="logo"
                        defaultMessage="Logo"/>
                </Typography>

                <div className={classes.imageDropzone}>
                    <Field
                        name={LOGO_FIELD_NAME}
                        component={RenderDropzone}
                        type="file"
                        validate={[required]}/>
                </div>

                <Field
                    name="street"
                    component={RenderTextField}
                    type="text"
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="address"
                                defaultMessage="Address"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    validate={[required]}/>

                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        name="city"
                        component={RenderTextField}
                        type="text"
                        hintText={
                            <React.Fragment>
                                <FormattedMessage
                                    id="city"
                                    defaultMessage={`City`}/>
                                &nbsp;*
                            </React.Fragment>
                        }
                        validate={[required]}/>

                    <Field
                        name="zipCode"
                        component={RenderTextField}
                        type="text"
                        hintText={<React.Fragment>
                            <FormattedMessage
                                id="zipCode"
                                defaultMessage={`Zip code`}/>
                            &nbsp;*
                        </React.Fragment>
                        }
                        validate={[required]}/>
                </div>

                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        name="email"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="email"
                            defaultMessage="Email"/>}/>

                    <Field
                        name="phone"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="phone"
                            defaultMessage="Phone"/>}/>
                </div>


                <Field
                    name="smsSenderName"
                    component={RenderTextField}
                    type="text"
                    helperText={
                        <React.Fragment>
                            <FormattedMessage
                                id="smsSenderNameHint"
                                defaultMessage={`3-11 characters (only a-z, A-Z, 0-9, no spaces)`}/>
                        </React.Fragment>
                    }
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="smsSenderName"
                                defaultMessage="SMS sender name"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    validate={[required]}/>

                <Field
                    name="website"
                    component={RenderTextField}
                    type="text"
                    hintText={<FormattedMessage
                        id="website"
                        defaultMessage="Website"/>}/>

                <Field
                    name="language"
                    label={<FormattedMessage
                        id="defaultMemberLanguage"
                        defaultMessage="Default member language:"/>}
                    component={RenderSelect}
                    defaultValue={SWEDISH}
                >
                    {LANGUAGES.map((option, index) => (
                        <MenuItem key={index}
                                  value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))
                    }
                </Field>

                <Typography
                    variant="subtitle1"
                    className={classes.subtitle}>
                    <FormattedMessage
                        id="openingHours"
                        defaultMessage="Opening hours"/>
                </Typography>

                <FieldArray name="openingHours" component={OpeningHoursFields}/>

                <Button
                    icon={<Save/>}
                    label={<FormattedMessage
                        id="save"
                        defaultMessage="Save"/>}
                    type="submit"
                    disabled={submitting}/>

                <RequiredInfo/>
            </form>
        );
    }
}

export default reduxForm({
    form: 'pointOfSaleChainForm',
    validate
})(withStyles(styles)(SinglePosChainForm))
