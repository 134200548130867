import axios from "axios";
import {
    FETCH_COMMERCIAL_CHAIN_PURCHASES,
    REGISTER_PURCHASE,
    REMOVE_PURCHASE,
    UPDATE_PURCHASE
} from "../../common/actions/types";
import {apiError, apiSuccessMessage, headerConfig} from "../../common/actions/utils-actions";
import {ROOT_URL} from "../../common/config/karming-links";
import {startSubmit, stopSubmit} from "redux-form";
import {REGISTER_PURCHASE_FORM_NAME} from "../components/purchase/RegisterPurchaseForm";
import {UPDATE_PURCHASE_FORM_NAME} from "../components/purchase/UpdatePurchaseForm";

const ENDPOINT_URL = `${ROOT_URL}/merchant`;

export function registerPurchase(values, history) {
    return dispatch => {
        dispatch(startSubmit(REGISTER_PURCHASE_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/purchase/register`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: REGISTER_PURCHASE,
                    payload: response.data
                });
                history.push("/purchases");
                dispatch(apiSuccessMessage("Purchase registered"))
                dispatch(stopSubmit(REGISTER_PURCHASE_FORM_NAME));
            }).catch(response => {
            dispatch(stopSubmit(REGISTER_PURCHASE_FORM_NAME));
            dispatch(apiError('Unable to register purchase', response));
        });
    }
}

export function updatePurchase(values, history, purchaseId) {
    if (!purchaseId) {
        throw "updatePurchase: purchaseId Required";
    }

    return dispatch => {
        dispatch(startSubmit(UPDATE_PURCHASE_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/purchase/update/${purchaseId}`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: UPDATE_PURCHASE,
                    payload: response.data
                });
                history.push("/purchases");
                dispatch(apiSuccessMessage("Purchase updated"))
                dispatch(stopSubmit(UPDATE_PURCHASE_FORM_NAME));
            }).catch(response => {
            dispatch(stopSubmit(UPDATE_PURCHASE_FORM_NAME));
            dispatch(apiError('Unable to update purchase', response));
        });
    }
}

export function removePurchase(purchaseId, history) {
    return dispatch => {
        dispatch(startSubmit(REGISTER_PURCHASE_FORM_NAME));
        axios.get(`${ENDPOINT_URL}/purchase/remove/${purchaseId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: REMOVE_PURCHASE,
                    payload: response.data
                });
                history.push("/purchases");
                dispatch(apiSuccessMessage("Purchase removed"))
                dispatch(stopSubmit(REGISTER_PURCHASE_FORM_NAME));
            }).catch(response => {
            dispatch(stopSubmit(REGISTER_PURCHASE_FORM_NAME));
            dispatch(apiError('Unable to remove purchase', response));
        });
    }
}

export function fetchCommercialChainPurchasesCounted(searchConfig) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/chain/purchases`, searchConfig, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_COMMERCIAL_CHAIN_PURCHASES,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch purchases', response));
            });
    }
}