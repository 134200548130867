import axios from "axios";
import {ROOT_URL} from "../../common/config/karming-links";
import {apiError, apiSuccessMessage, headerConfig, setStartGuideVisibility} from "../../common/actions/utils-actions";
import {
    CREATE_CAMPAIGN,
    FETCH_CAMPAIGN,
    FETCH_CAMPAIGNS,
    FETCH_CONSUMER_CAMPAIGNS,
    UPDATE_CAMPAIGN,
    UPDATE_CAMPAIGN_STATUS
} from "../../common/actions/types";
import {fetchCommercialChain} from "./merchant-chain-actions";
import {reset, startSubmit, stopSubmit} from "redux-form";
import {CAMPAIGN_FORM_NAME} from "../components/campaign/form/CampaignForm";
import {fetchUserDetails} from "../../common/actions/user-actions";

const ENDPOINT_URL = `${ROOT_URL}/merchant`;

export function createWelcomeOfferCampaign(chainId, values, history) {
    if (!chainId) {
        throw "createWelcomeOfferCampaign: chainId Required";
    }

    return dispatch => {
        dispatch(startSubmit(CAMPAIGN_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/${chainId}/campaign/create`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(CAMPAIGN_FORM_NAME));
                dispatch({
                    type: CREATE_CAMPAIGN,
                    payload: response.data
                });
                history.push("/attract");
                dispatch(apiSuccessMessage("Campaign created"))
                dispatch(reset('campaignForm'));
                dispatch(fetchCampaigns(localStorage.getItem('currentChainId'), false));
            }).catch(response => {
            dispatch(stopSubmit(CAMPAIGN_FORM_NAME));
            dispatch(apiError('Unable to create welcome offer campaign', response));
        });
    }
}

export function createCampaign(chainId, values, history) {
    if (!chainId) {
        throw "createCampaign: chainId Required";
    }

    return dispatch => {
        dispatch(startSubmit(CAMPAIGN_FORM_NAME))
        axios.post(`${ENDPOINT_URL}/${chainId}/campaign/create`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(CAMPAIGN_FORM_NAME));
                dispatch({
                    type: CREATE_CAMPAIGN,
                    payload: response.data
                });

                history.push("/campaigns/" + response.data);  // TODO ugly
                dispatch(apiSuccessMessage("Campaign created"))
                dispatch(reset('campaignForm'));
            }).catch(response => {
            dispatch(stopSubmit(CAMPAIGN_FORM_NAME));
            dispatch(apiError('Unable to create campaign', response));
        });
    }
}

export function updateCampaign(campaignId, values, fetchByCampaignId) {
    if (!campaignId) {
        throw "updateCampaign: campaignId Required";
    }

    return dispatch => {
        dispatch(startSubmit(CAMPAIGN_FORM_NAME))
        axios.post(`${ENDPOINT_URL}/campaigns/${campaignId}`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(CAMPAIGN_FORM_NAME));
                dispatch({
                    type: UPDATE_CAMPAIGN,
                    payload: response.data
                });
                dispatch(apiSuccessMessage("Campaign updated"))
                dispatch(reset('campaignForm'));
                if (fetchByCampaignId) {
                    dispatch(fetchCampaign(campaignId));    // TODO ugly
                } else {
                    dispatch(fetchCampaigns(localStorage.getItem('currentChainId'), false));
                }
            })
            .catch(response => {
                dispatch(stopSubmit(CAMPAIGN_FORM_NAME));
                dispatch(apiError('Unable to update campaign', response));
            });
    }
}

export function updateCampaignStatus(campaignId, publishStatus, fetchByCampaignId, history, pushToSendout,
                                     shouldPopUpStartGuide) {
    if (!campaignId) {
        throw "updateCampaign: campaignId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/campaigns/${campaignId}/${publishStatus}`, headerConfig())
            .then(response => {
                dispatch({
                    type: UPDATE_CAMPAIGN_STATUS,
                    payload: response.data
                });
                campaignsUpdatedForStartGuide(dispatch, localStorage.getItem('currentChainId'), shouldPopUpStartGuide) //TODO ugly
                dispatch(apiSuccessMessage(publishStatus === "ACTIVE" ? "Campaign published" : "Campaign deactivated"))
                dispatch(reset('campaignForm'));
                if (fetchByCampaignId) {
                    dispatch(fetchCampaign(campaignId));    // TODO ugly
                } else {
                    dispatch(fetchCampaigns(localStorage.getItem('currentChainId'), false));
                }
                if (publishStatus === "ACTIVE" && pushToSendout) {
                    history.push("/campaigns/" + campaignId + "/sendout");
                }
            })
            .catch(response => {
                dispatch(apiError('Unable to update campaign', response));
            });
    }
}

export function fetchCampaign(campaignId) {
    if (!campaignId) {
        throw "fetchCampaign: campaignId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/campaign/${campaignId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CAMPAIGN,
                    payload: response.data
                })
            }).catch(response => {
            dispatch(apiError('Unable to fetch campaign', response));
        });
    }
}

export function fetchCampaigns(chainId, dateAndActiveStatusValidation, allEverPublished) {
    if (!chainId) {
        throw "fetchCampaigns: chainId Required";
    }

    return dispatch => {
        axios.post(`${ENDPOINT_URL}/chain/${chainId}/campaigns`,
            {
                dateAndActiveStatusValidation: dateAndActiveStatusValidation,
                allEverPublished: allEverPublished
            },
            headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CAMPAIGNS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch campaigns', response));
            });
    }
}

export function fetchCampaignsForConsumer(consumerId, excludedPurchaseId, allEverPublishedCampaigns, purchaseId) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/campaigns/consumer`,
            {
                consumerId: consumerId,
                excludedPurchaseId: excludedPurchaseId,
                allEverPublishedCampaigns: allEverPublishedCampaigns,
                purchaseId: purchaseId
            },
            headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CONSUMER_CAMPAIGNS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch campaigns', response));
            });
    }
}

//FIXME if campaign is draft before --> do that action and setStartGuideVisibility(true)
function campaignsUpdatedForStartGuide(dispatch, chainId, shouldPopUpStartGuide) {
    dispatch(fetchCommercialChain(chainId));
    if (shouldPopUpStartGuide) {
        dispatch(fetchUserDetails())
        dispatch(setStartGuideVisibility(true));
    }
}