import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";

import RenderSelect from "../../../common/components/common/RenderSelect";
import RenderDatePicker, {atLeastOneDateRequired} from "../../../common/components/common/form/RenderDatePicker";
import Button from "../../../common/components/common/Button";

import {dateRangeNestedValidation} from "../../../common/utils/karming-validators";

export const MADE_PURCHASE = <FormattedMessage id="madePurchase" defaultMessage="Have made purchases"/>;
export const NOT_MADE_PURCHASE = <FormattedMessage id="notMadePurchase" defaultMessage="Have not made purchases"/>;

export const madePurchaseInDateRangeOption = {
    YES: {
        value: true,
        label: MADE_PURCHASE
    },
    NO: {
        value: false,
        label: NOT_MADE_PURCHASE
    }
};

const validate = values => {
    return dateRangeNestedValidation(
        {name: "purchaseDateFrom", value: values.dateFilter ? values.dateFilter.purchaseDateFrom : undefined},
        {name: "purchaseDateTo", value: values.dateFilter ? values.dateFilter.purchaseDateTo : undefined},
        "dateFilter"
    )
};

const styles = theme => ({
    subtitle: {
        marginTop: theme.spacing(1)
    }
});

class DatePurchaseFilterForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        purchaseDateFrom: PropTypes.object,
        purchaseDateTo: PropTypes.object,
        selectMembersInfo: PropTypes.object,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {classes, handleSubmit, purchaseDateFrom, purchaseDateTo, selectMembersInfo} = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage
                        id="purchaseDate"
                        defaultMessage="Purchase date"/>
                </Typography>

                <Typography
                    variant="body1"
                    component="div">
                    {selectMembersInfo}
                </Typography>

                <Field
                    name="dateFilter.purchaseInDateRange"
                    component={RenderSelect}
                    defaultValue={madePurchaseInDateRangeOption.YES.value}>
                    <MenuItem value={madePurchaseInDateRangeOption.YES.value}>
                        {madePurchaseInDateRangeOption.YES.label}
                    </MenuItem>

                    <MenuItem value={madePurchaseInDateRangeOption.NO.value}>
                        {madePurchaseInDateRangeOption.NO.label}
                    </MenuItem>
                </Field>

                <Field
                    name="dateFilter.purchaseDateFrom"
                    component={RenderDatePicker}
                    hintText={<FormattedMessage
                        id="after"
                        defaultMessage="After"/>}
                    validate={purchaseDateTo ? undefined : atLeastOneDateRequired}/>

                <Field
                    name="dateFilter.purchaseDateTo"
                    component={RenderDatePicker}
                    endOfDay={true}
                    hintText={<FormattedMessage
                        id="before"
                        defaultMessage="Before"/>}
                    validate={purchaseDateFrom ? undefined : atLeastOneDateRequired}/>

                <Typography
                    variant="body1"
                    className={classes.subtitle}>
                    <FormattedMessage
                        id="noteAnyAdditionalSelectionPart1"
                        defaultMessage="Note! Any additional selection of type"/>
                    &nbsp;
                    <i>
                        <FormattedMessage
                            id="sum"
                            defaultMessage="Sum"/>
                    </i>
                    ,
                    &nbsp;
                    <i>
                        <FormattedMessage
                            id="quantity"
                            defaultMessage="Quantity"/>
                    </i>
                    &nbsp;
                    <FormattedMessage
                        id="or"
                        defaultMessage="or"/>
                    &nbsp;
                    <i>
                        <FormattedMessage
                            id="taste"
                            defaultMessage="Taste"/>
                    </i>
                    &nbsp;
                    <FormattedMessage
                        id="noteAnyAdditionalSelectionPart2"
                        defaultMessage="will be validated within the same period as defined for purchase date."/>
                </Typography>

                <Button
                    icon={<Add/>}
                    label={<FormattedMessage
                        id="addSmartSelection"
                        defaultMessage="Add Smart Selection"/>}
                    type="submit"
                    disabled={!purchaseDateTo && !purchaseDateFrom}/>
            </form>
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('datePurchaseFilterForm');
    const purchaseDateFrom = selector(state, 'dateFilter.purchaseDateFrom');
    const purchaseDateTo = selector(state, 'dateFilter.purchaseDateTo');
    return {
        purchaseDateFrom,
        purchaseDateTo
    }
}

export default reduxForm({
    form: 'datePurchaseFilterForm',
    validate
})(connect(mapStateToProps, null)(withStyles(styles)(DatePurchaseFilterForm)))
