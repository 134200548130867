import React from "react";
import {FormattedMessage} from "react-intl";

import {createSelectRender} from "./enums";

const createBillingTypeSelectRender = (enumValue) => createSelectRender(enumValue, YES_NO_TYPE_RENDERS);

export const YES = true;
export const NO = false;

const YES_RENDER = <FormattedMessage id="yes" defaultMessage="Yes"/>;
const NO_RENDER = <FormattedMessage id="no" defaultMessage="No"/>;

export const YES_NO_TYPE_RENDERS = {
    [NO]: NO_RENDER,
    [YES]: YES_RENDER,
};

export const YES_NO_TYPES = [
    createBillingTypeSelectRender(NO),
    createBillingTypeSelectRender(YES),

];
