import React from "react";
import Dropzone from "react-dropzone";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';

import Button from "../Button";

import {setFormValue} from "../../../../merchant/actions/merchant-forms-actions";

export const required = value => (
    value ? undefined : 'You have to upload image first'
);

const styles = theme => ({
    root: {
        width: 'initial',
        padding: theme.spacing(1.5),
        height: 200,
        border: `2px dashed ${theme.palette.grey.main}`,
        borderRadius: theme.shape.borderRadius
    },
    preview: {
        marginTop: -218,
        marginBottom: 30,
        width: '95%',
        height: '210px !important'
    },
    acceptedImage: {
        border: `2px solid ${theme.palette.green.main}`
    },
    invalidImage: {
        border: `2px solid ${theme.palette.error.main}`
    },
    button: {
        marginTop: -theme.spacing(1),
    }
});

class RenderDropzone extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            imageFile: '',
        };
    }

    onDrop(imageFile) {
        this.setState({
            imageFile: imageFile
        });
        this.props.setFormValue(this.props.meta.form, this.props.input.name, imageFile)
    }

    removeFile() {
        this.setState({
            imageFile: ''
        });
    }

    componentWillUnmount() {
        if (this.state.imageFile && this.state.imageFile.preview) {
            window.URL.revokeObjectURL(this.state.imageFile.preview);
        }
    }

    render() {
        const {classes, input, hasImage, meta: {touched, error, warning}} = this.props;

        return (
            <div>
                <Dropzone
                    {...input}
                    accept="image/jpeg, image/png"
                    className={classes.root}
                    rejectClassName={classes.invalidImage}
                    acceptClassName={classes.acceptedImage}
                    multiple={false}
                    onDrop={this.onDrop.bind(this)}>
                    {({isDragActive, isDragReject}) => {
                        if (isDragReject) {
                            return (
                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="thisFillWillBe"
                                        defaultMessage="This file will be"/>
                                    &nbsp;
                                    <FormattedMessage
                                        id="rejected"
                                        defaultMessage="rejected"/>
                                </Typography>
                            )
                        }
                        if (isDragActive) {
                            return (
                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="thisFillWillBe"
                                        defaultMessage="This file will be"/>
                                    &nbsp;
                                    <FormattedMessage
                                        id="accepted"
                                        defaultMessage="accepted"/>
                                </Typography>
                            )
                        }
                        if (!hasImage
                            && (!this.state.imageFile
                                || (this.state.imageFile
                                    && this.state.imageFile.length === 0))) {
                            return (
                                <React.Fragment>
                                    <Typography
                                        paragraph
                                        variant="body1">
                                        <FormattedMessage
                                            id="clickToUploadOwnImageText"
                                            defaultMessage="Click to upload your own image."/>
                                    </Typography>

                                    <Typography
                                        paragraph
                                        variant="body1">
                                        <FormattedMessage
                                            id="youCanAlsoDragAndDropYourOwnImageText"
                                            defaultMessage="You can also drag and drop your own image here."/>
                                    </Typography>
                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="supportedFileFormat"
                                            defaultMessage="Supported file format"/>: <i>jpg</i>, <i>png</i>.
                                    </Typography>
                                </React.Fragment>
                            )
                        }
                        return "";
                    }}
                </Dropzone>

                {this.state.imageFile && this.state.imageFile.length > 0
                    ? (
                        <div>
                            <div>
                                {this.state.imageFile.map((file) => (
                                        <img
                                            className={classes.preview}
                                            key={file} src={file.preview}/>
                                    )
                                )}
                            </div>
                            <Button
                                icon={<DeleteOutlined/>}
                                label={<FormattedMessage
                                    id="removeFile"
                                    defaultMessage="Remove file"/>}
                                customColor="red"
                                className={classes.button}
                                onClick={this.removeFile.bind(this)}/>
                        </div>
                    )
                    : null
                }
                {touched && (
                    error && (
                        <Typography
                            color="error"
                            align="left">
                            {error}
                        </Typography>
                    )
                    || (warning && <span>{warning}</span>)
                )}
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(RenderDropzone));
