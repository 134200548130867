const JANUARY = {key: "Januari", value: 1};
const FEBRUARY = {key: "Februari", value: 2};
const MARCH = {key: "Mars", value: 3};
const APRIL = {key: "April", value: 4};
const MAY = {key: "Maj", value: 5};
const JUNE = {key: "Juni", value: 6};
const JULY = {key: "Juli", value: 7};
const AUGUST = {key: "Augusti", value: 8};
const SEPTEMBER = {key: "September", value: 9};
const OCTOBER = {key: "Oktober", value: 10};
const NOVEMBER = {key: "November", value: 11};
const DECEMBER = {key: "December", value: 12};

export const months = [JANUARY, FEBRUARY, MARCH, APRIL, MAY, JUNE, JULY, AUGUST, SEPTEMBER, OCTOBER, NOVEMBER, DECEMBER];
