import React from "react";
import {reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import Dialog from './../../../../common/components/common/Dialog'

import {KARMING_TERMS_OF_SERVICE_ADDRESS} from "../../../../common/config/karming-links";

class SmsNumberForm extends React.Component {

    static propTypes = {
        activateSmsService: PropTypes.func,
    };

    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleConfirm = () => {
        this.setState({open: false});
        this.props.activateSmsService();
    };

    handleCancel = () => {
        this.setState({open: false});
    };

    render() {
        const actions = [
            <Button
                key="cancel"
                label={<FormattedMessage
                    id="cancel"
                    defaultMessage="Cancel"/>}
                color="secondary"
                onClick={this.handleCancel}/>,
            <Button
                key="confirm"
                label={<FormattedMessage
                    id="confirm"
                    defaultMessage="Confirm"/>}
                onClick={this.handleConfirm}/>
        ];

        return (
            <React.Fragment>
                <Button
                    label={<FormattedMessage
                        id="activateSmsService"
                        defaultMessage="Activate SMS service"/>}
                    onClick={this.handleOpen}/>

                <Dialog
                    onClose={this.handleCancel}
                    title={<FormattedMessage
                        id="activateConfirmation"
                        defaultMessage="Activate Confirmation"/>}
                    actions={actions}
                    open={this.state.open}
                    content={
                        <div>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="smsActivationText"
                                    defaultMessage="I confirm activation of SMS the service and that I read, understand and accept"/>
                                &nbsp;
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={KARMING_TERMS_OF_SERVICE_ADDRESS}>
                                    <FormattedMessage
                                        id="termsAndConditions"
                                        defaultMessage="Terms and Conditions"/>.
                                </a>
                            </Typography>
                        </div>
                    }/>
            </React.Fragment>
        );
    }

}

export default reduxForm({
    form: 'registerNumberForm'
})(SmsNumberForm)
