import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import BannerBuilder, {bannerRequired} from "../../../banner/BannerBuilder";

class StampcardCouponFormPage2 extends React.Component {

    static propTypes = {
        coupon: PropTypes.object,
        stampcard: PropTypes.object,
        initialValues: PropTypes.object,
        stampsAmount: PropTypes.number,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func
    };

    render() {
        const {handleSubmit, previousPage, initialValues, coupon: {reward, hideTitle}, stampcard: {imagePath, title, subtitle}} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="rewardCoupon"
                        defaultMessage="Stamp card coupon"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="chooseBackgroundForRewardCoupon"
                        defaultMessage="Choose a background for the reward coupon"/>
                </Typography>

                <Field
                    name="image"
                    stampcardCoupon={true}
                    title={title}
                    reward={reward}
                    hideTitle={hideTitle}
                    subtitle={subtitle}
                    component={BannerBuilder}
                    isStampcardCoupon={true}
                    currentImageFile={this.getBannerPath(initialValues)}
                    type="blob"
                    onNext={handleSubmit}
                    onPrevious={previousPage}
                    isUpdate={imagePath}
                    validate={[bannerRequired]}/>
            </form>
        );
    }

    getBannerPath(initialValues) {
        if (initialValues) {
            return initialValues.imagePath;
        }
    }

}

export default reduxForm({
    form: "stampcardCouponForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StampcardCouponFormPage2)
