import React from "react";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

export default class MerchantSupport extends React.Component {

    render() {
        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <Typography variant="h5">
                        <FormattedMessage
                            id="support"
                            defaultMessage="Support"/>
                    </Typography>

                    <img width="80%" src="/static/images/support-animation.svg"/>
                    <Typography variant="body1">
                        <Link href="mailto:support@karming.se">support@karming.se</Link>
                    </Typography>
                </Paper>
            </Container>
        )
    }

}
