import React from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import RenderSelect from "../../../common/components/common/RenderSelect";
import RenderSlider from "../../../common/components/common/RenderSlider";
import {minMaxFieldsValidation} from "../../../common/utils/karming-validators";

export const MADE_PURCHASE = (
    <FormattedMessage
        id="haveMadeBelowNumberOfPurchases"
        defaultMessage="Have made below number of purchases"/>
);
export const NOT_MADE_PURCHASE = (
    <FormattedMessage
        id="haveNotMadeBelowNumberOfPurchases"
        defaultMessage="Have not made below number of purchases"/>
);

export const madePurchaseOption = {
    YES: {
        value: true,
        label: MADE_PURCHASE
    },
    NO: {
        value: false,
        label: NOT_MADE_PURCHASE
    }
};

const MIN_QUANTITY = 0;

const validate = (values, props) => {
    return minMaxFieldsValidation(
        'quantityFilter',
        true,
        {
            name: "purchaseQuantityFrom",
            value: values.quantityFilter ? values.quantityFilter.purchaseQuantityFrom : MIN_QUANTITY,
            min: MIN_QUANTITY
        },
        {
            name: "purchaseQuantityTo",
            value: values.quantityFilter ? values.quantityFilter.purchaseQuantityTo : props.chain.maxPurchaseQuantity,
            max: props.chain.maxPurchaseQuantity
        }
    )
};

class PurchaseQuantityFilterForm extends React.Component {

    static propTypes = {
        chain: PropTypes.object,
        button: PropTypes.object,
        selectMembersInfo: PropTypes.object,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    render() {
        const {handleSubmit, button, chain, selectMembersInfo} = this.props;

        const maxPurchaseQuantity = chain.maxPurchaseQuantity;

        return (
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage
                        id="numberOfPurchases"
                        defaultMessage="Number of purchases"/>
                </Typography>
                
                <Typography
                    variant="body1"
                    component="div">
                    {selectMembersInfo}
                </Typography>

                <Field
                    name="quantityFilter.madePurchases"
                    component={RenderSelect}
                    defaultValue={madePurchaseOption.YES.value}>
                    <MenuItem value={madePurchaseOption.YES.value}>
                        {madePurchaseOption.YES.label}
                    </MenuItem>
                </Field>

                <Field
                    name="purchaseQuantity"
                    component={RenderSlider}
                    steps={1}
                    minFieldName="quantityFilter.purchaseQuantityFrom"
                    maxFieldName="quantityFilter.purchaseQuantityTo"
                    min={MIN_QUANTITY}
                    max={maxPurchaseQuantity}
                    defaultValue={[MIN_QUANTITY, maxPurchaseQuantity]}/>

                {button}
            </form>
        );
    }

}

export default reduxForm({
    form: 'purchaseQuantityFilterForm',
    touchOnChange: true,
    validate
})(PurchaseQuantityFilterForm)
