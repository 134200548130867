import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import RenderTextField from "../../../../../common/components/common/RenderTextField";
import Button from "../../../../../common/components/common/Button";

class StampcardCouponFormPage3 extends React.Component {

    static propTypes = {
        coupon: PropTypes.object,
        initialValues: PropTypes.object,
        handleSubmit: PropTypes.func
    };

    render() {
        const {handleSubmit, initialValues, coupon: {description}} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="rewardCoupon"
                        defaultMessage="Stamp card coupon"/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="provideADescriptionThatWillDisplayed"
                        defaultMessage={`Provide a description that will be displayed. For example any limitations etc.`}/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="youCanSkip"
                        defaultMessage="You can skip this as well as change later."/>
                </Typography>

                <Field
                    name="description"
                    hintText={<FormattedMessage
                        id="description"
                        defaultMessage="Description"/>}
                    component={RenderTextField}
                    type="text"
                    multiline={true}
                    rowsMax={7}/>

                <Button
                    type="submit"
                    label={
                        !initialValues.description && !description
                            ? <FormattedMessage
                                id="skip"
                                defaultMessage="Skip"/>
                            : <FormattedMessage
                                id="next"
                                defaultMessage="Next"/>
                    }/>
            </form>
        );
    }

}

export default reduxForm({
    form: "stampcardCouponForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StampcardCouponFormPage3)
