import React from 'react';
import PropTypes from "prop-types";

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {getUniqueObjectProperties} from "../../../../common/utils/data-utils";

const useStyles = makeStyles(theme => ({
    textRemoved: {
        textDecoration: 'line-through'
    },
    textGreen: {
        color: theme.palette.green.dark
    }
}));

export const CategoriesList = props => {
    const {purchase, array, index} = props;
    const classes = useStyles();

    const previousCategories = array[0] && array[index - 1]
        ? array[index - 1].categories
        : array[0];
    const addedCategories = array[index - 1] && purchase.categories.filter(currCategory =>
            previousCategories.every(prevCategory => prevCategory.id !== currCategory.id));
    const removedCategories = array[index - 1] && previousCategories && previousCategories.filter(currCategory =>
            purchase.categories.every(prevCategory => prevCategory.id !== currCategory.id));

    const recentCategories = previousCategories && [...previousCategories, ...purchase.categories];
    const categories = getUniqueObjectProperties(recentCategories);

    return (
        <React.Fragment>
            {categories && categories.length > 0 && categories.map((category, categoryIndex) => {
                    const isNewCategory = addedCategories && addedCategories.some(addedCategory =>
                        addedCategory.id === category.id);
                    const isRemovedCategory = removedCategories && removedCategories.some(removedCategory =>
                        removedCategory.id === category.id);
                    return (
                        <Typography
                            key={categoryIndex}
                            variant="subtitle2"
                            display="inline"
                            color={isRemovedCategory && 'error' || 'inherit'}
                            className={
                                isRemovedCategory && classes.textRemoved
                                || isNewCategory && classes.textGreen
                                || undefined
                            }>
                            {category && category.name}
                        </Typography>
                    )
                }).reduce((prev, curr) => [prev, ', ', curr])}
        </React.Fragment>
    );
};

CategoriesList.propTypes = {
    purchase: PropTypes.object.isRequired,
    array: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired
};
