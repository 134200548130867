import React from "react";
import {formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import CampaignFormPage1 from "./CampaignFormPage1";
import CampaignFormPage2 from "./CampaignFormPage2";
import CampaignFormPage3 from "./CampaignFormPage3";
import CampaignFormPage4 from "./CampaignFormPage4";
import CampaignFormPage5 from "./CampaignFormPage5";
import CampaignFormPage6 from "./CampaignFormPage6";

export const CAMPAIGN_FORM_NAME = 'campaignForm';

const CAMPAIGN_FORM_LAST_PAGE = 6;

class CampaignForm extends React.Component {

    static propTypes = {
        campaign: PropTypes.object,
        auth: PropTypes.object,
        history: PropTypes.object,
        initialValues: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        submitting: PropTypes.bool,
        isWelcomeOffer: PropTypes.bool,
        handleSubmit: PropTypes.func,
        handlePublish: PropTypes.func,
        handleCreateSendout: PropTypes.func,
        onSubmit: PropTypes.func,
        chain: PropTypes.object,
        handleDeactivate: PropTypes.func
    };

    static defaultProps = {
        title: "Campaign",
        isWelcomeOffer: false,
    };

    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.state = {
            page: (!this.props.initialValues || !this.props.initialValues.bannerPath ? 1 : CAMPAIGN_FORM_LAST_PAGE)
        }
    }

    firstPage() {
        this.setState({page: 1})
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
    }

    skipNextPage() {
        this.setState({page: this.state.page + 2})
    }

    previousPage() {
        this.setState({page: this.state.page - 1})
    }

    title() {
        if (this.props.isWelcomeOffer) {
            return (
                <FormattedMessage
                    id="welcomeOffering"
                    defaultMessage="Welcome offering"/>
            )
        }

        return (
            <FormattedMessage
                id="campaign"
                defaultMessage="Campaign"/>
        )
    }

    render() {
        const {chain, onSubmit, handlePublish, handleDeactivate, submitting, initialValues, isWelcomeOffer, campaign, auth, history, isUpdate} = this.props;
        const {page} = this.state;
        const title = this.title();

        return (
            <React.Fragment>
                {page === 1 && (
                    <CampaignFormPage1
                        title={title}
                        initialValues={initialValues}
                        isWelcomeOffer={isWelcomeOffer}
                        onSubmit={this.nextPage}
                    />
                )}
                {page === 2 && (
                    <CampaignFormPage2
                        title={title}
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        isWelcomeOffer={isWelcomeOffer}
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}
                    />
                )}
                {page === 3 && (
                    <CampaignFormPage3
                        title={title}
                        initialValues={initialValues}
                        campaign={campaign}
                        isWelcomeOffer={isWelcomeOffer}
                        previousPage={this.previousPage}
                        onSubmit={chain.ecrIntegrationEnabled
                            ? this.nextPage
                            : this.skipNextPage.bind(this)}
                    />
                )}
                {page === 4 && (
                    <CampaignFormPage4
                        title={title}
                        initialValues={initialValues}
                        campaign={campaign}
                        isWelcomeOffer={isWelcomeOffer}
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}
                    />
                )}
                {page === 5 && (
                    <CampaignFormPage5
                        title={title}
                        initialValues={initialValues}
                        isWelcomeOffer={isWelcomeOffer}
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}
                    />
                )}
                {page === 6 && (
                    <CampaignFormPage6
                        auth={auth}
                        title={title}
                        initialValues={initialValues}
                        isWelcomeOffer={isWelcomeOffer}
                        firstPage={this.firstPage.bind(this)}
                        handlePublish={handlePublish}
                        handleDeactivate={handleDeactivate}
                        campaign={campaign}
                        onSubmit={onSubmit}
                        history={history}
                        isUpdate={isUpdate}
                        submitting={submitting}
                    />
                )}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    const selector = formValueSelector('campaignForm');
    const {name, banner, description, startDate, endDate, actionButtonLabel, actionButtonTarget} = selector(state,
        'name', 'banner', 'description', 'startDate', 'endDate', 'actionButtonTarget', 'actionButtonLabel')

    return {
        isReady: state.form.campaignForm && !state.form.campaignForm.syncErrors,
        campaign: { //FIXME
            name,
            banner,
            description,
            startDate,
            endDate,
            actionButtonLabel,
            actionButtonTarget
        },
        chain: state.chains.current
    }
}

export default reduxForm({
    form: "campaignForm"
})(connect(mapStateToProps, null)(CampaignForm))
