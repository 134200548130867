import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import RenderTextField from "../../../common/components/common/RenderTextField";
import Button from "../../../common/components/common/Button";

class CouponFormPage5 extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        initialValues: PropTypes.object,
        description: PropTypes.string,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func,
    };

    render() {
        const {handleSubmit, title, description, previousPage, initialValues} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {title}
                </Typography>

                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="provideADescriptionThatWillDisplayed"
                        defaultMessage="Provide a description that will be displayed. For example any limitations etc."/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="youCanSkip"
                        defaultMessage="You can skip this as well as change later."/>
                </Typography>

                <Field
                    name="description"
                    hintText={<FormattedMessage
                        id="description"
                        defaultMessage="Description">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="text"
                    multiline
                    rowsMax={7}/>

                <Button
                    label={<FormattedMessage
                        id="previous"
                        defaultMessage="Previous"/>}
                    color={'secondary'}
                    onClick={previousPage}/>

                <Button
                    label={!initialValues && !description
                        ? <FormattedMessage
                            id="skip"
                            defaultMessage="Skip"/>
                        : <FormattedMessage
                            id="next"
                            defaultMessage="Next"/>}
                    type="submit"/>
            </form>
        );
    }

}
function mapStateToProps(state) {
    const selector = formValueSelector('couponForm');
    const description = selector(state, 'description');
    return {
        description,
    }
}

export default reduxForm({
    form: "couponForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connect(mapStateToProps, null)(CouponFormPage5))
