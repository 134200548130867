import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import RenderTextField from "../../../common/components/common/RenderTextField";
import {required} from "../../../common/components/common/form/FormUtils";
import Button from "../../../common/components/common/Button";

import {setFormValue} from "../../actions/merchant-forms-actions";

const styles = {
    button: {
        width: '30%'
    },
    field: {
        fontWeight: 'bold'
    }
};

class ClubPointsFormPage4 extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        title: PropTypes.object,
        submitting: PropTypes.bool,
        isTemplate: PropTypes.bool,
        generateTemplate: PropTypes.func,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func,
        untouch: PropTypes.func,
        clearFields: PropTypes.func
    };

    render() {
        const {classes, submitting, handleSubmit, title, previousPage, isTemplate, generateTemplate} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {title}
                </Typography>

                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="termsForPoints"
                        defaultMessage="Terms for points"/>
                </Typography>

                <Field
                    name="termsTitle"
                    type="text"
                    component={RenderTextField}
                    hintText={<FormattedMessage
                        id="paragraphTitle"
                        defaultMessage="Paragraph title"/>}
                    className={classes.field}
                    validate={required}/>

                <Field
                    name="termsContent"
                    type="text"
                    component={RenderTextField}
                    hintText={<FormattedMessage
                        id="paragraphContent"
                        defaultMessage="Paragraph content"/>}
                    multiline={true}
                    validate={required}/>

                <Button
                    color='secondary'
                    label={<FormattedMessage
                        id="previous"
                        defaultMessage="Previous"/>}
                    onClick={this.resetTermsAndMoveToPreviousPage.bind(this, previousPage)}
                    className={classes.button}/>

                <Button
                    label={isTemplate
                        ? <FormattedMessage
                            id="reset"
                            defaultMessage="Reset"/>
                        : <FormattedMessage
                            id="clear"
                            defaultMessage="Clear"/>}
                    onClick={isTemplate
                        ? this.resetTerms.bind(this, generateTemplate)
                        : this.clearTerms.bind(this)}
                    color="secondary"
                    variant="outlined"/>

                <Button
                    label={<FormattedMessage
                        id="save"
                        defaultMessage="Save"/>}
                    type="submit"
                    disabled={submitting}
                    className={classes.button}/>
            </form>
        );
    }

    resetTermsAndMoveToPreviousPage(previousPage) {
        this.clearTerms();
        previousPage()
    }

    resetTerms(generateTemplate) {
        generateTemplate();
        this.props.untouch("clubPointsForm", "termsTitle", "termsContent");
    }

    clearTerms() {
        this.props.clearFields("clubPointsForm", false, false, "termsTitle", "termsContent");
        this.props.untouch("clubPointsForm", "termsTitle", "termsContent");
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default reduxForm({
    form: "clubPointsForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(connect(null, mapDispatchToProps)(withStyles(styles)(ClubPointsFormPage4)))
