import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import RenderTextField from "../../../../../common/components/common/RenderTextField";
import Button from "../../../../../common/components/common/Button";

class StampcardCouponFormPage4 extends React.Component {

    static propTypes = {
        coupon: PropTypes.object,
        initialValues: PropTypes.object,
        handleSubmit: PropTypes.func
    };

    render() {
        const {handleSubmit, initialValues, coupon: {description}} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="rewardCoupon"
                        defaultMessage="Stamp card coupon"/>
                </Typography>

                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="provideEcrIdForCoupon"
                        defaultMessage="Provide a EcrId for this coupon - so this coupon could be used with ECR automatic purchases"/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="youCanSkip"
                        defaultMessage="You can skip this as well as change later."/>
                </Typography>

                <Field
                    name="stampcardCouponEcrId"
                    hintText={<FormattedMessage
                        id="stampcardCouponEcrId"
                        defaultMessage="StampCard Coupon ECR ID"/>}
                    component={RenderTextField}
                    type="text"/>

                <Field
                    name="couponDetails.valueType"
                    hintText={<FormattedMessage
                        id="valueType"
                        defaultMessage="Value Type">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="text"/>

                <Field
                    name="couponDetails.cashierDescription"
                    hintText={<FormattedMessage
                        id="cashierDescription"
                        defaultMessage="Cashier Descriptions">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="text"/>

                <Field
                    name="couponDetails.value"
                    hintText={<FormattedMessage
                        id="value"
                        defaultMessage="Value">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="text"/>

                <Field
                    name="couponDetails.validArticles"
                    hintText={<FormattedMessage
                        id="validArticles"
                        defaultMessage="Valid Articles">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="text"/>

                <Field
                    name="couponDetails.validArticleCategory"
                    hintText={<FormattedMessage
                        id="validArticleCategory"
                        defaultMessage="Valid Article Category">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="text"/>

                <Field
                    name="couponDetails.claimedBy"
                    hintText={<FormattedMessage
                        id="claimedBy"
                        defaultMessage="Claimed By">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="text"/>

                <Field
                    name="couponDetails.minimumSpend"
                    hintText={<FormattedMessage
                        id="minimumSpend"
                        defaultMessage="Minimum Spend">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="number"/>

                <Field
                    name="couponDetails.minimumItems"
                    hintText={<FormattedMessage
                        id="minimumItems"
                        defaultMessage="Minimum Items">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="number"/>

                <Button
                    type="submit"
                    label={
                        !initialValues.description && !description
                            ? <FormattedMessage
                                id="skip"
                                defaultMessage="Skip"/>
                            : <FormattedMessage
                                id="next"
                                defaultMessage="Next"/>
                    }/>
            </form>
        );
    }

}

export default reduxForm({
    form: "stampcardCouponForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StampcardCouponFormPage4)
