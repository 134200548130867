import React from "react";
import {FormattedMessage} from "react-intl";
import {formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import ClubPointsFormPage1 from "./ClubPointsFormPage1";
import ClubPointsFormPage2 from "./ClubPointsFormPage2";
import ClubPointsFormPage3 from "./ClubPointsFormPage3";
import ClubPointsFormPage4 from "./ClubPointsFormPage4";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {getAppConsumerUrlWithoutProtocol} from "../../../common/config/karming-links";

import {createClubPoints, fetchClubPoints, updateClubPoints} from "../../actions/merchant-points-actions";
import {setFormValue} from "../../actions/merchant-forms-actions";

export const CLUB_POINTS_FORM_NAME = 'clubPointsForm';

class ClubPointsForm extends React.Component {

    static propTypes = {
        chain: PropTypes.object,
        initialValues: PropTypes.object,
        history: PropTypes.object,
        title: PropTypes.object,
        monthValidation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        submitting: PropTypes.bool,
        updateClubPoints: PropTypes.func,
        createClubPoints: PropTypes.func,
        setFormValue: PropTypes.func
    };

    static defaultProps = {
        title: <FormattedMessage
            id="clubPoints"
            defaultMessage="Club Points"/>
    };


    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.state = {
            page: 1,
            isTemplate: false,
        }
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
    }

    previousPage() {
        this.setState({page: this.state.page - 1})
    }

    setIsTemplate(isTemplate) {
        this.setState({isTemplate: isTemplate})
    }

    onSubmit(chain, history, isUpdate, clubPointsId, values) {
        if (isUpdate) {
            this.props.updateClubPoints(clubPointsId, history, values);
        } else {
            this.props.createClubPoints(chain.id, values, history);
        }
    }

    render() {

        const {chain, isUpdate, submitting, initialValues, history, title, monthValidation} = this.props;

        let description = '';
        if (!isUpdate && monthValidation) {
            description = "Alla dina köp hos oss ger dig poäng.\n"
                + "1 SEK = 1 poäng\n"
                + "Dina poäng löser du in mot värdekuponger.\n"
                + `Intjänade poäng är giltiga i ${monthValidation} månader.`
        }

        const onSubmit = this.onSubmit.bind(this, chain, history, isUpdate, isUpdate ? initialValues.id : '');

        const {page} = this.state;
        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    {page === 1 && (
                        <ClubPointsFormPage1
                            title={title}
                            chain={chain}
                            onSubmit={this.nextPage}/>
                    )}
                    {page === 2 && (
                        <ClubPointsFormPage2
                            title={title}
                            previousPage={this.previousPage}
                            chain={chain}
                            isUpdate={isUpdate}
                            initialValues={isUpdate ?
                                initialValues
                                : {
                                    ...initialValues,
                                    'description': description,
                                    'monthValidation': monthValidation
                                }}
                            onSubmit={isUpdate ? onSubmit : this.nextPage}/>
                    )}
                    {page === 3 && (
                        <ClubPointsFormPage3
                            title={title}
                            previousPage={this.previousPage}
                            nextPage={this.nextPage}
                            setIsTemplate={this.setIsTemplate.bind(this)}
                            generateTemplate={this.generateTemplate.bind(this)}
                            onSubmit={onSubmit}
                            submitting={submitting}/>
                    )}

                    {page === 4 && (
                        <ClubPointsFormPage4
                            title={title}
                            previousPage={this.previousPage}
                            generateTemplate={this.generateTemplate.bind(this)}
                            isTemplate={this.state.isTemplate}
                            onSubmit={onSubmit}
                            submitting={submitting}/>
                    )}
                </Paper>
            </Container>
        )
    }

    generateTemplate() {
        const chain = this.props.chain;
        const chainUrl = getAppConsumerUrlWithoutProtocol(chain.subdomain);
        const monthValidation = this.props.monthValidation;
        this.props.setFormValue("clubPointsForm", "termsTitle", "Poäng");
        this.props.setFormValue("clubPointsForm", "termsContent", `Samtliga köp hos ${chain.commercialName} ger poäng, förutom köp av presentkort och köp betalade med eventuell värdekupong som tilldelats till följd av insamlade poäng.\n1 kr ger 1 poäng.\n\nInsamlade poäng ligger till grund för förmåner som erbjudanden, rabatter eller värdekuponger som presenteras på ${chainUrl} eller vår hemsida. Insamlade poäng är giltiga i ${monthValidation} månader.\n\nGiltigt medlemsnummer skall uppges och köpet skall registreras som ett medlemsköp i samband med betalning för att generera poäng.\n\nDe poäng du har erhållit vid köp dras från ditt konto om du returnerar köpet.\n\nFelaktigt registrerade poäng har rätt att återkallas likväl om eventuella förmåner som tillhandahållits till följd av felaktigt registrerade poäng.\n\nPoängen som du har tjänat in är personlig och kan inte överföras på en annan person eller medlem.\n\nReglerna och villkor för poäng kan komma att förändras, vid var tid gällande regler och villkor redovisas på ${chainUrl} eller vår hemsida.`
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('clubPointsForm');
    const monthValidation = selector(state, 'monthValidation');
    const termsTitle = selector(state, 'termsTitle)');
    return {
        monthValidation,
        termsTitle,
        clubPoints: state.points.clubPoints
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createClubPoints,
        updateClubPoints,
        fetchClubPoints,
        setFormValue
    }, dispatch);
}

export default reduxForm({
    form: "clubPointsForm"
})(connect(mapStateToProps, mapDispatchToProps)(ClubPointsForm))
