import axios from "axios";
import {ROOT_URL} from "../../common/config/karming-links";
import {
    ACTIVATE_CLUB_POINTS,
    CREATE_CLUB_POINTS,
    CREATE_COUPON,
    DEACTIVATE_CLUB_POINTS,
    FETCH_CLUB_POINTS,
    FETCH_COUPON,
    FETCH_COUPONS,
    UPDATE_CLUB_POINTS,
    UPDATE_COUPON,
    UPDATE_COUPON_STATUS
} from "../../common/actions/types";
import {apiError, apiSuccessMessage, headerConfig} from "../../common/actions/utils-actions";
import {reset, startSubmit, stopSubmit} from "redux-form";
import {CLUB_POINTS_FORM_NAME} from "../components/points/ClubPointsForm";
import {COUPON_FORM_NAME} from "../components/points/CouponForm";

const ENDPOINT_URL = `${ROOT_URL}/merchant/points`;

export function createClubPoints(chainId, values, history) {
    return dispatch => {
        dispatch(startSubmit(CLUB_POINTS_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/create/${chainId}`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(CLUB_POINTS_FORM_NAME));
                dispatch({
                    type: CREATE_CLUB_POINTS,
                    payload: response.data
                });

                // Is necessary?????
                dispatch(reset('clubPointsForm'));
                history.push('/points');
            }).catch(response => {
            dispatch(stopSubmit(CLUB_POINTS_FORM_NAME));
            dispatch(apiError('Unable to create Club Points', response));
        });
    }
}

export function updateClubPoints(clubPointsId, history, values) {
    return dispatch => {
        dispatch(startSubmit(CLUB_POINTS_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/update/${clubPointsId}`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(CLUB_POINTS_FORM_NAME));
                dispatch({
                    type: UPDATE_CLUB_POINTS,
                    payload: response.data
                });

                history.push('/points');
                dispatch(reset('clubPointsForm'));
            }).catch(response => {
            dispatch(stopSubmit(CLUB_POINTS_FORM_NAME));
            dispatch(apiError('Unable to update Club Points', response));
        });
    }
}

export function fetchClubPoints(chainId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/${chainId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CLUB_POINTS,
                    payload: response.data
                });

            }).catch(response => {
            dispatch(apiError('Unable to fetch Club points', response));
        });
    }
}

export function activateClubPointsActivation(clubPointsId, chainId, values) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/activate/${clubPointsId}`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: ACTIVATE_CLUB_POINTS,
                    payload: response.data
                });
                dispatch(fetchClubPoints(chainId));
            }).catch(response => {
            dispatch(apiError('Unable to activate Club points', response));
        });
    }
}

export function deactivateClubPointsActivation(clubPointsId, chainId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/deactivate/${clubPointsId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: DEACTIVATE_CLUB_POINTS,
                    payload: response.data
                });
                dispatch(fetchClubPoints(chainId));
            }).catch(response => {
            dispatch(apiError('Unable to deactivate Club points', response));
        });
    }
}

export function createCoupon(chainId, values, history) {

    if (!chainId) {
        throw "createCoupon: chainId Required";
    }

    return dispatch => {
        dispatch(startSubmit(COUPON_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/coupons/create/${chainId}`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(COUPON_FORM_NAME));
                dispatch({
                    type: CREATE_COUPON,
                    payload: response.data
                });

                history.push("/coupons/" + response.data);
                dispatch(apiSuccessMessage("Coupon created"))
                dispatch(reset(COUPON_FORM_NAME));
            }).catch(response => {
            dispatch(stopSubmit(COUPON_FORM_NAME));
            dispatch(apiError('Unable to create coupon', response));
        });
    }
}

export function updateCoupon(couponId, values) {
    if (!couponId) {
        throw "updateCoupon: couponId Required";
    }

    return dispatch => {
        dispatch(startSubmit(COUPON_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/coupons/update/${couponId}`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(COUPON_FORM_NAME));
                dispatch({
                    type: UPDATE_COUPON,
                    payload: response.data
                });
                dispatch(apiSuccessMessage("Coupon updated"));
                dispatch(reset("couponForm"));
                dispatch(fetchCoupon(couponId));
            })
            .catch(response => {
                dispatch(stopSubmit(COUPON_FORM_NAME));
                dispatch(apiError('Unable to update coupon', response));
            });
    }
}

export function fetchCoupon(couponId) {
    if (!couponId) {
        throw "fetchCoupon: couponId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/coupons/${couponId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_COUPON,
                    payload: response.data
                })
            }).catch(response => {
            dispatch(apiError('Unable to fetch coupon', response));
        });
    }
}

export function fetchCoupons(chainId, activeStatusValidation, purchaseId) {
    if (!chainId) {
        throw "fetchCoupons: chainId Required";
    }

    return dispatch => {
        axios.post(`${ENDPOINT_URL}/${chainId}/coupons`, {
            activeStatusValidation: activeStatusValidation,
            purchaseId: purchaseId
        }, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_COUPONS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch coupons', response));
            });
    }
}

export function updateCouponStatus(couponId, publishStatus, history) {
    if (!couponId) {
        throw "updateCoupon: couponId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/coupons/${couponId}/${publishStatus}`, headerConfig())
            .then(response => {
                dispatch({
                    type: UPDATE_COUPON_STATUS,
                    payload: response.data
                });
                dispatch(apiSuccessMessage(publishStatus === "ACTIVE" ? "Coupon published" : "Coupon deactivated"))
                dispatch(reset(COUPON_FORM_NAME));
                dispatch(fetchCoupon(couponId));

                if (history) {
                    history.push("/points");
                }

            })
            .catch(response => {
                dispatch(apiError('Unable to update coupon', response));
            });
    }
}
