import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import {withStyles} from "@material-ui/core/styles";
import Undo from "@material-ui/icons/Undo";
import {IconButton} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";

import CategoriesForm from "./CategoriesForm";
import Spinner from "../../../../common/components/common/Spinner";
import CategoriesEditorForm from "./CategoriesEditorForm";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {
    createCategory,
    fetchCategories,
    reactiveCategory,
    removeCategory,
    updateCategory
} from "../../../actions/merchant-category-actions";

const styles = theme => ({
    categoryItem: {
        textAlign: 'left'
    },
    noBulletList: {
        listStyleType: 'none'
    },
    editIcon: {
        fontSize: theme.spacing(2.5)
    },
    closeIconButton: {
        right: theme.spacing(2)
    }
});

class Categories extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        app: PropTypes.object,
        categories: PropTypes.array,
        fetchCategories: PropTypes.func,
        createCategory: PropTypes.func,
        reactiveCategory: PropTypes.func,
        removeCategory: PropTypes.func,
        updateCategory: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            isNewCategory: false,
            editable: null
        };
    }

    componentDidMount() {
        this.props.fetchCategories()
    }

    UNSAFE_componentWillReceiveProps() {
        if (this.state.isNewCategory) {
            this.props.fetchCategories();
            this.setState({
                isNewCategory: false
            });
        }
    }

    createCategory(values) {
        this.props.createCategory(values);
        this.setState({
            isNewCategory: true
        });
    }

    render() {
        const {classes, categories} = this.props;

        if (!this.props.categories) {
            return <Spinner app={this.props.app}/>
        }

        const activeCategories = categories.filter(category => category.active);
        const inactiveCategories = categories.filter(category => !category.active);

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <CategoriesForm onSubmit={this.createCategory.bind(this)}/>

                    {activeCategories.map((c) => (
                        <li
                            className={classnames(classes.categoryItem, this.state.editable === c.id && classes.noBulletList)}
                            key={c.id}>
                            {this.renderCategory(c)}

                            {this.state.editable !== c.id && (
                                <IconButton
                                    disabled={this.state.editable !== null}
                                    onClick={() => this.makeEditableField(c.id)}>
                                    <Edit className={classes.editIcon}/>
                                </IconButton>
                            )}

                            {this.state.editable !== c.id && (
                                <IconButton
                                    className={classes.closeIconButton}
                                    onClick={() => this.props.removeCategory(c.id)}>
                                    <Close/>
                                </IconButton>
                            )}
                        </li>
                    ))}


                    {inactiveCategories && inactiveCategories.length > 0 && (
                        <React.Fragment>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="inactiveCategories"
                                    defaultMessage="Inactive categories"/>
                            </Typography>

                            {inactiveCategories.map((c) => (
                                <li
                                    className={classnames(classes.categoryItem, this.state.editable === c.id && classes.noBulletList)}
                                    key={c.id}>
                                    {c.name}
                                    <IconButton onClick={() => this.props.reactiveCategory(c.id)}>
                                        <Undo/>
                                    </IconButton>
                                </li>
                            ))}
                        </React.Fragment>
                    )}
                </Paper>
            </Container>
        )
    }

    makeEditableField(id) {
        this.setState({
            editable: id
        });
    }

    handleUpdateCategory(categoryId, values) {
        this.props.updateCategory(categoryId, values);
        this.handleStopEdition();
    }

    handleStopEdition() {
        this.setState({
            editable: null
        });
    }

    renderCategory(category) {
        if (category.id === this.state.editable) {
            return (
                <CategoriesEditorForm
                    enableReinitialize={true}
                    handleStopEdition={this.handleStopEdition.bind(this)}
                    initialValues={{"name": category.name}}
                    onSubmit={this.handleUpdateCategory.bind(this, category.id)}/>
            )
        }
        return category.name
    }

}

function mapStateToProps(state) {
    return {
        categories: state.merchant.categories
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCategories,
        createCategory,
        removeCategory,
        updateCategory,
        reactiveCategory
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Categories));
