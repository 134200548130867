import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import PointOfSaleForm from "./PointOfSaleForm";
import Spinner from "../../../../../common/components/common/Spinner";
import Container from "../../../../../common/components/common/Container";
import Paper from "../../../../../common/components/common/Paper";

import {prepareFormData} from "../../../../utils/FormHelper";
import {setFormValue} from "../../../../actions/merchant-forms-actions";
import {fetchUserDetails} from "../../../../../common/actions/user-actions";
import {createPointOfSale, fetchPointOfSale, updatePointOfSale} from "../../../../actions/merchant-pos-actions";

class PointOfSale extends React.Component {

    static propTypes = {
        match: PropTypes.object,
        history: PropTypes.object,
        chain: PropTypes.object,
        app: PropTypes.object,
        pointOfSale: PropTypes.object,
        user: PropTypes.object,
        setFormValue: PropTypes.func,
        fetchUserDetails: PropTypes.func,
        fetchPointOfSale: PropTypes.func,
        updatePointOfSale: PropTypes.func,
        createPointOfSale: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchUserDetails();
        if (this.isUpdate()) {
            this.props.fetchPointOfSale(this.props.match.params.id)
        }
    }

    isUpdate() {
        return this.props.match.params.id != null;
    }

    onSubmit(user, values) {
        const chainId = this.props.chain.id;
        const body = prepareFormData({...values, 'commercialChainId': chainId});

        if (this.isUpdate()) {
            this.props.updatePointOfSale(this.props.match.params.id, body, this.props.history, user)
        } else {
            this.props.createPointOfSale(body, this.props.history, user, chainId)
        }
    }

    render() {
        const {app, chain, pointOfSale, user} = this.props;

        if (!user || (this.isUpdate() && (!pointOfSale || !chain) || !chain)) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <PointOfSaleForm
                        setFormValue={this.props.setFormValue}
                        isUpdate={this.isUpdate()}
                        enableReinitialize={true}
                        initialValues={this.getInitialValues(pointOfSale, chain)}
                        onSubmit={this.onSubmit.bind(this, user)}
                        chain={chain}/>
                </Paper>
            </Container>
        )
    }

    getInitialValues(pointOfSale, chain) {
        if (this.isUpdate()) {
            return {
                ...pointOfSale,
                "city": pointOfSale.address.city,
                "street": pointOfSale.address.street,
                "zipCode": pointOfSale.address.zipCode,
            };
        }
        return {
            ...pointOfSale,
            "commercialName": chain.commercialName,
            "phone": chain.phone,
            "email": chain.email,
        };
    }

}

function mapStateToProps(state) {
    return {
        pointOfSale: state.merchant.pointOfSale,
        user: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPointOfSale,
        updatePointOfSale,
        createPointOfSale,
        setFormValue,
        fetchUserDetails
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PointOfSale);
