import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import {withStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    root: {
        margin: 0
    },
    checkbox: {
        display: 'table-cell',
        padding: 0
    },
    defaultIcon: {
        fill: theme.palette.common.black
    },
    checkedIcon: {
        fill: theme.palette.primary.main
    }
});

class ReadCheckbox extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        label: PropTypes.object,
        table: PropTypes.bool,
        checked: PropTypes.bool
    };

    render() {
        const {classes, label, checked, table} = this.props;

        return (
            <FormControlLabel
                className={classnames(table && classes.root)}
                control={
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon className={classes.defaultIcon}/>}
                        checkedIcon={<CheckBoxIcon className={classes.checkedIcon}/>}
                        className={classnames(table && classes.checkbox)}
                        checked={checked}
                        disabled={true}/>
                }
                label={label}/>
        )
    }
}

export default withStyles(styles)(ReadCheckbox)
