import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {fetchCommercialChains} from "../../../../actions/merchant-chain-actions";

import Add from '@material-ui/icons/Add';

import Spinner from "../../../../../common/components/common/Spinner";
import Button from "../../../../../common/components/common/Button";
import {KarmingTable} from "../../../../../common/components/common/table/KarmingTable";
import Container from "../../../../../common/components/common/Container";
import Paper from "../../../../../common/components/common/Paper";
import TableTitle from "../../../../../common/components/common/table/TableTitle";

class CommercialChains extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        app: PropTypes.object,
        chains: PropTypes.object,
        fetchCommercialChains: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchCommercialChains();
    }

    moveToAddCommercialChain() {
        this.props.history.push("/chains/create");
    }

    render() {
        if (!this.props.chains || !this.props.chains.commercialChains) {
            return <Spinner app={this.props.app}/>
        }

        const columns = [
            {
                Header: <FormattedMessage
                    id="commercialName"
                    defaultMessage="Commercial name"/>,
                id: "commercialName",
                accessor: l => l.commercialName,
            },

            {
                Header: <FormattedMessage
                    id="email"
                    defaultMessage="Email"/>,
                id: "email",
                accessor: l => l.email,
            },
            {
                Header: <FormattedMessage
                    id="phone"
                    defaultMessage="Phone"/>,
                id: "phone",
                accessor: l => l.phone,
            },
        ];

        return (
            <Container size={Container.SIZE_HUGE}>
                <Paper>
                    <TableTitle
                        title={<FormattedMessage
                            id="commercialChains"
                            defaultMessage="Commercial chains"/>}
                        button={
                            <Button
                                icon={<Add/>}
                                label={<FormattedMessage
                                    id="add"
                                    defaultMessage="Add"/>}
                                onClick={this.moveToAddCommercialChain.bind(this)}/>
                        }/>

                    <KarmingTable
                        history={this.props.history}
                        data={this.props.chains.commercialChains}
                        columns={columns}
                        pageSize={10}
                        onRowClickHistoryPush={(state, row) => {
                            return (
                                {
                                    pathname: "/chains/" + row.id
                                }
                            )
                        }}/>
                </Paper>
            </Container>
        );
    }

}

function mapStateToProps(state) {
    return {
        chains: state.chains,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCommercialChains}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CommercialChains);
