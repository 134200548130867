import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {withStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Domain from '@material-ui/icons/Domain';
import Store from '@material-ui/icons/Store';
import Divider from "@material-ui/core/Divider";
import GroupAdd from '@material-ui/icons/GroupAdd';
import ArrowForward from '@material-ui/icons/ArrowForward';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import Typography from "@material-ui/core/Typography";

import Spinner from "../../common/components/common/Spinner";
import Button from "../../common/components/common/Button";
import Dialog from "../../common/components/common/Dialog";
import Container from "../../common/components/common/Container";
import Paper from "../../common/components/common/Paper";
import AddToHomeScreenPopup from "../../common/components/common/pwa/AddToHomeScreenPopup";

import {ROLE_MERCHANT} from "../../common/components/auth/roles";
import {chooseCurrentPoS} from "../actions/merchant-chain-actions";
import {fetchUserDetails} from "../../common/actions/user-actions";

import {ENGLISH, FRENCH, SWEDISH} from "../../common/utils/enums/languages";

const styles = theme => ({
    paper: {
        width: '92%',
        maxWidth: 400,
        margin: 0,
    },
    salePointWrapper: {
        textAlign: 'left'
    },
    buttonsWrapper: {
        display: 'grid'
    },
    commercialChainsList: {
        padding: 0,
        textAlign: 'left',
        listStyleType: 'none'
    },
    subtitle: {
        paddingLeft: theme.spacing(4),
        fontStyle: 'italic'
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
        height: 2
    },
    checkbox: {
        bottom: 2,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(0.5)
    },
    icon: {
        top: 4,
        position: 'relative'
    }
});

class MerchantHome extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        app: PropTypes.object,
        user: PropTypes.object,
        auth: PropTypes.object,
        chains: PropTypes.object,
        history: PropTypes.object,
        setStartGuideVisibility: PropTypes.func.isRequired,
        fetchUserDetails: PropTypes.func,
        chooseCurrentPoS: PropTypes.func
    };

    state = {
        openLocationSelection: true,
        selectedLocation: undefined
    };

    componentDidMount() {
        this.props.fetchUserDetails();
        setTimeout(() => this.props.setStartGuideVisibility(true), 1500);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {user: {profile}} = nextProps;
        const {selectedLocation} = prevState;

        if (profile) {
            if (selectedLocation === undefined && profile.lastUsedPointOfSale) {
                return {
                    selectedLocation: profile.lastUsedPointOfSale
                }
            }
        }
        return null
    }

    handleCloseLocationSelection = () => {
        this.setState({openLocationSelection: false});
    };

    handleConfirmLocationSelection = () => {
        const {selectedLocation} = this.state;
        this.props.chooseCurrentPoS(selectedLocation, true);
        localStorage.setItem('chosenLocation', "true");
        this.setState({openLocationSelection: false});
    };

    togglePosCheckbox(pointOfSaleId) {
        this.setState({selectedLocation: pointOfSaleId});
    }

    renderSelectCurrentLocationDialog(chains, user) {
        const {classes} = this.props;
        const {openLocationSelection, selectedLocation} = this.state;
        if (chains
            && chains.commercialChains
            && chains.commercialChains.length > 0
            && localStorage.getItem('chosenLocation') !== "true") {

            const hasAnyPointOfSale = chains.commercialChains.some(chain => chain.pointsOfSale.length > 0);
            const lastUsedPointOfSaleId = user.lastUsedPointOfSale;
            const lastUsedPointOfSale = chains.commercialChains
                .map(chain => chain.pointsOfSale
                    .find(pos => pos.id === lastUsedPointOfSaleId))
                .find(pos => !!pos);
            const onlyOnePointOfSale = chains.commercialChains.length === 1 && chains.commercialChains[0].pointsOfSale
                .filter(pointOfSale => user.authority === ROLE_MERCHANT || user.pointsOfSaleIds.includes(pointOfSale.id));

            if (onlyOnePointOfSale.length === 1) {
                this.props.chooseCurrentPoS(onlyOnePointOfSale[0].id, true);
                localStorage.setItem('chosenLocation', "true");
                return null;
            }

            return (
                <Dialog
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={this.handleCloseLocationSelection}
                    open={openLocationSelection && hasAnyPointOfSale}
                    rootClasses={{paper: classes.paper}}
                    title={<FormattedMessage
                        id="locationSelection"
                        defaultMessage="Choose your current location"/>}
                    actions={[
                        <Button
                            key="confirm"
                            label={<FormattedMessage
                                id="confirm"
                                defaultMessage="Confirm"/>}
                            onClick={this.handleConfirmLocationSelection.bind(this)}/>
                    ]}
                    content={
                        <React.Fragment>
                            {lastUsedPointOfSale && (
                                <div className={classes.salePointWrapper}>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="lastChosenLocation"
                                            defaultMessage="Last chosen location"/>:
                                    </Typography>

                                    <Typography
                                        variant="subtitle1"
                                        component="div">
                                        <Checkbox
                                            className={classes.checkbox}
                                            checked={selectedLocation === lastUsedPointOfSaleId}
                                            onChange={this.togglePosCheckbox.bind(this, lastUsedPointOfSaleId)}/>

                                        {lastUsedPointOfSale.commercialName}
                                    </Typography>

                                    <Typography
                                        variant="caption"
                                        className={classes.subtitle}>
                                        {lastUsedPointOfSale.address.street}, {lastUsedPointOfSale.address.city}
                                    </Typography>

                                    <Divider className={classes.divider}/>
                                </div>
                            )}

                            <ul className={classes.commercialChainsList}>
                                {chains.commercialChains.map((chain, chainIndex) => {
                                    const isSinglePoS = chain.pointsOfSale.find(pos => pos.singlePoint === true);
                                    if (!(isSinglePoS && lastUsedPointOfSale && chain.pointsOfSale[0].id === lastUsedPointOfSale.id)) {
                                        return (
                                            isSinglePoS
                                                ? chain.pointsOfSale
                                                    .map((pointOfSale, index) =>
                                                        <li key={index}>
                                                            <Typography
                                                                variant="subtitle1"
                                                                component="div">
                                                                <Store
                                                                    className={classes.icon}
                                                                    color="secondary"/>&nbsp;
                                                                {pointOfSale.commercialName}

                                                                <Checkbox
                                                                    className={classes.checkbox}
                                                                    checked={selectedLocation === pointOfSale.id}
                                                                    onChange={this.togglePosCheckbox.bind(this, pointOfSale.id)}/>
                                                            </Typography>

                                                            <Typography
                                                                variant="caption"
                                                                className={classes.subtitle}>
                                                                {pointOfSale.address.street}, {pointOfSale.address.city}
                                                            </Typography>
                                                        </li>
                                                    )
                                                : <li key={chainIndex}>
                                                    <Typography
                                                        variant="subtitle1"
                                                        component="div">
                                                        <Domain
                                                            className={classes.icon}
                                                            color="secondary"/>&nbsp;
                                                        {chain.commercialName}
                                                    </Typography>

                                                    {chain.pointsOfSale
                                                        .filter(pointOfSale => (user.authority === ROLE_MERCHANT
                                                            || user.pointsOfSaleIds.includes(pointOfSale.id))
                                                            && (!lastUsedPointOfSale || pointOfSale.id !== lastUsedPointOfSale.id))
                                                        .map((pointOfSale, index) =>
                                                            <div key={index}>
                                                                <Typography variant="subtitle1">
                                                                    <Checkbox
                                                                        className={classes.checkbox}
                                                                        checked={selectedLocation === pointOfSale.id}
                                                                        onChange={this.togglePosCheckbox.bind(this, pointOfSale.id)}/>
                                                                    {pointOfSale.commercialName}
                                                                </Typography>

                                                                <Typography
                                                                    variant="caption"
                                                                    className={classes.subtitle}>
                                                                    {pointOfSale.address.street}, {pointOfSale.address.city}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                </li>
                                        )
                                    }
                                })}
                            </ul>
                        </React.Fragment>
                    }/>
            )
        }
    }

    render() {
        const {classes, app, user, auth, chains} = this.props;

        if (!app || !user || !user.profile) {
            return <Spinner app={app}/>
        }

        const language = user.profile.language === FRENCH && ENGLISH || SWEDISH;

        if (!user.profile.company.startGuideCompleted && auth.role === ROLE_MERCHANT) {
            return (
                <Container size={Container.SIZE_TINY}>
                    <Paper padding>
                        <Typography
                            variant="h5"
                            paragraph>
                            <FormattedMessage
                                id="merchant-home-start-guide-not-completed.startGuideCongratulationsText"
                                defaultMessage="Congratulations to a Smart decision!"/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="merchant-home-start-guide-not-completed.startGuideWillHelpText"
                                defaultMessage="The START GUIDE will help you finalize the setup of your account
                                until you’re ready to onboard and boos your relation with customers!"/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="merchant-home-start-guide-not-completed.youWillFindTheStartGuideOnTheRightText"
                                defaultMessage="You will find the START GUIDE to the right until you have reach 100%!"/>
                        </Typography>

                        <ArrowForward/>
                    </Paper>

                    {this.renderSelectCurrentLocationDialog(chains, user.profile)}
                    <AddToHomeScreenPopup language={language}/>
                </Container>
            )
        }

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <div className={classes.buttonsWrapper}>
                        <Button
                            icon={<AddShoppingCart/>}
                            label={<FormattedMessage
                                id="registerPurchase"
                                defaultMessage="Register purchase"/>}
                            onClick={this.moveToRegisterPurchase.bind(this)}/>

                        <Button
                            icon={<GroupAdd/>}
                            label={<FormattedMessage
                                id="inviteMember"
                                defaultMessage="Invite member"/>}
                            color="secondary"
                            onClick={this.moveToInviteMember.bind(this)}/>
                    </div>
                </Paper>

                {this.renderSelectCurrentLocationDialog(chains, user.profile)}
                <AddToHomeScreenPopup language={language}/>
            </Container>
        );
    }

    moveToRegisterPurchase() {
        this.props.history.push("/purchase/search");
    }

    moveToInviteMember() {
        this.props.history.push("/invite");
    }

}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        chooseCurrentPoS,
        fetchUserDetails
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MerchantHome));
