import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import classnames from "classnames"

const styles = theme => ({
    root: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        minWidth: 38,
        height: 'auto',
        borderRadius: theme.shape.borderRadius,
    },
    input: {
        display: 'none',
    },
});

const SquareIconButton = (props) => {
    const {classes, style, className, type, disabled, onClick, icon} = props;

    const buttonClass = classnames(classes.root, className);

    return (
        <IconButton
            disableRipple
            style={style}
            className={buttonClass}
            type={type}
            disabled={disabled}
            onClick={onClick}
            icon={icon}>
            {icon}
        </IconButton>
    )
};

SquareIconButton.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
    type: PropTypes.object,
    icon: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

export default withStyles(styles)(SquareIconButton);
