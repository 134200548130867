import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../../../common/components/common/Spinner";
import ClubPointsForm from "./ClubPointsForm";

import {fetchClubPoints} from "../../actions/merchant-points-actions";


class ClubPointsEdit extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        history: PropTypes.object,
        chain: PropTypes.object,
        clubPoints: PropTypes.object,
        fetchClubPoints: PropTypes.func
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain || prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchClubPoints} = this.props;
        if (chain && chain.id) {
            fetchClubPoints(chain.id);
        }
    }

    render() {
        const {app, history, clubPoints} = this.props;

        if (!clubPoints) {
            return <Spinner app={app}/>
        }

        return (
            <ClubPointsForm
                initialValues={clubPoints}
                history={history}
                isUpdate={true}/>
        )
    }

}

function mapStateToProps(state) {
    return {
        clubPoints: state.points.clubPoints,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchClubPoints}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ClubPointsEdit);
