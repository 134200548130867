import React from 'react';
import PropTypes from "prop-types";
import {StampcardUsageList} from "./StampcardUsageList";
import _ from "lodash"

export const StampcardUsages = props => {
    const {purchase, array, index} = props;

    const historicPurchases = {...array};

    const historicPurchase = {...purchase}; //deep copy

    let purchaseStampcardUsageNew = [];
    let purchaseStampcardUsageID = [];

    const deepCopyOfHistoric = _.cloneDeep(historicPurchase.purchaseStampcardUsage);
    deepCopyOfHistoric.forEach(purchaseStampcardUsage_ => {
        if (purchaseStampcardUsageID.includes(purchaseStampcardUsage_.stampcardId)) {
            purchaseStampcardUsageNew.forEach(purchaseStampcardUsage__ => {
                if (purchaseStampcardUsage__.stampcardId === purchaseStampcardUsage_.stampcardId) {
                    purchaseStampcardUsage__.addedStamps += purchaseStampcardUsage_.addedStamps;
                    if (purchaseStampcardUsage_.previousStampsAmount) {
                        purchaseStampcardUsage__.previousStampsAmount += purchaseStampcardUsage_.previousStampsAmount;
                    }
                    if (purchaseStampcardUsage_.usageOrder < purchaseStampcardUsage__.usageOrder) {
                        purchaseStampcardUsage__.usageOrder = purchaseStampcardUsage_.usageOrder;
                    }
                }
            })
        } else {
            purchaseStampcardUsageNew.push(purchaseStampcardUsage_);
            purchaseStampcardUsageID.push(purchaseStampcardUsage_.stampcardId);
        }
    });
    historicPurchase.purchaseStampcardUsage = purchaseStampcardUsageNew;

    const getHistoricPurchase = (historicPurchase, historicPurchases) => {
        historicPurchase.purchaseStampcardUsage.map(stampcard => {
            const stampcardUsage = historicPurchases[index]
                && historicPurchases[index].purchaseStampcardUsage
                    .find(usage => usage.stampcardId === stampcard.stampcardId);

            const previousStampcardUsage = historicPurchases[index - 1]
                && historicPurchases[index - 1].purchaseStampcardUsage
                    .find(usage => usage.stampcardId === stampcard.stampcardId);

            let previousStampsAmount = 0;
            if (historicPurchases[index - 1]
                && previousStampcardUsage
                && previousStampcardUsage.usageOrder >= stampcard.usageOrder) {
                previousStampsAmount = previousStampcardUsage.addedStamps
            }

            let pointsDifference = stampcardUsage.addedStamps - previousStampsAmount;
            stampcardUsage.previousStampsAmount = previousStampsAmount;

            if (previousStampcardUsage) {
                const previousAddedStamps = previousStampcardUsage.allStampsAmount
                    ? previousStampcardUsage.allStampsAmount
                    : previousStampcardUsage.addedStamps;

                pointsDifference = stampcard.addedStamps - previousStampsAmount;

                stampcard.allStampsAmount = previousAddedStamps + pointsDifference;
            } else {
                stampcard.allStampsAmount = stampcardUsage.addedStamps;
            }
        });

        return historicPurchase.purchaseStampcardUsage;
    };

    const historicPurchaseUsages = React.useMemo(
        () => getHistoricPurchase(historicPurchase, historicPurchases),
        [historicPurchase, historicPurchases]
    );

    return (
        <StampcardUsageList
            usages={historicPurchaseUsages}
            purchaseIndex={index}
        />
    )
};

StampcardUsages.propTypes = {
    purchase: PropTypes.object.isRequired,
    array: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired
};
