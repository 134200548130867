import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
    dots: {
        "& *": {
            opacity: 0,
            animation: '$show 1.3s infinite'
        },
        "& > :first-child": {
            animationDelay: '0.0s'
        },
        "& > :nth-child(2)": {
            animationDelay: '0.2s'
        },
        "& > :nth-child(3)": {
            animationDelay: '0.3s'
        }
    },
    '@keyframes show': {
        '0%': {
            opacity: 0
        },
        '50%': {
            opacity: 0
        },
        '100%': {
            opacity: 1
        }
    }
};

const LoadingText = ({classes, text}) => {

    return (
        <React.Fragment>
            {text}
            <span className={classes.dots}>
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </span>
        </React.Fragment>
    );

};

LoadingText.propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.object.isRequired
};

export default withStyles(styles)(LoadingText)
