import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";
import Save from '@material-ui/icons/Save';
import {makeStyles} from "@material-ui/core/styles/index";

import Button from "../../../common/components/common/Button";
import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import RenderTextField from "../../../common/components/common/RenderTextField";

import CustomerDetailsBar from "./CustomerDetailsBar";
import TitleWithBackButton from "../../../common/components/TitleWithBackButton";
import {HistoryExpansionPanel} from "./history/HistoryExpansionPanel";
import {PurchaseHistory} from "./history/PurchaseHistory";
import ChangeEarnedPointsField from "./ChangeEarnedPointsField";
import {ClubPointsMessage} from "./history/ClubPointsMessage";

import {PURCHASES_URL} from "../../../../static/utils/redirect-const";
import {getTranslatedDate} from "../../../common/utils/date-utils";
import {UNSIGNED_FLOAT_FORMAT_CONFIG} from "../../utils/formats";
import {connect} from "react-redux";
import {colorOrange, colorRed} from "../../../common/utils/color-const";
import {isBefore} from "date-fns";

const useStyles = makeStyles(theme => ({
    wrapper: {
        marginTop: theme.spacing(1),
        textAlign: 'left'
    },
    buttonsWrapper: {
        display: 'inline-flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: theme.spacing(1)
    },
    doubleFieldsWrapper: {
        display: 'flex',
        "& > :nth-child(2)": {
            marginLeft: theme.spacing(2)
        }
    },
    errorText: {
        color: colorRed,
        fontSize: 12
    }
}));

export const UPDATE_PURCHASE_FORM_NAME = 'updatePurchaseForm';

let UpdatePurchaseForm = props => {
    const {
        handleSubmit, submitting, consumer, clubPointsActive, categoriesOptions, couponsOptions, campaignOptions,
        stampcards, initialValues, history, consumerPoints, purchaseStampcardUsage, purchase,
        consumerStampcardCoupons, pristine, subtractTradedPoints, addTradedPoints,
        removeMember, merchantUpdateMember, purchase: {historicPurchases}, tradedPoints,
        setFormValue, earnedPointsManual, earnedPoints, totalAmount, clubPointsCountedPointsFrom, chain
    } = props;
    const classes = useStyles();

    const madeChanges = earnedPoints - initialValues.earnedPoints - tradedPoints + initialValues.tradedPoints;
    const currentBalance = consumerPoints + madeChanges;

    let usedPoints = 0;
    purchase.pointsUsages && purchase.pointsUsages.length > 0 &&
    purchase.pointsUsages.map(usage => {
        if (!usage.returnPointsDate) {
            usedPoints += usage.usedPoints;
        }
    });

    const minEarnedPoints = (earnedPoints - usedPoints) < currentBalance ? usedPoints : earnedPoints - currentBalance;
    const isEarnedPointsLowerThanMinValue = totalAmount < minEarnedPoints;

    const isClubPointsValidForPurchase = isBefore(clubPointsCountedPointsFrom, purchase.dateOfPurchase);

    purchaseStampcardUsage.map(purchaseStampcardUsage_ => {
        const stampcardUsageFiltered = consumer.stampcardUsage.filter(stampcardUsage => stampcardUsage.stampcardId === purchaseStampcardUsage_.stampcardId);
        const purchaseStampcardUsageFiltered = purchaseStampcardUsage.filter(purchaseStampcardUsage__ => purchaseStampcardUsage__.stampcardId === purchaseStampcardUsage_.stampcardId);
        if (purchaseStampcardUsageFiltered.length > 1) {
            if (purchaseStampcardUsageFiltered.reduce((a, b) => a.usageOrder > b.usageOrder ? a : b).id !== purchaseStampcardUsage_.id) {
                return;
            }
        }
        if (stampcardUsageFiltered[0] && stampcardUsageFiltered[0].historicStampcardUsages && (purchaseStampcardUsage_.usageOrder < stampcardUsageFiltered[0].historicStampcardUsages.length)) {
            const filterStampcards = stampcards.filter(stampcard => stampcard.value === purchaseStampcardUsage_.stampcardId && stampcard.stampcardUsageStatus !== "COMPLETED");
            if (filterStampcards[0] && filterStampcards[0].added) {
                filterStampcards[0].added = 0;
            }
        }
    });

    return (
        <form onSubmit={handleSubmit} autoComplete="on">
            <TitleWithBackButton
                title={
                    <Typography
                        variant="h5"
                        paragraph>
                        <FormattedMessage
                            id="purchaseUpdateTitle"
                            defaultMessage="Purchase - {date}"
                            values={{
                                date: getTranslatedDate(purchase.dateOfPurchase, chain.language)
                            }}/>
                    </Typography>
                }
                onBackClick={() => history.push(PURCHASES_URL)}/>

            <CustomerDetailsBar
                consumer={consumer}
                history={history}
                removeMember={removeMember}
                merchantUpdateMember={merchantUpdateMember}/>

            {historicPurchases.length > 0 && (
                <HistoryExpansionPanel
                    hasHideButton
                    title={<FormattedMessage
                        id="reviewPurchaseUpdates"
                        defaultMessage="Review previous updates of this purchase"/>}
                    content={<PurchaseHistory
                        purchase={purchase}
                        language={consumer.language}/>}/>
            )}

            <div className={classes.wrapper}>
                {stampcards && stampcards.length > 0 && (
                    <React.Fragment>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="stampcards"
                                defaultMessage="Stamp cards"/>
                        </Typography>

                        <Field
                            name="stampcards"
                            component={ImageBoxRow}
                            options={stampcards}
                            purchaseStampcardUsage={purchaseStampcardUsage}
                            stampcard
                            isUpdate
                            initiallySelectedOptions={initialValues
                                ? initialValues.stampcards
                                : null}/>
                    </React.Fragment>
                )}

                {consumerStampcardCoupons && consumerStampcardCoupons.length > 0 && (
                    <React.Fragment>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="stampcardsRewards"
                                defaultMessage="Stamp cards rewards"/>
                        </Typography>

                        <Field
                            name="stampcardCouponUsages"
                            component={ImageBoxRow}
                            stampcardCoupon
                            isUpdate
                            options={consumerStampcardCoupons}
                            initiallySelectedOptions={initialValues
                                ? initialValues.stampcardCouponUsages
                                : null}/>
                    </React.Fragment>
                )}

                {campaignOptions && campaignOptions.length > 0 && (
                    <React.Fragment>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="campaigns"
                                defaultMessage="Campaigns"/>
                        </Typography>

                        <Field
                            name="campaigns"
                            component={ImageBoxRow}
                            options={campaignOptions}
                            initiallySelectedOptions={initialValues
                                ? initialValues.campaigns
                                : null}/>
                    </React.Fragment>
                )}

                {clubPointsActive && couponsOptions && (
                    <React.Fragment>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="coupons"
                                defaultMessage="Coupons"/>
                        </Typography>

                        {isClubPointsValidForPurchase &&

                        <ClubPointsMessage
                            currentBalance={currentBalance}
                            madeChanges={madeChanges}
                        />
                        }

                        {!isClubPointsValidForPurchase &&
                        <div style={{
                            border: `1px solid ${colorOrange}`,
                            borderRadius: 8,
                            textAlign: 'left',
                            padding: 8,
                            margin: 8
                        }}>
                            <FormattedMessage
                                id="purchaseOutsidePointsValidity"
                                defaultMessage="This purchase is outside the points current validity period."/>
                            <br/>
                            <FormattedMessage
                                id="notPossibleToRedeemOrReturnCoupons"
                                defaultMessage="It's not possible to redeem or return coupons."/>
                        </div>
                        }

                        <Field
                            readOnly={!isClubPointsValidForPurchase}
                            name="coupons"
                            component={ImageBoxRow}
                            consumerPoints={consumerPoints}
                            coupon
                            options={couponsOptions}
                            remainingPoints={currentBalance}
                            addTradedPoints={addTradedPoints.bind(this)}
                            subtractTradedPoints={subtractTradedPoints.bind(this)}
                            initiallySelectedOptions={initialValues
                                ? initialValues.coupons
                                : null}/>
                    </React.Fragment>
                )}

                {categoriesOptions
                && categoriesOptions.length > 0 && (
                    <React.Fragment>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="categories"
                                defaultMessage="Categories"/>
                        </Typography>

                        <Field
                            name="categories"
                            component={ImageBoxRow}
                            options={categoriesOptions}
                            squareShape
                            initiallySelectedOptions={initialValues
                                ? initialValues.categories
                                : null}/>
                    </React.Fragment>
                )}

                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        name="totalAmount"
                        hintText={<FormattedMessage
                            id="totalAmountPaid"
                            defaultMessage="Total amount paid"/>}
                        component={RenderTextField}
                        onChange={(event, value) => {
                            if (!earnedPointsManual) {
                                const fieldValue = value || 0;
                                setFormValue(
                                    UPDATE_PURCHASE_FORM_NAME,
                                    'earnedPoints',
                                    (isClubPointsValidForPurchase &&
                                        (clubPointsActive && fieldValue < minEarnedPoints)
                                        || minEarnedPoints > 0 && currentBalance > 0) ?
                                        minEarnedPoints
                                        : fieldValue
                                )
                            }
                        }}
                        formatConfig={UNSIGNED_FLOAT_FORMAT_CONFIG}
                        type="number"
                        inputMode="numeric"
                        helperText={
                            isClubPointsValidForPurchase
                            && isEarnedPointsLowerThanMinValue
                                ? (
                                    <span className={classes.errorText}>
                                    <FormattedMessage
                                        id="pointsSetToLowestPossibleWarning"
                                        defaultMessage="! Points is set to lowest possible = {minEarnedPoints}p"
                                        values={{
                                            minEarnedPoints: minEarnedPoints
                                        }}/>
                                </span>
                                )
                                : ""}/>

                    {clubPointsActive &&
                    <Field
                        name="earnedPoints"
                        hintText={<FormattedMessage
                            id="earnedPoints"
                            defaultMessage="Earned points"/>}
                        component={ChangeEarnedPointsField}
                        usedPoints={usedPoints}
                        currentBalance={currentBalance}
                        minEarnedPoints={minEarnedPoints}
                        hasDashedBorder
                        setFormValue={setFormValue}
                        purchase={purchase}
                        earnedPoints={earnedPoints}
                        remainingPoints={currentBalance}/>
                    }
                </div>

                <div className={classes.buttonsWrapper}>
                    <Button
                        icon={<Save/>}
                        label={<FormattedMessage
                            id="update"
                            defaultMessage="Update"/>}
                        type="submit"
                        disabled={submitting || pristine}/>
                </div>


            </div>
        </form>
    );

};

UpdatePurchaseForm.propTypes = {
    consumer: PropTypes.object,
    initialValues: PropTypes.object,
    history: PropTypes.object,
    purchase: PropTypes.object,
    chain: PropTypes.object,
    purchaseStampcardUsage: PropTypes.array,
    categoriesOptions: PropTypes.array,
    consumerStampcardCoupons: PropTypes.array,
    customerStampcardUsage: PropTypes.array,
    couponsOptions: PropTypes.array,
    campaignOptions: PropTypes.array,
    stampcards: PropTypes.array,
    consumerPoints: PropTypes.number,
    remainingPoints: PropTypes.number,
    tradedPoints: PropTypes.number,
    clubPointsActive: PropTypes.bool,
    clubPointsCountedPointsFrom: PropTypes.number,
    clubPointsMonthValidation: PropTypes.number,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    handleSubmit: PropTypes.func,
    removeMember: PropTypes.func,
    merchantUpdateMember: PropTypes.func,
    removePurchase: PropTypes.func,
    subtractTradedPoints: PropTypes.func,
    addTradedPoints: PropTypes.func
};

UpdatePurchaseForm = reduxForm({
    form: 'updatePurchaseForm',
})(UpdatePurchaseForm);

const selector = formValueSelector('updatePurchaseForm');
UpdatePurchaseForm = connect(state => {

    const {earnedPointsManual, earnedPoints, totalAmount} = selector(state, 'earnedPointsManual', 'earnedPoints', 'totalAmount');
    return {
        earnedPointsManual,
        earnedPoints,
        totalAmount
    }
})(UpdatePurchaseForm);

export default UpdatePurchaseForm
