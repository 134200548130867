import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import NewReleases from "@material-ui/icons/NewReleases";
import Typography from "@material-ui/core/Typography";
import LocalOffer from "@material-ui/icons/LocalOffer";

import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import TitleWithBackButton from "../../../common/components/TitleWithBackButton";
import CategoriesFilterForm from "./CategoriesFilterForm";
import CampaignsFilterForm from "./CampaignsFilterForm";

const Filters = {
    CATEGORIES_FILTER: "CATEGORIES_FILTER",
    CAMPAIGNS_FILTER: "CAMPAIGNS_FILTER",
};

const styles = theme => ({
    icon: {
        color: theme.palette.secondary.main,
        fontSize: 40
    }
});

class TasteFilters extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        selectMembersInfo: PropTypes.object,
        categories: PropTypes.array,
        campaigns: PropTypes.array,
        moveBack: PropTypes.func,
        handleSubmit: PropTypes.func
    };

    state = {
        currentFilter: null,
    };

    onFilterClick(filter) {
        this.setState({currentFilter: filter});
    }

    moveBack(moveBack) {
        if (this.state.currentFilter === null) {
            moveBack();
        }
        this.setState({currentFilter: null});
    }

    render() {
        const {classes, moveBack, handleSubmit, categories, campaigns, selectMembersInfo} = this.props;

        const TasteFiltersOptions = [
            {
                label: <FormattedMessage id="category" defaultMessage="Category"/>,
                icon: <LocalOffer className={classes.icon}/>,
                value: Filters.CATEGORIES_FILTER
            },
            {
                label: <FormattedMessage id="campaign" defaultMessage="Campaign"/>,
                icon: <NewReleases className={classes.icon}/>,
                value: Filters.CAMPAIGNS_FILTER
            }
        ];

        return (
            <React.Fragment>
                <TitleWithBackButton
                    title={<FormattedMessage
                        id="smartSelection"
                        defaultMessage="Smart Selection"/>}
                    onBackClick={this.moveBack.bind(this, moveBack)}/>

                {!this.state.currentFilter && (
                    <React.Fragment>
                        <Typography
                            variant="body1"
                            paragraph>
                            <FormattedMessage
                                id="whatKindOfPurchaseHistoryYouWantToUse"
                                defaultMessage="What kind of purchase history you want to use for your selection"/>:
                        </Typography>

                        <ImageBoxRow
                            options={TasteFiltersOptions}
                            filter
                            onFilterClick={this.onFilterClick.bind(this)}
                            squareShape/>
                    </React.Fragment>
                )}

                {this.renderFilter(handleSubmit, categories, campaigns, selectMembersInfo)}
            </React.Fragment>
        );
    }

    renderFilter(handleSubmit, categories, campaigns, selectMembersInfo) {
        switch (this.state.currentFilter) {
            case Filters.CATEGORIES_FILTER:
                return (
                    <CategoriesFilterForm
                        categories={categories}
                        selectMembersInfo={selectMembersInfo}
                        onSubmit={handleSubmit}/>
                );
            case Filters.CAMPAIGNS_FILTER:
                return (
                    <CampaignsFilterForm
                        campaigns={campaigns}
                        selectMembersInfo={selectMembersInfo}
                        onSubmit={handleSubmit}/>
                );
        }
    }

}

export default withStyles(styles)(TasteFilters)
