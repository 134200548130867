import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import RenderTextField from "../../../../common/components/common/RenderTextField";
import Button from "../../../../common/components/common/Button";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {required, RequiredInfo} from "../../../../common/components/common/form/FormUtils";
import {categoryOrCampaignNameValidation} from "../../../../common/utils/karming-validators";

const validate = values => {
    return categoryOrCampaignNameValidation({name: "name", value: values.name})
};

class CampaignFormPage1 extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        handleSubmit: PropTypes.func
    };

    render() {
        const {handleSubmit, title} = this.props;

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <form onSubmit={handleSubmit} autoComplete="on">
                        <Typography
                            variant="h5"
                            paragraph>
                            {title}
                        </Typography>

                        <Field
                            name="name"
                            component={RenderTextField}
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="campaignName"
                                        defaultMessage="Campaign name"/>
                                    &nbsp;*
                                </React.Fragment>
                            }
                            type="text"
                            validate={[required]}/>

                        <Button
                            label={<FormattedMessage
                                id="next"
                                defaultMessage="Next"/>}
                            type="submit"/>

                        <RequiredInfo/>
                    </form>
                </Paper>
            </Container>
        );
    }

}

export default reduxForm({
    form: "campaignForm",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
})(CampaignFormPage1);
