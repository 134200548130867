import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Add from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";

import RenderSelect from "../../../common/components/common/RenderSelect";
import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import Button from "../../../common/components/common/Button";

export const MADE_PURCHASE_IN_ANY = {
    value: "MADE_PURCHASE_IN_ANY",
    label: <FormattedMessage
        id="madePurchaseInAnyOfTheseCategories"
        defaultMessage="Made purchase in any of below categories"/>
};

export const MADE_PURCHASE_IN_ALL = {
    value: "MADE_PURCHASE_IN_ALL",
    label: <FormattedMessage
        id="madePurchaseInAllOfTheseCategories"
        defaultMessage="Made purchase in all of below categories"/>
};

export const NOT_MADE_PURCHASE_IN_ANY = {
    value: "NOT_MADE_PURCHASE_IN_ANY",
    label: <FormattedMessage
        id="notMadePurchaseInAnyOfTheseCategories"
        defaultMessage="Not made purchase in any of below categories"/>
};

export const NOT_MADE_PURCHASE_IN_ALL = {
    value: "NOT_MADE_PURCHASE_IN_ALL",
    label: <FormattedMessage
        id="noMadePurchaseInAllOfTheseCategories"
        defaultMessage="Not made purchase in all of below categories"/>
};

export const madePurchaseCategoriesOption = [
    MADE_PURCHASE_IN_ANY,
    MADE_PURCHASE_IN_ALL,
    NOT_MADE_PURCHASE_IN_ANY,
    NOT_MADE_PURCHASE_IN_ALL
];

const styles = theme => ({
    categoriesWrapper: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        }
    }
});

class CategoriesFilterForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        selectedCategories: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        selectMembersInfo: PropTypes.object,
        categories: PropTypes.array,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    render() {
        const {classes, handleSubmit, categories, selectedCategories, selectMembersInfo} = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage
                        id="category"
                        defaultMessage="Category"/>
                </Typography>

                <Typography
                    variant="body1"
                    component="div">
                    {selectMembersInfo}
                </Typography>

                <Field
                    name="tasteFilter.madePurchaseCategories"
                    component={RenderSelect}
                    defaultValue={MADE_PURCHASE_IN_ANY.value}>
                    <MenuItem value={MADE_PURCHASE_IN_ANY.value}>
                        {MADE_PURCHASE_IN_ANY.label}
                    </MenuItem>

                    <MenuItem value={MADE_PURCHASE_IN_ALL.value}>
                        {MADE_PURCHASE_IN_ALL.label}
                    </MenuItem>

                    <MenuItem value={NOT_MADE_PURCHASE_IN_ANY.value}>
                        {NOT_MADE_PURCHASE_IN_ANY.label}
                    </MenuItem>

                    <MenuItem value={NOT_MADE_PURCHASE_IN_ALL.value}>
                        {NOT_MADE_PURCHASE_IN_ALL.label}
                    </MenuItem>
                </Field>

                <div className={classes.categoriesWrapper}>
                    <Field
                        name="tasteFilter.categories"
                        component={ImageBoxRow}
                        options={this.getCategories(categories)}
                        squareShape={true}/>
                </div>

                <Button
                    icon={<Add/>}
                    label={<FormattedMessage
                        id="addSmartSelection"
                        defaultMessage="Add Smart Selection"/>}
                    type="submit"
                    disabled={!selectedCategories || !selectedCategories.length > 0}/>
            </form>
        );
    }

    getCategories(categories) {
        if (categories) {
            const filteredCategories = categories.filter(category => category.active);
            return (
                filteredCategories.map(key => ({
                    label: key.name.charAt(0).toUpperCase() + key.name.slice(1),
                    value: key.id
                }))
            )
        }
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('categoriesFilterForm');
    const selectedCategories = selector(state, 'tasteFilter.categories');
    return {
        selectedCategories
    }
}

export default reduxForm({
    form: 'categoriesFilterForm',
})(connect(mapStateToProps, null)(withStyles(styles)(CategoriesFilterForm)))
