import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import withStyles from "@material-ui/core/styles/withStyles";
import Search from '@material-ui/icons/Search';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import RenderTextField from "../../../common/components/common/RenderTextField";
import Button from "../../../common/components/common/Button";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import Numpad from "./Numpad";

import {emailStringValidation, nameStringValidation} from "../../../common/utils/karming-validators";
import {getFormattedDate, getTime} from "../../../common/utils/date-utils";
import {setFormValue} from "../../actions/merchant-forms-actions";

const validate = values => {
    return Object.assign(
        emailStringValidation({name: "email", value: values.email}),
        nameStringValidation({name: "firstName", value: values.firstName}),
        nameStringValidation({name: "lastName", value: values.lastName})
    )
};

const SEARCH_CONSUMER_FORM_NAME = 'searchConsumerForm';

const styles = theme => ({
    titleWrapper: {
        position: 'relative'
    },
    buttonsWrapper: {
        display: 'inline-flex',
        justifyContent: 'center',
        width: '100%',
        "& > button": {
            flex: 'auto'
        },
        [theme.breakpoints.down("sm")]: {
            "& > button": {
                margin: theme.spacing(0.5),
                "& > span": {
                    fontSize: 12
                }
            }
        }
    }
});

class SearchConsumerForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        phoneMemberId: PropTypes.string,
        submitting: PropTypes.bool,
        pristine: PropTypes.bool,
        handleSubmit: PropTypes.func,
        change: PropTypes.func,
        reset: PropTypes.func
    };

    state = {
        mobileMemberIdOpen: true,
        emailOpen: false,
        nameOpen: false,
        dateOfPurchase: null
    };

    componentDidUpdate(prevProps) {
        const {setFormValue} = prevProps;
        const {dateOfPurchase} = this.state;

        if (dateOfPurchase !== prevProps.dateOfPurchase) {
            setFormValue(SEARCH_CONSUMER_FORM_NAME, 'dateOfPurchase', dateOfPurchase)
        }
    }

    handleClickMobileNumber(reset) {
        if (!this.state.mobileMemberIdOpen) {
            reset();
            this.setState({
                mobileMemberIdOpen: true,
                emailOpen: false,
                nameOpen: false
            });
        }
    }

    handleClickEmail(reset) {
        if (!this.state.emailOpen) {
            reset();
            this.setState({
                mobileMemberIdOpen: false,
                emailOpen: true,
                nameOpen: false
            });
        }
    }

    handleClickName(reset) {
        if (!this.state.nameOpen) {
            reset();
            this.setState({
                mobileMemberIdOpen: false,
                emailOpen: false,
                nameOpen: true
            });
        }
    }

    onNumberClickHandle(number, event) {
        event.preventDefault();
        this.props.change(
            "phoneMemberId",
            this.props.phoneMemberId
                ? this.props.phoneMemberId + number
                : number)
    }

    onDeleteClick() {
        this.props.change(
            "phoneMemberId",
            this.props.phoneMemberId
                ? this.props.phoneMemberId.slice(0, -1)
                : '')
    }

    handleOnEnterKeyPress(event) {
        const {handleSubmit, pristine} = this.props;
        if (event.key === 'Enter') {
            event.preventDefault();
            if (!pristine) {
                handleSubmit();
            }
        }
    }

    render() {
        const {classes, handleSubmit, reset, submitting, pristine} = this.props;
        const {mobileMemberIdOpen, emailOpen, nameOpen, dateOfPurchase} = this.state;
        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Container size={Container.SIZE_TINY}>
                    <Paper padding>
                        <div className={classes.titleWrapper}>
                            {/*<ChoosePurchaseDate setDateOfPurchase={this.setDateOfPurchase}/>*/}

                            <Typography
                                variant="h5"
                                paragraph>
                                <FormattedMessage
                                    id="registerPurchase"
                                    defaultMessage="Register purchase"/>
                            </Typography>
                        </div>

                        {dateOfPurchase && (
                            <Typography>
                                <IconButton
                                    onClick={() => this.deleteDateOfPurchase()}
                                    disableRipple
                                    disableFocusRipple>
                                    <DeleteForever color="error"/>
                                </IconButton>
                                <FormattedMessage
                                    id="registerPurchaseMade"
                                    defaultMessage="Register purchase made:"/>
                                &nbsp;
                                {getFormattedDate(dateOfPurchase)} {getTime(dateOfPurchase)}
                            </Typography>
                        )}

                        <div className={classes.buttonsWrapper}>
                            <Button
                                label={<FormattedMessage
                                    id="mobileMembershipId"
                                    defaultMessage="Mobile/ Member Id"/>}
                                disabled={submitting}
                                color={mobileMemberIdOpen ? "primary" : "default"}
                                onClick={this.handleClickMobileNumber.bind(this, reset)}/>

                            <Button
                                label={<FormattedMessage
                                    id="email"
                                    defaultMessage="Email"/>}
                                disabled={submitting}
                                color={emailOpen ? "primary" : "default"}
                                onClick={this.handleClickEmail.bind(this, reset)}/>

                            <Button
                                label={<FormattedMessage
                                    id="name"
                                    defaultMessage="Name"/>}
                                disabled={submitting}
                                color={nameOpen ? "primary" : "default"}
                                onClick={this.handleClickName.bind(this, reset)}/>
                        </div>

                        {mobileMemberIdOpen && (
                            <React.Fragment>
                                <Field
                                    name="phoneMemberId"
                                    hintText={<FormattedMessage
                                        id="mobileMembershipId"
                                        defaultMessage="Mobile/ Member Id"/>}
                                    component={RenderTextField}
                                    type="text"
                                    onKeyPress={(event) => {
                                        this.handleOnEnterKeyPress(event)
                                    }}
                                    helperText={<FormattedMessage
                                        id="provideMembershipIdOrFullMobileNumber"
                                        defaultMessage="Provide Membership-Id full mobile number"/>
                                    }/>

                                <Numpad
                                    on1Click={this.onNumberClickHandle.bind(this, "1")}
                                    on2Click={this.onNumberClickHandle.bind(this, "2")}
                                    on3Click={this.onNumberClickHandle.bind(this, "3")}
                                    on4Click={this.onNumberClickHandle.bind(this, "4")}
                                    on5Click={this.onNumberClickHandle.bind(this, "5")}
                                    on6Click={this.onNumberClickHandle.bind(this, "6")}
                                    on7Click={this.onNumberClickHandle.bind(this, "7")}
                                    on8Click={this.onNumberClickHandle.bind(this, "8")}
                                    on9Click={this.onNumberClickHandle.bind(this, "9")}
                                    on0Click={this.onNumberClickHandle.bind(this, "0")}
                                    onDeleteClick={this.onDeleteClick.bind(this)}/>
                            </React.Fragment>
                        )}

                        {emailOpen && (
                            <Field
                                name="email"
                                hintText={<FormattedMessage
                                    id="email"
                                    defaultMessage="Email"/>}
                                component={RenderTextField}
                                type="text"
                                helperText={<FormattedMessage
                                    id="provideMinimum5Characters"
                                    defaultMessage="Provide minimum 5 characters"/>
                                }/>
                        )}

                        {nameOpen && (
                            <React.Fragment>
                                <Field
                                    name="firstName"
                                    hintText={<FormattedMessage
                                        id="firstName"
                                        defaultMessage="First name"/>}
                                    component={RenderTextField}
                                    type="text"
                                    helperText={<FormattedMessage
                                        id="provideMinimum3Characters"
                                        defaultMessage="Provide minimum 3 characters"/>
                                    }/>

                                <Field
                                    name="lastName"
                                    hintText={<FormattedMessage
                                        id="lastName"
                                        defaultMessage="Last name"/>}
                                    component={RenderTextField}
                                    type="text"
                                    helperText={<FormattedMessage
                                        id="provideMinimum3Characters"
                                        defaultMessage="Provide minimum 3 characters"/>
                                    }/>
                            </React.Fragment>
                        )}

                        <Button
                            label={<FormattedMessage
                                id="findConsumer"
                                defaultMessage="Find consumer"/>}
                            icon={<Search/>}
                            type="submit"
                            disabled={pristine || submitting}/>
                    </Paper>
                </Container>
            </form>
        )
    }

    setDateOfPurchase = (value) => {
        this.setState({
            dateOfPurchase: value
        });
    };

    deleteDateOfPurchase = () => {
        this.setState({
            dateOfPurchase: null
        });
    };

}

function mapStateToProps(state) {
    const selector = formValueSelector('searchConsumerForm');
    const phoneMemberId = selector(state, 'phoneMemberId');
    const email = selector(state, 'email');
    const firstName = selector(state, 'firstName');
    const lastName = selector(state, 'lastName');
    const dateOfPurchase = selector(state, 'dateOfPurchase');

    return {
        phoneMemberId,
        email,
        firstName,
        lastName,
        dateOfPurchase
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default reduxForm({
    form: SEARCH_CONSUMER_FORM_NAME,
    validate
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchConsumerForm)))
