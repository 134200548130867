import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Button from "../../../../../common/components/common/Button";
import RenderTextField from "../../../../../common/components/common/RenderTextField";

import {UNSIGNED_INTEGER_FORMAT_CONFIG} from "../../../../utils/formats";
import {textLengthValidation} from "../../../../../common/utils/karming-validators";

const validate = values => {
    return textLengthValidation(
        {name: "reward", value: values.reward},
        60
    )
};

class StampcardCouponFormPage1 extends React.Component {

    static propTypes = {
        pageTitle: PropTypes.object,
        coupon: PropTypes.object,
        stampsAmount: PropTypes.number,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {handleSubmit, pageTitle, stampsAmount, coupon: {reward, dayValidation}} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {pageTitle}
                </Typography>

                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="rewardCoupon"
                        defaultMessage="Stamp card coupon"/>
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="whatDoesCustomerGetForReward"
                        defaultMessage="What does the customer get for reward?"/>

                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="brieflyDescribeReward"
                        defaultMessage="Briefly describe the reward the customer receives after collecting {stampsAmount} stamps."
                        values={{
                            stampsAmount: stampsAmount
                        }}/>
                </Typography>

                <Typography variant="body1">
                    <b>
                        <FormattedMessage
                            id="example"
                            defaultMessage="Example"/>:
                        &nbsp;
                    </b>

                    <FormattedMessage
                        id="stampcardCouponFormPage1.example"
                        defaultMessage={`"1 free coffee/lunch/cut”, ”50% discount on X "or" Bonus check: 100 kr"`}/>
                </Typography>

                <Field
                    name="reward"
                    hintText={<FormattedMessage
                        id="reward"
                        defaultMessage="Reward"/>}
                    component={RenderTextField}
                    helperText={<FormattedMessage
                        id="provideMaximum60Characters"
                        defaultMessage="Provide maximum 60 characters."/>}/>

                <Typography variant="body1">
                    <FormattedMessage
                        id="toBeUsedWithin"
                        defaultMessage="To be used within"/>:
                </Typography>

                <Field
                    name="dayValidation"
                    hintText={<FormattedMessage
                        id="days"
                        defaultMessage="Days"/>}
                    component={RenderTextField}
                    helperText={<FormattedMessage
                        id="provideNumberOfDays"
                        defaultMessage="Provide number of days"/>}
                    type="number"
                    formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}/>

                <Button
                    label={<FormattedMessage
                        id="next"
                        defaultMessage="Next"/>}
                    type="submit"
                    disabled={!reward || !dayValidation}/>
            </form>
        );
    }

}

export default reduxForm({
    form: "stampcardCouponForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
    touchOnChange: true,
})(StampcardCouponFormPage1);
