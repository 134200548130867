import React from "react";
import {Route} from "react-router-dom";

import PasswordResetRequest from "./PasswordResetRequest";
import PasswordReset from "./PasswordReset";
import Login from "./Login";

const AuthRoutes = () => {
    return [
        <Route
            key='1'
            exact path="/login"
            component={Login}/>,

        <Route
            key='2'
            exact path="/auth/password/request"
            component={PasswordResetRequest}/>,

        <Route
            key='3'
            exact path="/auth/password/reset/:id"
            component={PasswordReset}/>,
    ]
};

export default AuthRoutes
