import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Radio from '@material-ui/core/Radio';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Typography from "@material-ui/core/Typography";

import RenderTextField from "../../../common/components/common/RenderTextField";
import {required} from "../../../common/components/common/form/FormUtils";
import Button from "../../../common/components/common/Button";
import RenderRadioButtonGroup from "../../../common/components/common/form/RenderRadioButtonGroup";

import {UNSIGNED_INTEGER_FORMAT_CONFIG} from "../../utils/formats";
import {biggerThanZero} from "../../../common/utils/karming-validators";

const validate = values => {
    return biggerThanZero({name: "fixedValue", value: values.fixedValue})
};

class CouponFormPage3 extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        rewardFixedValue: PropTypes.string,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func,
    };

    render() {
        const {handleSubmit, title, previousPage, rewardFixedValue} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {title}
                </Typography>

                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="isTheRewardInSwedishKrona"
                        defaultMessage="Is the reward a bonus coupon in Swedish krona that the customer can use to pay?"/>
                </Typography>

                <Field
                    name="rewardFixedValue"
                    component={RenderRadioButtonGroup}>
                    <FormControlLabel
                        value="YES"
                        control={<Radio/>}
                        label={<FormattedMessage
                            id="yes"
                            defaultMessage="Yes"/>}/>

                    <FormControlLabel
                        value="NO"
                        control={<Radio/>}
                        label={<FormattedMessage
                            id="no"
                            defaultMessage="No"/>}/>
                </Field>

                {rewardFixedValue === "YES" && (
                    <Field
                        name="fixedValue"
                        component={RenderTextField}
                        type="text"
                        formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}
                        validate={required}
                        hintText={<FormattedMessage
                            id="bonusFixedValueInSEK"
                            defaultMessage="Bonus fixed value in SEK"/>}/>
                )}

                <Button
                    label={<FormattedMessage
                        id="previous"
                        defaultMessage="Previous"/>}
                    color="secondary"
                    onClick={previousPage}/>

                <Button
                    label={<FormattedMessage
                        id="next"
                        defaultMessage="Next"/>}
                    disabled={!rewardFixedValue}
                    type="submit"/>
            </form>
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('couponForm');
    const rewardFixedValue = selector(state, 'rewardFixedValue');
    return {
        rewardFixedValue
    }
}

export default reduxForm({
    form: "couponForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(connect(mapStateToProps, null)(CouponFormPage3))
