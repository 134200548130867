import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";
import DashedWrapper from "../../../common/components/common/DashedWrapper";
import RenderTextField from "../../../common/components/common/RenderTextField";

import {required, RequiredInfo} from "../../../common/components/common/form/FormUtils";

import {setFormValue} from "../../actions/merchant-forms-actions";

const styles = theme => ({
    fieldWrapper: {
        marginBottom: theme.spacing(2)
    }
});

class ClubPointsFormPage2 extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        title: PropTypes.object,
        description: PropTypes.string,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func
    };

    render() {
        const {classes, handleSubmit, title, previousPage, description, isUpdate} = this.props;

        if (description) {
            const newline = String.fromCharCode(13, 10);
            description.replace('\\n', newline);
        }

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {title}
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="HowShouldTheClubPointsBeDescribedToYourCustomers"
                        defaultMessage="How should the Club Points be described to your customers"/>
                    ?
                </Typography>

                <div className={classes.fieldWrapper}>
                    <Field
                        name="description"
                        hintText={<FormattedMessage
                            id="description"
                            defaultMessage="Description"/>}
                        component={RenderTextField}
                        type="text"
                        multiline={true}
                        rows={4}
                        rowsMax={4}
                        validate={required}/>
                </div>

                <Typography variant="body1">
                    <FormattedMessage
                        id="thisIsHowItsDisplayedToYourMembers"
                        defaultMessage="This is how it’s displayed to your members"/>
                    :
                </Typography>

                <DashedWrapper>
                    <Typography variant="body1">
                        {description}
                    </Typography>
                </DashedWrapper>

                <Button
                    label={<FormattedMessage
                        id="previous"
                        defaultMessage="Previous"/>}
                    color="secondary"
                    onClick={previousPage}/>

                <Button
                    label={isUpdate
                        ? <FormattedMessage
                            id="save"
                            defaultMessage="Save"/>
                        : <FormattedMessage
                            id="next"
                            defaultMessage="Next"/>}
                    type="submit"/>
                <RequiredInfo/>
            </form>
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('clubPointsForm');
    const description = selector(state, 'description');
    return {
        description
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default reduxForm({
    form: "clubPointsForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClubPointsFormPage2)))
