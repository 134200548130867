import React from "react";
import {FormattedMessage} from "react-intl";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import ReactTooltip from 'react-tooltip'
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Add from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";
import FilterList from "../selection/FilterList";
import LoadingText from "../../../common/components/common/LoadingText";

export const START_FILTER_MEMBERS_FOR_CAMPAIGN = "START_FILTER_MEMBERS_FOR_CAMPAIGN";
export const END_FILTER_MEMBERS_FOR_CAMPAIGN = "END_FILTER_MEMBERS_FOR_CAMPAIGN";

const styles = theme => ({
    itemWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0.5)
        }
    }
});

class SendoutFormPage1 extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        smsAccount: PropTypes.object,
        currentSmartSelection: PropTypes.object,
        campaigns: PropTypes.array,
        selectedMembers: PropTypes.array,
        membersWithEmail: PropTypes.array,
        membersWithPhone: PropTypes.array,
        categories: PropTypes.array,
        sendoutHistory: PropTypes.array,
        campaignId: PropTypes.number,
        handleOpenSmartSelection: PropTypes.func,
        handleSubmit: PropTypes.func
    };
    state = {
        isLoading: false,
    };

    static getDerivedStateFromProps(nextProps) {
        const {apiResult} = nextProps;

        if (apiResult && apiResult.requestId) {
            return ({
                isLoading: apiResult.requestId.startsWith(START_FILTER_MEMBERS_FOR_CAMPAIGN)
            });
        }

        return null;
    }

    render() {
        const {
            handleSubmit, selectedMembers, membersWithEmail, membersWithPhone,
            smsAccount, handleOpenSmartSelection, currentSmartSelection, campaigns, categories,
            campaignId, sendoutHistory
        } = this.props;
        const {isLoading} = this.state;

        let credits = 0;
        if (smsAccount && smsAccount.credits) {
            credits = smsAccount.credits;
        }

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    <FormattedMessage
                        id="campaignSendout"
                        defaultMessage="Campaign Sendout"/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="campaign.toWhichMembersSendout"
                        defaultMessage="To which members do you want to make a send-out?"/>
                </Typography>

                <Button
                    variant="outlined"
                    color="secondary"
                    icon={<Add/>}
                    label={<FormattedMessage
                        id="addSmartSelection"
                        defaultMessage="Add Smart Selection"/>}
                    onClick={handleOpenSmartSelection}/>

                {this.renderAudience(selectedMembers, membersWithEmail, membersWithPhone)}

                <Typography
                    variant="body1"
                    color={membersWithPhone > credits ? "error" : "inherit"}>
                      <span data-tip data-for='buyCreditsWarning'>
                          <FormattedMessage
                              id="balanceForCredits"
                              defaultMessage="Balance: {credits} Credits"
                              values={{
                                  credits: credits
                              }}/>
                      </span>
                </Typography>

                {membersWithPhone > credits && (
                    <ReactTooltip
                        id='buyCreditsWarning'
                        type='warning'>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="youHaveToBuyCreditsToUseSmsSendout"
                                defaultMessage="You have to buy credits to use sms sendout"/>
                        </Typography>
                    </ReactTooltip>
                )}

                <FilterList
                    sendout={true}
                    campaignId={campaignId}
                    editable={true}
                    currentSmartSelection={currentSmartSelection}
                    campaigns={campaigns}
                    sendoutHistory={sendoutHistory}
                    categories={categories}/>

                <Button
                    label={<FormattedMessage
                        id="next"
                        defaultMessage="Next"/>}
                    type="submit"
                    disabled={isLoading || membersWithEmail.length + membersWithPhone.length === 0}/>
            </form>
        )
    }

    renderAudience(selectedMembers, membersWithEmail, membersWithPhone) {
        const {classes} = this.props;
        const {isLoading} = this.state;

        const countingText = (
            <Typography variant="body1" color="error">
                <LoadingText text={<FormattedMessage id="counting" defaultMessage="Counting"/>}/>
            </Typography>
        );

        return (
            <React.Fragment>
                <div className={classes.itemWrapper}>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="currentSelection"
                            defaultMessage="Current selection"/>:
                    </Typography>

                    {isLoading
                        ? countingText
                        : <Typography variant="body1">
                            {selectedMembers.length}
                            &nbsp;
                            <FormattedMessage
                                id="members"
                                defaultMessage="Members"/>
                        </Typography>
                    }
                </div>

                <div className={classes.itemWrapper}>
                    <Typography variant="subtitle1">
                        SMS:
                    </Typography>

                    {isLoading
                        ? countingText
                        : <Typography variant="body1">
                            {membersWithPhone.length}
                        </Typography>
                    }

                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="email"
                            defaultMessage="Email"/>:
                    </Typography>

                    {isLoading
                        ? countingText
                        : <Typography variant="body1">
                            {membersWithEmail.length}
                        </Typography>
                    }
                </div>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        audience: state.merchant.audience,
        apiResult: state.app.status
    }
}

export default reduxForm({
    form: "sendoutForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connect(mapStateToProps, null)(withStyles(styles)(SendoutFormPage1)))
