import React, {useState} from "react";
import PropTypes from "prop-types";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/esm/Edit";
import {makeStyles} from "@material-ui/core/styles/index";

import RenderTextField from "../../../common/components/common/RenderTextField";

import ChangeEarnedPointsDialog from "./ChangeEarnedPointsDialog";

const useStyles = makeStyles({
    editButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }
});

const ChangeEarnedPointsField = props => {
    const {remainingPoints, earnedPoints, purchase, setFormValue, minEarnedPoints, usedPoints, currentBalance, ...rest} = props;
    const [openDialog, setOpenDialog] = useState(false);
    const classes = useStyles();

    return (
        <React.Fragment>
            <RenderTextField
                {...rest}
                hasDashedBorder
                disabled
                type="number"
                inputMode="numeric"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setOpenDialog(true)}
                            disableRipple
                            disableFocusRipple
                            className={classes.editButton}>
                            <Edit color="secondary"/>
                        </IconButton>
                    </InputAdornment>
                }/>

            <ChangeEarnedPointsDialog
                purchase={purchase}
                earnedPoints={earnedPoints}
                minEarnedPoints={minEarnedPoints}
                currentBalance={currentBalance}
                usedPoints={usedPoints}
                setFormValue={setFormValue}
                remainingPoints={remainingPoints}
                openDialog={openDialog}
                closeDialog={() => setOpenDialog(false)}
                onSubmit={(newValue) => {
                    props.input.onChange(newValue);
                    setOpenDialog(false)
                }}/>
        </React.Fragment>
    )
};

ChangeEarnedPointsField.propTypes = {
    purchase: PropTypes.object,
    remainingPoints: PropTypes.number,
    earnedPoints: PropTypes.number
};

export default ChangeEarnedPointsField
