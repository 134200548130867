import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
    startGuideButton: {
        paddingTop: 130,
        paddingLeft: theme.spacing(1),
        height: 180,
        width: 22,
        zIndex: 9999,
        position: 'fixed',
        right: 0,
        top: 200,
        fontSize: theme.typography.subtitle2.fontSize,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.white}`,
        borderRadius: '20px 0 0 20px',
        transition: 'all 300ms ease',
        "&:hover": {
            opacity: 0.75
        },
        "&:focus": {
            outline: 0
        }
    },
    smallSize: {
        paddingTop: 35,
        height: 90
    },
    bigSize: {
        paddingTop: 130,
        height: 180
    }
});

const StartGuideButton = ({classes, size, onClick, label}) => {
    return (
        <button
            className={(classnames(classes.startGuideButton, classes[size + "Size"]))}
            onClick={onClick}>
            {label}
        </button>
    )
};

StartGuideButton.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.object,
    size: PropTypes.string,
    onClick: PropTypes.func
};

export default withStyles(styles)(StartGuideButton)
