import React from "react";
import {getFormValues, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import Save from '@material-ui/icons/Save';
import Edit from '@material-ui/icons/Edit';

import Button from "../../../../../common/components/common/Button";
import StampcardCouponPage from "../../../../../common/components/common/stampcard/StampcardCouponPage";

class StampcardCouponFormPage5 extends React.Component {

    static propTypes = {
        coupon: PropTypes.object,
        initialValues: PropTypes.object,
        title: PropTypes.object,
        formValues: PropTypes.object,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        firstPage: PropTypes.func
    };

    shouldComponentUpdate(nextProps) {
        return this.props && nextProps && this.props.coupon !== nextProps.coupon;
    }

    render() {
        const {handleSubmit, submitting, initialValues, firstPage, coupon, title, formValues} = this.props;

        if (!(coupon && ((coupon.image && coupon.image.newData) || coupon.imagePath))) {
            coupon.imagePath = initialValues.imagePath
        }

        if (formValues && (!formValues.image || !formValues.image.isModified)) {
            delete formValues['image'];
        }

        let isDirty = !initialValues ||
            initialValues && formValues &&
            ((formValues.image && formValues.image.isModified) || this.isFormChanged(formValues, initialValues));

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="subtitle1"
                    paragraph>
                    {title}
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="coupon"
                        defaultMessage="Coupon"/>
                </Typography>

                <StampcardCouponPage stampcardCoupon={coupon}/>

                <Button
                    icon={<Edit/>}
                    label={<FormattedMessage
                        id="edit"
                        defaultMessage="Edit"/>}
                    color={'secondary'}
                    onClick={firstPage}/>

                {isDirty && (
                    <Button
                        icon={<Save/>}
                        label={this.getSaveButtonLabel(initialValues)}
                        type="submit"
                        disabled={submitting}/>
                )}
            </form>
        );
    }

    getSaveButtonLabel(initialValues) {
        return initialValues ?
            <FormattedMessage
                id="save"
                defaultMessage="Save"/> :
            <React.Fragment>
                <FormattedMessage
                    id="save"
                    defaultMessage="Save"/>
                &nbsp;
                <FormattedMessage
                    id="draft"
                    defaultMessage="draft"/>
            </React.Fragment>
    }

    isFormChanged(formValues, initialValues) {
        return JSON.stringify(formValues) !== JSON.stringify(initialValues)
    }

}

function mapStateToProps(state) {
    return {
        formValues: getFormValues('stampcardCouponForm')(state),
    }
}

export default reduxForm({
    form: "stampcardCouponForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connect(mapStateToProps, null)(StampcardCouponFormPage5))
