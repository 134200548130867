import React from "react";
import {Route} from "react-router-dom";
import PointOfSale from "./account/saleslocation/locations/PointOfSale";
import Members from "./member/Members";
import SearchConsumer from "./purchase/SearchConsumer";
import Categories from "./account/categories/Categories";
import SmsUsage from "./account/sms/SmsUsage";
import RegisterPurchase from "./purchase/RegisterPurchase";
import CommercialChain from "./account/saleslocation/chains/CommercialChain";
import SmsService from "./account/sms/SmsService";
import MerchantPurchases from "./purchase/MerchantPurchases";
import Campaigns from "./campaign/Campaigns";
import PrivacyPolicyHome from "./account/terms/PrivacyPolicyHome";
import CommercialChains from "./account/saleslocation/chains/CommercialChains";
import Campaign from "./campaign/Campaign";
import MembershipConditionsHome from "./account/terms/MembershipConditionsHome";
import BillingDetails from "./account/payments/BillingDetails";
import InviteMember from "./member/InviteMember";
import MerchantSupport from "./account/MerchantSupport";
import MerchantIncidentReport from "./account/MerchantIncidentReport";
import PointsOfSale from "./account/saleslocation/locations/PointsOfSale";
import AuthRequiredRoute from "../../common/components/auth/AuthRequiredRoute";
import WelcomeOffering from "./campaign/WelcomeOffering";
import AttractMembers from "./campaign/AttractMembers";
import SinglePoSChain from "./account/saleslocation/SinglePoSChain";
import MerchantOnboarding from "./onboarding/MerchantOnboarding";
import MerchantOnboardingVerification from "./onboarding/MerchantOnboardingVerification";
import MerchantMemberEditor from "./member/MerchantMemberEditor";
import Sendouts from "./sendout/Sendouts";
import {KarmingDataProtection} from "./account/dataprotection/KarmingDataProtection";
import {KarmingRecordList} from "./account/dataprotection/KarmingRecordList";
import {KarmingDataProtectionUsefulLinks} from "./account/dataprotection/KarmingDataProtectionUsefulLinks";
import KarmingDataProcessingAgreementContainer from "./account/dataprotection/KarmingDataProcessingAgreementContainer";
import OnboardingConfirmation from "../../common/components/common/OnboardingConfirmation";
import InviteMerchant from "./company/InviteMerchant";
import MerchantUsers from "./company/MerchantUsers";
import MerchantEdit from "./company/MerchantEdit";
import SearchedMemberList from "./purchase/SearchedMemberList";
import ClubPoints from "./points/ClubPoints";
import ClubPointsStarter from "./points/ClubPointsStarter";
import ClubPointsForm from "./points/ClubPointsForm";
import ClubPointsEdit from "./points/ClubPointsEdit";
import Coupon from "./points/Coupon";
import SmartSelectionHome from "./selection/SmartSelectionHome";
import Stampcard from "./account/stampcard/Stampcard";
import Stampcards from "./account/stampcard/Stampcards";
import StampcardCoupon from "./account/stampcard/stampcardCoupon/StampcardCoupon";

const MerchantRoutes = (props) => {
    const {auth, app, user, chains} = props;

    const chain = chains.current;

    return [
        <Route
            key='1'
            exact path="/onboarding"
            component={MerchantOnboarding}/>,
        <Route
            key='37'
            exact path="/onboarding/:source"
            component={MerchantOnboarding}/>,

        <Route
            key='38'
            exact path="/onboarding/confirmation"
            component={OnboardingConfirmation}/>,
        <Route
            key='2'
            exact path="/onboarding/confirmation/:id/:companyId"
            app={app}
            auth={auth}
            component={MerchantOnboardingVerification}/>,

        <AuthRequiredRoute
            key='3'
            exact path="/purchase/search"
            auth={auth}
            app={app}
            chain={chain}
            component={SearchConsumer}/>,
        <AuthRequiredRoute
            key='4'
            exact path="/purchase/register/:consumer"
            auth={auth}
            app={app}
            chain={chain}
            component={RegisterPurchase}/>,
        <AuthRequiredRoute
            key='5'
            exact path="/purchases/update/:consumer/:purchase"
            auth={auth}
            app={app}
            chain={chain}
            component={RegisterPurchase}/>,
        <AuthRequiredRoute
            key='6'
            exact path="/purchases"
            auth={auth}
            app={app}
            chain={chain}
            component={MerchantPurchases}/>,

        <AuthRequiredRoute
            key='7'
            exact path="/invite"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={InviteMember}/>,

        <AuthRequiredRoute
            key='8'
            exact path="/attract"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={AttractMembers}/>,

        <AuthRequiredRoute
            key='9'
            exact path="/campaign/create"
            auth={auth}
            chain={chain}
            component={Campaign}/>,
        <AuthRequiredRoute
            key='10'
            exact path="/campaign/welcome"
            auth={auth}
            chain={chain}
            component={WelcomeOffering}/>,
        <AuthRequiredRoute
            key='11'
            exact path="/campaigns"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={Campaigns}/>,
        <AuthRequiredRoute
            key='32'
            exact path="/sendouts"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={Sendouts}/>,
        <AuthRequiredRoute
            key='12'
            exact path="/campaigns/:id"
            auth={auth}
            chain={chain}
            component={Campaign}/>,

        <AuthRequiredRoute
            key='14'
            exact path="/campaigns/:id/sendout"
            auth={auth}
            chain={chain}
            sendout={true}
            component={SmartSelectionHome}/>,

        <AuthRequiredRoute
            key='15'
            exact path="/categories"
            auth={auth}
            app={app}
            chain={chain}
            component={Categories}/>,

        <AuthRequiredRoute
            key='16'
            exact path="/members"
            auth={auth}
            app={app}
            chain={chain}
            component={Members}/>,
        <AuthRequiredRoute
            key='17'
            exact path="/poschain/create"
            auth={auth}
            app={app}
            component={SinglePoSChain}/>,

        <AuthRequiredRoute
            key='18'
            exact path="/chains/create"
            auth={auth}
            app={app}
            component={CommercialChain}/>,
        <AuthRequiredRoute
            key='19'
            exact path="/chains/:id"
            auth={auth}
            app={app}
            component={CommercialChain}/>,
        <AuthRequiredRoute
            key='20'
            exact path="/chains"
            auth={auth}
            app={app}
            component={CommercialChains}/>,
        <AuthRequiredRoute
            key='21'
            exact path="/locations/create"
            auth={auth}
            app={app}
            chain={chain}
            component={PointOfSale}/>,
        <AuthRequiredRoute
            key='22'
            exact path="/locations/:id"
            auth={auth}
            app={app}
            chain={chain}
            component={PointOfSale}/>,
        <AuthRequiredRoute
            key='23'
            exact path="/locations"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={PointsOfSale}/>,

        <AuthRequiredRoute
            key='24'
            exact path="/chain/membership/terms"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={MembershipConditionsHome}/>,
        <AuthRequiredRoute
            key='25'
            exact path="/chain/membership/privacy"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={PrivacyPolicyHome}/>,

        <AuthRequiredRoute
            key='26'
            exact path="/sms"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={SmsService}/>,

        <AuthRequiredRoute
            key='27'
            exact path="/sms/usage"
            auth={auth}
            app={app}
            user={user}
            component={SmsUsage}/>,

        <AuthRequiredRoute
            key='28'
            exact path="/billings/details"
            auth={auth}
            app={app}
            user={user}
            component={BillingDetails}/>,

        <AuthRequiredRoute
            key='29'
            exact path="/support"
            auth={auth}
            component={MerchantSupport}/>,
        <AuthRequiredRoute
            key='30'
            exact path="/account/incidentreport"
            auth={auth}
            component={MerchantIncidentReport}/>,
        <AuthRequiredRoute
            key='31'
            exact path="/members/:id"
            auth={auth}
            app={app}
            chain={chain}
            component={MerchantMemberEditor}/>,
        <AuthRequiredRoute
            key='33'
            exact path="/dataprotection/intro"
            auth={auth}
            component={KarmingDataProtection}/>,
        <AuthRequiredRoute
            key='34'
            exact path="/dataprotection/recordlist"
            auth={auth}
            component={KarmingRecordList}/>,
        <AuthRequiredRoute
            key='35'
            exact path="/dataprotection/usefullinks"
            auth={auth}
            component={KarmingDataProtectionUsefulLinks}/>,
        <AuthRequiredRoute
            key='36'
            exact path="/dataprotection/dpa"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={KarmingDataProcessingAgreementContainer}/>,
        <AuthRequiredRoute
            key='39'
            exact path="/users/invite"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={InviteMerchant}/>,
        <AuthRequiredRoute
            key='41'
            exact path="/users"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={MerchantUsers}/>,
        <AuthRequiredRoute
            key='42'
            exact path="/users/:id"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={MerchantEdit}/>,

        <AuthRequiredRoute
            key='43'
            exact path="/purchase/members"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={SearchedMemberList}/>,

        <AuthRequiredRoute
            key='44'
            exact path="/points"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={ClubPoints}/>,

        <AuthRequiredRoute
            key='45'
            exact path="/points/start"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={ClubPointsStarter}/>,

        <AuthRequiredRoute
            key='46'
            exact path="/points/define"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={ClubPointsForm}/>,

        <AuthRequiredRoute
            key='47'
            exact path="/points/edit"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={ClubPointsEdit}/>,

        <AuthRequiredRoute
            key='48'
            exact path="/coupons/create"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={Coupon}/>,

        <AuthRequiredRoute
            key='49'
            exact path="/coupons/:id"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            isUpdate={true}
            component={Coupon}/>,

        <AuthRequiredRoute
            key='50'
            exact path="/campaigns/recipients/:id"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={SmartSelectionHome}/>,

        <AuthRequiredRoute
            key='51'
            exact path="/stampcard"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={Stampcard}/>,

        <AuthRequiredRoute
            key='52'
            exact path="/stampcard/define"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={Stampcard}/>,

        <AuthRequiredRoute
            key='53'
            exact path="/stampcards"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={Stampcards}/>,

        <AuthRequiredRoute
            key='54'
            exact path="/stampcards/:id"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            isUpdate={true}
            component={Stampcard}/>,

        <AuthRequiredRoute
            key='55'
            exact path="/stampcard/:stampcardId/coupon"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            component={StampcardCoupon}/>,

        <AuthRequiredRoute
            key='56'
            exact path="/stampcard/:stampcardId/coupon/:couponId"
            auth={auth}
            app={app}
            user={user}
            chain={chain}
            isUpdate={true}
            component={StampcardCoupon}/>,
    ]
};

export default MerchantRoutes;