import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import AppBar from '@material-ui/core/AppBar';

import {renderKarmingLogo} from '../../../common/components/AppMenu';
import AppToolbar from "../../../common/components/common/menu/AppToolbar";
import LoggedUserAppBar from "./LoggedUserAppBar";

class AppBarMenu extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        auth: PropTypes.object,
        chains: PropTypes.object,
        history: PropTypes.object,
        currentPoS: PropTypes.object,
        logout: PropTypes.func,
        setSidebarVisibility: PropTypes.func,
        setAccountMenuVisibility: PropTypes.func,
        setStartGuideVisibility: PropTypes.func
    };

    render() {
        const {
            chains, auth, app, user, currentPoS, logout, setAccountMenuVisibility,
            setStartGuideVisibility, setSidebarVisibility
        } = this.props;

        if (!auth.authenticated) {
            return (
                <AppBar position="static">
                    <AppToolbar>
                        {renderKarmingLogo()}
                    </AppToolbar>
                </AppBar>
            );
        }

        return (
            <LoggedUserAppBar
                auth={auth}
                app={app}
                user={user}
                chains={chains}
                currentPoS={currentPoS}
                logout={logout}
                setSidebarVisibility={setSidebarVisibility}
                setAccountMenuVisibility={setAccountMenuVisibility}
                setStartGuideVisibility={setStartGuideVisibility}/>
        );
    }

}

export default withRouter(AppBarMenu)
