import React, {useEffect} from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import Typography from "@material-ui/core/Typography";
import Save from '@material-ui/icons/Save';
import {makeStyles} from "@material-ui/core/styles";

import RenderTextField from "../../../common/components/common/RenderTextField";
import Button from "../../../common/components/common/Button";
import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import CustomerDetailsBar from "./CustomerDetailsBar";
import {ClubPointsMessage} from "./history/ClubPointsMessage";

import {getTranslatedDate} from "../../../common/utils/date-utils";
import {UNSIGNED_FLOAT_FORMAT_CONFIG} from "../../utils/formats";

const useStyles = makeStyles((theme => ({
    wrapper: {
        marginTop: theme.spacing(1),
        textAlign: 'left'
    },
    paper: {
        margin: theme.spacing(1)
    },
    buttonsWrapper: {
        display: 'inline-flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: theme.spacing(1)
    }
})));

export const REGISTER_PURCHASE_FORM_NAME = 'registerPurchaseForm';

let RegisterPurchaseForm = props => {
    const {
        handleSubmit, submitting, consumer, clubPointsActive, categoriesOptions, couponsOptions,
        campaignOptions, stampcards, isUpdate, initialValues, history, consumerPoints,
        purchaseStampcardUsage, consumerStampcardCoupons, subtractTradedPoints, addTradedPoints,
        removeMember, merchantUpdateMember, totalAmount, tradedPoints, history: {location: {dateOfPurchase}},
        setFormValue, chain
    } = props;
    const classes = useStyles();

    const madeChanges = (totalAmount ? Number(totalAmount) : 0) - (tradedPoints ? tradedPoints : 0);
    const currentBalance = consumerPoints + madeChanges;

    useEffect(() => {
        setFormValue(REGISTER_PURCHASE_FORM_NAME, 'dateOfPurchase', dateOfPurchase)
    }, []);

    return (
        <form onSubmit={handleSubmit} autoComplete="on">
            <Typography
                variant="h5"
                paragraph>
                <FormattedMessage
                    id="registerPurchase"
                    defaultMessage="Register purchase"/>
                {dateOfPurchase && ` - ${getTranslatedDate(dateOfPurchase, chain.language)}`}
            </Typography>

            <CustomerDetailsBar
                consumer={consumer}
                history={history}
                removeMember={removeMember}
                merchantUpdateMember={merchantUpdateMember}/>

            <div className={classes.wrapper}>
                {stampcards && stampcards.length > 0 && (
                    <React.Fragment>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="stampcards"
                                defaultMessage="Stamp cards"/>
                        </Typography>

                        <Field
                            name="stampcards"
                            isUpdate={isUpdate}
                            component={ImageBoxRow}
                            options={stampcards}
                            purchaseStampcardUsage={purchaseStampcardUsage}
                            stampcard={true}
                        />
                    </React.Fragment>
                )}

                {consumerStampcardCoupons && consumerStampcardCoupons.length > 0 && (
                    <React.Fragment>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="Stamp card rewards"
                                defaultMessage="Stamp cards rewards"/>
                        </Typography>

                        <Field
                            name="stampcardCouponUsages"
                            isUpdate={isUpdate}
                            component={ImageBoxRow}
                            options={consumerStampcardCoupons}
                            stampcardCoupon={true}
                            initiallySelectedOptions={initialValues
                                ? initialValues.stampcardCouponUsages
                                : null}/>
                    </React.Fragment>
                )}

                {campaignOptions && campaignOptions.length > 0 &&
                <React.Fragment>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="campaigns"
                            defaultMessage="Campaigns"/>
                    </Typography>

                    <Field
                        name="campaigns"
                        component={ImageBoxRow}
                        options={campaignOptions}
                        initiallySelectedOptions={initialValues ? initialValues.campaigns : null}/>
                </React.Fragment>
                }

                {clubPointsActive && couponsOptions && couponsOptions.length > 0 && (
                    <React.Fragment>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="coupons"
                                defaultMessage="Coupons"/>
                        </Typography>

                        <ClubPointsMessage
                            currentBalance={currentBalance}
                            madeChanges={madeChanges}
                        />

                        <Field
                            name="coupons"
                            component={ImageBoxRow}
                            coupon={true}
                            consumerPoints={consumerPoints}
                            isUpdate={isUpdate}
                            options={couponsOptions}
                            remainingPoints={currentBalance}
                            subtractTradedPoints={subtractTradedPoints.bind(this)}
                            addTradedPoints={addTradedPoints.bind(this)}
                            initiallySelectedOptions={initialValues ? initialValues.coupons : null}/>
                    </React.Fragment>
                )}

                {categoriesOptions && categoriesOptions.length > 0 &&
                <React.Fragment>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="categories"
                            defaultMessage="Categories"/>
                    </Typography>

                    <Field
                        name="categories"
                        component={ImageBoxRow}
                        options={categoriesOptions}
                        squareShape={true}
                        initiallySelectedOptions={initialValues ? initialValues.categories : null}/>
                </React.Fragment>
                }

                <Field
                    name="totalAmount"
                    hintText={<FormattedMessage
                        id="totalAmountPaid"
                        defaultMessage="Total amount paid"/>}
                    component={RenderTextField}
                    formatConfig={UNSIGNED_FLOAT_FORMAT_CONFIG}
                    type="number"
                    inputMode="numeric"/>

                <div className={classes.buttonsWrapper}>
                    <Button
                        icon={<Save/>}
                        label={<FormattedMessage
                            id="register"
                            defaultMessage="Register"/>}
                        type="submit"
                        disabled={submitting}/>
                </div>
            </div>
        </form>
    );

};

RegisterPurchaseForm.propTypes = {
    consumer: PropTypes.object,
    initialValues: PropTypes.object,
    history: PropTypes.object,
    purchaseStampcardUsage: PropTypes.array,
    categoriesOptions: PropTypes.array,
    consumerStampcardCoupons: PropTypes.array,
    couponsOptions: PropTypes.array,
    campaignOptions: PropTypes.array,
    stampcards: PropTypes.array,
    consumerPoints: PropTypes.number,
    remainingPoints: PropTypes.number,
    clubPointsActive: PropTypes.bool,
    isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    submitting: PropTypes.bool,
    setFormValue: PropTypes.func,
    handleSubmit: PropTypes.func,
    removeMember: PropTypes.func,
    merchantUpdateMember: PropTypes.func,
    removePurchase: PropTypes.func,
    addTradedPoints: PropTypes.func,
    subtractTradedPoints: PropTypes.func,
    chain: PropTypes.object,
};

RegisterPurchaseForm = reduxForm({
    form: REGISTER_PURCHASE_FORM_NAME
})(RegisterPurchaseForm);

const selector = formValueSelector(REGISTER_PURCHASE_FORM_NAME);
RegisterPurchaseForm = connect(state => {
    const totalAmount = selector(state, 'totalAmount');
    const dateOfPurchase = selector(state, 'dateOfPurchase');
    return {
        totalAmount,
        dateOfPurchase
    }
})(RegisterPurchaseForm);

export default RegisterPurchaseForm
