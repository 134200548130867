import React from "react";
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";
import Palette from '@material-ui/icons/Palette';
import TextFields from '@material-ui/icons/TextFields';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import IconButton from "@material-ui/core/IconButton";

import {secondaryColor} from "../../../common/utils/color-const";
import {STAMPCARD_FORM_NAME} from "../account/stampcard/StampcardForm";
import RenderColorPicker from "./RenderColorPicker";

const styles = {
    textItem: {
        margin: 0,
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: secondaryColor
    },
    disabledIcon: {
        fill: 'currentColor',
        verticalAlign: 'middle',
    },
    icon: {
        fill: secondaryColor,
        verticalAlign: 'middle',
    },
    textField: {
        width: '100%'
    },
    editorSectionRight: {
        padding: '0px 0px 0px 16px'
    },
    editorSectionLeft: {
        padding: '0px 16px 0px 48px'
    },
    iconButton: {
        padding: 12
    }
};

class StampTextEditor extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        initialValues: PropTypes.object,
        onChange: PropTypes.func,
        setFormValue: PropTypes.func
    };

    constructor(props) {
        super(props);

        const {initialValues: {titleColor, titleSize, subtitleColor, subtitleSize}} = props;
        this.state = {
            titleColor: titleColor,
            titleSize: titleSize,
            subtitleColor: subtitleColor,
            subtitleSize: subtitleSize,
        }
    }

    onTitleColorChanged(color) {
        const {titleSize, subtitleColor, subtitleSize} = this.state;
        const {setFormValue} = this.props;

        this.setState({
            titleColor: color
        });

        const outputState = {
            titleSize: titleSize,
            titleColor: color,
            subtitleSize: subtitleSize,
            subtitleColor: subtitleColor
        };

        this.notifyChanged(outputState);
        setFormValue(STAMPCARD_FORM_NAME, 'titleColor', color);
    }

    onSubtitleColorChanged(color) {
        const {titleSize, titleColor, subtitleSize} = this.state;
        const {setFormValue} = this.props;

        this.setState({
            subtitleColor: color
        });

        const outputState = {
            titleSize: titleSize,
            titleColor: titleColor,
            subtitleSize: subtitleSize,
            subtitleColor: color
        };
        this.notifyChanged(outputState);
        setFormValue(STAMPCARD_FORM_NAME, 'subtitleColor', color);
    }

    onTitleFontChanged(add) {
        const {titleSize, titleColor, subtitleColor, subtitleSize} = this.state;
        const {setFormValue} = this.props;

        const titleSizeAfter = titleSize + (add ? 1 : -1);
        this.setState({
            titleSize: titleSizeAfter,
        });

        const outputState = {
            titleSize: titleSizeAfter,
            titleColor: titleColor,
            subtitleSize: subtitleSize,
            subtitleColor: subtitleColor
        };
        this.notifyChanged(outputState);
        setFormValue(STAMPCARD_FORM_NAME, 'titleSize', titleSizeAfter);
    }

    onSubtitleFontChanged(add) {
        const {titleSize, titleColor, subtitleColor, subtitleSize} = this.state;
        const {setFormValue} = this.props;
        const subtitleSizeAfter = subtitleSize + (add ? 1 : -1);
        this.setState({
            subtitleSize: subtitleSizeAfter,
        });

        const outputState = {
            titleSize: titleSize,
            titleColor: titleColor,
            subtitleSize: subtitleSizeAfter,
            subtitleColor: subtitleColor
        };
        this.notifyChanged(outputState);
        setFormValue(STAMPCARD_FORM_NAME, 'subtitleSize', subtitleSizeAfter);
    }

    notifyChanged(value) {
        const {onChange} = this.props;
        if (onChange) {
            onChange(value)
        }
    }

    render() {
        const {classes} = this.props;
        const {titleSize, subtitleSize, titleColor, subtitleColor} = this.state;

        return (
            <div>
                <div className={classes.textItem}>

                    <div className={classes.editorSectionLeft}>
                        <div style={{width: 80, display: 'inline-flex', justifyContent: 'flex-end'}}>
                            <FormattedMessage id="header" defaultMessage={`Header`}/>:
                            <Palette className={classes.icon}/>
                            <RenderColorPicker
                                color={titleColor}
                                onChange={this.onTitleColorChanged.bind(this)}/>
                        </div>
                    </div>

                    <div className={classes.editorSectionRight}>

                        <TextFields className={classes.icon}/>
                        <IconButton disabled={titleSize === 10} onClick={this.onTitleFontChanged.bind(this, false)}
                                    className={classes.iconButton}>
                            <RemoveCircleOutline
                                className={titleSize === 10 ? classes.disabledIcon : classes.icon}/>
                        </IconButton>
                        &nbsp;
                        <IconButton disabled={titleSize === 64} onClick={this.onTitleFontChanged.bind(this, true)}
                                    className={classes.iconButton}>
                            <AddCircleOutline className={titleSize === 64 ? classes.disabledIcon : classes.icon}/>
                        </IconButton>
                    </div>
                </div>

                <div className={classes.textItem}>

                    <div className={classes.editorSectionLeft}>
                        <div style={{width: 80, display: 'inline-flex', justifyContent: 'flex-end'}}>
                            <FormattedMessage
                                id="subheader"
                                defaultMessage={`Subheader`}/>:
                            <Palette className={classes.icon}/>
                            <RenderColorPicker
                                color={subtitleColor}
                                onChange={this.onSubtitleColorChanged.bind(this)}/>
                        </div>
                    </div>

                    <div className={classes.editorSectionRight}>

                        <TextFields className={classes.icon}/>
                        <IconButton disabled={subtitleSize === 10}
                                    onClick={this.onSubtitleFontChanged.bind(this, false)}
                                    className={classes.iconButton}>
                            <RemoveCircleOutline
                                className={subtitleSize === 10 ? classes.disabledIcon : classes.icon}/>
                        </IconButton>
                        &nbsp;
                        <IconButton disabled={subtitleSize === 40}
                                    onClick={this.onSubtitleFontChanged.bind(this, true)}
                                    className={classes.iconButton}>
                            <AddCircleOutline
                                className={subtitleSize === 40 ? classes.disabledIcon : classes.icon}/>
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }

}

export default withStyles(styles)(StampTextEditor);
