import React from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {makeStyles} from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    textRemoved: {
        textDecoration: 'line-through'
    }
});

export const CouponsUsageList = props => {
    const {purchase, array, index} = props;
    const classes = useStyles();

    const previousCoupons = array[0] && array[index - 1]
        ? array[index - 1].coupons
        : array[0];

    const removedCoupons = array[index - 1]
        && previousCoupons
        && previousCoupons.filter(prevCoupon => purchase.coupons.every(currCoupon => currCoupon.id !== prevCoupon.id));

    return (
        <React.Fragment>
            {removedCoupons && removedCoupons.length > 0 && removedCoupons.map((coupon, couponIndex) =>
                renderCouponDetails(coupon, couponIndex, true))}
            {purchase.coupons && purchase.coupons.length > 0 && purchase.coupons.map((coupon, couponIndex) =>
                renderCouponDetails(coupon, couponIndex, false))}
        </React.Fragment>
    );

    function renderCouponDetails(coupon, index, isRemovedCouponUsage) {
        return (
            <Typography
                key={index}
                variant="subtitle2"
                color={isRemovedCouponUsage ? 'error' : 'inherit'}
                className={isRemovedCouponUsage ? classes.textRemoved : undefined}>
                -{coupon.pointsRequired} <FormattedMessage
                id="couponPoints"
                defaultMessage="points"/>
                , &quot;{coupon.name}&quot; (id: {coupon.id})
            </Typography>
        )
    }
};

CouponsUsageList.propTypes = {
    purchase: PropTypes.object.isRequired,
    array: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired
};
