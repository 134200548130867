import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";

class StampcardFormPage7 extends React.Component {

    static propTypes = {
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        generateTemplate: PropTypes.func,
        setIsTemplate: PropTypes.func,
        nextPage: PropTypes.func
    };

    render() {
        const {submitting, handleSubmit, nextPage, setIsTemplate, generateTemplate} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="termsForStampCard"
                        defaultMessage="Terms for stamp card"/>
                </Typography>

                <Typography
                    variant="body1"
                    paragraph>
                    <FormattedMessage
                        id="stamp-card.formulateYourTerms"
                        defaultMessage="Formulate your terms for stamp-card to be added to your membership terms & conditions."/>
                </Typography>

                <Typography
                    variant="body1"
                    paragraph>
                    <FormattedMessage
                        id="club-points.youCanEitherFormulate"
                        defaultMessage="You can either formulate your own, or start using the ready made template."/>
                </Typography>

                <Button
                    variant="text"
                    fullWidth
                    disabled={submitting}
                    onClick={handleSubmit}
                    label={
                        <Typography
                            variant="body1"
                            color="inherit">
                            <FormattedMessage
                                id="stamp-card.noThankYou"
                                defaultMessage="No thank you, the terms is already adapted for stamp card."/>
                        </Typography>
                    }/>

                <Button
                    label={<FormattedMessage
                        id="tailorTerms"
                        defaultMessage="Tailor terms"/>}
                    variant="outlined"
                    onClick={this.setNoTemplateAndGoToNextPage.bind(this, nextPage, setIsTemplate)}/>

                <Button
                    label={<FormattedMessage
                        id="generateTemplate"
                        defaultMessage="Generate template"/>}
                    onClick={this.generateTemplate.bind(this, nextPage, setIsTemplate, generateTemplate)}/>
            </form>
        );
    }

    setNoTemplateAndGoToNextPage(nextPage, setIsTemplate) {
        setIsTemplate(false);
        nextPage()
    }

    generateTemplate(nextPage, setIsTemplate, generateTemplate) {
        generateTemplate();
        setIsTemplate(true);
        nextPage()
    }

}

export default reduxForm({
    form: "stampcardForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StampcardFormPage7)
