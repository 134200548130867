import React from "react"
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";

const styles = theme => ({
    card: {
        padding: theme.spacing(1),
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },
    itemTitle: {
        fontWeight: 'bold',
        marginRight: theme.spacing(0.5)
    }
});

class MemberPaper extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        member: PropTypes.object,
        onClick: PropTypes.func
    };

    render() {
        const {classes, member, onClick} = this.props;

        return (
            <Card onClick={onClick}>
                <CardActionArea className={classes.card}>
                    <div className={classes.wrapper}>
                        {this.renderConsumerName(member)}

                        <Typography variant="subtitle2">
                        <span className={classes.itemTitle}>
                            <FormattedMessage
                                id="id"
                                defaultMessage="Id"/>
                            :
                        </span>

                            {member.membershipId}
                        </Typography>
                    </div>

                    {(member.phone || member.email) && (
                        <div className={classes.wrapper}>
                            {member.phone && (
                                <Typography variant="subtitle2">
                                    {member.phonePrefix} {member.phone}
                                </Typography>
                            )}

                            {member.email && (
                                <Typography variant="subtitle2">
                                    {member.email}
                                </Typography>
                            )}
                        </div>
                    )}
                </CardActionArea>
            </Card>
        )
    }

    renderConsumerName(consumer) {
        if (consumer.firstName || consumer.lastName) {
            return (
                <Typography variant="subtitle2">
                    {consumer.firstName} {consumer.lastName}
                </Typography>
            )
        }
        return null
    }

}

export default withStyles(styles)(MemberPaper);
