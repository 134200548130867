import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Store from "@material-ui/icons/Store";

import Dialog from './../../../../common/components/common/Dialog'
import Button from "../../../../common/components/common/Button";
import StartGuideLink from "./StartGuideLink";

const styles = theme => ({
    button: {
        height: 60
    },
    iconsWrapper: {
        width: 40,
        height: 40,
        "& svg": {
            fontSize: 16
        }
    },
    actionsWrapper: {
        [theme.breakpoints.down("xs")]: {
            display: 'grid',
            "& > button:last-child": {
                marginTop: theme.spacing(1),
            }
        }
    }
});

class FirstPosDialog extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        moveToCreatePointOfSale: PropTypes.func,
        moveToAddCommercialChain: PropTypes.func
    };

    state = {
        open: false
    };

    moveToCreatePointOfSale = () => {
        this.handleClose();
        this.props.moveToCreatePointOfSale();
    };

    moveToAddCommercialChain = () => {
        this.handleClose();
        this.props.moveToAddCommercialChain();
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {classes} = this.props;

        const actions = [
            <Button
                key="multipleLocation"
                className={classes.button}
                icon={
                    <span className={classes.iconsWrapper}>
                        <Store/>
                        <Store/>
                        <Store/>
                    </span>
                }
                label={<FormattedMessage
                    id="first-pos-dialog.AChainWithMultipleSalesLocation"
                    defaultMessage="A chain with Multiple Sales Location"/>}
                onClick={this.moveToAddCommercialChain}/>,
            <Button
                key="singleLocation"
                className={classes.button}
                icon={<Store/>}
                label={<FormattedMessage
                    id="first-pos-dialog.SingleSaleLocation"
                    defaultMessage="Single Sales Location"/>}
                onClick={this.moveToCreatePointOfSale}/>
        ];

        return (
            <div>
                <StartGuideLink onClick={this.handleOpen}>
                    <FormattedMessage
                        id="first-pos-dialog.SetupYourFirstPointOfSale"
                        defaultMessage="Setup your first Point of sale"/>
                </StartGuideLink>

                <Dialog
                    onClick={this.handleClose}
                    title={<FormattedMessage
                        id="first-pos-dialog.setUpLoyaltyProgramFor"
                        defaultMessage="I’m setting up a loyalty program for:"/>}
                    actions={actions}
                    actionsClassName={classes.actionsWrapper}
                    open={this.state.open}/>
            </div>
        );
    }

}

export default withStyles(styles)(FirstPosDialog)
