import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {prepareCouponFormData} from "../../utils/FormHelper";
import Spinner from "../../../common/components/common/Spinner";
import CouponForm from "./CouponForm";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {createCoupon, fetchCoupon, updateCoupon, updateCouponStatus} from "../../actions/merchant-points-actions";
import {fetchCommercialChain} from "../../actions/merchant-chain-actions";

class Coupon extends React.Component {

    static propTypes = {
        match: PropTypes.object,
        chain: PropTypes.object,
        coupon: PropTypes.object,
        app: PropTypes.object,
        history: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        fetchCoupon: PropTypes.func,
        createCoupon: PropTypes.func,
        updateCoupon: PropTypes.func,
        updateCouponStatus: PropTypes.func,
        fetchCommercialChain: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: !!this.props.match.params.id
        };
    }

    componentDidMount() {
        let couponId = this.props.match.params.id;
        if (couponId) {
            this.props.fetchCoupon(couponId);
        }
        this.props.fetchCommercialChain(this.props.chain.id)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.isLoading && nextProps && nextProps.coupon) {
            this.setState({
                isLoading: false
            });
        }
    }

    render() {
        const {coupon, isUpdate, app, history} = this.props;

        if (this.state.isLoading) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <CouponForm
                        isUpdate={isUpdate}
                        keepDirtyOnReinitialize={true}
                        enableReinitialize={true}
                        initialValues={this.prepareInitialValues(coupon, isUpdate)}
                        onSubmit={this.onSubmit.bind(this)}
                        handlePublish={this.onSubmitPublish.bind(this, history)}
                        handleDeactivate={this.onSubmitDeactivate.bind(this)}/>
                </Paper>
            </Container>
        )
    }

    prepareInitialValues(coupon, isUpdate) {
        if (isUpdate) {
            return {
                ...coupon,
                "rewardFixedValue": coupon.fixedValue ? "YES" : "NO"
            }
        }
    }

    onSubmit(values) {
        let body = prepareCouponFormData(values);
        if (this.props.isUpdate) {
            this.props.updateCoupon(this.props.match.params.id, body)
        } else {
            this.props.createCoupon(this.props.chain.id, body, this.props.history);
        }
    }

    onSubmitPublish(history) {
        this.props.updateCouponStatus(this.props.match.params.id, "ACTIVE", history)
    }

    onSubmitDeactivate() {
        this.props.updateCouponStatus(this.props.match.params.id, "INACTIVE")
    }

}

function mapStateToProps(state) {
    return {
        coupon: state.points.coupon
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createCoupon,
        fetchCoupon,
        updateCoupon,
        updateCouponStatus,
        fetchCommercialChain
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Coupon);
