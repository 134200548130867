import React, {useState} from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import MemberPaper from "../member/MemberPaper";
import ProfileEditorForm from "../../../common/components/common/ProfileEditorForm";
import Dialog from "../../../common/components/common/Dialog";

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1)
    }
}));

const CustomerDetailsBar = props => {
    const {consumer, history, removeMember, merchantUpdateMember} = props;
    const classes = useStyles();
    const [openDialog, setDialogOpen] = useState(false);

    const handleToggle = () => {
        setDialogOpen(prevOpen => !prevOpen);
    };

    const handleOnRemoveMember = (consumerId, history) => {
        handleToggle();
        removeMember(consumerId, history)
    };

    const handleOnMemberUpdate = (consumerId, values) => {
        handleToggle();
        values = prepareFormBeforeSubmit(values);
        merchantUpdateMember(consumerId, null, values);
    };

    if (consumer && !consumer.enabled) {
        return (
            <Typography
                variant="body1"
                color="error">
                <FormattedMessage
                    id="removedMember"
                    defaultMessage="Removed Member"/>
            </Typography>
        )
    }

    return (
        <React.Fragment>
            <MemberPaper
                member={consumer}
                onClick={handleToggle}/>

            <Dialog
                rootClasses={{paper: classes.paper}}
                content={
                    <ProfileEditorForm
                        initialValues={consumer}
                        enableReinitialize
                        merchantEditMember
                        cancelButton
                        cancelDialog={handleToggle}
                        removeMember={handleOnRemoveMember.bind(this, consumer.id, history)}
                        onSubmit={(handleOnMemberUpdate.bind(this, consumer.id))}/>
                }
                open={openDialog}
                onClose={handleToggle}/>
        </React.Fragment>
    );

    function prepareFormBeforeSubmit(values) {
        if (values.address) {
            return ({
                ...values,
                'street': values.address.street,
                'city': values.address.city,
                'zipCode': values.address.zipCode
            })
        }
        return values
    }
};

CustomerDetailsBar.propTypes = {
    consumer: PropTypes.object,
    history: PropTypes.object,
    removeMember: PropTypes.func,
    merchantUpdateMember: PropTypes.func
};

export default CustomerDetailsBar
