import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import {withStyles} from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";

import SquareIconButton from "../../../common/components/common/SquareIconButton";

import {DEFAULT_FONT_FAMILIES, DEFAULT_FONT_SIZES} from "../../../common/constants";
import FontSize from "../../../common/utils/FontSize";
import {zIndex100} from "../../../common/utils/style-const";
import TextFields from '@material-ui/icons/TextFields';

const styles = theme => ({
    button: {
        background: theme.palette.common.white,
    },
    icon: {
        fill: theme.palette.common.black,
    },
    dialog: {
        position: 'absolute',
        zIndex: zIndex100,
        paddingTop: theme.spacing(1)
    },
    dialogClose: {
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
    },
    dialogContentWrapper: {
        width: 275,
        height: '100%'
    },
    dialogContent: {
        padding: theme.spacing(1),
        "& > :first-child": {
            marginBottom: theme.spacing(1)
        }
    },
    fontItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        "& > *": {
            flex: 2
        }
    }
});

class RenderFontPicker extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        font: PropTypes.shape({
            fontFamily: PropTypes.string,
            fontSize: PropTypes.instanceOf(FontSize),
        }),
        fontFamilies: PropTypes.arrayOf(PropTypes.string),
        fontSizes: PropTypes.arrayOf(PropTypes.instanceOf(FontSize)),
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            currentFontFamily: this.parseFontFamily(this.props.font),
            currentFontSize: this.parseFontSize(this.props.font)
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps) {
            this.setState({
                currentFontFamily: this.parseFontFamily(nextProps.font),
                currentFontSize: this.parseFontSize(nextProps.font)
            });
        }
    }

    parseFontFamily(font) {
        return font && font.fontFamily && {value: font.fontFamily};
    }

    parseFontSize(font) {
        return font && font.fontSize;
    }

    onClick() {
        this.state.isOpen ? this.onClose() : this.onOpen()
    }

    onOpen() {
        this.setState({
            isOpen: true
        });
    }

    onClose() {
        this.setState({
            isOpen: false
        });
    }

    onFontFamilyChanged(fontFamily) {
        this.setState({
            currentFontFamily: fontFamily
        });

        const newFont = {
            fontFamily: fontFamily.value,
            fontSize: this.state.currentFontSize
        };
        this.notifyChange(newFont);
    }

    onFontSizeChanged(fontSize) {
        this.setState({
            currentFontSize: fontSize
        });

        const newFont = {
            fontFamily: this.state.currentFontFamily.value,
            fontSize: fontSize
        };
        this.notifyChange(newFont);
    }

    notifyChange(newFont) {
        const {onChange} = this.props;
        if (onChange) {
            onChange(newFont);
        }
    }

    getFontFamilies() {
        return (this.props.fontFamilies ? this.props.fontFamilies : DEFAULT_FONT_FAMILIES)
            .map(fontFamily => ({value: fontFamily}));
    }

    getFontSizes() {
        return this.props.fontSizes ? this.props.fontSizes : DEFAULT_FONT_SIZES;
    }

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <SquareIconButton
                    className={classes.button}
                    icon={<TextFields className={classes.icon}/>}
                    onClick={this.onClick.bind(this)}/>

                {this.state.isOpen ? this.renderDialog() : null}
            </React.Fragment>
        )
    }

    renderDialog() {
        const {classes} = this.props;
        return (
            <div className={classes.dialog}>
                <div
                    className={classes.dialogClose}
                    onClick={this.onClose.bind(this)}/>

                <Paper className={classes.dialogContentWrapper}>
                    {this.renderDialogContent()}
                </Paper>
            </div>
        )
    }

    renderDialogContent() {
        const {classes} = this.props;
        return (
            <div className={classes.dialogContent}>
                {this.renderDialogContentRow(
                    "Font family:",
                    <Select
                        clearable={false}
                        valueKey="value"
                        labelKey="value"
                        value={this.state.currentFontFamily}
                        placeholder="Select font family"
                        closeOnSelect
                        optionRenderer={this.renderFontFamily}
                        options={this.getFontFamilies()}
                        onChange={this.onFontFamilyChanged.bind(this)}/>)}

                {this.renderDialogContentRow(
                    "Font size:",
                    <Select
                        clearable={false}
                        valueKey="value"
                        value={this.state.currentFontSize}
                        placeholder="Select font size"
                        closeOnSelect
                        options={this.getFontSizes()}
                        onChange={this.onFontSizeChanged.bind(this)}/>)}
            </div>
        );
    }

    renderDialogContentRow(prompt, component) {
        const {classes} = this.props;
        return (
            <div className={classes.fontItem}>
                <Typography variant="body1">
                    {prompt}
                </Typography>
                {component}
            </div>
        );
    }

    renderFontFamily(fontFamily) {
        return (
            <span style={{fontFamily: fontFamily.value}}>
                {fontFamily.value}
            </span>
        )
    }

}

export default withStyles(styles)(RenderFontPicker);
