import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {isAfter, isBefore, isEqual} from "date-fns";
import PropTypes from "prop-types";

import Spinner from "../../../common/components/common/Spinner";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import {withPurchaseFormData} from "./PurchaseForm";
import UpdatePurchaseForm from "./UpdatePurchaseForm";
import RegisterPurchaseForm from "./RegisterPurchaseForm";

import {addDaysToDate, endOfDayForDate, getFormattedDate} from "../../../common/utils/date-utils";

import {fetchConsumer, merchantUpdateMember, removeMember} from "../../actions/merchant-actions";
import {registerPurchase, removePurchase, updatePurchase} from "../../actions/merchant-purchase-actions";
import {fetchPurchase} from "../../../common/actions/purchase-actions";
import {fetchCategories} from "../../actions/merchant-category-actions";
import {fetchCampaigns, fetchCampaignsForConsumer} from "../../actions/merchant-campaign-actions";
import {fetchClubPoints, fetchCoupons} from "../../actions/merchant-points-actions";
import {
    fetchConsumerStampcardCoupons,
    fetchConsumerStampcardCouponsUsages,
    fetchStampcards
} from "../../actions/merchant-stampcard-actions";
import {setFormValue} from "../../actions/merchant-forms-actions";
import {COMPLETED, EXPIRED, WITHDRAWN} from "../../../common/utils/enums/publish-status";

const PurchaseUpdateForm = withPurchaseFormData(UpdatePurchaseForm);
const PurchaseRegisterForm = withPurchaseFormData(RegisterPurchaseForm);

class RegisterPurchase extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        match: PropTypes.object,
        app: PropTypes.object,
        consumer: PropTypes.object,
        clubPoints: PropTypes.object,
        chain: PropTypes.object,
        purchase: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        consumerStampcardCouponUsages: PropTypes.array,
        consumerCampaigns: PropTypes.array,
        stampcards: PropTypes.array,
        consumerStampcardCoupons: PropTypes.array,
        categories: PropTypes.array,
        coupons: PropTypes.array,
        consumerPoints: PropTypes.number,
        fetchConsumer: PropTypes.func,
        fetchConsumerStampcardCoupons: PropTypes.func,
        fetchCategories: PropTypes.func,
        fetchClubPoints: PropTypes.func,
        fetchCoupons: PropTypes.func,
        fetchPurchase: PropTypes.func,
        fetchStampcards: PropTypes.func,
        fetchCampaignsForConsumer: PropTypes.func,
        fetchConsumerStampcardCouponsUsages: PropTypes.func,
        removePurchase: PropTypes.func,
        registerPurchase: PropTypes.func,
        updatePurchase: PropTypes.func,
        removeMember: PropTypes.func,
        merchantUpdateMember: PropTypes.func
    };

    state = {
        isLoading: true
    };

    componentDidMount() {
        this.fetchDataIfPossible();
    }

    componentDidUpdate(prevProps) {
        if (this.props.consumerCampaigns !== prevProps.consumerCampaigns) {
            this.setState({isLoading: false});
        }
    }

    fetchDataIfPossible() {
        const chainId = localStorage.getItem('currentChainId');
        this.props.fetchConsumer(this.props.match.params.consumer);
        this.props.fetchConsumerStampcardCoupons(this.props.match.params.consumer);
        this.props.fetchCategories();
        if (chainId) {
            this.props.fetchClubPoints(chainId);
        }
        if (this.isUpdate()) {
            this.props.fetchPurchase(this.props.match.params.purchase);
            this.props.fetchStampcards(chainId, this.props.match.params.consumer, this.props.match.params.purchase);
            this.props.fetchCampaignsForConsumer(this.props.match.params.consumer, this.props.match.params.purchase, true, this.props.match.params.purchase);
            this.props.fetchConsumerStampcardCouponsUsages(this.props.match.params.consumer, true, this.props.match.params.purchase);
            this.props.fetchCoupons(chainId, false, this.props.match.params.purchase);
        } else {
            this.props.fetchStampcards(chainId, this.props.match.params.consumer);
            this.props.fetchCampaignsForConsumer(this.props.match.params.consumer);
            this.props.fetchCoupons(chainId, true, this.props.match.params.purchase);
        }
    }

    render() {
        const {
            app, history, consumer, clubPoints, categories, consumerCampaigns, coupons, purchase,
            stampcards, consumerStampcardCoupons, consumerStampcardCouponUsages, removeMember, merchantUpdateMember,
            setFormValue, chain
        } = this.props;

        const isUpdate = this.isUpdate();

        const clubPointsActive = clubPoints && clubPoints.active;
        const clubPointsCountedPointsFrom = clubPoints && clubPoints.countedPointsFrom;
        const clubPointsMonthValidation = clubPoints && clubPoints.monthValidation;

        if (this.state.isLoading) {
            return this.renderSpinner()
        }

        if (isUpdate && (!consumer || !categories || !consumerCampaigns || !purchase || purchase.id !== Number(this.props.match.params.purchase) || !clubPoints || !coupons || !stampcards || !consumerStampcardCoupons
            || !consumerStampcardCouponUsages)) {
            return this.renderSpinner()
        } else if (!isUpdate && (!consumer || !categories || !consumerCampaigns || !clubPoints || !coupons || !stampcards || !consumerStampcardCoupons)) {
            return this.renderSpinner()
        }

        const currentPoSId = Number(localStorage.getItem('currentPoSId'));

        const customProps = {
            stampcards: this.getStampcards(stampcards),
            purchase: isUpdate && purchase,
            removePurchase: this.removePurchase.bind(this, history),
            enableReinitialize: true,
            consumerStampcardCoupons: this.getStampcardCoupons(consumerStampcardCoupons),
            purchaseStampcardUsage: this.getPurchaseStampcardUsage(isUpdate, purchase),
            history: history,
            app: app,
            clubPointsActive: clubPointsActive,
            clubPointsCountedPointsFrom: clubPointsCountedPointsFrom,
            clubPointsMonthValidation: clubPointsMonthValidation,
            consumer: consumer,
            consumerPoints: consumer.clubPoints,
            initialValues: isUpdate ? this.prepareInitialValues(purchase) : null,
            categoriesOptions: this.getCategories(categories, isUpdate),
            campaignOptions: this.getCampaigns(consumerCampaigns),
            couponsOptions: this.getCoupons(coupons, purchase),
            onSubmit: this.registerAndRedirectToHome.bind(this, history, currentPoSId),
            removeMember: removeMember,
            merchantUpdateMember: merchantUpdateMember,
            setFormValue: setFormValue,
            chain: chain
        };

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    {isUpdate
                        ? <PurchaseUpdateForm {...customProps}/>
                        : <PurchaseRegisterForm {...customProps}/>}
                </Paper>
            </Container>
        )
    }

    renderSpinner(app) {
        return <Spinner app={app}/>
    }

    getPurchaseStampcardUsage(isUpdate, purchase) {
        if (isUpdate && purchase.purchaseStampcardUsage) {
            return purchase.purchaseStampcardUsage;
        }
    }

    removePurchase(history) {
        this.props.removePurchase(this.props.match.params.purchase, history)
    }

    isUpdate() {
        if (this.props.match.params.purchase) {
            return true;
        }
    }

    registerAndRedirectToHome(history, currentPoSId, values) {
        if (this.isUpdate()) {
            //Since this action is done from Web - ECR from purchase should be set to null
            values.ecrId = null;
            this.props.updatePurchase({
                ...values,
                'consumerId': this.props.match.params.consumer
            }, history, this.props.match.params.purchase);
        } else {
            this.props.registerPurchase({
                ...values,
                'consumerId': this.props.match.params.consumer,
                'currentPoSId': currentPoSId
            }, history);
        }
    }

    prepareInitialValues(purchase) {
        let initialValues = {...purchase};
        if (purchase && purchase.campaigns && purchase.campaigns.length > 0) {
            initialValues = {...initialValues, "campaigns": this.getInitialList(purchase.campaigns)};
        }
        if (purchase && purchase.categories && purchase.categories.length > 0) {
            initialValues = {...initialValues, "categories": this.getInitialList(purchase.categories)};
        }
        if (purchase && purchase.coupons && purchase.coupons.length > 0) {
            initialValues = {...initialValues, "coupons": this.getInitialList(purchase.coupons)};
        }
        if (purchase && purchase.stampcardCouponUsages && purchase.stampcardCouponUsages.length > 0) {
            initialValues = {
                ...initialValues,
                "stampcardCouponUsages": this.getInitialList(purchase.stampcardCouponUsages)
            };
        }

        return initialValues;
    }

    getInitialList(list) {
        if (list) {
            return (
                list.map(key =>
                    (
                        key.id
                    )
                )
            )
        }
    }

    getCategories(categories, isUpdate) {
        if (categories) {
            const filteredCategories = isUpdate ? categories : categories.filter(category => category.active);
            return (
                filteredCategories.map(key =>
                    ({
                        label: key.name.charAt(0).toUpperCase() + key.name.slice(1),
                        value: key.id
                    })
                )
            )
        }
    }

    getCampaigns(campaigns) {
        if (campaigns) {
            return (
                campaigns.map(key =>
                    ({
                        label: key.name.charAt(0).toUpperCase() + key.name.slice(1),
                        value: key.id,
                        path: key.bannerPath
                    })
                )
            )
        }
    }

    getStampcardCoupons(stampcardCoupons) {
        const {consumer, consumerStampcardCouponUsages} = this.props;
        if (stampcardCoupons) {
            const usage = this.isUpdate() ? consumerStampcardCouponUsages : consumer.stampcardCouponUsage;
            return (
                usage.filter(option => {
                    const validTillDate = endOfDayForDate(addDaysToDate(
                        option.startDate,
                        stampcardCoupons.find(option2 => option2.id === option.stampcardCouponId).dayValidation));

                    return this.isUpdate()
                        || (!option.used && (isAfter(
                            validTillDate,
                            new Date())));
                })
                    .map(singleUsage => {
                            const stampcardCoupon = stampcardCoupons
                                .find(option => option.id === singleUsage.stampcardCouponId);
                            return ({
                                value: singleUsage.id,
                                imagePath: stampcardCoupon.imagePath,
                                reward: stampcardCoupon.reward,
                                hideTitle: stampcardCoupon.hideTitle,
                                valid: getFormattedDate(addDaysToDate(singleUsage.startDate, stampcardCoupon.dayValidation))
                            })
                        }
                    )
            )
        }
    }

    getStampcards(stampcards, isStampcardsUsage) {
        const {consumer, purchase, history: {location: {dateOfPurchase}}} = this.props;
        const purchaseDate = this.isUpdate() ? purchase.dateOfPurchase : dateOfPurchase ? dateOfPurchase : new Date();
        if (stampcards) {
            let allVisibleStampcards = [];
            stampcards.map(key => {
                    let collectedStampsAmount = 0;
                    let isStampcardUsageFirstStampAfterPurchaseDate = false;
                    let matchingStampcardUsage;
                    if (consumer && consumer.stampcardUsage && consumer.stampcardUsage.length > 0) {
                        matchingStampcardUsage = consumer.stampcardUsage
                            .find(option => option.stampcardId === key.id);
                        collectedStampsAmount = matchingStampcardUsage && matchingStampcardUsage.collectedStamps
                            ? matchingStampcardUsage.collectedStamps
                            : 0;
                        isStampcardUsageFirstStampAfterPurchaseDate = matchingStampcardUsage && matchingStampcardUsage.stampcardUsageStatus === 'ACTIVE' && isAfter(matchingStampcardUsage.startDate, purchaseDate);
                        if (matchingStampcardUsage && this.isUpdate()) {
                            const historicStampcardUsages = matchingStampcardUsage.historicStampcardUsages;
                            if (historicStampcardUsages && historicStampcardUsages.length > 0) {
                                if (!isStampcardUsageFirstStampAfterPurchaseDate) {
                                    const lastHistoricStampcardUsage = historicStampcardUsages.slice(-1)[0];
                                    isStampcardUsageFirstStampAfterPurchaseDate = matchingStampcardUsage.stampcardUsageStatus === 'NEW'
                                        && (isAfter(lastHistoricStampcardUsage.startDate, purchaseDate))
                                }
                                historicStampcardUsages
                                    .filter(historicStampcardUsage => historicStampcardUsage.collectedStamps > 0)
                                    .map(historicStampcardUsage => {
                                        if (purchaseDate && historicStampcardUsage.startDate
                                            && (isAfter(purchaseDate, historicStampcardUsage.startDate)
                                                || isEqual(purchaseDate, historicStampcardUsage.startDate))
                                            && (isAfter(historicStampcardUsage.lastStatusUpdateDate, purchaseDate)
                                                || isEqual(historicStampcardUsage.lastStatusUpdateDate, purchaseDate))
                                        ) {
                                            allVisibleStampcards.push(
                                                {
                                                    title: key.title,
                                                    hideTitle: key.hideTitle,
                                                    titleColor: key.titleColor,
                                                    value: key.id,
                                                    imagePath: key.imagePath,
                                                    stampPath: key.stampPath,
                                                    stampUncollectedPath: key.stampUncollectedPath,
                                                    stampsAmount: key.stampsAmount,
                                                    monthValidation: key.monthValidation,
                                                    collected: historicStampcardUsage.collectedStamps,
                                                    disabled: true,
                                                    stampcardUsageStatus: historicStampcardUsage.stampcardUsageStatus,
                                                    lastStatusUpdateDate: historicStampcardUsage.lastStatusUpdateDate,
                                                })
                                        }
                                    });
                            } else if (purchase.purchaseStampcardUsage
                                && purchase.purchaseStampcardUsage.find(purchaseStampcardUsage => purchaseStampcardUsage.stampcardId === key.id)) {

                                let stampcardUsageStatus;
                                let lastStatusUpdateDate;
                                let isPurchaseDateBeforeCompleted;

                                const lastCollectedStampcardDate = matchingStampcardUsage.collectedStampcardDate && matchingStampcardUsage.collectedStampcardDate.length > 0 &&
                                    Math.max.apply(Math, matchingStampcardUsage.collectedStampcardDate);

                                if (lastCollectedStampcardDate) {
                                    isPurchaseDateBeforeCompleted = (isBefore(purchaseDate, lastCollectedStampcardDate) || isEqual(purchaseDate, lastCollectedStampcardDate));
                                    if (!isStampcardUsageFirstStampAfterPurchaseDate) {
                                        isStampcardUsageFirstStampAfterPurchaseDate = matchingStampcardUsage.stampcardUsageStatus === 'NEW'
                                            && (isAfter(lastCollectedStampcardDate, purchaseDate))
                                    }
                                }

                                if (isPurchaseDateBeforeCompleted) {
                                    stampcardUsageStatus = COMPLETED.value
                                } else if (key.publishStatus === WITHDRAWN.value) {
                                    stampcardUsageStatus = WITHDRAWN.value;
                                    lastStatusUpdateDate = key.withdrawnDate;
                                } else if (matchingStampcardUsage.expirationDate) {
                                    stampcardUsageStatus = EXPIRED.value;
                                    lastStatusUpdateDate = matchingStampcardUsage.expirationDate;
                                }

                                if (stampcardUsageStatus) {
                                    allVisibleStampcards.push(
                                        {
                                            title: key.title,
                                            hideTitle: key.hideTitle,
                                            titleColor: key.titleColor,
                                            value: key.id,
                                            imagePath: key.imagePath,
                                            stampPath: key.stampPath,
                                            stampUncollectedPath: key.stampUncollectedPath,
                                            stampsAmount: key.stampsAmount,
                                            monthValidation: key.monthValidation,
                                            collected: key.stampsAmount,
                                            disabled: true,
                                            stampcardUsageStatus: stampcardUsageStatus,
                                            lastStatusUpdateDate: lastStatusUpdateDate,
                                        })
                                }
                            }
                        }
                    }
                    let added = 0;

                    if (isStampcardsUsage) {
                        return ({
                            id: key.id,
                            title: key.title,
                            collected: collectedStampsAmount,
                            stampsAmount: key.stampsAmount
                        })
                    }

                    if (this.isUpdate()
                        && purchase.purchaseStampcardUsage
                        && purchase.purchaseStampcardUsage.length > 0) {
                        const purchaseStampcardUsageForStampcard = purchase.purchaseStampcardUsage.filter(option => option.stampcardId === key.id)
                        if (purchaseStampcardUsageForStampcard.length > 0) {
                            const matchingPurchaseStampcardUsage =
                                (!matchingStampcardUsage || !matchingStampcardUsage.historicStampcardUsages || !matchingStampcardUsage.historicStampcardUsages.length > 0) ?
                                    purchaseStampcardUsageForStampcard[0]
                                    : purchaseStampcardUsageForStampcard.find(option => option.usageOrder === matchingStampcardUsage.historicStampcardUsages.length)

                            added = matchingPurchaseStampcardUsage ? matchingPurchaseStampcardUsage.addedStamps : collectedStampsAmount
                        } else {
                            added = 0
                        }
                    }
                    if (!this.isUpdate() && key.publishStatus === "PUBLISHED"
                        || (key.publishStatus === "PUBLISHED" && !matchingStampcardUsage)
                        || (matchingStampcardUsage &&
                            ((key.publishStatus === "STOPPED" && (matchingStampcardUsage.stampcardUsageStatus !== 'NEW' && matchingStampcardUsage.stampcardUsageStatus !== 'COMPLETED')) || key.publishStatus === "PUBLISHED")
                            && (
                                (!this.isUpdate() && matchingStampcardUsage.stampcardUsageStatus === 'STOPPED')
                                || (this.isUpdate()
                                    && (
                                    (matchingStampcardUsage.stampcardUsageStatus === 'NEW' &&
                                        (isStampcardUsageFirstStampAfterPurchaseDate && !
                                            (matchingStampcardUsage.historicStampcardUsages && matchingStampcardUsage.historicStampcardUsages.length > 0))
                                        || (matchingStampcardUsage.historicStampcardUsages && matchingStampcardUsage.historicStampcardUsages.length > 0 &&
                                            ((isBefore(matchingStampcardUsage.historicStampcardUsages[0].startDate, purchase.dateOfPurchase))
                                                || isEqual(matchingStampcardUsage.historicStampcardUsages[0].startDate, purchase.dateOfPurchase))))
                                    ||
                                    (matchingStampcardUsage.startDate
                                        && (isStampcardUsageFirstStampAfterPurchaseDate
                                            || (isAfter(purchase.dateOfPurchase, matchingStampcardUsage.startDate)
                                                || isEqual(matchingStampcardUsage.startDate, purchase.dateOfPurchase)))
                                        && matchingStampcardUsage.stampcardUsageStatus !== 'COMPLETED'))
                                )))
                    ) {
                        allVisibleStampcards.push(
                            {
                                title: key.title,
                                hideTitle: key.hideTitle,
                                titleColor: key.titleColor,
                                value: key.id,
                                imagePath: key.imagePath,
                                stampPath: key.stampPath,
                                stampUncollectedPath: key.stampUncollectedPath,
                                stampsAmount: key.stampsAmount,
                                monthValidation: key.monthValidation,
                                collected: collectedStampsAmount,
                                added: added,
                                afterPurchase: isStampcardUsageFirstStampAfterPurchaseDate
                            })
                    }
                }
            );
            return allVisibleStampcards.sort(a => a.disabled ? -1 : 1);
        }
    }

    getCoupons(coupons, purchase) {
        if (coupons) {
            let couponsRet = coupons;
            if (this.isUpdate()) {
                let purchaseCoupons = [...purchase.coupons];
                let index;
                for (index = 0; index < coupons.length; index++) {
                    const coupon = coupons[index];
                    let contains = false;
                    for (let i = 0; i < purchaseCoupons.length; i++) {
                        if (purchaseCoupons[i].id === coupon.id) {
                            contains = true;
                        }
                    }

                    if (!contains) {
                        purchaseCoupons.push(coupon);
                    }
                }
                couponsRet = purchaseCoupons;
            }

            return (
                couponsRet.map(key =>
                    ({
                        name: key.name.charAt(0).toUpperCase() + key.name.slice(1),
                        value: key.id,
                        imagePath: key.imagePath,
                        pointsRequired: key.pointsRequired
                    })
                )
            )
        }
    }
}

function mapStateToProps(state) {
    return {
        purchase: state.purchase.purchase,
        consumer: state.merchant.consumer,
        categories: state.merchant.categories,
        coupons: state.points.coupons,
        clubPoints: state.points.clubPoints,
        consumerCampaigns: state.merchant.consumerCampaigns,
        consumerStampcardCoupons: state.merchant.consumerStampcardCoupons,
        consumerStampcardCouponUsages: state.merchant.consumerStampcardCouponUsages,
        stampcards: state.merchant.stampcards,
        currentPoS: state.merchant.currentPointOfSale,
        chain: state.chains.current
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchConsumer,
        fetchCategories,
        fetchCampaignsForConsumer,
        registerPurchase,
        fetchPurchase,
        fetchCampaigns,
        updatePurchase,
        removePurchase,
        removeMember,
        merchantUpdateMember,
        fetchCoupons,
        fetchClubPoints,
        fetchStampcards,
        fetchConsumerStampcardCoupons,
        fetchConsumerStampcardCouponsUsages,
        setFormValue
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPurchase);
