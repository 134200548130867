import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Done from "@material-ui/icons/Done";

import Button from "../../../common/components/common/Button";
import Dialog from "../../../common/components/common/Dialog";
import RenderTextField from "../../../common/components/common/RenderTextField";
import {HistoryExpansionPanel} from "./history/HistoryExpansionPanel";
import {PointsUpdatesHistory} from "./history/PointsUpdatesHistory";
import {UPDATE_PURCHASE_FORM_NAME} from "./UpdatePurchaseForm";
import {colorRed, primaryColor} from "../../../common/utils/color-const";
import {UNSIGNED_INTEGER_FORMAT_CONFIG} from "../../utils/formats";

const useStyles = makeStyles(theme => ({
    paper: {
        margin: theme.spacing(1)
    },
    dialog: {
        margin: theme.spacing(2)
    },
    itemTitle: {
        display: 'table-cell',
        width: 150,
        textAlign: 'start',
        fontWeight: 'bold'
    },
    itemValueEarned: {
        display: 'table-cell',
        color: primaryColor
    },
    itemValueUsed: {
        display: 'table-cell',
        color: colorRed
    },
    textUnderlined: {
        textDecoration: 'underline'
    },
    message: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

const ChangeEarnedPointsDialog = props => {
    const {
        earnedPoints, openDialog, closeDialog, onSubmit, purchase, minEarnedPoints, setFormValue, usedPoints,
        currentBalance
    } = props;
    const [fieldValue, setFieldValue] = React.useState(earnedPoints);
    const classes = useStyles();

    const isUpdateLimited = minEarnedPoints > 0;
    const isUsedPointsAsLimit = minEarnedPoints === usedPoints;

    return (
        <Dialog
            open={openDialog}
            rootClasses={{paper: classes.dialog}}
            onClose={closeDialog}
            title={<FormattedMessage
                id="changeEarnedPointsDialogTitle"
                defaultMessage="Adjust points earned for this purchase"/>}
            content={
                <React.Fragment>
                    <div>
                        <Typography
                            variant="body1"
                            className={classes.itemTitle}>
                            <FormattedMessage
                                id="earnedPoints"
                                defaultMessage="Earned points"/>:
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.itemValueEarned}>
                            {earnedPoints} p
                        </Typography>
                    </div>

                    <div>
                        <Typography
                            variant="body1"
                            className={classes.itemTitle}>
                            <FormattedMessage
                                id="usedPoints"
                                defaultMessage="Used"/>:
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.itemValueUsed}>
                            {usedPoints ? usedPoints : 0} p
                        </Typography>
                    </div>

                    {isUpdateLimited && generateMessage(isUsedPointsAsLimit)}

                    <RenderTextField
                        meta={{}}
                        hintText={<FormattedMessage
                            id="earnedPoints"
                            defaultMessage="Earned points"/>}
                        value={fieldValue || earnedPoints}
                        formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}
                        onChange={(value) => {
                            if (value !== undefined && value !== null) {
                                setFieldValue(value);
                                setFormValue(UPDATE_PURCHASE_FORM_NAME, 'earnedPointsManual', true)
                            }
                        }}
                        type="number"
                        inputMode="numeric"/>

                    {(purchase.historicPurchases.length > 0 || purchase.pointsUsages.length > 0) && (
                        <HistoryExpansionPanel
                            content={<PointsUpdatesHistory purchase={purchase}/>}
                            title={<FormattedMessage
                                id="changePointsSeeDetailedHistory"
                                defaultMessage="See detailed history"/>}/>
                    )}
                </React.Fragment>
            }
            actions={[
                <Button
                    key="cancel"
                    label={<FormattedMessage
                        id="cancel"
                        defaultMessage="Cancel"/>}
                    variant="outlined"
                    customColor="red"
                    onClick={closeDialog}/>,
                <Button
                    key="confirm"
                    icon={<Done/>}
                    label={<FormattedMessage
                        id="confirm"
                        defaultMessage="Confirm"/>}
                    onClick={() => onSubmit && onSubmit(fieldValue)}
                    disabled={fieldValue === undefined
                    || fieldValue === earnedPoints
                    || fieldValue < minEarnedPoints}/>
            ]}/>
    );

    function getCantBeSetLessThanMessage() {
        return (
            <Typography
                variant="body1"
                component="div"
                align="left">
                <FormattedMessage
                    id="thusPointsEarnedForThisPurchase"
                    defaultMessage="Thus points earned for this purchase {cantBeSetLessThan}"
                    values={{
                        cantBeSetLessThan:
                            <Typography
                                variant="body1"
                                component="span"
                                className={classes.textUnderlined}>
                                <FormattedMessage
                                    id="cantBeSetLessThan"
                                    defaultMessage="can't be set less than {minAcceptablePoints}."
                                    values={{
                                        minAcceptablePoints: minEarnedPoints
                                    }}/>
                            </Typography>
                    }}/>
            </Typography>
        );
    }

    function generateMessage(isUsedPointsAsLimit) {
        if (isUsedPointsAsLimit) {
            return (
                <div className={classes.message}>
                    <Typography
                        variant="body1"
                        align="left">
                        <FormattedMessage
                            id="usedPointsCantBeDeducted"
                            defaultMessage="Used points can't be deducted."/>
                    </Typography>
                    {getCantBeSetLessThanMessage()}
                </div>
            )
        } else {
            return (
                <div className={classes.message}>
                    <Typography
                        variant="body1"
                        align="left">
                        <FormattedMessage
                            id="pointsEarnedCantBeLowered"
                            defaultMessage="Points earned can’t be lowered more than member’s current points balance
                            which is {currentBalance}."
                            values={{currentBalance: currentBalance}}/>
                    </Typography>
                    {getCantBeSetLessThanMessage()}
                </div>
            )
        }
    }
};

ChangeEarnedPointsDialog.propTypes = {
    purchase: PropTypes.object,
    earnedPoints: PropTypes.number,
    minEarnedPoints: PropTypes.number,
    usedPoints: PropTypes.number,
    currentBalance: PropTypes.number,
    pristine: PropTypes.bool,
    submitting: PropTypes.bool,
    openDialog: PropTypes.bool,
    closeDialog: PropTypes.func,
    setFormValue: PropTypes.func,
    onSubmit: PropTypes.func,
    initialize: PropTypes.func
};

export default reduxForm({form: 'changePurchaseTotalAmountForm'})(ChangeEarnedPointsDialog)
