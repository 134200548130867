import React from "react";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Home from "@material-ui/icons/Home";

import AccountMenu from "./AccountMenu";
import {renderKarmingLogo} from '../../../common/components/AppMenu';
import RenderOnMobile from "../../../common/components/common/RenderOnMobile";

import {zIndex999998} from "../../../common/utils/style-const";

const styles = theme => ({
    appBar: {
        zIndex: zIndex999998,
        boxShadow: 'none'
    },
    itemsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    icon: {
        color: theme.palette.common.white
    },
    toolbar: {
        position: 'static'
    }
});

class LoggedUserAppBar extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        app: PropTypes.object,
        user: PropTypes.object,
        auth: PropTypes.object,
        chains: PropTypes.object,
        history: PropTypes.object,
        currentPoS: PropTypes.object,
        logout: PropTypes.func,
        setSidebarVisibility: PropTypes.func,
        setAccountMenuVisibility: PropTypes.func,
        setStartGuideVisibility: PropTypes.func
    };

    render() {
        const {classes} = this.props;

        return (
            <AppBar
                position="fixed"
                className={classes.appBar}>
                <Toolbar classes={{root: classes.toolbar}}>
                    {this.renderIconElementLeft()}
                    {this.renderIconElementRight()}
                </Toolbar>
            </AppBar>
        );
    }

    renderIconElementLeft() {
        const {classes} = this.props;
        return (
            <div className={classes.itemsWrapper}>
                <RenderOnMobile>
                    <IconButton onClick={this.toggleSidebar.bind(this)}>
                        <MenuIcon className={classes.icon}/>
                    </IconButton>
                    <IconButton component={Link} to="/">
                        <Home className={classes.icon}/>
                    </IconButton>
                </RenderOnMobile>
                {renderKarmingLogo()}
            </div>
        );
    }

    renderIconElementRight() {
        const {
            user, chains, app, auth, logout, setStartGuideVisibility, setAccountMenuVisibility,
            setSidebarVisibility, currentPoS
        } = this.props;

        return (
            <RenderOnMobile>
                {isMobile => (
                    <AccountMenu
                        user={user}
                        currentPoS={currentPoS}
                        chains={chains}
                        app={app}
                        auth={auth}
                        logout={logout}
                        isMobile={isMobile}
                        setSidebarVisibility={setSidebarVisibility}
                        setStartGuideVisibility={setStartGuideVisibility}
                        setAccountMenuVisibility={setAccountMenuVisibility}/>
                )}
            </RenderOnMobile>
        )
    }

    toggleSidebar() {
        const {setSidebarVisibility, app} = this.props;
        setSidebarVisibility(!app.sidebarOpen);
    }

}

export default withRouter(withStyles(styles)(LoggedUserAppBar))
