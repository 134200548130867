import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import Checkbox from "../../../../common/components/common/form/RenderCheckbox";
import RenderDatePicker from "../../../../common/components/common/form/RenderDatePicker";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {dateRangeValidation} from "../../../../common/utils/karming-validators";
import {required} from "../../../../common/components/common/form/FormUtils";

const validate = values => {
    return dateRangeValidation(
        {name: "startDate", value: values.startDate},
        {name: "endDate", value: values.endDate}
    )
};

class CampaignFormPage5 extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        isWelcomeOffer: PropTypes.bool,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func
    };

    render() {
        const {handleSubmit, isWelcomeOffer, previousPage, title} = this.props;

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <form onSubmit={handleSubmit} autoComplete="on">
                        <Typography
                            variant="h5"
                            paragraph>
                            {title}
                        </Typography>

                        {!isWelcomeOffer && (
                            <Field
                                name="oneTime"
                                label={<FormattedMessage
                                    id="oneTimeOffer"
                                    defaultMessage="One-time offer"/>}
                                type="checkbox"
                                component={Checkbox}/>
                        )}

                        {!isWelcomeOffer && (
                            <React.Fragment>
                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="campaign-form.campaignDateText"
                                        defaultMessage="For which period shall the Campaign be visible in the Club App?">
                                    </FormattedMessage>
                                </Typography>

                                <Field
                                    name="startDate"
                                    component={RenderDatePicker}
                                    hintText={
                                        <React.Fragment>
                                            <FormattedMessage
                                                id="startDate"
                                                defaultMessage="Start date">
                                            </FormattedMessage>
                                            *
                                        </React.Fragment>
                                    }
                                    validate={[required]}/>

                                <Field
                                    name="endDate"
                                    component={RenderDatePicker}
                                    hintText={
                                        <React.Fragment>
                                            <FormattedMessage
                                                id="endDate"
                                                defaultMessage="End date">
                                            </FormattedMessage>
                                            *
                                        </React.Fragment>}
                                    validate={[required]}/>
                            </React.Fragment>
                        )}

                        {isWelcomeOffer && (
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="campaign-form.welcomeOfferIsValidText"
                                    defaultMessage="The welcome offering is visible for each member during
                                    {durationInMonths} months from the date of registration , or until then you register
                                     a purchase for that member and mark the Welcome offering as claimed for that purchase."
                                    values={{
                                        durationInMonths: 3
                                    }}/>
                            </Typography>
                        )}

                        <Button
                            label={<FormattedMessage
                                id="previous"
                                defaultMessage="Previous"/>}
                            color="secondary"
                            onClick={previousPage}/>

                        <Button
                            label={<FormattedMessage
                                id="next"
                                defaultMessage="Next"/>}
                            type="submit"/>
                    </form>
                </Paper>
            </Container>
        );
    }

}

export default reduxForm({
    form: "campaignForm",
    validate,
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CampaignFormPage5);
