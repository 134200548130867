import axios from "axios";
import {ROOT_URL} from "../../common/config/karming-links";
import {
    CREATE_POINT_OF_SALE,
    FETCH_MERCHANT_CURRENT_POINT_OF_SALE,
    FETCH_MERCHANT_POINT_OF_SALE,
    FETCH_MERCHANT_POINTS_OF_SALE,
    UPDATE_POINT_OF_SALE
} from "../../common/actions/types";
import {apiError, headerConfig} from "../../common/actions/utils-actions";
import {chooseCurrentChain, fetchCommercialChain} from "./merchant-chain-actions";

const ENDPOINT_URL = `${ROOT_URL}/merchant`;

export function fetchPointOfSale(pointOfSaleId, current, fetchChain) {
    if (!pointOfSaleId) {
        throw "fetchPointOfSale: pointOfSaleId Required";
    }
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/locations/${pointOfSaleId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: current ? FETCH_MERCHANT_CURRENT_POINT_OF_SALE : FETCH_MERCHANT_POINT_OF_SALE,
                    payload: response.data
                });
                if (current && fetchChain) {
                    dispatch(chooseCurrentChain(response.data.commercialChainId))
                }
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch point of sale', response));
            });
    }
}

export function fetchPointsOfSale(chainId) {
    if (!chainId) {
        throw "fetchPointsOfSale: chainId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/${chainId}/locations`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_MERCHANT_POINTS_OF_SALE,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch points of sale', response));
            });
    }
}

export function createPointOfSale(values, history, user, chainId) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/locations/create`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: CREATE_POINT_OF_SALE,
                    payload: response.data
                });
                dispatch(fetchCommercialChain(chainId, user));
                history.push("/locations")
            })
            .catch(response => {
                dispatch(apiError('Unable to create point of sale', response));
            });
    }
}

export function updatePointOfSale(pointOfSaleId, values, history) {
    if (!pointOfSaleId) {
        throw "fetchPointOfSale: pointOfSaleId Required";
    }

    return dispatch => {
        axios.post(`${ENDPOINT_URL}/locations/${pointOfSaleId}`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: UPDATE_POINT_OF_SALE,
                    payload: response.data
                });
                history.push("/locations")
            })
            .catch(response => {
                dispatch(apiError('Unable to update point of sale', response));
            });
    }
}
