import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import List from '@material-ui/icons/List';
import Collapse from "@material-ui/core/Collapse";
import AccountBalance from '@material-ui/icons/AccountBalance';
import PhoneIphone from '@material-ui/icons/PhoneIphone';

import {renderExpandableMenuIcon} from "./AccountMenu";

import {KARMING_TERMS_OF_SERVICE_ADDRESS} from "../../../common/config/karming-links";
import {BILLING_DETAILS_URL, SMS_URL} from "../../../../static/utils/redirect-const";

function MySubscriptionSubmenu(props) {
    const {classes, onItemClicked} = props;
    const [openSubmenu, setSubmenuOpen] = useState(false);

    const handleToggle = () => {
        setSubmenuOpen(prevOpen => !prevOpen);
    };

    return (
        <React.Fragment>
            <MenuItem
                className={openSubmenu
                    ? classes.expandedMenuItemAccount
                    : classes.menuItemAccount}
                onClick={handleToggle}>
                <List className={classes.menuItemIconMain}/>
                <Typography color="inherit">
                    <FormattedMessage
                        id="mySubscription"
                        defaultMessage="My Subscription"/>
                </Typography>

                {renderExpandableMenuIcon(openSubmenu)}
            </MenuItem>

            <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                <MenuItem
                    className={classes.submenuItemAccount}
                    component={Link}
                    to={BILLING_DETAILS_URL}
                    onClick={onItemClicked}>
                    <AccountBalance className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="billingDetails"
                            defaultMessage="Billing details"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.submenuItemAccount}
                    component={Link}
                    to={SMS_URL}
                    onClick={onItemClicked}>
                    <PhoneIphone className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="smsService"
                            defaultMessage="SMS service"/>
                    </Typography>
                </MenuItem>

                <MenuItem className={classes.submenuItemAccount}>
                    <Typography
                        color="inherit"
                        component="div">
                        <a
                            className={classes.menuLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={KARMING_TERMS_OF_SERVICE_ADDRESS}>
                            <List className={classes.menuItemIconMain}/>
                            <FormattedMessage
                                id="termsAndConditions"
                                defaultMessage="Terms and Conditions"/>
                        </a>
                    </Typography>
                </MenuItem>

                <Divider className={classes.divider}/>
            </Collapse>
        </React.Fragment>
    )
}

MySubscriptionSubmenu.propTypes = {
    classes: PropTypes.object,
    onItemClicked: PropTypes.func
};

export default withRouter(MySubscriptionSubmenu)
