import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";

class SendoutFormPage3 extends React.Component {

    static propTypes = {
        email: PropTypes.object,
        campaigns: PropTypes.array,
        membersWithPhone: PropTypes.array,
        membersWithEmail: PropTypes.array,
        selectedMembers: PropTypes.array,
        submitting: PropTypes.bool,
        sms: PropTypes.bool,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func
    };

    render() {
        const {handleSubmit, previousPage, submitting, sms, email, selectedMembers, membersWithEmail, membersWithPhone} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    <FormattedMessage
                        id="campaignConfirmation"
                        defaultMessage="Campaign confirmation"/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="sendout-form-page-three.yourMessageWillBeSendTo"
                        defaultMessage="Your message will be send to {receiversNumber} Members,
                         including {smsReceiversNumber} SMS and {emailReceiversNumber} email recipients."
                        values={{
                            receiversNumber: this.getTotalUniqueReceiverNumber(selectedMembers, membersWithPhone, membersWithEmail, sms, email),
                            smsReceiversNumber: this.getSmsReceiverNumber(membersWithPhone, sms),
                            emailReceiversNumber: this.getEmailReceiverNumber(membersWithEmail, email)
                        }}/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="sendout-form-page-three.totalCreditsFor"
                        defaultMessage="Total credits for SMS: {smsReceiversNumber} credits."
                        values={{
                            smsReceiversNumber: this.getSmsReceiverNumber(membersWithPhone, sms),
                        }}/>
                </Typography>

                {selectedMembers.length === 0 && (
                    <Typography
                        variant="body1"
                        color="error">
                        <FormattedMessage
                            id="sendout-form-page-three.thereIsNoPotentialReceivers"
                            defaultMessage="There is no potential receivers"/>.
                    </Typography>
                )}

                <Button
                    label={<FormattedMessage
                        id="previous"
                        defaultMessage="Previous"/>}
                    color="secondary"
                    onClick={previousPage}/>

                <Button
                    label={<FormattedMessage
                        id="confirm"
                        defaultMessage="Confirm"/>}
                    type="submit"
                    disabled={selectedMembers.length === 0 || submitting}/>
            </form>
        );
    }

    getSmsReceiverNumber(receivers, sms) {
        return sms ? receivers.length : 0;
    }

    getEmailReceiverNumber(receivers, email) {
        return email ? receivers.length : 0;
    }

    getTotalUniqueReceiverNumber(receivers, membersWithPhone, membersWithEmail, sms, email) {
        return receivers.filter(receiver => (sms && membersWithPhone.includes(receiver) || email && membersWithEmail.includes(receiver))).length
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('sendoutForm');
    const sms = selector(state, 'sms');
    const email = selector(state, 'email');
    return {
        sms,
        email
    }
}

export default reduxForm({
    form: "sendoutForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connect(mapStateToProps, null)(SendoutFormPage3))
