import {FormattedMessage} from "react-intl";
import React from "react";

export const DRAFT =
    {
        value: "DRAFT",
        label: <FormattedMessage
            id="draftUppercase"
            defaultMessage={`Draft`}/>
    };
export const PUBLISHED =
    {
        value: "PUBLISHED",
        label: <FormattedMessage
            id="published"
            defaultMessage={`Published`}/>
    };
export const ACTIVE =
    {
        value: "ACTIVE",
        label: <FormattedMessage
            id="active"
            defaultMessage={`Active`}/>
    };
export const INACTIVE =
    {
        value: "INACTIVE",
        label: <FormattedMessage
            id="inactive"
            defaultMessage={`Inactive`}/>
    };
export const WITHDRAWN =
    {
        value: "WITHDRAWN",
        label: <FormattedMessage
            id="withdrawn"
            defaultMessage={`Withdrawn`}/>
    };

export const STOPPED =
    {
        value: "STOPPED",
        label: <FormattedMessage
            id="stopped"
            defaultMessage={`Stopped`}/>
    };

export const publishStatusOption = [DRAFT, PUBLISHED, ACTIVE, INACTIVE, WITHDRAWN, STOPPED];
