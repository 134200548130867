import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import AuthRequiredRoute from "../../common/components/auth/AuthRequiredRoute";
import Profile from "../../common/components/common/Profile";
import AppMessageBar from "../../common/components/AppMessageBar";
import MerchantRoutes from "../../merchant/components/MerchantRoutes";
import AppRightSticker from "../../common/components/AppRightSticker"
import NotFoundPage from "../../common/components/common/NotFoundPage";
import Dialog from '../../common/components/common/Dialog'
import Button from "../../common/components/common/Button";
import MerchantHome from "./MerchantHome";
import AuthRoutes from "../../common/components/auth/AuthRoutes";
import MainPageContainer from "../../common/components/common/MainPageContainer";
import MerchantAppMenu from "./menu/MerchantAppMenu";

import {logout} from "../../common/actions/auth-actions";
import {
    setAccountMenuVisibility,
    setSidebarVisibility,
    setStartGuideVisibility
} from "../../common/actions/utils-actions";
import {ROLE_CASHIER, ROLE_LOCAL_MERCHANT, ROLE_MERCHANT} from "../../common/components/auth/roles";
import {
    fetchCommercialChain,
    fetchCommercialChains,
    prepareMerchantChainsState
} from "../../merchant/actions/merchant-chain-actions";
import {fetchUserDetails} from "../../common/actions/user-actions";

import "../../../static/style/main.scss";

class App extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        auth: PropTypes.object,
        user: PropTypes.object,
    };

    state = {
        open: false,
        text: ""
    };

    handleAppOpenDialog = (text) => {
        this.setState({open: true, text: text});
    };

    handleAppCloseDialog = () => {
        this.setState({open: false, text: ""});
    };

    dialogActions = [
        <Button
            key="close"
            label={<FormattedMessage
                id="close"
                defaultMessage="Close"/>}
            onClick={this.handleAppCloseDialog}/>,
    ];

    componentDidMount() {
        this.props.fetchUserDetails();

        switch (this.props.auth.role) {

            case ROLE_MERCHANT:
                this.props.prepareMerchantChainsState();
                break;
            case ROLE_LOCAL_MERCHANT:
                this.props.prepareMerchantChainsState();
                break;
            case ROLE_CASHIER:
                this.props.prepareMerchantChainsState();
                break;
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.auth.role !== nextProps.auth.role) {
            switch (nextProps.auth.role) {
                case ROLE_MERCHANT:
                    this.props.prepareMerchantChainsState();
                    break;
                case ROLE_LOCAL_MERCHANT:
                    this.props.prepareMerchantChainsState();
                    break;
                case ROLE_CASHIER:
                    this.props.prepareMerchantChainsState();
                    break;
            }
        }
    }

    render() {
        const {
            auth, app, user, chains, currentPoS, logout, setSidebarVisibility, setAccountMenuVisibility,
            setStartGuideVisibility
        } = this.props;

        return (
            <Router>
                <div>
                    <MerchantAppMenu
                        auth={auth}
                        app={app}
                        user={user}
                        chains={chains}
                        currentPoS={currentPoS}
                        logout={logout}
                        setSidebarVisibility={setSidebarVisibility}
                        setAccountMenuVisibility={setAccountMenuVisibility}
                        setStartGuideVisibility={setStartGuideVisibility}/>

                    <AppMessageBar
                        auth={auth}
                        status={app.status}/>

                    <MainPageContainer userRole={auth.role}>
                        <Switch>
                            <AuthRequiredRoute
                                {...this.props}
                                exact path="/"
                                auth={auth}
                                app={app}
                                user={user}
                                chains={chains}
                                component={MerchantHome}/>

                            {AuthRoutes(this.props)}

                            {MerchantRoutes(this.props)}

                            <AuthRequiredRoute
                                exact path="/user/profile"
                                auth={auth}
                                app={app}
                                user={user}
                                component={Profile}/>

                            <Route component={NotFoundPage}/>
                        </Switch>

                        <AppRightSticker
                            {...this.props}
                            auth={auth}
                            app={app}
                            user={user}
                            setSidebarVisibility={setSidebarVisibility}
                            setStartGuideVisibility={setStartGuideVisibility}
                            setAccountMenuVisibility={setAccountMenuVisibility}
                            handleAppOpenDialog={this.handleAppOpenDialog.bind(this)}/>

                        <Dialog
                            onClose={this.handleAppCloseDialog}
                            title={<FormattedMessage
                                id="congrats"
                                defaultMessage="Congrats!"/>}
                            actions={this.dialogActions}
                            open={this.state.open}
                            content={
                                <React.Fragment>
                                    {this.state.text}
                                </React.Fragment>
                            }/>
                    </MainPageContainer>
                </div>
            </Router>
        );
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        app: state.app,
        user: state.user,
        chains: state.chains,
        currentPoS: state.merchant.currentPointOfSale
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        logout, setSidebarVisibility, setStartGuideVisibility, setAccountMenuVisibility, fetchUserDetails,
        fetchCommercialChain, fetchCommercialChains, prepareMerchantChainsState
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
