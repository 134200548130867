import React from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    wrapper: {
        display: "inline-flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(2)
        },
        "& > :first-child": {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1)
        }
    }
});

const TableTitle = (props) => {
    const {classes, button, title} = props;

    return (
        <div className={classes.wrapper}>
            <Typography variant="h5">
                {title}
            </Typography>
            {button}
        </div>
    )

};

TableTitle.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.object.isRequired,
    button: PropTypes.object
};

export default withStyles(styles)(TableTitle)
