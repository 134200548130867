import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";
import Add from '@material-ui/icons/Add';

import Button from "../../../common/components/common/Button";
import Spinner from "../../../common/components/common/Spinner";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {DEFAULT_PAGE_SIZE, KarmingTable} from "../../../common/components/common/table/KarmingTable";
import {getFormattedDate} from "../../../common/utils/date-utils";

import {fetchCommercialChainPurchasesCounted} from "../../actions/merchant-purchase-actions";
import TableTitle from "../../../common/components/common/table/TableTitle";

class MerchantPurchases extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        history: PropTypes.object,
        chain: PropTypes.object,
        purchases: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        fetchPurchases: PropTypes.func,
        fetchCommercialChainPurchasesCounted: PropTypes.func
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain ||
            prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchCommercialChainPurchasesCounted} = this.props;
        if (chain && chain.id) {
            const chainId = chain.id;
            fetchCommercialChainPurchasesCounted({
                chainId: chainId,
                page: 0,
                entriesPerPage: DEFAULT_PAGE_SIZE
            });
        }
    }

    moveToRegisterPurchase() {
        this.props.history.push("/purchase/search");
    }

    render() {
        const {chain, history, purchases, app} = this.props;

        if (!chain || !purchases) {
            return <Spinner app={app}/>
        }

        const columns = [
            {
                Header: <FormattedMessage
                    id="membershipId"
                    defaultMessage="Membership Id"/>,
                id: "consumerMembership",
                accessor: purchase => purchase.memberEnabled ? purchase.membershipId : "-"

            },
            {
                Header: <FormattedMessage
                    id="name"
                    defaultMessage="Name"/>,
                id: "consumerName",
                accessor: purchase =>
                    purchase.memberEnabled
                        ? (purchase.memberFirstName || purchase.memberLastName
                        ? purchase.memberFirstName + " " + purchase.memberLastName
                        : '') :
                        <Typography variant="body1" color="error">
                            <FormattedMessage id="removedMember" defaultMessage="Removed Member"/>
                        </Typography>
            },
            {
                Header: <FormattedMessage
                    id="totalAmountPaid"
                    defaultMessage="Total amount paid"/>,
                id: "totalAmount",
                accessor: purchase => purchase.totalAmount ? purchase.totalAmount + " SEK" : ""
            },
            {
                Header: <FormattedMessage
                    id="dateOfPurchase"
                    defaultMessage="Date of purchase"/>,
                id: "dateOfPurchase",
                accessor: purchase => getFormattedDate(purchase.dateOfPurchase)
            },
            {
                Header: <FormattedMessage
                    id="pointOfSale"
                    defaultMessage="Point of Sale"/>,
                id: "pointOfSale",
                accessor: purchase => purchase.pointOfSaleName
            }
        ];

        return (
            <Container size={Container.SIZE_HUGE}>
                <Paper>
                    <TableTitle
                        title={<FormattedMessage
                            id="purchases"
                            defaultMessage="Purchases"/>}
                        button={
                            <Button
                                icon={<Add/>}
                                label={<FormattedMessage
                                    id="register"
                                    defaultMessage="Register"/>}
                                onClick={this.moveToRegisterPurchase.bind(this)}/>
                        }/>

                    <KarmingTable
                        title={<FormattedMessage
                            id="purchases"
                            defaultMessage="Purchases"/>}
                        button={
                            <Button
                                icon={<Add/>}
                                label={<FormattedMessage
                                    id="register"
                                    defaultMessage="Register"/>}
                                onClick={this.moveToRegisterPurchase.bind(this)}/>
                        }
                        history={history}
                        sortable={false}
                        pager={true}
                        totalCount={purchases.totalCount}
                        onSearchConfigChanged={this.fetchData.bind(this)}
                        data={purchases.pageItems}
                        columns={columns}
                        sortByDateOfPurchase={true}
                        onRowClickHistoryPush={(state, row) => {
                            return (
                                {
                                    pathname: "/purchases/update/" + row.memberId + "/" + row.id
                                })
                        }}
                        baseSearchConfig={{chainId: this.props.chain.id}}/>
                </Paper>
            </Container>
        );
    }

    fetchData(searchConfig) {
        const {fetchCommercialChainPurchasesCounted, chain} = this.props;
        if (chain && chain.id) {
            searchConfig.chainId = chain.id;
            fetchCommercialChainPurchasesCounted(searchConfig)
        }
    }
}

function mapStateToProps(state) {
    return {
        purchases: state.purchase.purchases,
        chain: state.chains.current,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCommercialChainPurchasesCounted}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantPurchases);
