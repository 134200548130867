import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";

import StartGuide from "../../merchant/components/account/start/StartGuide";
import StartGuideButton from "./StartGuideButton";

import {ROLE_MERCHANT} from "./auth/roles";
import RenderOnMobile from "./common/RenderOnMobile";

const styles = theme => ({
    drawer: {
        "& > div": {
            marginTop: 64,
            paddingTop: theme.spacing(1),
            boxShadow: 'none',
            [theme.breakpoints.down('sm')]: {
                paddingTop: 15,
                width: '100%'
            },
            [theme.breakpoints.up('sm')]: {
                left: 'initial',
                right: 0,
                width: 280,
                borderLeft: `1px solid ${theme.palette.grey.light}`
            }
        }
    },
    buttonText: {
        letterSpacing: 3,
        transform: 'rotate(-90deg)'
    }
});

class AppRightSticker extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        app: PropTypes.object,
        user: PropTypes.object,
        auth: PropTypes.object,
        chains: PropTypes.object,
        history: PropTypes.object,
        toggleStartGuide: PropTypes.func,
        handleAppOpenDialog: PropTypes.func.isRequired,
        setStartGuideVisibility: PropTypes.func,
        setAccountMenuVisibility: PropTypes.func,
        setSidebarVisibility: PropTypes.func
    };

    render() {
        const {auth, app, user, history, chains} = this.props;

        if (!auth
            || !auth.authenticated
            || !user
            || !user.profile
            || !user.profile.company
            || auth.role !== ROLE_MERCHANT
            || user.profile.company.startGuideCompleted) {
            return null;
        }

        const company = user.profile.company;
        let chain = chains && chains.current ? chains.current : null;
        return this.renderStartGuide(app, chain, company, history)
    }

    renderStartGuide(app, chain, company, history) {
        const {classes} = this.props;
        return (
            <div>
                <RenderOnMobile>
                    {isMobile => (
                        <Drawer
                            className={classes.drawer}
                            anchor="right"
                            variant={isMobile ? "temporary" : "persistent"}
                            open={app.startGuideOpen}>
                            <StartGuide
                                app={app}
                                history={history}
                                chain={chain}
                                company={company}
                                handleAppOpenDialog={this.props.handleAppOpenDialog}/>

                            {/*FIXME workaround to make scrollable on mobile*/}
                            <MenuItem disabled/>
                            <MenuItem disabled/>
                            <MenuItem disabled/>
                            <MenuItem disabled/>
                        </Drawer>
                    )}
                </RenderOnMobile>

                <StartGuideButton
                    onClick={this.toggleStartGuide.bind(this, app)}
                    label={this.renderStartGuideButtonContent(app.startGuideOpen)}
                    size={app.startGuideOpen ? 'small' : 'big'}/>
            </div>
        );
    }

    renderStartGuideButtonContent(startGuideOpen) {
        const {classes} = this.props;

        if (startGuideOpen) {
            return (
                <div className={classes.buttonText}>
                    <FormattedMessage
                        id="hide"
                        defaultMessage="HIDE"/>
                </div>
            )
        } else
            return (
                <div className={classes.buttonText}>
                    <FormattedMessage
                        id="start"
                        defaultMessage="START"/>
                    &nbsp;
                    <FormattedMessage
                        id="guide"
                        defaultMessage="GUIDE"/>
                </div>
            )
    }

    //FIXME move setVisibility to real actions
    toggleStartGuide(app) {
        this.props.setStartGuideVisibility(!app.startGuideOpen);
        if (app.accountMenuOpen && !app.startGuideOpen) {
            this.props.setAccountMenuVisibility(false)
        }
        app.sidebarOpen && this.props.setSidebarVisibility(false);
    }
}

export default (withRouter(withStyles(styles)(AppRightSticker)));
