import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ReactTooltip from 'react-tooltip';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Add from '@material-ui/icons/Add';

import Button from "../../../../common/components/common/Button";
import {KarmingTable} from "../../../../common/components/common/table/KarmingTable";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";
import Spinner from "../../../../common/components/common/Spinner";
import {publishStatusOption} from "../../../utils/publish-status-const";

import {fetchStampcards} from "../../../actions/merchant-stampcard-actions";

const styles = theme => ({
    title: {
        display: "inline-flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        "& > :first-child": {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        }
    }
});

class Stampcards extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        app: PropTypes.object,
        chain: PropTypes.object,
        stampcards: PropTypes.array,
        fetchStampcards: PropTypes.func
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain || prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchStampcards} = this.props;
        if (chain && chain.id) {
            fetchStampcards(chain.id);
        }
    }

    render() {
        const {classes, history, app, stampcards} = this.props;

        if (!stampcards) {
            return <Spinner app={app}/>
        }

        if (stampcards && stampcards.length === 0) {
            return this.renderStartingText()
        }

        const columns = [
            {
                Header: <FormattedMessage
                    id="internalName"
                    defaultMessage="Internal name"/>,
                id: "internalName",
                accessor: c => c.internalName,
            },
            {
                Header: <FormattedMessage
                    id="reward"
                    defaultMessage="Reward"/>,
                id: "reward",
                accessor: c => c.stampcardCoupon && c.stampcardCoupon.reward,
            },
            {
                Header: <FormattedMessage
                    id="numberOfStamps"
                    defaultMessage="Number of stamps"/>,
                id: "stampsAmount",
                accessor: c => c.stampsAmount,
            },
            {
                Header: <FormattedMessage
                    id="validityPeriod"
                    defaultMessage="Validity period"/>,
                id: "monthValidation",
                accessor: c => c.monthValidation + " månader",
            },
            {
                Header: <FormattedMessage
                    id="stampCardStatus"
                    defaultMessage="Stamp card status"/>,
                id: "publishStatus",
                accessor: c => publishStatusOption.find(status => status.value === c.publishStatus).label,
            }
        ];

        return (
            <Container size={Container.SIZE_HUGE}>
                <Paper>
                    <div className={classes.title}>
                        <Typography variant="h5">
                            <FormattedMessage
                                id="stampcard"
                                defaultMessage="Stamp card"/>
                        </Typography>

                        <Button
                            icon={<Add/>}
                            label={<FormattedMessage
                                id="create"
                                defaultMessage="Create"/>}
                            onClick={() => history.push('stampcard/define')}/>
                    </div>

                    <KarmingTable
                        history={this.props.history}
                        data={this.props.stampcards}
                        columns={columns}
                        onRowClickHistoryPush={(state, row) => {
                            return (
                                {
                                    pathname: "/stampcards/" + row.id
                                })
                        }}/>
                </Paper>
            </Container>
        );
    }

    renderStartingText() {
        const {history, chain} = this.props;

        const createStampCardDisabled = !chain.membershipConditionsPublished;

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <Typography
                        variant="h5"
                        paragraph>
                        <FormattedMessage
                            id="stampcard"
                            defaultMessage="Stamp card"/>
                    </Typography>

                    <Typography variant="h6">
                        <FormattedMessage
                            id="rewardYourFaithfulCustomers"
                            defaultMessage="Reward your faithful customers"/>
                        !
                    </Typography>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="stampCardsAreAppreciatedWayOfCreatingAndMaintainingRecurringAndProfitableCustomers"
                            defaultMessage="Stamp cards are an appreciated way of creating and maintaining recurring and profitable customers."/>
                    </Typography>


                    <Typography variant="h6">
                        <FormattedMessage
                            id="easy"
                            defaultMessage="Easy"/>
                    </Typography>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="theCustomerReceivesAStampDirectlyOnTheMobilePhone"
                            defaultMessage="The customer receives a stamp directly on the mobile phone."/>
                    </Typography>

                    <Typography variant="h6">
                        <FormattedMessage
                            id="automatic"
                            defaultMessage="Automatic"/>
                    </Typography>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="rewardIsAwardedAutomatically"
                            defaultMessage="Reward is awarded automatically."/>
                    </Typography>

                    <Typography variant="h6">
                        <FormattedMessage
                            id="profitable"
                            defaultMessage="Profitable"/>
                    </Typography>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="customerIsRewardedAsYouProfit"
                            defaultMessage="Customer is rewarded as you profit"/>
                    </Typography>

                    <span data-tip data-for='beforeCreateStampcard'>
                    <Button
                        label={
                            <React.Fragment>
                                <FormattedMessage
                                    id="okLetsStart"
                                    defaultMessage="Ok, let's start"/>
                                !
                            </React.Fragment>
                        }
                        onClick={() => history.push("/stampcard/define")}
                        disabled={createStampCardDisabled}/>
                    </span>

                    {createStampCardDisabled && (
                        <ReactTooltip id='beforeCreateStampcard' type='info'>
                            <Typography color="inherit">
                                <FormattedMessage
                                    id="beforeCreatingStampcardYouHaveToPublish"
                                    defaultMessage="Before creating Stamp card you have to publish Membership Conditions"/>
                            </Typography>
                            <Typography color="inherit">
                                <FormattedMessage
                                    id="seeMenu"
                                    defaultMessage="See menu"/>
                                :&nbsp;
                                <FormattedMessage
                                    id="account"
                                    defaultMessage="Account"/>
                                <FormattedMessage
                                    id="membershipConditions"
                                    defaultMessage="Membership Conditions"/>
                            </Typography>
                        </ReactTooltip>
                    )}
                </Paper>
            </Container>
        )
    }

}

function mapStateToProps(state) {
    return {
        stampcards: state.merchant.stampcards,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchStampcards,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Stampcards));
