import axios from "axios";
import {apiError, headerConfig, setStartGuideVisibility} from "../../common/actions/utils-actions";
import {ROOT_URL} from "../../common/config/karming-links";
import {MEMBERSHIP_CONDITIONS, PRIVACY_POLICY} from "../../common/actions/types";
import {fetchCommercialChain} from "./merchant-chain-actions";
import {fetchUserDetails} from "../../common/actions/user-actions";

const ENDPOINT_URL = `${ROOT_URL}/merchant`;

export function defaultMembershipConditions(chainId) {
    if (!chainId) {
        throw "defaultMembershipConditions: chainId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/${chainId}/terms/membership/default`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: MEMBERSHIP_CONDITIONS,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch default Membership Conditions', response));
        });
    }
}

export function fetchMembershipConditions(chainId) {
    if (!chainId) {
        throw "defaultMembershipConditions: chainId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/${chainId}/terms/membership/`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: MEMBERSHIP_CONDITIONS,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch Membership Conditions', response));
        });
    }
}

export function updateMembershipConditions(chainId, values) {
    if (!chainId) {
        throw "updateMembershipConditions: chainId Required";
    }

    return dispatch => {
        axios.post(`${ENDPOINT_URL}/chain/${chainId}/terms/membership/`, values, headerConfig())
            .then(response => {
                if (response) {
                    dispatch(fetchMembershipConditions(chainId));
                    termsUpdatedForStartGuide(values, dispatch, chainId)
                }

            }).catch(response => {
            dispatch(apiError('Unable to update Membership Conditions', response));
        });
    }
}


export function defaultPrivacyPolicy(chainId) {
    if (!chainId) {
        throw "defaultPrivacyPolicy: chainId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/${chainId}/terms/privacy/default`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: PRIVACY_POLICY,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch default Privacy Policy', response));
        });
    }
}

export function fetchPrivacyPolicy(chainId) {
    if (!chainId) {
        throw "fetchMembershipConditions: chainId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/${chainId}/terms/privacy/`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: PRIVACY_POLICY,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch Privacy Policy', response));
        });
    }
}

export function updatePrivacyPolicy(chainId, values) {
    if (!chainId) {
        throw "updatePrivacyPolicy: chainId Required";
    }

    return dispatch => {
        axios.post(`${ENDPOINT_URL}/chain/${chainId}/terms/privacy/`, values, headerConfig())
            .then(response => {
                if (response) {
                    dispatch(fetchPrivacyPolicy(chainId));
                    termsUpdatedForStartGuide(values, dispatch, chainId)
                }
            }).catch(response => {
            dispatch(apiError('Unable to update Privacy Policy', response));
        });
    }
}

function termsUpdatedForStartGuide(values, dispatch, chainId) {
    if (values.published) {
        dispatch(fetchUserDetails())
        dispatch(fetchCommercialChain(chainId))
        dispatch(setStartGuideVisibility(true))
    }
}
