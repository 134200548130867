import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import MenuItem from "@material-ui/core/MenuItem";
import Add from "@material-ui/icons/Add";
import Checkbox from "@material-ui/core/Checkbox";
import Search from "@material-ui/icons/Search";
import Radio from "@material-ui/core/Radio";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMore from '@material-ui/icons/ExpandMore';
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";

import RenderSelect from "../../../common/components/common/RenderSelect";
import Button from "../../../common/components/common/Button";
import TitleWithBackButton from "../../../common/components/TitleWithBackButton";
import RenderDatePicker from "../../../common/components/common/form/RenderDatePicker";
import RenderRadioButtonGroup from "../../../common/components/common/form/RenderRadioButtonGroup";
import FilterList from "./FilterList";

import {getFormattedDate} from "../../../common/utils/date-utils";
import {setFormValue} from "../../actions/merchant-forms-actions";
import {fetchSendoutHistory} from "../../actions/merchant-actions";

export const RECEIVED_ANY = {
    value: "RECEIVED_ANY",
    label: <FormattedMessage
        id="receivedAnySendout"
        defaultMessage="Have received any of marked send-outs"/>
};

export const RECEIVED_ALL = {
    value: "RECEIVED_ALL",
    label: <FormattedMessage
        id="receivedAllSendout"
        defaultMessage="Have received all of marked send-outs"/>
};

export const NOT_RECEIVED_ANY = {
    value: "NOT_RECEIVED_ANY",
    label: <FormattedMessage
        id="notReceivedAnySendout"
        defaultMessage="Have not received any of marked send-outs"/>
};

export const NOT_RECEIVED_ALL = {
    value: "NOT_RECEIVED_ALL",
    label: <FormattedMessage
        id="notReceivedAllSendout"
        defaultMessage="Have not received all of marked send-outs"/>
};

export const receivedSendoutOption = [RECEIVED_ANY, RECEIVED_ALL, NOT_RECEIVED_ANY, NOT_RECEIVED_ALL];

export const SMS_OR_EMAIL = {
    value: "SMS_OR_EMAIL",
    label: <FormattedMessage
        id="smsOrEmail"
        defaultMessage="Sms or email"/>
};

export const SMS = {
    value: "SMS",
    label: <FormattedMessage
        id="sms"
        defaultMessage="SMS"/>
};

export const EMAIL = {
    value: "EMAIL",
    label: <FormattedMessage
        id="email"
        defaultMessage="Email"/>
};

export const receivedSendoutMedium = [SMS_OR_EMAIL, SMS, EMAIL];

const styles = theme => ({
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1)
        }
    },
    expandIcon: {
        position: 'absolute',
        bottom: -theme.spacing(2),
        "& svg": {
            fontSize: 36
        }
    },
    expansionPanel: {
        backgroundColor: 'inherit',
        boxShadow: 'none',
    },
    expansionPanelSummary: {
        padding: 0,
        "& > div": {
            display: 'initial'
        }
    },
    checkbox: {
        padding: `0 !important`,
        position: "absolute",
        top: '40%',
        right: 8,
        transform: 'translateY(-50%) rotate(0deg)'
    },
    selectButtonWrapper: {
        textAlign: 'right'
    },
    card: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        position: 'relative',
        textAlign: 'left'
    },
    cardDisabled: {
        backgroundColor: theme.palette.grey.light
    }
});

class SendoutFilterForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        selectMembersInfo: PropTypes.object,
        chain: PropTypes.object,
        initialValues: PropTypes.object,
        sendOuts: PropTypes.object,
        endDate: PropTypes.object,
        startDate: PropTypes.object,
        sendouts: PropTypes.array,
        sendoutHistory: PropTypes.array,
        categories: PropTypes.array,
        campaigns: PropTypes.array,
        form: PropTypes.string,
        submitting: PropTypes.bool,
        moveBack: PropTypes.func,
        fetchSendoutHistory: PropTypes.func,
        handleSubmit: PropTypes.func,
        setFormValue: PropTypes.func
    };

    state = {
        expansionPanelOpen: []
    };

    componentDidMount() {
        this.props.setFormValue(
            this.props.form,
            "sendoutFilter.sendouts",
            this.props.sendoutHistory.map(sendout => sendout.id))
    }

    render() {
        const {
            handleSubmit, moveBack, selectMembersInfo, sendoutHistory, chain, startDate, endDate, sendouts,
            campaigns, categories, initialValues, classes
        } = this.props;

        return (
            <React.Fragment>
                <TitleWithBackButton
                    title={<FormattedMessage
                        id="smartSelection"
                        defaultMessage="Smart Selection"/>}
                    onBackClick={moveBack.bind(this, moveBack)}/>

                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage
                        id="sendoutHistory"
                        defaultMessage="Sendout history"/>
                </Typography>

                <form onSubmit={handleSubmit}>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="sendoutReceived"
                            defaultMessage="Send-out received"/>:
                    </Typography>

                    <div className={classes.doubleFieldsWrapper}>
                        <Field
                            name="startDate"
                            component={RenderDatePicker}
                            hintText={<FormattedMessage
                                id="after"
                                defaultMessage="After"/>}/>

                        <Field
                            name="endDate"
                            component={RenderDatePicker}
                            endOfDay
                            hintText={<FormattedMessage
                                id="before"
                                defaultMessage="Before"/>}/>
                    </div>

                    <Button
                        color="secondary"
                        icon={<Search/>}
                        label={<FormattedMessage
                            id="updateSendoutDates"
                            defaultMessage="Update send-out dates"/>}
                        onClick={this.handleSearchByDate.bind(this, chain.id, {startDate: startDate, endDate: endDate})}
                        disabled={getFormattedDate(initialValues.startDate) === getFormattedDate(startDate)
                        && getFormattedDate(initialValues.endDate) === getFormattedDate(endDate)}/>

                    <Typography variant="body1">
                        {selectMembersInfo}
                    </Typography>

                    <Field
                        name="sendoutFilter.received"
                        component={RenderSelect}
                        defaultValue={NOT_RECEIVED_ANY.value}>

                        {receivedSendoutOption.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Field>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="sentAs"
                            defaultMessage="Sent as"/>:
                    </Typography>

                    <Field
                        name="sendoutFilter.medium"
                        component={RenderRadioButtonGroup}
                        defaultValue={SMS_OR_EMAIL.value}>

                        <FormControlLabel
                            value={SMS_OR_EMAIL.value}
                            control={<Radio/>}
                            label={SMS_OR_EMAIL.label}/>

                        <FormControlLabel
                            value={SMS.value}
                            control={<Radio/>}
                            label={SMS.label}/>

                        <FormControlLabel
                            value={EMAIL.value}
                            control={<Radio/>}
                            label={EMAIL.label}/>
                    </Field>

                    {sendoutHistory.length > 0 && (
                        <React.Fragment>
                            <div className={classes.selectButtonWrapper}>
                                <Button
                                    color="secondary"
                                    label={sendouts && sendouts.length === sendoutHistory.length
                                        ? <FormattedMessage
                                            id="deselectAll"
                                            defaultMessage="Deselect all"/>
                                        : <FormattedMessage
                                            id="selectAll"
                                            defaultMessage="Select all"/>}
                                    onClick={this.addOrRemoveAllSendoutHistoryFromList.bind(
                                        this,
                                        sendoutHistory,
                                        sendouts
                                        && sendouts.length === sendoutHistory.length)}/>
                            </div>

                            {sendoutHistory
                                .sort((a, b) => b.date - a.date)
                                .map((sendout, index) => {
                                    return (
                                        <Card
                                            key={index}
                                            className={classnames(classes.card,
                                                !sendouts || !sendouts.includes(sendout.id) && classes.cardDisabled)}
                                            elevation={1}>
                                            <ExpansionPanel
                                                expanded={this.state.expansionPanelOpen.includes(index)}
                                                index={index}
                                                className={classes.expansionPanel}>
                                                <ExpansionPanelSummary
                                                    classes={{expandIcon: this.props.classes.expandIcon}}
                                                    className={classes.expansionPanelSummary}
                                                    IconButtonProps={{
                                                        disableRipple: true,
                                                        onClick: () => {
                                                            this.setState({
                                                                expansionPanelOpen:
                                                                    this.state.expansionPanelOpen.includes(index)
                                                                        ? this.state.expansionPanelOpen
                                                                            .filter(open => open !== (index))
                                                                        : [...this.state.expansionPanelOpen, index]
                                                            })
                                                        }
                                                    }}
                                                    expandIcon={sendout.smartSelection === null
                                                        ? undefined
                                                        : <ExpandMore/>
                                                    }>

                                                    <Typography variant="subtitle1">
                                                        {sendout.name}
                                                    </Typography>

                                                    <Typography variant="body1">
                                                        <FormattedMessage
                                                            id="type"
                                                            defaultMessage="Type"/>: {sendout.sendoutType}
                                                    </Typography>

                                                    <Typography variant="body1">
                                                        <FormattedMessage
                                                            id="sendoutDate"
                                                            defaultMessage="Send-out date"/>: {getFormattedDate(sendout.date)}
                                                    </Typography>

                                                    <Typography variant="body1">
                                                        <FormattedMessage
                                                            id="mediumAmount"
                                                            defaultMessage="Medium (amount)"/>: SMS
                                                        ({sendout.receivedSmsConsumers.length}), E-post
                                                        ({sendout.receivedEmailConsumers.length})
                                                    </Typography>

                                                    <Typography variant="body1">
                                                        <FormattedMessage
                                                            id="recipients"
                                                            defaultMessage="Recipients"/>: {sendout.uniqueConsumers.length}
                                                    </Typography>

                                                    <Checkbox
                                                        checked={!!(sendouts && sendouts.includes(sendout.id))}
                                                        onChange={this.addOrRemoveSendoutHistoryFromList.bind(this, sendout.id, sendouts)}
                                                        className={classes.checkbox}/>
                                                </ExpansionPanelSummary>

                                                <ExpansionPanelDetails>
                                                    {sendout.smartSelection !== null && (
                                                        <FilterList
                                                            currentSmartSelection={sendout.smartSelection}
                                                            campaigns={campaigns}
                                                            sendoutHistory={sendoutHistory}
                                                            categories={categories}/>
                                                    )}
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Card>
                                    )
                                })}
                        </React.Fragment>
                    )}

                    {sendoutHistory.length === 0 && (
                        <Typography variant="body1">
                            <FormattedMessage
                                id="noSendoutMarchingGivenDateRanges"
                                defaultMessage=" There is no sendout matching given date ranges"/>
                        </Typography>
                    )}

                    <Button
                        icon={<Add/>}
                        label={<FormattedMessage
                            id="addSmartSelection"
                            defaultMessage="Add Smart Selection"/>}
                        onClick={handleSubmit}
                        disabled={!sendouts || !sendouts.length > 0}/>
                </form>
            </React.Fragment>
        );
    }


    handleSearchByDate(chainId, values) {
        this.props.fetchSendoutHistory(chainId, values);
        this.props.setFormValue(this.props.form, "sendoutFilter.sendouts", [])
    }

    addOrRemoveAllSendoutHistoryFromList(sendoutHistory, allSelected) {
        this.props.setFormValue(this.props.form,
            "sendoutFilter.sendouts",
            allSelected ? [] : sendoutHistory.map(sendout => sendout.id));
        this.forceUpdate()
    }

    addOrRemoveSendoutHistoryFromList(id, sendouts) {
        if (sendouts === undefined) {
            sendouts = [];
            sendouts = [...sendouts, id];
        } else {
            const idAlreadyInArray = sendouts.includes(id);
            if (idAlreadyInArray) {
                sendouts = sendouts.filter(option => option !== id);
            } else {
                sendouts = [...sendouts, id];
            }
        }
        this.props.setFormValue(this.props.form, "sendoutFilter.sendouts", sendouts)
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('sendoutFilterForm');
    const startDate = selector(state, 'startDate');
    const endDate = selector(state, 'endDate');
    const sendouts = selector(state, 'sendoutFilter.sendouts');
    return {
        chain: state.chains.current,
        sendoutHistory: state.merchant.sendoutHistory,
        startDate,
        endDate,
        sendouts
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchSendoutHistory, setFormValue}, dispatch);
}

export default reduxForm({
    form: 'sendoutFilterForm'
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SendoutFilterForm)))
