import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import ReactTooltip from 'react-tooltip'

import Send from '@material-ui/icons/Send';

import Button from "../../../common/components/common/Button";
import {DEFAULT_PAGE_SIZE, KarmingTable} from "../../../common/components/common/table/KarmingTable";
import Spinner from "../../../common/components/common/Spinner";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import TableTitle from "../../../common/components/common/table/TableTitle";

import {fetchConsumersCounted} from "../../actions/merchant-actions";

const renderName = (consumer) => {
    const c = consumer.original || consumer;

    return (
        (c.firstName ? c.firstName : "") + " " + (c.lastName ? c.lastName : "")
    )
};

class Members extends React.Component {

    static propTypes = {
        chain: PropTypes.object,
        history: PropTypes.object,
        app: PropTypes.object,
        consumers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        fetchConsumers: PropTypes.func,
        fetchPrivacyPolicy: PropTypes.func,
        fetchMembershipConditions: PropTypes.func,
        fetchConsumersCounted: PropTypes.func
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain ||
            prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchConsumersCounted} = this.props;
        if (chain && chain.id) {
            const chainId = chain.id;
            fetchConsumersCounted({
                chainId: chainId,
                page: 0,
                entriesPerPage: DEFAULT_PAGE_SIZE
            });
        }
    }

    moveToInviteConsumer() {
        this.props.history.push("/invite");
    }

    render() {
        const {chain, history, consumers, app} = this.props;

        if (!chain || !consumers || !consumers) {
            return <Spinner app={app}/>
        }

        const columns = [
            {
                Header: <FormattedMessage
                    id="membershipNumber"
                    defaultMessage="Membership number"/>,
                id: "membershipId",
                accessor: consumer => consumer.membershipId,
            },
            {
                Header: <FormattedMessage
                    id="name"
                    defaultMessage="Name"/>,
                id: "name",
                Cell: consumer => (
                    <div>
                        {renderName(consumer)}
                    </div>
                ),
                accessor: consumer => renderName(consumer),
            },
            {
                Header: <FormattedMessage
                    id="email"
                    defaultMessage="Email"/>,
                id: "email",
                accessor: consumer => consumer.email,
            },
            {
                Header: <FormattedMessage
                    id="phone"
                    defaultMessage="Phone"/>,
                id: "phone",
                accessor: consumer => consumer.phone,
            },
        ];

        const inviteDisabled = !chain.membershipConditionsPublished || !chain.privacyPolicyPublished;

        return (
            <Container size={Container.SIZE_HUGE}>
                <Paper>
                    <TableTitle
                        title={<FormattedMessage
                            id="members"
                            defaultMessage="Members"/>}
                        button={
                            <span data-tip data-for='beforeInvite'>
                            <Button
                                icon={<Send/>}
                                disabled={inviteDisabled}
                                label={<FormattedMessage
                                    id="invite"
                                    defaultMessage="Invite"/>}
                                onClick={this.moveToInviteConsumer.bind(this)}/>
                            </span>
                        }/>

                    {inviteDisabled && (
                        <ReactTooltip id='beforeInvite' type='info'>
                            <span>
                                 <React.Fragment>
                                     <FormattedMessage
                                         id="beforeInviteMemberYouHaveToDefine"
                                         defaultMessage="Before invite a member you have to publish"/>
                                     &nbsp;
                                     <FormattedMessage
                                         id="privacyPolicy"
                                         defaultMessage="Privacy Policy"/>
                                     &nbsp;
                                     <FormattedMessage
                                         id="and"
                                         defaultMessage="and"/>
                                     &nbsp;
                                     <FormattedMessage
                                         id="membershipConditions"
                                         defaultMessage="Membership Conditions"/>
                                 </React.Fragment>
                                  </span>
                        </ReactTooltip>
                    )}

                    <KarmingTable
                        pager={true}
                        onSearchConfigChanged={this.fetchData.bind(this)}
                        history={history}
                        sortable={false}
                        data={consumers.pageItems}
                        totalCount={consumers.totalCount}
                        columns={columns}
                        onRowClickHistoryPush={(state, row) => {
                            return ({
                                pathname: "/members/" + row.id,
                            })
                        }}
                        baseSearchConfig={{chainId: chain.id}}/>
                </Paper>
            </Container>
        );
    }

    fetchData(searchConfig) {
        const {fetchConsumersCounted, chain} = this.props;
        if (chain && chain.id) {
            searchConfig.chainId = chain.id;
            fetchConsumersCounted(searchConfig)
        }
    }
}

function mapStateToProps(state) {
    return {
        consumers: state.merchant.consumers,
        chain: state.chains.current,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchConsumersCounted}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Members);
