import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Warning from '@material-ui/icons/Warning';
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Typography from "@material-ui/core/Typography";

import StampcardDistribution from "./StampcardDistribution";

import {renderStamps, STANDARD_STAMPCARD} from "../../../../common/components/common/stampcard/StampcardUtils";
import {getFormattedDate} from "../../../../common/utils/date-utils";
import {publishStatusOption} from "../../../utils/publish-status-const";

const styles = theme => ({
    itemsWrapper: {
        margin: '10px 0',
        width: '100%',
        display: 'inline-flex',
        position: 'relative'
    },
    itemWrapper: {
        paddingLeft: '15%',
        paddingRight: '15%',
        textAlign: 'left'
    },
    stampcardWrapper: {
        position: 'relative',
        marginTop: theme.spacing(2),
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 400
    },
    arrowIcon: {
        position: 'absolute',
        right: 0,
        bottom: 0
    },
    warningIcon: {
        fill: 'orange',
        position: 'absolute',
        top: theme.spacing(1),
        left: theme.spacing(1),
    }
});

class StampcardInfo extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        stampcard: PropTypes.object,
        stampcards: PropTypes.array,
        stampcardUsages: PropTypes.array,
        publishStampcard: PropTypes.func,
        withdrawStampcard: PropTypes.func,
        stopSharingStampcard: PropTypes.func,
        goToEditMode: PropTypes.func,
        updateReplacedStampcards: PropTypes.func
    };

    state = {
        distributionOpen: false
    };

    UNSAFE_componentWillMount() {
        if (this.props.stampcard.imagePath) {
            window.URL.revokeObjectURL(this.props.stampcard.imagePath)
        }
    }

    componentWillUnmount() {
        if (this.props.stampcard.imagePath) {
            window.URL.revokeObjectURL(this.props.stampcard.imagePath)
        }
    }

    render() {
        const {
            classes, stampcard, history, publishStampcard, withdrawStampcard, stopSharingStampcard, stampcards,
            goToEditMode, updateReplacedStampcards, stampcardUsages
        } = this.props;
        const {distributionOpen} = this.state;

        const timestamp = new Date().getTime();
        const bannerPath = stampcard.image && stampcard.image.newData ? window.URL.createObjectURL(stampcard.image.newData) : stampcard.imagePath + '?' + timestamp;

        const publishStatusLabel = publishStatusOption.find(option => option.value === stampcard.publishStatus).label;
        const publishStatusDate = stampcard.publishStatus === "PUBLISHED" ? getFormattedDate(stampcard.publishDate) : stampcard.publishStatus === "WITHDRAWN" ?
            getFormattedDate(stampcard.withdrawnDate) : stampcard.publishStatus === "STOPPED" ? getFormattedDate(stampcard.stoppedDate) : '';
        const stampcardPublishStatusDescription = this.getStampcardPublishStatusDescription(stampcard, publishStatusLabel, publishStatusDate);

        if (distributionOpen) {
            return (
                <StampcardDistribution
                    stampcardPublishStatusDescription={stampcardPublishStatusDescription}
                    stampcards={stampcards}
                    stampcard={stampcard}
                    stampcardUsages={stampcardUsages}
                    publishStampcard={publishStampcard}
                    stopSharingStampcard={stopSharingStampcard}
                    withdrawStampcard={withdrawStampcard}
                    updateReplacedStampcards={updateReplacedStampcards}/>
            )
        }

        return (
            <React.Fragment>
                <div className={classes.itemsWrapper}>
                    {stampcard.publishStatus === "DRAFT" && (
                        <Tooltip title="Before publish you have to create a reward coupon">
                            <Warning className={classes.warningIcon}/>
                        </Tooltip>
                    )}

                    <div className={classes.itemWrapper}>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="stampCardStatus"
                                defaultMessage="Stamp card status"/>
                        </Typography>

                        {stampcardPublishStatusDescription}
                    </div>

                    <div className={classes.arrowIcon}>
                        <IconButton onClick={() => this.setState({distributionOpen: true})}>
                            <ChevronRight/>
                        </IconButton>
                    </div>
                </div>

                <Divider/>

                <div className={classes.itemWrapper}>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="internalName"
                            defaultMessage="Internal name"/>
                    </Typography>

                    <Typography variant="body1">
                        {stampcard.internalName}
                    </Typography>
                </div>

                <div className={classes.itemWrapper}>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="numberOfStamps"
                            defaultMessage="Number of stamps"/>
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="rewardsIsGivenAfter"
                            defaultMessage="Rewards is given after {stampsAmount} stamps"
                            values={{
                                stampsAmount: stampcard.stampsAmount
                            }}/>
                    </Typography>
                </div>

                <div className={classes.itemWrapper}>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="validityPeriod"
                            defaultMessage="Validity period"/>
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="stampcardIsValidForMonths"
                            defaultMessage="A stamp card is valid for {monthValidation} months"
                            values={{
                                monthValidation: stampcard.monthValidation
                            }}/>
                    </Typography>
                </div>

                <div className={classes.itemsWrapper}>
                    <div className={classes.itemWrapper}>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="designAndText"
                                defaultMessage="Design and text"/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="backgroundTextStamps"
                                defaultMessage="Background, text and stamps"/>
                        </Typography>

                        <div className={classes.arrowIcon}>
                            <IconButton onClick={goToEditMode.bind(this, 'design')}>
                                <ChevronRight/>
                            </IconButton>
                        </div>
                    </div>
                </div>

                <Divider/>

                <div className={classes.itemsWrapper}>
                    {!stampcard.stampcardCoupon && (
                        <Tooltip title="Before publish you have to create a reward coupon">
                            <Warning className={classes.warningIcon}/>
                        </Tooltip>
                    )}

                    <div className={classes.itemWrapper}>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="rewardCoupon"
                                defaultMessage="Stamp card coupon"/>
                        </Typography>

                        {stampcard.stampcardCoupon && (
                            <Typography variant="body1">
                                {stampcard.stampcardCoupon.reward}
                            </Typography>
                        )}

                        {!stampcard.stampcardCoupon && (
                            <Typography
                                variant="body1"
                                color="error">
                                <FormattedMessage
                                    id="createCouponReward"
                                    defaultMessage="Create coupon reward"/>
                            </Typography>
                        )}

                        <div className={classes.arrowIcon}>
                            <IconButton onClick={() => history.push(
                                stampcard.stampcardCoupon
                                    ? `/stampcard/${stampcard.id}/coupon/${stampcard.stampcardCoupon.id}`
                                    : `/stampcard/${stampcard.id}/coupon`)}>
                                <ChevronRight/>
                            </IconButton>
                        </div>
                    </div>
                </div>

                <Divider/>

                <div className={classes.stampcardWrapper}>
                    {renderStamps(stampcard.stampsAmount, stampcard.title, stampcard.subtitle, stampcard.stampPath,
                        stampcard.stampUncollectedPath, STANDARD_STAMPCARD, undefined, stampcard.hideTitle, stampcard.hideSubtitle,
                        stampcard.titleColor, stampcard.titleSize, stampcard.subtitleColor, stampcard.subtitleSize)}
                    <img width="100%" src={bannerPath}/>
                </div>
            </React.Fragment>
        );
    }

    getStampcardPublishStatusDescription(stampcard, publishStatusLabel, publishStatusDate) {
        return (
            <Typography
                variant="body1"
                color={stampcard.publishStatus === "PUBLISHED" ? "primary" : "error"}>
                {publishStatusLabel} {publishStatusDate}
            </Typography>
        )
    }

}

export default withStyles(styles)(StampcardInfo);
