import React from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";
import Save from '@material-ui/icons/Save';
import Undo from '@material-ui/icons/Undo';

import RenderTextField from "../../../../common/components/common/RenderTextField";
import Button from "../../../../common/components/common/Button";
import {required} from "../../../../common/components/common/form/FormUtils";

import {categoryOrCampaignNameValidation} from "../../../../common/utils/karming-validators";

const validate = values => {
    return categoryOrCampaignNameValidation({name: "name", value: values.name})
};

const styles = {
    root: {
        textAlign: 'center'
    }
};

class CategoriesForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func,
        handleStopEdition: PropTypes.func,
        submitting: PropTypes.bool,
    };

    render() {
        const {classes, handleSubmit, handleStopEdition, submitting} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on" className={classes.root}>
                <Field
                    name="name"
                    hintText={<FormattedMessage
                        id="categoryName"
                        defaultMessage="Category name"/>}
                    component={RenderTextField}
                    type="text"
                    validate={[required]}/>

                <Button
                    icon={<Undo/>}
                    label={
                        <React.Fragment>
                            <FormattedMessage
                                id="undo"
                                defaultMessage="Undo"/>
                        </React.Fragment>
                    }
                    color="secondary"
                    onClick={handleStopEdition}
                    disabled={submitting}/>

                <Button
                    icon={<Save/>}
                    label={
                        <React.Fragment>
                            <FormattedMessage
                                id="edit"
                                defaultMessage="Edit"/>
                            &nbsp;
                            <FormattedMessage
                                id="category"
                                defaultMessage="Category"/>
                        </React.Fragment>
                    }
                    type="submit"
                    disabled={submitting}/>
            </form>
        );
    }

}

export default reduxForm({
    form: 'categoriesEditorForm',
    validate
})(withStyles(styles)(CategoriesForm))
