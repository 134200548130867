import React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {defineMessages, injectIntl} from "react-intl";

import SendoutForm from "./SendoutForm";
import Spinner from "../../../common/components/common/Spinner";

import {getAppConsumerUrlWithoutProtocol} from "../../../common/config/karming-links";

import {fetchCompany} from "../../actions/merchant-company-actions";
import {sendoutCampaign} from "../../actions/merchant-actions";
import {fetchCampaign} from "../../actions/merchant-campaign-actions";

class Sendout extends React.Component {

    static propTypes = {
        chain: PropTypes.object,
        app: PropTypes.object,
        campaign: PropTypes.object,
        company: PropTypes.object,
        history: PropTypes.object,
        intl: PropTypes.object,
        currentSmartSelection: PropTypes.object,
        placeholderText: PropTypes.object,
        membersForCampaign: PropTypes.array,
        categories: PropTypes.array,
        campaigns: PropTypes.array,
        sendoutHistory: PropTypes.array,
        campaignId: PropTypes.string,
        submitting: PropTypes.bool,
        fetchCompany: PropTypes.func,
        fetchCampaign: PropTypes.func,
        sendoutCampaign: PropTypes.func,
        handleOpenSmartSelection: PropTypes.func,
        fetchCampaignWithoutAuth: PropTypes.func,
        handleSubmit: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            isLoading: props.membersForCampaign,
            isReloaded: false
        };
    }

    componentDidMount() {
        this.props.fetchCampaign(this.props.campaignId);
        this.props.fetchCompany();
    }

    componentDidUpdate(prevProps) {
        if (prevProps && prevProps.membersForCampaign !== this.props.membersForCampaign) {
            this.setState({isLoading: false});
            this.setState({isReloaded: true});
        }
    }

    render() {
        const {
            app, campaign, company, history, intl, placeholderText, currentSmartSelection, membersForCampaign,
            handleOpenSmartSelection, categories, campaigns, sendoutHistory, chain
        } = this.props;

        const {isLoading, isReloaded} = this.state;

        //TODO think if better separate loading state only for numbers and button grey
        if (!campaign || !company || isLoading || !isReloaded) {
            return <Spinner app={app}/>
        }

        const membersWithEmail = membersForCampaign.filter(member => member.emailNotification);
        const membersWithPhone = membersForCampaign.filter(member => member.phoneNotification);

        const firstPartOfSMSPlaceholderText = defineMessages({
            placeholder: {
                id: 'sendout-form.smsPlaceholderText',
                defaultMessage: 'Hi, visit the member area to read about the',
            },
        });

        const receiversIdList = membersForCampaign.map(consumer => consumer.id);

        return (
            <SendoutForm
                smsAccount={company.smsAccount}
                handleOpenSmartSelection={handleOpenSmartSelection}
                currentSmartSelection={currentSmartSelection}
                history={history}
                categories={categories}
                campaigns={campaigns}
                sendoutHistory={sendoutHistory}
                selectedMembers={membersForCampaign}
                membersWithEmail={membersWithEmail}
                membersWithPhone={membersWithPhone}
                campaign={campaign}
                initialValues={{
                    smsMessage:
                    intl.formatMessage(firstPartOfSMSPlaceholderText.placeholder, {text: placeholderText})
                    + " "
                    + campaign.name
                    + "\n"
                    + getAppConsumerUrlWithoutProtocol(chain.subdomain) + "/c/" + campaign.id
                }}
                onSubmit={this.onSubmit.bind(this, receiversIdList, campaign.id, currentSmartSelection)}/>
        )
    }

    onSubmit(receivers, campaignId, currentSmartSelection, values) {
        this.props.sendoutCampaign({
            ...values,
            'receiversIdList': receivers,
            'smartSelection': currentSmartSelection
        }, campaignId);
    }

}

function mapStateToProps(state) {
    return {
        campaign: state.merchant.campaign,
        company: state.company.data,
        chain: state.chains.current,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCampaign, sendoutCampaign, fetchCompany}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Sendout));
