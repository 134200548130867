import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import Spinner from "../../../common/components/common/Spinner";
import ProfileEditorForm from "../../../common/components/common/ProfileEditorForm";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {fetchConsumer, merchantUpdateMember, removeMember} from "../../actions/merchant-actions";

class MerchantMemberEditor extends React.Component {

    static propTypes = {
        consumer: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        app: PropTypes.object,
        auth: PropTypes.object,
        fetchConsumer: PropTypes.func,
        merchantUpdateMember: PropTypes.func,
        removeMember: PropTypes.func,
    };

    prepareFormBeforeSubmit(values) {
        if (values.address) {
            return ({
                ...values,
                'street': values.address.street,
                'city': values.address.city,
                'zipCode': values.address.zipCode
            })
        }
        return values
    }

    componentDidMount() {
        this.props.fetchConsumer(this.props.match.params.id)
    }

    updateAndRedirectToMembers(memberId, history, values) {
        values = this.prepareFormBeforeSubmit(values);
        this.props.merchantUpdateMember(memberId, history, values);
    }

    removeMemberAndRedirectToMembers(memberId, history) {
        this.props.removeMember(memberId, history);
    }

    render() {
        const {app, auth, history, consumer} = this.props;

        if (!consumer) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <ProfileEditorForm
                        removeMember={this.removeMemberAndRedirectToMembers.bind(this, consumer.id, history)}
                        enableReinitialize={true}
                        auth={auth}
                        merchantEditMember={true}
                        initialValues={consumer}
                        app={app}
                        onSubmit={this.updateAndRedirectToMembers.bind(this, consumer.id, history)}>
                    </ProfileEditorForm>
                </Paper>
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        consumer: state.merchant.consumer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({merchantUpdateMember, fetchConsumer, removeMember}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantMemberEditor);
