import React from 'react';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from "@material-ui/core/Card";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {CategoriesList} from "./CategoriesList";
import {CampaignsList} from "./CampaignsList";
import {CouponsUsageList} from "./CouponsUsageList";
import {getTranslatedDate} from "../../../../common/utils/date-utils";
import {StampcardUsages} from "./StampcardUsages";
import {AmountDifference} from "./AmountDifference";

const useStyles = makeStyles(theme => ({
    textItalic: {
        fontStyle: 'italic'
    },
    textBold: {
        fontWeight: 'bold'
    },
    card: {
        width: '100%',
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.grey.light}`,
        boxShadow: 'none'
    },
    details: {
        padding: 0,
        textAlign: 'left'
    },
    nextIcon: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    textSpaced: {
        marginLeft: theme.spacing(0.3),
        "& > span": {
            marginLeft: theme.spacing(0.3)
        }
    }
}));

export const PurchaseHistory = props => {
    const {purchase: {historicPurchases}, purchase, language} = props;
    const classes = useStyles();
    historicPurchases.sort((previousValue, nextValue) => previousValue.dateOfUpdate - nextValue.dateOfUpdate);
    const historicPurchasesItems = [...historicPurchases, purchase];
    const historicPurchasesSize = historicPurchasesItems.length;

    return (
        <React.Fragment>
            {historicPurchasesItems.map((historicPurchase, historicPurchaseIndex, historicPurchasesArray) => {
                    return (
                        <div key={historicPurchaseIndex}>
                            <ExpansionPanelDetails classes={{root: classes.details}}>
                                <Card classes={{root: classes.card}}>
                                    <Typography
                                        variant="subtitle2"
                                        className={classes.textItalic}>
                                        {historicPurchaseIndex > 0
                                            ? <FormattedMessage
                                                id="updateDate"
                                                defaultMessage="Update"/>
                                            : <FormattedMessage
                                                id="firstRegistration"
                                                defaultMessage="First registration"/>
                                        }:
                                        <span className={classes.textSpaced}>
                                        {getTranslatedDate(historicPurchaseIndex > 0
                                            ? historicPurchasesArray[historicPurchaseIndex - 1].dateOfUpdate
                                            : historicPurchase.dateOfPurchase,
                                            language)}
                                    </span>
                                    </Typography>

                                    {historicPurchase.ecrId !== null && (
                                        <Typography variant="subtitle2">
                                            <FormattedMessage
                                                id="ecrId"
                                                defaultMessage="Ecr ID"/>:
                                            <span className={classes.textSpaced}>
                                                {historicPurchase.ecrId}
                                            </span>
                                        </Typography>
                                    )}

                                    <Typography variant="subtitle2">
                                        <FormattedMessage
                                            id="user"
                                            defaultMessage="User"/>:
                                        <span className={classes.textSpaced}>
                                            {historicPurchase.merchant.firstName} {historicPurchase.merchant.lastName} / {historicPurchase.merchant.id}
                                        </span>
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        className={classes.textBold}>
                                        <FormattedMessage
                                            id="categories"
                                            defaultMessage="Categories"/>:
                                    </Typography>

                                    <CategoriesList
                                        purchase={historicPurchase}
                                        array={historicPurchasesArray}
                                        index={historicPurchaseIndex}/>

                                    <StampcardUsages
                                        purchase={historicPurchase}
                                        array={historicPurchasesArray}
                                        index={historicPurchaseIndex}/>

                                    <Typography
                                        variant="subtitle2"
                                        className={classes.textBold}>
                                        <FormattedMessage
                                            id="usedCampaign"
                                            defaultMessage="Used campaign"/>:
                                    </Typography>

                                    <CampaignsList
                                        purchase={historicPurchase}
                                        array={historicPurchasesArray}
                                        index={historicPurchaseIndex}/>

                                    <Typography
                                        variant="subtitle2"
                                        className={classes.textBold}>
                                        <FormattedMessage
                                            id="totalAmountPaid"
                                            defaultMessage="Total amount paid"/>: {historicPurchase.totalAmount}

                                        {historicPurchaseIndex > 0 && (
                                            <AmountDifference
                                                current={historicPurchase.totalAmount}
                                                previous={historicPurchasesArray[historicPurchaseIndex - 1]
                                                && historicPurchasesArray[historicPurchaseIndex - 1].totalAmount}/>
                                        )}
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        className={classes.textBold}>
                                        <FormattedMessage
                                            id="earnedPoints"
                                            defaultMessage="Earned points"/>: {historicPurchase.earnedPoints > 0 && ' +'}
                                        {historicPurchase.earnedPoints} p


                                        {historicPurchaseIndex > 0 && (
                                            <AmountDifference
                                                current={historicPurchase.earnedPoints}
                                                previous={historicPurchasesArray[historicPurchaseIndex - 1]
                                                && historicPurchasesArray[historicPurchaseIndex - 1].earnedPoints}
                                                isPointsAmount/>
                                        )}
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        className={classes.textBold}>
                                        <FormattedMessage
                                            id="tradedPoints"
                                            defaultMessage="Traded points"/>: {historicPurchase.tradedPoints > 0 && ' -'}
                                        {historicPurchase.tradedPoints} p

                                        {historicPurchaseIndex > 0 && (
                                            <AmountDifference
                                                current={historicPurchase.tradedPoints}
                                                previous={historicPurchasesArray[historicPurchaseIndex - 1]
                                                && historicPurchasesArray[historicPurchaseIndex - 1].tradedPoints}
                                                isPointsAmount/>
                                        )}
                                    </Typography>

                                    <CouponsUsageList
                                        purchase={historicPurchase}
                                        array={historicPurchasesArray}
                                        index={historicPurchaseIndex}/>
                                </Card>
                            </ExpansionPanelDetails>

                            {(historicPurchasesSize) !== historicPurchaseIndex + 1 && (
                                <ExpandMoreIcon
                                    className={classes.nextIcon}
                                    color="disabled"/>
                            )}
                        </div>
                    );
                }
            )}
        </React.Fragment>
    )
};

PurchaseHistory.propTypes = {
    purchase: PropTypes.object.isRequired,
    language: PropTypes.string
};
