export const stripeApiKey = stripeKey()
export const API_URL = process.env.API_URL;

function stripeKey() {
    /* eslint-disable no-undef */
    if (process.env.NODE_PROFILE === 'local') {
        return 'pk_test_zI7PzHr01IaKmKRa2PxjB8iB'
    } else if (process.env.NODE_PROFILE === 'dev') {
        return 'pk_test_GZRqRt82MCefnt36U891ireQ'
    } else if (process.env.NODE_PROFILE === 'prod') {
        return 'pk_live_z0rZ9pbonU9D8X9uZlozsRrN'
    }
    /* eslint-enable no-undef */
}
