import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import StampcardCouponForm from "./StampcardCouponForm";
import Spinner from "../../../../../common/components/common/Spinner";
import Container from "../../../../../common/components/common/Container";
import Paper from "../../../../../common/components/common/Paper";

import {prepareStampcardFormData} from "../../../../utils/FormHelper";
import {
    createStampcardCoupon,
    fetchStampcard,
    updateStampcardCoupon
} from "../../../../actions/merchant-stampcard-actions";

import {fetchCommercialChain} from "../../../../actions/merchant-chain-actions";

class StampcardCoupon extends React.Component {

    static propTypes = {
        chain: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        app: PropTypes.object,
        stampcard: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        fetchStampcard: PropTypes.func,
        updateStampcardCoupon: PropTypes.func,
        createStampcardCoupon: PropTypes.func,
        fetchCommercialChain: PropTypes.func
    };

    componentDidMount() {
        let stampcardId = this.props.match.params.stampcardId;
        if (stampcardId) {
            this.props.fetchStampcard(stampcardId);
        }
        this.props.fetchCommercialChain(this.props.chain.id)
    }

    render() {
        const {app, stampcard, isUpdate} = this.props;

        if (!stampcard) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <StampcardCouponForm
                        initialValues={isUpdate
                            ? stampcard.stampcardCoupon
                            : !isUpdate && {imagePath: stampcard.imagePath}}
                        stampcard={stampcard}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit.bind(this)}/>
                </Paper>
            </Container>
        )
    }

    onSubmit(values) {
        const {history} = this.props;
        let body = prepareStampcardFormData(values);
        if (this.props.isUpdate) {
            this.props.updateStampcardCoupon(this.props.stampcard.id, body, history)
        } else {
            this.props.createStampcardCoupon(this.props.stampcard.id, body, history);
        }
    }

}

function mapStateToProps(state) {
    return {
        stampcard: state.merchant.stampcard,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchStampcard,
        createStampcardCoupon,
        updateStampcardCoupon,
        fetchCommercialChain
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StampcardCoupon)
