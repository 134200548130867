import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import TermsHome from "./common/TermsHome";
import Spinner from "../../../../common/components/common/Spinner";

import {
    defaultMembershipConditions,
    fetchMembershipConditions,
    updateMembershipConditions
} from "../../../actions/merchant-terms-actions";

class MembershipConditionsHome extends React.Component {

    static propTypes = {
        terms: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        chain: PropTypes.object,
        app: PropTypes.object,
        user: PropTypes.object,
        history: PropTypes.object,
        defaultMembershipConditions: PropTypes.func,
        fetchMembershipConditions: PropTypes.func,
        updateMembershipConditions: PropTypes.func
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain ||
            prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchMembershipConditions} = this.props;
        if (chain && chain.id) {
            fetchMembershipConditions(chain.id);
        }
    }

    render() {
        const {app, chain, terms, updateMembershipConditions, defaultMembershipConditions} = this.props;

        if (!chain || !chain.id) {
            return <Spinner app={app}/>
        }

        return (
            <TermsHome
                termsPublished={chain.membershipConditionsPublished}
                app={app}
                user={app}
                history={app}
                title={<FormattedMessage
                    id="membershipConditions"
                    defaultMessage="Membership Conditions"/>}
                chainId={chain.id}
                terms={terms}
                updateTerms={updateMembershipConditions.bind(this, chain.id)}
                introInfo={this.renderNoTerms()}
                fetchDefaultTerms={defaultMembershipConditions.bind(this, chain.id)}/>
        )
    }

    renderNoTerms() {
        return (
            <React.Fragment>
                <Typography
                    variant="h5"
                    paragraph>
                    <FormattedMessage
                        id="membershipConditions"
                        defaultMessage="Membership Conditions"/>
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage
                        id="membership-conditions-home.membershipConditionsFirstPartText"
                        defaultMessage="Before you invite members to your customer club, you need to formulate your membership conditions."/>
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage
                        id="membership-conditions-home.membershipConditionsSecondPartText"
                        defaultMessage="Either you formulate your own, or start from the ready-made example based on your data."/>
                </Typography>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        terms: state.terms.membershipConditions
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchMembershipConditions,
        updateMembershipConditions,
        defaultMembershipConditions
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipConditionsHome)
