import React from "react";
import {Field} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";
import Delete from '@material-ui/icons/Delete';
import {IconButton} from "@material-ui/core";
import Add from '@material-ui/icons/Add';

import RenderTextField from "../../../../../common/components/common/RenderTextField";
import Button from "../../../../../common/components/common/Button";

const styles = theme => ({
    list: {
        paddingLeft: 0
    },
    listItemWrapper: {
        backgroundColor: theme.palette.grey.light
    },
    listItem: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[50],
        listStyleType: 'none'
    },
    title: {
        fontWeight: 'bold'
    },
    iconWrapper: {
        textAlign: 'right'
    },
    icon: {
        fill: theme.palette.error.main
    }
});

const renderEditParagraph = ({classes, fields}) => (
    <div>
        <ul className={classes.list}>
            {fields.map((paragraph, index) => (
                <li className={classes.listItem} key={index}>
                    <div className={classes.iconWrapper}>
                        <IconButton onClick={() => fields.remove(index)}>
                            <Delete className={classes.icon}/>
                        </IconButton>
                    </div>

                    <Field
                        name={`${paragraph}.title`}
                        type="text"
                        component={RenderTextField}
                        className={classes.title}
                        hintText={<FormattedMessage
                            id="paragraphTitle"
                            defaultMessage="Paragraph title"/>}/>

                    <Field
                        name={`${paragraph}.content`}
                        type="text"
                        component={RenderTextField}
                        hintText={<FormattedMessage
                            id="paragraphContent"
                            defaultMessage="Paragraph content"/>}
                        multiline/>
                </li>
            ))}
        </ul>
        <Button
            icon={<Add/>}
            color="secondary"
            onClick={() => fields.push({})}
            label={
                <React.Fragment>
                    <FormattedMessage
                        id="add"
                        defaultMessage="Add"/>
                    &nbsp;
                    <FormattedMessage
                        id="paragraph"
                        defaultMessage="Paragraph"/>
                </React.Fragment>
            }/>
    </div>
);

renderEditParagraph.propTypes = {
    classes: PropTypes.object.isRequired,
    fields: PropTypes.object
};

export default withStyles(styles)(renderEditParagraph)
