import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";

import RenderSelect from "../../../common/components/common/RenderSelect";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import RenderSlider from "../../../common/components/common/RenderSlider";
import {getCurrentYear} from "../../../common/utils/date-utils";
import Checkbox from "../../../common/components/common/form/RenderCheckbox";
import {atLeastOneOfThreeCheckboxRequired, minMaxFieldsValidation,} from "../../../common/utils/karming-validators";
import Button from "../../../common/components/common/Button";
import Add from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";

export const BORN = <FormattedMessage id="wasBorn" defaultMessage="Was born"/>;
export const NOT_BORN = <FormattedMessage id="wasNotBorn" defaultMessage="Was not born"/>;

export const bornInDateRangeOption = {
    YES: {
        value: true,
        label: BORN
    },
    NO: {
        value: false,
        label: NOT_BORN
    }
};

const validate = values => {
    return Object.assign(
        atLeastOneOfThreeCheckboxRequired(
            {name: "day", value: values.dateFilter ? values.dateFilter.day : false},
            {name: "month", value: values.dateFilter ? values.dateFilter.month : false},
            {name: "year", value: values.dateFilter ? values.dateFilter.year : false},
            'dateFilter'
        ),
        minMaxFieldsValidation(
            'dateFilter',
            values.dateFilter ? values.dateFilter.day : false,
            {name: "bornDayFrom", value: values.dateFilter ? values.dateFilter.bornDayFrom : 1, min: 1},
            {name: "bornDayTo", value: values.dateFilter ? values.dateFilter.bornDayTo : 31, max: 31},
            values.dateFilter ? values.dateFilter.month : false,
            {name: "bornMonthFrom", value: values.dateFilter ? values.dateFilter.bornMonthFrom : 1, min: 1},
            {name: "bornMonthTo", value: values.dateFilter ? values.dateFilter.bornMonthTo : 12, max: 12},
            values.dateFilter ? values.dateFilter.year : false,
            {name: "bornYearFrom", value: values.dateFilter ? values.dateFilter.bornYearFrom : 1940, min: 1940},
            {
                name: "bornYearTo",
                value: values.dateFilter ? values.dateFilter.bornYearTo : getCurrentYear() - 16,
                max: getCurrentYear() - 16
            }
        )
    )
};

const styles = theme => ({
    checkboxField: {
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        marginBottom: -theme.spacing(3.5)
    },
    subtitleAge: {
        marginTop: theme.spacing(1)
    }
});

class DateBornFilterForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        selectMembersInfo: PropTypes.object,
        day: PropTypes.bool,
        month: PropTypes.bool,
        year: PropTypes.bool,
        isYear: PropTypes.bool,
        bornYearFrom: PropTypes.number,
        bornYearTo: PropTypes.number,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    render() {
        const {classes, handleSubmit, selectMembersInfo, bornYearFrom, bornYearTo, isYear, day, month, year} = this.props;

        const currentYear = getCurrentYear();

        const ageFrom = currentYear - bornYearTo;
        const ageTo = currentYear - bornYearFrom;

        const maxYear = currentYear - 16;

        return (
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage
                        id="birthday/age"
                        defaultMessage="Birthday / Age"/>
                </Typography>

                <Typography
                    variant="body1"
                    component="div">
                    {selectMembersInfo}
                </Typography>

                <Field
                    name="dateFilter.bornInDateRange"
                    component={RenderSelect}
                    defaultValue={bornInDateRangeOption.YES.value}>
                    <MenuItem value={bornInDateRangeOption.YES.value}>
                        {bornInDateRangeOption.YES.label}
                    </MenuItem>

                    <MenuItem value={bornInDateRangeOption.NO.value}>
                        {bornInDateRangeOption.NO.label}
                    </MenuItem>
                </Field>

                <Field
                    className={classes.checkboxField}
                    name="dateFilter.day"
                    label={
                        <React.Fragment>
                            <FormattedMessage
                                id="day"
                                defaultMessage="Day"/>:
                        </React.Fragment>
                    }
                    type="checkbox"
                    component={Checkbox}/>

                <Field
                    name="day"
                    component={RenderSlider}
                    steps={1}
                    minFieldName="dateFilter.bornDayFrom"
                    maxFieldName="dateFilter.bornDayTo"
                    min={1}
                    max={31}
                    defaultValue={[1, 31]}/>

                <Field
                    className={classes.checkboxField}
                    name="dateFilter.month"
                    label={
                        <React.Fragment>
                            <FormattedMessage
                                id="month"
                                defaultMessage="Month"/>:
                        </React.Fragment>
                    }
                    type="checkbox"
                    component={Checkbox}/>

                <Field
                    name="month"
                    component={RenderSlider}
                    steps={1}
                    minFieldName="dateFilter.bornMonthFrom"
                    maxFieldName="dateFilter.bornMonthTo"
                    min={1}
                    max={12}
                    defaultValue={[1, 12]}/>

                <Field
                    className={classes.checkboxField}
                    name="dateFilter.year"
                    label={
                        <React.Fragment>
                            <FormattedMessage
                                id="year"
                                defaultMessage="Year"/>:
                        </React.Fragment>
                    }
                    type="checkbox"
                    component={Checkbox}/>

                <Field
                    name="year"
                    component={RenderSlider}
                    steps={1}
                    minFieldName="dateFilter.bornYearFrom"
                    maxFieldName="dateFilter.bornYearTo"
                    min={1940}
                    max={maxYear}
                    defaultValue={[1940, maxYear]}/>

                {isYear && (
                    <Typography
                        variant="body1"
                        className={classes.subtitleAge}>
                        <FormattedMessage
                            id="selectedAgeBetweenAnd"
                            defaultMessage="Selected age between {from} and {to}"
                            values={{
                                from: <b>{ageFrom}</b>,
                                to: <b>{ageTo}</b>
                            }}/>
                    </Typography>
                )}

                <Button
                    icon={<Add/>}
                    label={<FormattedMessage
                        id="addSmartSelection"
                        defaultMessage="Add Smart Selection"/>}
                    type="submit"
                    disabled={!day && !month && !year}/>
            </form>
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('dateBornFilterForm');
    const isYear = selector(state, 'dateFilter.year');
    const bornYearFrom = selector(state, 'dateFilter.bornYearFrom');
    const bornYearTo = selector(state, 'dateFilter.bornYearTo');
    const day = selector(state, 'dateFilter.day');
    const month = selector(state, 'dateFilter.month');
    const year = selector(state, 'dateFilter.year');
    return {
        isYear,
        bornYearFrom,
        bornYearTo,
        day,
        month,
        year
    }
}

export default reduxForm({
    form: 'dateBornFilterForm',
    touchOnChange: true,
    validate
})(connect(mapStateToProps, null)(withStyles(styles)(DateBornFilterForm)))
