import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import RenderTextField from "../../../../common/components/common/RenderTextField";
import {KARMING_TERMS_OF_SERVICE_ADDRESS} from "../../../../common/config/karming-links";
import Dialog from './../../../../common/components/common/Dialog';
import {UNSIGNED_INTEGER_FORMAT_CONFIG} from "../../../utils/formats";
import {required} from "../../../../common/components/common/form/FormUtils";

class SmsCreditsForm extends React.Component {

    static propTypes = {
        credits: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        autoCharge: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        change: PropTypes.func
    };

    state = {
        open: false,
        openTurnOffAutoWarning: false
    };

    handleOpenTurnOffAutoWarning = () => {
        this.setState({openTurnOffAutoWarning: true});
    };

    handleCancelTurnOffAutoWarning = () => {
        this.setState({openTurnOffAutoWarning: false});
    };

    handleConfirmWarning = () => {
        this.setState({openTurnOffAutoWarning: false});
        this.props.change("autoCharge", false);
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleConfirm = () => {
        this.setState({open: false});
        this.props.handleSubmit();
    };

    handleCancel = () => {
        this.setState({open: false});
    };

    render() {
        const {handleSubmit, submitting, credits, autoCharge} = this.props;

        const actionsForPurchaseConfirmation = [
            <Button
                key="cancel"
                label={<FormattedMessage
                    id="cancel"
                    defaultMessage="Cancel"/>}
                color="secondary"
                onClick={this.handleCancel}/>,
            <Button
                key="confirm"
                label={<FormattedMessage
                    id="confirm"
                    defaultMessage="Confirm"/>}
                onClick={this.handleConfirm.bind(this)}/>
        ];

        const actionsForWarning = [
            <Button
                key="cancel"
                label={<FormattedMessage
                    id="cancel"
                    defaultMessage="Cancel"/>}
                color={'secondary'}
                onClick={this.handleCancelTurnOffAutoWarning}/>,
            <Button
                key="yes"
                customColor="red"
                label={<FormattedMessage
                    id="yes"
                    defaultMessage="Yes"/>}
                onClick={this.handleConfirmWarning}/>
        ];

        return (
            <form onSubmit={handleSubmit} autoComplete="on">

                {/*FIXME min value validate working*/}
                <Field
                    name="credits"
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="credits"
                                defaultMessage="Credits"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    component={RenderTextField}
                    type="number"
                    formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}
                    min={100}
                    validate={[required]}/>

                {/*Hide for now*/}
                {/*<Field*/}
                {/*name="autoCharge"*/}
                {/*label={<FormattedMessage*/}
                {/*id="sms-credits-settings-form.autoCharge"*/}
                {/*defaultMessage={`Auto charge credits when my balance is not sufficient`}/>}*/}
                {/*onChange={(e, value) => {*/}
                {/*if (!value) {*/}
                {/*e.preventDefault();*/}
                {/*this.handleOpenTurnOffAutoWarning();*/}
                {/*}*/}
                {/*}}*/}
                {/*type="checkbox"*/}
                {/*component={Checkbox}*/}
                {/*/>*/}

                <Dialog
                    onClose={this.handleCancelTurnOffAutoWarning}
                    title={
                        <Typography
                            variant="h5"
                            color="error">
                            <FormattedMessage
                                id="warning"
                                defaultMessage="Warning"/>
                        </Typography>
                    }
                    actions={actionsForWarning}
                    open={this.state.openTurnOffAutoWarning}
                    content={
                        <span>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="confirmDeactivatingText"
                                    defaultMessage="Confirm deactivating automatic refill of SMS-credits"/>
                            </Typography>

                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="pleaseBeAwareText"
                                    defaultMessage="Please be aware that to disable automatic refill of SMS-credits mean that
                        you customers can not sign up using their phone number if you forget to buy credits before your
                        balance reaches zero."/>
                            </Typography>

                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="areYouSureText"
                                    defaultMessage="Are you sure you want to deactivate automatic refill of SMS credits?"/>
                            </Typography>
                        </span>
                    }/>

                <Dialog
                    onClose={this.handleCancel}
                    title={<FormattedMessage
                        id="order"
                        defaultMessage="Order"/>}
                    actions={actionsForPurchaseConfirmation}
                    open={this.state.open}
                    content={
                        <span>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="purchaseOfSMSCreditsWorth"
                                    defaultMessage="Purchase of SMS credits worth {amount} SEK"
                                    values={{
                                        amount: credits
                                    }}/>
                            </Typography>

                            {autoCharge && (
                                <React.Fragment>
                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="and"
                                            defaultMessage="and"/>
                                    </Typography>

                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="thereAutomaticallyPurchaseText"
                                            defaultMessage="there automatically purchase necessary credits when balance is too low."/>
                                    </Typography>

                                    <Typography variant="subtitle1">
                                        <FormattedMessage
                                            id="purchaseIsMadeInEvenText"
                                            defaultMessage="purchase is made in even {amount} SEK"
                                            values={{
                                                amount: credits
                                            }}/>
                                    </Typography>
                                </React.Fragment>
                            )}
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="orderConfirmationText"
                                    defaultMessage="I confirm my order and that I have read, understand and accept the"/>
                                &nbsp;
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={KARMING_TERMS_OF_SERVICE_ADDRESS}>
                                    <FormattedMessage
                                        id="termsAndConditions"
                                        defaultMessage="Terms and Conditions"/>.
                                </a>
                            </Typography>
                        </span>
                    }/>

                <Button
                    label={
                        <FormattedMessage
                            id="messages.2"
                            defaultMessage={`{1} {2}`}
                            values={{
                                1: <FormattedMessage
                                    id="add"
                                    defaultMessage="Add"/>,
                                2: <FormattedMessage
                                    id="credits"
                                    defaultMessage="Credits"/>
                            }}/>
                    }
                    onClick={this.handleOpen} disabled={submitting}/>
            </form>
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('registerNumberCreditsForm');
    const credits = selector(state, 'credits');
    const autoCharge = selector(state, 'autoCharge');
    return {
        credits,
        autoCharge
    }
}

export default reduxForm({
    form: 'registerNumberCreditsForm',
})(connect(mapStateToProps, null)(SmsCreditsForm))
