import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core/styles";
import {getFormattedDate} from "../../../../common/utils/date-utils";

const useStyles = makeStyles(theme => ({
    rewardWrapper: {
        position: 'relative',
        textAlign: 'left',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    checkbox: {
        padding: 0,
        position: "absolute",
        top: '50%',
        right: theme.spacing(2),
        transform: 'translateY(-50%) rotate(0deg)'
    }
}));

const ReplacedStampcardsList = props => {
    const {replacedStampcards, replacedStampcardsIds, onCheckboxChanged, stampcardUsages} = props;
    const classes = useStyles();

    const activeUsages = stampcardUsages.length > 0 && stampcardUsages
        .filter(usage => (usage.stampcardUsageStatus === 'ACTIVE' || usage.stampcardUsageStatus === 'STOPPED') && usage.startDate);

    return (
        <React.Fragment>
            {replacedStampcards
                .filter(stampcard => replacedStampcardsIds && replacedStampcardsIds.includes(stampcard.id))
                .map((stampcard, index) => {
                    const stampcardChecked = replacedStampcardsIds && replacedStampcardsIds.includes(stampcard.id);
                    const lastExpirationDate = activeUsages && activeUsages.length > 0 && activeUsages
                        .map((usage) => usage.startDate)
                        .reduce((previous, current) => current < previous ? previous : current);

                    return (
                        <React.Fragment key={index}>
                            <Card className={classes.rewardWrapper}>
                                <CardContent>
                                    <Typography
                                        variant="subtitle1"
                                        align="left">
                                        {stampcard.title}
                                    </Typography>

                                    <Typography
                                        variant="body1"
                                        align="left">
                                        <FormattedMessage
                                            id="activeCards"
                                            defaultMessage="Active cards"/>: {activeUsages.length || 0}
                                    </Typography>

                                    {lastExpirationDate && (
                                        <Typography
                                            variant="body1"
                                            align="left">
                                            <FormattedMessage
                                                id="lastCardFallDue"
                                                defaultMessage="Last card fall due"/>: {getFormattedDate(lastExpirationDate)}
                                        </Typography>
                                    )}

                                    <Typography
                                        variant="body1"
                                        align="left">
                                        <FormattedMessage
                                            id="reward"
                                            defaultMessage="Reward"/>: {stampcard.stampcardCoupon.reward}
                                    </Typography>

                                    <Checkbox
                                        checked={stampcardChecked}
                                        color="primary"
                                        onChange={() => onCheckboxChanged(stampcard.id)}
                                        className={classes.checkbox}/>
                                </CardContent>
                            </Card>
                        </React.Fragment>
                    )
                })}
        </React.Fragment>
    );

};

ReplacedStampcardsList.propTypes = {
    replacedStampcards: PropTypes.array,
    replacedStampcardsIds: PropTypes.array,
    stampcardUsages: PropTypes.array,
    onCheckboxChanged: PropTypes.func
};

export default ReplacedStampcardsList
