import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Search from '@material-ui/icons/Search';

import Dialog from '../../../common/components/common/Dialog';
import Button from '../../../common/components/common/Button';
import CampaignPage from "../../../common/components/common/campaign/CampaignPage";

export default class EmailSendoutPreview extends React.Component {

    static propTypes = {
        label: PropTypes.object,
        campaign: PropTypes.object,
        disabled: PropTypes.bool
    };

    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {label, disabled, campaign} = this.props;

        const actions = [
            <Button
                key="close"
                label={<FormattedMessage
                    id="close"
                    defaultMessage={`Close`}/>}
                onClick={this.handleClose}/>
        ];

        return (
            <React.Fragment>
                <Button
                    icon={<Search/>}
                    label={label}
                    color={'secondary'}
                    disabled={disabled}
                    onClick={this.handleOpen}/>

                <Dialog
                    onCancel={this.handleClose}
                    title={label}
                    actions={actions}
                    open={this.state.open}
                    content={<CampaignPage campaign={campaign} sendout={true}/>}/>
            </React.Fragment>
        )
    }

}
