import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import RenderTextField from "../../../../common/components/common/RenderTextField";

import {UNSIGNED_INTEGER_FORMAT_CONFIG} from "../../../utils/formats";

{/*TODO UPDATE TEXT CONTENT*/
}

class StampcardFormPage2 extends React.Component {

    static propTypes = {
        monthValidation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        handleSubmit: PropTypes.func
    };

    render() {
        const {handleSubmit, monthValidation} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="howLongDoesTheCustomerTakeToCollectTheStampsToBeRewarded"
                        defaultMessage="How long does the customer take to collect the stamps to be rewarded"/>
                    ?
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="stampcardIsValidFor"
                        defaultMessage="The stamp card is valid for"/>
                    :
                </Typography>

                <Field
                    name="monthValidation"
                    hintText={<FormattedMessage
                        id="months"
                        defaultMessage="Months"/>}
                    helperText={<FormattedMessage
                        id="provideNumberOfMonths"
                        defaultMessage="Provide number of months"/>}
                    component={RenderTextField}
                    type="number"
                    formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}/>

                {monthValidation && monthValidation !== 0 && (
                    <Typography variant="body1">
                        <FormattedMessage
                            id="stampcardFormPage2.longText"
                            defaultMessage="If the customer within {monthValidation} months from the first stamp,
                             have not collected enough stamps to get the reward, the stamp card will be set to zero.
                             If the stamp card is still active, the customer will be able to start collecting new stamps on a new card."
                            values={{
                                monthValidation: monthValidation
                            }}/>
                    </Typography>
                )}

                <Button
                    label={<FormattedMessage
                        id="next"
                        defaultMessage="Next"/>}
                    type="submit"
                    disabled={!monthValidation || monthValidation === 0}/>
            </form>
        );
    }

}

export default reduxForm({
    form: "stampcardForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StampcardFormPage2);
