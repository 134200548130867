import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import SearchConsumerForm from "./SearchConsumerForm";

import {findMembers} from "../../actions/merchant-actions";

class SearchConsumer extends React.Component {

    static propTypes = {
        chain: PropTypes.object,
        consumer: PropTypes.object,
        history: PropTypes.object,
        findMembers: PropTypes.func
    };

    render() {
        return (
            <SearchConsumerForm
                enableReinitialize={true}
                onSubmit={this.submitAndRedirectToRegisterOnSuccess.bind(this)}/>
        )
    }

    submitAndRedirectToRegisterOnSuccess(values) {
        const {history} = this.props;
        const historyData = {...history, 'dateOfPurchase': values.dateOfPurchase};
        delete values.dateOfPurchase;
        const membersData = {...values, 'commercialChain': this.props.chain.id};

        // TODO - why
        this.props.findMembers(membersData, historyData)
    }

}

function mapStateToProps(state) {
    return {
        consumer: state.merchant.consumer,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({findMembers}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchConsumer);
