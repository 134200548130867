import React from "react";
import PropTypes from "prop-types";
import {ChromePicker} from 'react-color';

import {withStyles} from "@material-ui/core/styles";

import SquareIconButton from "../../../common/components/common/SquareIconButton";

import {colorBlack} from "../../../common/utils/color-const";
import {zIndex100} from "../../../common/utils/style-const";

const styles = theme => ({
    root: {
        position: 'relative'
    },
    dialog: {
        position: 'absolute',
        zIndex: zIndex100,
        paddingTop: theme.spacing(1)
    },
    colorPicker: {
        touchAction: 'none'
    },
    button: {
        border: `1px solid ${colorBlack}`,
        margin: 0,
        minWidth: 24,
        width: 24,
        height: 24
    },
});

class RenderColorPicker extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        color: PropTypes.string,
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            currentColor: this.parseColor(this.props.color)
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            this.setState({
                currentColor: this.parseColor(nextProps.color)
            });
        }
    }

    parseColor(color) {
        return color || colorBlack;
    }

    onClick() {
        this.state.isOpen ? this.onClose() : this.onOpen()
    }

    onOpen() {
        this.setState({
            isOpen: true
        });
    }

    onClose() {
        this.setState({
            isOpen: false
        });
    }

    onColorChanged(color) {
        this.setState({
            currentColor: color.hex
        });

        this.notifyChange(color.hex);
    }

    notifyChange(color) {
        const {onChange} = this.props;
        if (onChange) {
            onChange(color);
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <SquareIconButton
                    style={{background: this.state.currentColor}}
                    className={classes.button}
                    onClick={this.onClick.bind(this)}/>

                {this.state.isOpen ? this.renderDialog() : null}
            </div>
        )
    }

    renderDialog() {
        const {classes} = this.props;
        return (
            <div className={classes.dialog}>
                <div
                    className={classes.colorPicker}
                    onClick={this.onClose.bind(this)}>
                    <ChromePicker
                        disableAlpha={true}
                        color={this.state.currentColor}
                        onChange={this.onColorChanged.bind(this)}/>
                </div>
            </div>
        )
    }

}

export default withStyles(styles)(RenderColorPicker);
