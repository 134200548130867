import React from "react";

import Typography from "@material-ui/core/Typography";

import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

export class KarmingDataProtectionUsefulLinks extends React.Component {

    render() {
        return (
            <Container>
                <Paper padding>
                    <Typography
                        variant="body1"
                        align="justify"
                        component="div">
                        <Typography
                            paragraph
                            variant="h5"
                            align="center">
                            Nyttiga länkar
                        </Typography>
                        <div>
                            <Typography variant="h6">
                                Datainspektionen
                            </Typography>
                            <Typography
                                variant="body1"
                                component="div"
                                paragraph>
                                <p>
                                    Datainspektionen är tillsynsmyndighet som ämnar se till att behandling av
                                    personuppgifter inte leder
                                    till otillbörliga intrång i enskilda individers personliga integritet. Läs mer om
                                    myndighetens arbetssätt
                                    och mål, samt hur du kommer i kontakt med dem på deras hemsida
                                </p>
                                <a href="https://www.datainspektionen.se/">
                                    https://www.datainspektionen.se/
                                </a>
                            </Typography>
                        </div>

                        <div>
                            <Typography variant="h6">
                                Knowit Digital Law
                            </Typography>
                            <Typography
                                variant="body1"
                                component="div"
                                paragraph>
                                <p>
                                    Karming AB samarbetar med KnowIT Digital Law när det kommer till dataskyddsfrågor.
                                    Vi
                                    kan
                                    rekommendera alla som vill ha juridisk experthjälp kring dataskydd eller GDPR att
                                    kontakta dem.
                                </p>
                                <a href="https://www.knowit.se/">
                                    https://www.knowit.se/
                                </a>
                            </Typography>
                        </div>

                        <div>
                            <Typography variant="h6">
                                EU hemsida
                            </Typography>
                            <Typography
                                variant="body1"
                                component="div"
                                paragraph>
                                <p>
                                    På Europeiska unionens hemsida hittar man väldigt detaljerad information om hur
                                    förordningen kan
                                    påverka både företag och medborgare. Texten på länken är på engelska.
                                </p>
                                <a href="https://ec.europa.eu/info/law/law-topic/data-protection/reform_en">
                                    https://ec.europa.eu/info/law/law-topic/data-protection/reform_en
                                </a>
                            </Typography>
                        </div>
                    </Typography>
                </Paper>
            </Container>
        )
    }

}
