import React from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import RenderSlider from "../../../common/components/common/RenderSlider";
import RenderSelect from "../../../common/components/common/RenderSelect";

import {minMaxFieldsValidation} from "../../../common/utils/karming-validators";

export const HAVE_POINTS = <FormattedMessage id="havePoints" defaultMessage="Have points balance"/>;
export const NOT_HAVE_POINTS = <FormattedMessage id="notHavePoints" defaultMessage="Not have points balance"/>;

export const madePurchaseOption = {
    YES: {
        value: true,
        label: HAVE_POINTS
    },
    NO: {
        value: false,
        label: NOT_HAVE_POINTS
    }
};

const validate = (values, props) => {
    return minMaxFieldsValidation(
        'sumFilter',
        true,
        {
            name: "pointsBalanceFrom",
            value: values.sumFilter ? values.sumFilter.pointsBalanceFrom : MIN_VALUE_BALANCE,
            min: MIN_VALUE_BALANCE
        },
        {
            name: "pointsBalanceTo",
            value: values.sumFilter ? values.sumFilter.pointsBalanceTo : props.maxPointsBalance,
            max: props.maxPointsBalance
        }
    )
};

const MIN_VALUE_BALANCE = 0;

class PointsBalanceFilterForm extends React.Component {

    static propTypes = {
        button: PropTypes.object,
        selectMembersInfo: PropTypes.object,
        maxPointsBalance: PropTypes.number,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {handleSubmit, button, maxPointsBalance, selectMembersInfo} = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage
                        id="pointsBalance"
                        defaultMessage="Points balance"/>
                </Typography>

                <Typography
                    variant="body1"
                    component="div">
                    {selectMembersInfo}
                </Typography>

                <Field
                    name="sumFilter.havePoints"
                    component={RenderSelect}
                    defaultValue={madePurchaseOption.YES.value}>
                    <MenuItem value={madePurchaseOption.YES.value}>
                        {madePurchaseOption.YES.label}
                    </MenuItem>
                </Field>

                <Field
                    name="pointsBalance"
                    component={RenderSlider}
                    steps={100}
                    minFieldName="sumFilter.pointsBalanceFrom"
                    maxFieldName="sumFilter.pointsBalanceTo"
                    min={MIN_VALUE_BALANCE}
                    max={maxPointsBalance}
                    defaultValue={[MIN_VALUE_BALANCE, maxPointsBalance]}/>

                {button}
            </form>
        );
    }

}

export default reduxForm({
    form: 'pointsBalanceFilterForm',
    touchOnChange: true,
    validate
})(PointsBalanceFilterForm)
