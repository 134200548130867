import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Spinner from "../../../../common/components/common/Spinner";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";
import SmsUsageForm from "./SmsUsageForm";

import {fetchSmsHistory} from "../../../actions/merchant-actions";
import {getStartOfMonth} from "../../../../common/utils/date-utils";

const ONBOARDING_MEMBER_CONFIRMATION = 'ONBOARDING_MEMBER_CONFIRMATION';
const RESET_PASSWORD = 'RESET_PASSWORD';
const CAMPAIGN = 'CAMPAIGN';

class SmsUsage extends React.Component {

    static propTypes = {
        smsHistory: PropTypes.array,
        app: PropTypes.object,
        fetchSmsHistory: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            initialDateFrom: getStartOfMonth(),
            initialDateTo: new Date()
        };
    }

    componentDidMount() {
        const {initialDateFrom, initialDateTo} = this.state;
        this.props.fetchSmsHistory({dateFrom: initialDateFrom, dateTo: initialDateTo});
    }

    render() {
        const {app, smsHistory, fetchSmsHistory} = this.props;
        const {initialDateFrom, initialDateTo} = this.state;

        if (!smsHistory) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <Typography
                        variant="h5"
                        paragraph>
                        <FormattedMessage
                            id="smsUsage"
                            defaultMessage="SMS Usage"/>
                    </Typography>

                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="dateRange"
                            defaultMessage="Date Range:"/>
                    </Typography>

                    <SmsUsageForm
                        initialValues={{dateFrom: initialDateFrom, dateTo: initialDateTo}}
                        total={smsHistory.length}
                        campaigns={this.getCampaignsNumber(smsHistory)}
                        memberOnboarding={this.getOnboardingMemberSmsNumber(smsHistory)}
                        resetPassword={this.getResetPasswordSmsNumber(smsHistory)}
                        onSubmit={fetchSmsHistory.bind(this)}/>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="total"
                            defaultMessage="Total"/>
                    </Typography>
                    <Typography variant="body1">
                        {smsHistory.length}
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="memberOnboarding"
                            defaultMessage="Member Onboarding"/>
                    </Typography>
                    <Typography variant="body1">
                        {this.getOnboardingMemberSmsNumber(smsHistory)}
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="campaigns"
                            defaultMessage="Campaigns"/>
                    </Typography>
                    <Typography variant="body1">
                        {this.getCampaignsNumber(smsHistory)}
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="resetPassword"
                            defaultMessage="Reset Password"/>
                    </Typography>
                    <Typography variant="body1">
                        {this.getResetPasswordSmsNumber(smsHistory)}
                    </Typography>
                </Paper>
            </Container>
        )
    }

    getCampaignsNumber(smsHistoryList) {
        return smsHistoryList.filter(smsHistoryList => smsHistoryList.message === CAMPAIGN).length
    }

    getOnboardingMemberSmsNumber(smsHistoryList) {
        return smsHistoryList.filter(smsHistoryList => smsHistoryList.message === ONBOARDING_MEMBER_CONFIRMATION).length
    }

    getResetPasswordSmsNumber(smsHistoryList) {
        return smsHistoryList.filter(smsHistoryList => smsHistoryList.message === RESET_PASSWORD).length
    }
}

function mapStateToProps(state) {
    return {
        smsHistory: state.merchant.smsHistory
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchSmsHistory}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SmsUsage);
