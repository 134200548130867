import React from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import RenderTextField from "../../../common/components/common/RenderTextField";
import {FormattedMessage} from "react-intl";
import {required, RequiredInfo} from "../../../common/components/common/form/FormUtils";
import Button from "../../../common/components/common/Button";
import {UNSIGNED_INTEGER_FORMAT_CONFIG} from "../../utils/formats";
import {biggerThanZero} from "../../../common/utils/karming-validators";
import {colorRed} from "../../../common/utils/color-const";

const validate = values => {
    return biggerThanZero({name: "pointsRequired", value: values.pointsRequired})
};

class CouponFormPage1 extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        pristine: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    render() {
        const {handleSubmit, pristine, title, isUpdate} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {title}
                </Typography>

                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="howManyPoints"
                        defaultMessage="How many points does the customer need to trade in to redeem the reward?"/>
                </Typography>


                <Typography variant="body1" align="left">
                    <FormattedMessage
                        id="rewardIsTradedFor"
                        defaultMessage="The reward is traded in for"/>
                    :
                </Typography>

                {isUpdate &&
                <div style={{border: `1px solid ${colorRed}`, borderRadius: 8, textAlign: 'left', padding: 8}}>

                    <span style={{color: colorRed, fontSize: 24}}>!</span> <br/>

                    <FormattedMessage
                        id="pointsRequiredDisabledOnUpdatePart1"
                        defaultMessage="Points required to redeem a reward can not be
                    changed after the reward is published."/> <br/> <br/>

                    <FormattedMessage
                        id="pointsRequiredDisabledOnUpdatePart2"
                        defaultMessage="You can deactivate this reward and create a
                    new reward set to be traded in for the amount
                    of points you want."/>

                </div>
                }

                <Field
                    disabled={isUpdate}
                    name="pointsRequired"
                    component={RenderTextField}
                    type="number"
                    formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}
                    validate={required}
                    hintText={<FormattedMessage
                        id="points"
                        defaultMessage="Points"/>}/>

                <Button
                    label={<FormattedMessage
                        id="next"
                        defaultMessage="Next"/>}
                    type="submit"
                    disabled={isUpdate ? '' : pristine}/>

                <RequiredInfo/>
            </form>
        );
    }

}

export default reduxForm({
    form: "couponForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate
})(CouponFormPage1);
