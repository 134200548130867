import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import classnames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";

import {madePurchaseCampaignsOption} from "../selection/CampaignsFilterForm";
import {receivedSendoutMedium, receivedSendoutOption} from "../selection/SendoutFilterForm";
import {BECAME_MEMBER, NOT_BECAME_MEMBER} from "../selection/DateBecameMemberFilterForm";
import {BORN, NOT_BORN} from "../selection/DateBornFilterForm";
import {MADE_PURCHASE, NOT_MADE_PURCHASE} from "../selection/DatePurchaseFilterForm";
import {madePurchaseCategoriesOption} from "../selection/CategoriesFilterForm";
import {HAVE_POINTS, NOT_HAVE_POINTS} from "../selection/PointsBalanceFilterForm";
import {getFormattedDate} from "../../../common/utils/date-utils";

import {filterMembersForCampaign} from "../../actions/merchant-actions";

import {months} from "../../../merchant/utils/month-const";

const styles = theme => ({
    wrapper: {
        width: '100%'
    },
    expansionPanel: {
        boxShadow: 'none',
        textAlign: 'center'
    },
    expansionPanelSummary: {
        display: 'inline-box',
        color: theme.palette.secondary.main
    },
    expandMoreIcon: {
        fill: theme.palette.secondary.main
    },
    divider: {
        backgroundColor: theme.palette.secondary.main,
        height: 3
    },
    cardWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        marginBottom: theme.spacing(2)
    },
    cardDisabled: {
        backgroundColor: theme.palette.grey.light
    },
    card: {
        width: '100%',
        padding: theme.spacing(1.5)
    },
    checkbox: {
        position: "absolute",
        top: '50%',
        transform: 'translateY(-50%)' + ' rotate(0deg)',
        left: 5
    }
});

class FilterList extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        currentSmartSelection: PropTypes.object,
        categories: PropTypes.array,
        sendoutHistory: PropTypes.array,
        campaigns: PropTypes.array,
        campaignId: PropTypes.number,
        editable: PropTypes.bool,
        sendout: PropTypes.bool,
        filterMembersForCampaign: PropTypes.func,
    };

    render() {
        const {currentSmartSelection, editable} = this.props;

        return (
            this.renderFilters(currentSmartSelection, editable)
        )
    }

    renderFilters(smartSelection, editable) {
        const {classes} = this.props;

        if (smartSelection) {
            const dateFilter = smartSelection.dateFilter
                && smartSelection.dateFilter.filter(filter => filter.id !== undefined);
            const newDateFilter = smartSelection.dateFilter
                && smartSelection.dateFilter.filter(filter => !filter.id);
            const sumFilter = smartSelection.sumFilter
                && smartSelection.sumFilter.filter(filter => filter.id !== undefined);
            const newSumFilter = smartSelection.sumFilter
                && smartSelection.sumFilter.filter(filter => !filter.id);

            const quantityFilter = smartSelection.quantityFilter
                && smartSelection.quantityFilter.filter(filter => filter.id !== undefined);
            const newQuantityFilter = smartSelection.quantityFilter
                && smartSelection.quantityFilter.filter(filter => !filter.id);

            const tasteFilter = smartSelection.tasteFilter
                && smartSelection.tasteFilter.filter(filter => filter.id !== undefined);
            const newTasteFilter = smartSelection.tasteFilter
                && smartSelection.tasteFilter.filter(filter => !filter.id);

            const sendoutFilter = smartSelection.sendoutFilter
                && smartSelection.sendoutFilter.filter(filter => filter.id !== undefined);
            const newSendoutFilter = smartSelection.sendoutFilter
                && smartSelection.sendoutFilter.filter(filter => !filter.id);

            return (
                <div className={classes.wrapper}>
                    {editable && (
                        <ExpansionPanel className={classes.expansionPanel}>
                            <ExpansionPanelSummary
                                className={classes.expansionPanelSummary}
                                expandIcon={<ExpandMore className={classes.expandMoreIcon}/>}>

                                <Typography
                                    variant="subtitle1"
                                    color="secondary">
                                    <FormattedMessage
                                        id="seeCampaignSelection"
                                        defaultMessage="See campaign selection"/>
                                </Typography>
                            </ExpansionPanelSummary>

                            <ExpansionPanelDetails>
                                {this.renderFiltersWithId(dateFilter, editable, sumFilter, quantityFilter, tasteFilter, sendoutFilter)}
                            </ExpansionPanelDetails>

                            <Divider className={classes.divider}/>
                        </ExpansionPanel>
                    )}

                    {!editable && this.renderFiltersWithId(dateFilter, editable, sumFilter, quantityFilter, tasteFilter, sendoutFilter)}

                    {editable && (
                        <React.Fragment>
                            <Typography
                                variant="subtitle1"
                                paragraph>
                                <FormattedMessage
                                    id="sendoutSelection"
                                    defaultMessage="Send-out Selection"/>:
                            </Typography>

                            {newDateFilter && newDateFilter
                                .map((filter, index) => this.renderDateFilter(filter, editable, index))}

                            {newSumFilter && newSumFilter
                                .map((filter, index) => this.renderSumFilter(filter, editable, index))}

                            {newQuantityFilter && newQuantityFilter
                                .map((filter, index) => this.renderQuantityFilter(filter, editable, index))}

                            {newTasteFilter && newTasteFilter
                                .map((filter, index) => this.renderTasteFilter(filter, editable, index))}

                            {newSendoutFilter && newSendoutFilter
                                .map((filter, index) => this.renderSendoutFilter(filter, editable, index))}
                        </React.Fragment>
                    )}
                </div>
            )
        }
        return null
    }

    renderFiltersWithId(dateFilter, editable, sumFilter, quantityFilter, tasteFilter, sendoutFilter) {
        const {classes} = this.props;

        if (
            (dateFilter && dateFilter.length > 0)
            || (sumFilter && sumFilter.length > 0)
            || (quantityFilter && quantityFilter.length > 0)
            || (tasteFilter && tasteFilter.length > 0)
            || (sendoutFilter && sendoutFilter.length > 0)
        ) {
            return (
                <div className={classes.wrapper}>
                    {dateFilter && dateFilter
                        .map((filter, index) => this.renderDateFilter(filter, editable, index))}

                    {sumFilter && sumFilter
                        .map((filter, index) => this.renderSumFilter(filter, editable, index))}

                    {quantityFilter && quantityFilter
                        .map((filter, index) => this.renderQuantityFilter(filter, editable, index))}

                    {tasteFilter && tasteFilter
                        .map((filter, index) => this.renderTasteFilter(filter, editable, index))}

                    {sendoutFilter && sendoutFilter
                        .map((filter, index) => this.renderSendoutFilter(filter, editable, index))}
                </div>
            )
        }
        return (
            <div className={classes.wrapper}>
                <Typography variant="body1">
                    <FormattedMessage
                        id="visibleToEachMember"
                        defaultMessage="It's visible to each member"/>
                </Typography>
            </div>
        )
    }

    renderTasteFilter(filter, editable, index) {
        const {classes} = this.props;

        const isCategoryFilter = filter.categories && filter.categories.length > 0;
        const isCampaignFilter = filter.campaigns && filter.campaigns.length > 0;

        if (filter.campaign) {
            if (editable || !filter.inactive) {
                return (
                    <div
                        className={classes.cardWrapper}
                        key={index}>
                        <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                            {editable && !filter.id && this.createCheckbox(filter)}
                            <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="excludeMembersThatHaveUsedThisCampaignAlready"
                                        defaultMessage="Exclude members that have used this campaign already"/>
                                </Typography>
                            </div>
                        </Card>
                    </div>
                )
            }
        } else {

            if (isCategoryFilter) {
                const filterDescription = madePurchaseCategoriesOption
                    .find(option => option.value === filter.madePurchaseCategories).label;
                const categoriesNames = this.props.categories
                    .filter(category => filter.categories
                        .includes(category.id))
                    .map(option => option.name);

                return (
                    <div
                        className={classes.cardWrapper}
                        key={index}>
                        <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                            {editable && !filter.id && this.createCheckbox(filter)}
                            <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                                <Typography variant="body1">
                                    {filterDescription}:
                                </Typography>

                                {categoriesNames.map((category, index) => (
                                    <span key={index}>
                                        {index > 0 && ", "}
                                        {category}
                                    </span>
                                ))}
                            </div>
                        </Card>
                    </div>
                )
            }
            if (isCampaignFilter) {
                const filterDescription = madePurchaseCampaignsOption
                    .find(option => option.value === filter.madePurchaseCampaigns).label;
                const campaignsNames = this.props.campaigns
                    .filter(campaign => filter.campaigns
                        .includes(campaign.id))
                    .map(option => option.name);

                return (
                    <div
                        className={classes.cardWrapper}
                        key={index}>
                        <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                            {editable && !filter.id && this.createCheckbox(filter)}
                            <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                                <Typography variant="body1">
                                    {filterDescription}:
                                </Typography>
                                <br/>
                                {campaignsNames.map((campaign, index) => (
                                    <span key={index}>
                                        {index > 0 && ", "}{campaign}
                                    </span>
                                ))}
                            </div>
                        </Card>
                    </div>
                )
            }
        }
    }

    renderQuantityFilter(filter, editable, index) {
        const {classes} = this.props;
        const isPurchaseQuantityFilter = this.checkIfNotNullOrUndefined(filter.purchaseQuantityFrom)
            && this.checkIfNotNullOrUndefined(filter.purchaseQuantityTo);

        let filterDescription;
        let quantityFrom;
        let quantityTo;

        if (isPurchaseQuantityFilter) {
            filterDescription = filter.madePurchases ? MADE_PURCHASE : NOT_MADE_PURCHASE;
            quantityFrom = filter.purchaseQuantityFrom;
            quantityTo = filter.purchaseQuantityTo;
        }

        return (
            <div
                className={classes.cardWrapper}
                key={index}>
                <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                    {editable && !filter.id && this.createCheckbox(filter)}
                    <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                        <Typography variant="body1">
                            {filterDescription}
                        </Typography>

                        <Typography variant="body1">
                            {quantityFrom && (
                                <React.Fragment>
                                    <FormattedMessage
                                        id="from"
                                        defaultMessage="From"/>
                                    &nbsp;

                                    {quantityFrom}
                                </React.Fragment>
                            )}

                            {quantityTo && (
                                <React.Fragment>
                                    &nbsp;
                                    <FormattedMessage
                                        id="till"
                                        defaultMessage="till"/>
                                    &nbsp;

                                    {quantityTo}

                                    &nbsp;
                                    <FormattedMessage
                                        id="times"
                                        defaultMessage="times"/>
                                </React.Fragment>
                            )}
                        </Typography>
                    </div>
                </Card>
            </div>
        )
    }

    checkIfNotNullOrUndefined(filter) {
        return filter !== undefined && filter !== null;
    }

    renderSumFilter(filter, editable, index) {
        const {classes} = this.props;

        const isPurchasesAmountFilter = this.checkIfNotNullOrUndefined(filter.purchasesAmountFrom)
            && this.checkIfNotNullOrUndefined(filter.purchasesAmountTo);
        const isOnePurchaseAmountFilter = this.checkIfNotNullOrUndefined(filter.onePurchaseAmountFrom)
            && this.checkIfNotNullOrUndefined(filter.onePurchaseAmountTo);
        const isPointsBalance = this.checkIfNotNullOrUndefined(filter.pointsBalanceFrom)
            && this.checkIfNotNullOrUndefined(filter.pointsBalanceTo);

        if (isPurchasesAmountFilter || isOnePurchaseAmountFilter || isPointsBalance) {
            return (
                <div
                    className={classes.cardWrapper}
                    key={index}>
                    <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                        {editable && !filter.id && this.createCheckbox(filter)}
                        <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                            {isPurchasesAmountFilter && this.renderPurchaseFilter(filter, true)}
                            {isOnePurchaseAmountFilter && this.renderPurchaseFilter(filter, false)}
                            {isPointsBalance && (
                                <div>
                                    <Typography variant="body1">
                                        {filter.havePoints ? HAVE_POINTS : NOT_HAVE_POINTS}
                                    </Typography>

                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="from"
                                            defaultMessage="From"/>
                                        &nbsp;

                                        {filter.pointsBalanceFrom}

                                        &nbsp;
                                        <FormattedMessage
                                            id="till"
                                            defaultMessage="till"/>
                                        &nbsp;

                                        {filter.pointsBalanceTo}

                                        <FormattedMessage
                                            id="points"
                                            defaultMessage="points"/>
                                    </Typography>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            )
        }
    }

    renderPurchaseFilter(filter, isPurchasesAmount, index) {
        let filterDescription;
        let amountFrom;
        let amountTo;

        if (isPurchasesAmount) {
            filterDescription = (
                <React.Fragment>
                    {filter.madePurchase ? MADE_PURCHASE : NOT_MADE_PURCHASE}
                    &nbsp;

                    <FormattedMessage
                        id="inTotal"
                        defaultMessage="In total"/>
                </React.Fragment>
            );
            amountFrom = filter.purchasesAmountFrom;
            amountTo = filter.purchasesAmountTo;
        } else {
            filterDescription = (
                <React.Fragment>
                    {filter.madePurchase ? MADE_PURCHASE : NOT_MADE_PURCHASE}
                    &nbsp;

                    <FormattedMessage
                        id="atSinglePurchaseTransaction"
                        defaultMessage="At a single purchase transaction"/>
                </React.Fragment>
            );
            amountFrom = filter.onePurchaseAmountFrom;
            amountTo = filter.onePurchaseAmountTo;
        }

        return (
            <div key={index}>
                <Typography variant="body1">
                    {filterDescription}
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="from"
                        defaultMessage="From"/>
                    &nbsp;

                    {amountFrom} SEK

                    &nbsp;
                    <FormattedMessage
                        id="till"
                        defaultMessage="till"/>
                    &nbsp;

                    {amountTo} SEK
                </Typography>
            </div>
        )
    }

    renderDateFilter(filter, editable, index) {
        const {classes} = this.props;

        const isPurchaseDateFilter = filter.purchaseDateFrom || filter.purchaseDateTo;
        const isBecameMemberDateFilter = filter.becameMemberDateFrom || filter.becameMemberDateTo;
        const isBornDateFilter = filter.day || filter.month || filter.year;

        let filterDescription;
        let dateFrom;
        let dateTo;

        if (filter.campaign) {
            if (editable || !filter.inactive) {
                return (
                    <div
                        className={classes.cardWrapper}
                        key={index}>
                        <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                            {editable && !filter.id && this.createCheckbox(filter)}
                            <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="excludeMembersThatHaveNotMadePurchaseTheLast12Months"
                                        defaultMessage="Exclude members who have not made purchases in 12 months"/>
                                </Typography>
                            </div>
                        </Card>
                    </div>
                )
            }
        } else {

            if (isPurchaseDateFilter) {
                filterDescription = filter.purchaseInDateRange ? MADE_PURCHASE : NOT_MADE_PURCHASE;
                dateFrom = filter.purchaseDateFrom;
                dateTo = filter.purchaseDateTo;

            } else if (isBecameMemberDateFilter) {
                filterDescription = filter.becameMemberInDateRange ? BECAME_MEMBER : NOT_BECAME_MEMBER;
                dateFrom = filter.becameMemberDateFrom;
                dateTo = filter.becameMemberDateTo;
            } else if (isBornDateFilter) {
                filterDescription = filter.bornInDateRange ? BORN : NOT_BORN;
                const dayFrom = filter.bornDayFrom;
                const dayTo = filter.bornDayTo;
                const monthFrom = filter.bornMonthFrom;
                const monthTo = filter.bornMonthTo;
                const yearFrom = filter.bornYearFrom;
                const yearTo = filter.bornYearTo;

                const isDay = filter.day;
                const isMonth = filter.month;
                const isYear = filter.year;

                return (
                    <div
                        className={classes.cardWrapper}
                        key={index}>
                        <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                            {editable && !filter.id && this.createCheckbox(filter)}
                            <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                                {filterDescription}

                                {isDay && (
                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="from"
                                            defaultMessage="From"/>
                                        &nbsp;

                                        <FormattedMessage
                                            id="dayLowercase"
                                            defaultMessage="day"/>
                                        &nbsp;

                                        {dayFrom}

                                        &nbsp;
                                        <FormattedMessage
                                            id="till"
                                            defaultMessage="till"/>
                                        &nbsp;

                                        {dayTo}
                                        <FormattedMessage
                                            id="dayOfMonth"
                                            defaultMessage="dayOfMonth"/>
                                    </Typography>
                                )}

                                {isMonth && (
                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="from"
                                            defaultMessage="From"/>
                                        &nbsp;

                                        {months.find(m => m.value === monthFrom).key}

                                        &nbsp;
                                        <FormattedMessage
                                            id="till"
                                            defaultMessage="till"/>
                                        &nbsp;

                                        {months.find(m => m.value === monthTo).key}
                                    </Typography>
                                )}

                                {isYear && (
                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="from"
                                            defaultMessage="From"/>
                                        &nbsp;

                                        <FormattedMessage
                                            id="yearLowercase"
                                            defaultMessage="year"/>
                                        &nbsp;
                                        {yearFrom}

                                        &nbsp;
                                        <FormattedMessage
                                            id="till"
                                            defaultMessage="till"/>
                                        &nbsp;

                                        {yearTo}
                                    </Typography>
                                )}
                            </div>
                        </Card>
                    </div>
                )
            }

            return (
                <div
                    className={classes.cardWrapper}
                    key={index}>
                    <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                        {editable && !filter.id && this.createCheckbox(filter)}
                        <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                            <Typography variant="body1">
                                {filterDescription}
                            </Typography>

                            <Typography variant="body1">
                                {dateFrom && (
                                    <React.Fragment>
                                        <FormattedMessage
                                            id="from"
                                            defaultMessage="From"/>
                                        &nbsp;

                                        {getFormattedDate(dateFrom)}
                                    </React.Fragment>
                                )}

                                {dateTo && (
                                    <React.Fragment>
                                        &nbsp;
                                        <FormattedMessage
                                            id="till"
                                            defaultMessage="till"/>
                                        &nbsp;

                                        {getFormattedDate(dateTo)}
                                    </React.Fragment>
                                )}
                            </Typography>
                        </div>
                    </Card>
                </div>
            )
        }
    }

    renderSendoutFilter(filter, editable, index) {
        const {classes} = this.props;

        if (filter.campaign) {
            if (editable || !filter.inactive) {
                return (
                    <div
                        className={classes.cardWrapper}
                        key={index}>
                        <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                            {editable && !filter.id && this.createCheckbox(filter)}
                            <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="excludeMembersThatAlreadyReceivedSendout"
                                        defaultMessage="Exclude members that already received a send-out for this campaign"/>
                                </Typography>
                            </div>
                        </Card>
                    </div>
                )
            }
        } else {
            const filterDescription = receivedSendoutOption.find(option => option.value === filter.received).label;
            const filterDescription2 = receivedSendoutMedium.find(option => option.value === filter.medium).label;
            const sendouts = this.props.sendoutHistory.filter(sendout => filter.sendouts.includes(sendout.id));
            return (
                <div
                    className={classes.cardWrapper}
                    key={index}>
                    <Card className={classnames(classes.card, filter.inactive && classes.cardDisabled)}>
                        {editable && !filter.id && this.createCheckbox(filter)}
                        <div style={{marginLeft: editable && !filter.id ? 25 : 0}}>
                            <Typography variant="body1">
                                {filterDescription}:
                            </Typography>

                            {sendouts.map((sendout, index) =>
                                <span key={index}>
                                    {index > 0 && <br/>}
                                    {sendout.name}
                                    ({getFormattedDate(sendout.date)}) - {filterDescription2}
                                </span>
                            )}
                        </div>
                    </Card>
                </div>
            )
        }
    }

    createCheckbox(filter) {
        const {classes} = this.props;
        return (
            <Checkbox
                onClick={this.onChangeCheckbox.bind(this, filter)}
                checked={!filter.inactive}
                className={classes.checkbox}/>
        )
    }

    onChangeCheckbox(filter) {
        filter.inactive = !filter.inactive;
        this.props.filterMembersForCampaign(this.props.currentSmartSelection, this.props.campaignId, this.props.sendout);
        this.forceUpdate();
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({filterMembersForCampaign}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(FilterList));
