import axios from "axios";
import {
    COMPLETE_GUIDE,
    FETCH_CONSUMER,
    FETCH_CONSUMERS,
    FETCH_CONSUMERS_NUMBER,
    FETCH_SENDOUT_HISTORY,
    FETCH_SMART_SELECTION,
    FETCH_SMS_HISTORY,
    FILTER_MEMBERS_FOR_CAMPAIGNS,
    INVITE_MERCHANT,
    MERCHANT_FETCH_MERCHANT,
    MERCHANT_FETCH_MERCHANTS,
    MERCHANT_REMOVE_MERCHANT,
    MERCHANT_UPDATE_MEMBER,
    PUBLISH_CAMPAIGN,
    REGISTER_CREDIT_CARD,
    REGISTER_NUMBER,
    REMOVE_CONSUMER,
    REMOVE_CREDIT_CARD,
    SENDOUT_CAMPAIGN,
    SET_CURRENT_SMART_SELECTION
} from "../../common/actions/types";
import {apiError, apiSuccessMessage, headerConfig} from "../../common/actions/utils-actions";
import {ROOT_URL} from "../../common/config/karming-links";
import {fetchCommercialChain} from "./merchant-chain-actions";
import {fetchCompany} from "./merchant-company-actions";
import {fetchUserDetails} from "../../common/actions/user-actions";
import {fetchBillingDetails} from "./merchant-billing-actions";
import {startSubmit, stopSubmit} from "redux-form";
import {MERCHANT_ACCESS_FORM_NAME} from "../components/company/MerchantAccessForm";
import {fetchCampaigns} from "./merchant-campaign-actions";
import {
    END_FILTER_MEMBERS_FOR_CAMPAIGN,
    START_FILTER_MEMBERS_FOR_CAMPAIGN
} from "../components/sendout/SendoutFormPage1";

const ENDPOINT_URL = `${ROOT_URL}/merchant`;

export function completeGuide() {
    return dispatch => {

        axios.post(`${ENDPOINT_URL}/guide/complete`, {}, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: COMPLETE_GUIDE,
                        payload: response.data
                    });

                    updateForStartGuide(dispatch)
                }
            }).catch(response => {
            dispatch(apiError('Unable to complete guide', response));
        });
    }
}

export function findMembers(values, history) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/consumer/search`, values, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_CONSUMERS,
                        payload: response.data
                    });
                    if (response.data && response.data.length > 0) {
                        if (response.data.length === 1) {
                            history.push({
                                pathname: "/purchase/register/" + response.data[0].id,
                                dateOfPurchase: history.dateOfPurchase
                            });
                        } else if (response.data.length > 1) {
                            history.push({
                                pathname: '/purchase/members',
                                state: {consumers: response.data},
                                dateOfPurchase: history.dateOfPurchase
                            })
                        }
                    } else {
                        dispatch(apiError('Kund inte hitta medlem', response));
                    }
                }
            }).catch(response => {
            dispatch(apiError('Kund inte hitta medlem', response));
        });
    }
}

export function fetchConsumersNumber(chainId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chain/${chainId}/consumers/number`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CONSUMERS_NUMBER,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch consumers number', response));
            });
    }
}

export function fetchConsumersCounted(searchConfig) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/chain/consumers`, searchConfig, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CONSUMERS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch consumers', response));
            });
    }
}

export function fetchConsumer(id) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/consumers/${id}`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_CONSUMER,
                        payload: response.data
                    })
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch consumer', response));
        });
    }
}

export function removeMember(memberId, history) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/members/${memberId}/remove`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: REMOVE_CONSUMER,
                        payload: response.data
                    })
                }
                history.push("/members");
            }).catch(response => {
            dispatch(apiError('Unable to remove member', response));
        });
    }
}

export function fetchMerchant(id) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/users/${id}`, headerConfig())
            .then(response => {
                dispatch({
                    type: MERCHANT_FETCH_MERCHANT,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch user', response));
            });
    }
}

export function fetchMerchants() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/users`, headerConfig())
            .then(response => {
                dispatch({
                    type: MERCHANT_FETCH_MERCHANTS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch users', response));
            });
    }
}

export function removeMerchant(userId, history) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/users/${userId}/remove`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: MERCHANT_REMOVE_MERCHANT,
                        payload: response.data
                    })
                }
                history.push(`/users/`);
            }).catch(response => {
            dispatch(apiError('Unable to remove user', response));
        });
    }
}

export function merchantInviteMerchant(values) {
    return dispatch => {
        dispatch(startSubmit(MERCHANT_ACCESS_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/users/invite`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(MERCHANT_ACCESS_FORM_NAME));
                if (response) {
                    dispatch({
                        type: INVITE_MERCHANT,
                        payload: response.data
                    });
                }
                dispatch(apiSuccessMessage("Invitation email sent"))
            }).catch(response => {
            dispatch(stopSubmit(MERCHANT_ACCESS_FORM_NAME));
            dispatch(apiError('Unable to invite user', response));
        });
    }
}

export function changeMerchantAccess(values) {
    return dispatch => {
        dispatch(startSubmit(MERCHANT_ACCESS_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/users/access`, values, headerConfig())
            .then(() => {
                dispatch(stopSubmit(MERCHANT_ACCESS_FORM_NAME));
                dispatch(apiSuccessMessage("User access changed"))
            }).catch(response => {
            dispatch(stopSubmit(MERCHANT_ACCESS_FORM_NAME));
            dispatch(apiError('Unable to change user access', response));
        });
    }
}

export function merchantUpdateMember(memberId, history, values) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/members/${memberId}`, values, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: MERCHANT_UPDATE_MEMBER,
                        payload: response.data
                    });
                    if (history) {
                        history.push("/members");
                    } else {
                        dispatch(fetchConsumer(memberId));
                    }
                }
            }).catch(response => {
            dispatch(apiError('Unable to update member', response));
        });
    }
}

export function generateMerchantECR(merchantId) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/users/${merchantId}/ecr/generate`, null, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: MERCHANT_FETCH_MERCHANT,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to generate ECR token', response));
        });
    }
}

export function sendoutCampaign(values, campaignId) {
    if (!campaignId) {
        throw "sendoutCampaign: campaignId Required";
    }

    return dispatch => {

        axios.post(`${ENDPOINT_URL}/campaigns/${campaignId}/sendout`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: SENDOUT_CAMPAIGN,
                    payload: response.data
                })
            }).catch(response => {
            dispatch(apiError('Unable to send out campaign', response));
        });
    }
}

export function fetchSmartSelection(campaignId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/selection/${campaignId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_SMART_SELECTION,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch smart selection', response));
            });
    }
}

export function fetchSendoutHistory(chainId, values) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/sendout/history/${chainId}`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_SENDOUT_HISTORY,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch sendout history', response));
            });
    }
}

export function filterMembersForCampaign(smartSelection, campaignId, sendout, values) {

    if (values) {
        const dateFilter = values.dateFilter;
        const sumFilter = values.sumFilter;
        const quantityFilter = values.quantityFilter;
        const tasteFilter = values.tasteFilter;
        const sendoutFilter = values.sendoutFilter;

        smartSelection.name = undefined;
        if (dateFilter) {
            smartSelection.dateFilter = !smartSelection.dateFilter ? [dateFilter] : [...smartSelection.dateFilter, dateFilter]
        }

        if (sumFilter) {
            if (!sumFilter.totalAmount) {
                sumFilter.purchasesAmountFrom = null;
                sumFilter.purchasesAmountTo = null;
            }
            if (!sumFilter.onePurchaseAmount) {
                sumFilter.onePurchaseAmountFrom = null;
                sumFilter.onePurchaseAmountTo = null;
            }

            smartSelection.sumFilter = !smartSelection.sumFilter ? [sumFilter] : [...smartSelection.sumFilter, sumFilter]
        }

        if (quantityFilter) {
            smartSelection.quantityFilter = !smartSelection.quantityFilter ? [quantityFilter] : [...smartSelection.quantityFilter, quantityFilter]
        }

        if (tasteFilter) {
            smartSelection.tasteFilter = !smartSelection.tasteFilter ? [tasteFilter] : [...smartSelection.tasteFilter, tasteFilter]
        }

        if (sendoutFilter) {
            smartSelection.sendoutFilter = !smartSelection.sendoutFilter ? [sendoutFilter] : [...smartSelection.sendoutFilter, sendoutFilter]
        }
    }
    smartSelection.sendout = sendout;
    setCurrentSmartSelection(smartSelection);
    return dispatch => {
        dispatch(apiSuccessMessage(null, START_FILTER_MEMBERS_FOR_CAMPAIGN));
        axios.post(`${ENDPOINT_URL}/selection/number/${campaignId}`, smartSelection, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: FILTER_MEMBERS_FOR_CAMPAIGNS,
                        payload: response.data
                    });
                }
                dispatch(apiSuccessMessage(null, END_FILTER_MEMBERS_FOR_CAMPAIGN))
            }).catch(response => {
            dispatch(apiError(null, END_FILTER_MEMBERS_FOR_CAMPAIGN));
            dispatch(apiError('Unable to update smart selection', response));
        });
    }
}

export function getMembersForCampaign(campaignId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/selection/number/${campaignId}`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: FILTER_MEMBERS_FOR_CAMPAIGNS,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to get number of members for campaign', response));
        });
    }
}

export function setCurrentSmartSelection(smartSelection) {
    return {
        type: SET_CURRENT_SMART_SELECTION,
        payload: smartSelection,
    }
}

export function publishCampaign(campaignId, currentSmartSelection) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/selection/publish/${campaignId}`, currentSmartSelection, headerConfig())
            .then(response => {
                    dispatch({
                        type: PUBLISH_CAMPAIGN,
                        payload: response.data
                    });
                    dispatch(apiSuccessMessage("Campaign published"));
                    dispatch(fetchCampaigns(localStorage.getItem('currentChainId'), false, true));
                }
            )
            .catch(response => {
                dispatch(apiError('Unable to publish campaign', response));
            });
    }
}

export function registerCard(stripe, billingDetails, redirectUrl) {
    let stripeSource = null;

    return dispatch => {
        stripe.createSource()
            .then(payload => {
                stripeSource = payload.source;
                const is3DSecure = stripeSource.card && stripeSource.card.three_d_secure &&
                    (stripeSource.card.three_d_secure === 'required' ||
                        stripeSource.card.three_d_secure === 'recommended' ||
                        stripeSource.card.three_d_secure === 'optional');
                axios.post(`${ENDPOINT_URL}/card/create`,
                    stripeSource,
                    headerConfig())
                    .then(response => {
                        dispatch({
                            type: REGISTER_CREDIT_CARD,
                            payload: response.data
                        });
                    })
                    .then(() => {
                        if (is3DSecure) {
                            stripe.createSource({
                                type: 'three_d_secure',
                                amount: 2500,
                                currency: "sek",
                                three_d_secure: {
                                    customer: billingDetails.stripeCustomerId,
                                    card: stripeSource.id
                                },
                                redirect: {
                                    return_url: redirectUrl
                                },
                                //    usage: "reusable" // Usage `reusable` is not supported by this source type.
                            }).then(payload3DSource => {
                                    const source3D = payload3DSource.source;
                                    window.location = source3D.redirect.url
                                }
                            )
                        } else {
                            dispatch(apiSuccessMessage('REGISTER_CARD_SUCCESS'));
                        }
                    })
                    .catch(() => {
                        dispatch(apiError('Kortet gick inte att registrera. Vänligen kontrollera dina uppgifter och försök igen eller använda ett annat kort.'));
                    });
            })
    }
}

export function update3Dsecure(source3DSecure, history, redirectUrl) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/card/3Dsecure`,
            source3DSecure,
            headerConfig())
            .then(response => {
                dispatch({
                    type: REGISTER_CREDIT_CARD,
                    payload: response.data
                });
                history.push(redirectUrl);
                dispatch(apiSuccessMessage("REGISTER_CARD_SUCCESS"));
            })
            .catch(() => {
                history.push(redirectUrl);
                dispatch(apiError('Kortet kunde inte verifieras vid ägarkontroll hos din bank. Vänligen försök igen eller använda ett annat kort.'));
            });
    }
}

export function removeCard(cardId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/card/${cardId}/remove`, headerConfig())
            .then(response => {
                dispatch({
                    type: REMOVE_CREDIT_CARD,
                    payload: response.data
                });
                dispatch(fetchBillingDetails())
            })
            .catch(response => {
                dispatch(apiError('Unable to remove payment card', response));
            });
    }
}

export function fetchSmsHistory(values) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/sms/history`, values, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_SMS_HISTORY,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch SMS usage history', response));
        });
    }
}

export function activateSmsService(actionId) {
    const chainId = localStorage.getItem('currentChainId');

    return dispatch => {
        axios.post(`${ENDPOINT_URL}/number/create`, {}, headerConfig())
            .then(response => {
                dispatch({
                    type: REGISTER_NUMBER,
                    payload: response.data
                });

                //FOR START GUIDE
                if (chainId) {
                    dispatch(fetchCommercialChain(chainId))
                }
                dispatch(fetchCompany());
                dispatch(fetchUserDetails());
                dispatch(apiSuccessMessage(null, actionId))
            })
            .catch(response => {
                dispatch(apiError('Unable to register number', response));
            });
    }
}

export function updateMaxCreditsPerMonth(values) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/number/credits`,
            {
                credits: values.credits || 0,
                maxCreditsPerMonth: values.maxCreditsPerMonth,
                autoCharge: values.autoCharge
            },
            headerConfig())
            .then(() => {
                // FIXME remove from refresh
                dispatch(fetchCompany())
            })
            .catch(response => {
                dispatch(apiError('Unable to update MaxCreditsPerMonth', response));
            });
    }
}

export function buySmsCredits(values) {
    return dispatch => {
        dispatch(startSubmit('registerNumberCreditsForm'));
        axios.post(`${ENDPOINT_URL}/number/credits`,
            {
                credits: values.credits || 0,
            },
            headerConfig())
            .then(() => {
                dispatch(stopSubmit('registerNumberCreditsForm'));
                // FIXME remove from refresh
                dispatch(fetchCompany())
            })
            .catch(response => {
                dispatch(stopSubmit('registerNumberCreditsForm'));
                dispatch(apiError('Unable to buy SMS Credits', response));
            });
    }
}

function updateForStartGuide(dispatch) {
    dispatch(fetchUserDetails())
}
