import axios from "axios";
import {ROOT_URL} from "../../common/config/karming-links";
import {apiError, headerConfig} from "../../common/actions/utils-actions";
import {FETCH_BILLING_DETAILS, UPDATE_BILLING_DETAILS} from "../../common/actions/types";


const ENDPOINT_URL = `${ROOT_URL}/merchant/billing`;

export function fetchBillingDetails() {
    return dispatch => {

        axios.get(`${ENDPOINT_URL}`, headerConfig())
            .then(response => {
                if (response) {
                    dispatch({
                        type: FETCH_BILLING_DETAILS,
                        payload: response.data
                    });
                }
            }).catch(response => {
            dispatch(apiError('Unable to fetch Billing details', response));
        });
    }
}

export function updateBillingDetails(values) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/update`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: UPDATE_BILLING_DETAILS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to update Billing details', response));
            });
    }
}