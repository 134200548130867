import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import RenderTextField from "../../../../common/components/common/RenderTextField";
import {stampcardTitleValidation} from "../../../../common/utils/karming-validators";

const validate = values => {
    return stampcardTitleValidation(
        {name: "title", value: values.title},
        30,
        2
    )
};

const styles = {
    itemTitle: {
        fontWeight: 'bold'
    }
};

class StampcardFormPage3 extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        stampcard: PropTypes.object,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {classes, handleSubmit, stampcard: {title, stampsAmount}} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="giveATitleToYourStampCard"
                        defaultMessage="Give a title to your stamp card"/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="enterAShortAndAttractiveHeadingOnYourStampCard"
                        defaultMessage="Enter a short and attractive heading on your stamp card."/>
                </Typography>

                <Typography variant="body1">
                    <span className={classes.itemTitle}>
                        <FormattedMessage
                            id="example"
                            defaultMessage="Example"/>: </span>
                    <FormattedMessage
                        id="stampcardFormPage3.example"
                        defaultMessage={`"Every {stampsAmountPlusOne} product / service X free", "Collect {stampsAmount} stamps and get X" or simply "Stamp card".`}
                        values={{
                            stampsAmountPlusOne: stampsAmount + 1,
                            stampsAmount: stampsAmount
                        }}/>
                </Typography>

                <Field
                    name="title"
                    hintText={<FormattedMessage
                        id="title"
                        defaultMessage="Title"/>}
                    component={RenderTextField}
                    multiline
                    rows={2}
                    rowsMax={2}
                    type="text"
                    helperText={<FormattedMessage
                        id="provideMaximumCharactersPerTitleRow2"
                        defaultMessage={`Provide maximum {numberOfCharacters} characters per title row. Create an extra row by hitting “Enter”.`}
                        values={{
                            numberOfCharacters: 30
                        }}/>}/>

                <Button
                    label={<FormattedMessage
                        id="next"
                        defaultMessage="Next"/>}
                    type="submit"
                    disabled={!title}/>
            </form>
        )
    }

}

export default reduxForm({
    form: "stampcardForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnChange: true,
    validate
})(withStyles(styles)(StampcardFormPage3));
