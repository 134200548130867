import React from 'react';
import {Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Save from '@material-ui/icons/Save';
import Delete from '@material-ui/icons/Delete';

import RenderTextField from '../RenderTextField';
import Dialog from './../../common/Dialog';
import Button from '../../common/Button';

import {generateMerchantECR} from '../../../../merchant/actions/merchant-actions';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

const styles = theme => ({
    buttonsWrapper: {
        marginTop: theme.spacing(1),
    },
});

class MerchantDetailsForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        submitting: PropTypes.bool,
        isAdmin: PropTypes.bool,
        handleSubmit: PropTypes.func,
        removeMerchant: PropTypes.func,
        generateMerchantECR: PropTypes.func,
        initialValues: PropTypes.object.isRequired,
    };

    state = {
        openRemoveMerchant: false,
    };

    render() {
        const {initialValues, classes, handleSubmit, submitting, removeMerchant, isAdmin, generateMerchantECR} = this.props;

        const removeMemberDialogActions = [
            <Button
                key="close"
                label={<FormattedMessage
                    id="close"
                    defaultMessage="Close"/>}
                color="secondary"
                onClick={this.handleCancelRemoveMerchant}/>,
            <Button
                color="primary"
                key="submit"
                label={<FormattedMessage
                    id="yes"
                    defaultMessage="Yes"/>}
                onClick={this.handleConfirmRemoveMerchant.bind(this, removeMerchant)}/>,
        ];

        return (
            <form onSubmit={handleSubmit}>
                <Typography variant="h5">
                    <React.Fragment>
                        <FormattedMessage
                            id="edit"
                            defaultMessage="Edit"/>
                        &nbsp;
                        <FormattedMessage
                            id="user"
                            defaultMessage="user"/>
                    </React.Fragment>
                </Typography>

                {isAdmin && (
                    <Field
                        name="company.name"
                        hintText={<FormattedMessage
                            id="company"
                            defaultMessage="Company"/>}
                        component={RenderTextField}
                        type="text"/>
                )}

                <Field
                    name="email"
                    hintText={<FormattedMessage
                        id="email"
                        defaultMessage="Email"/>}
                    component={RenderTextField}
                    type="email"
                    disabled={!isAdmin}/>

                <Field
                    name="firstName"
                    hintText={<FormattedMessage
                        id="firstName"
                        defaultMessage="First name"/>}
                    component={RenderTextField}
                    type="text"
                    disabled={!isAdmin}/>

                <Field
                    name="lastName"
                    hintText={<FormattedMessage
                        id="lastName"
                        defaultMessage="Last name"/>}
                    component={RenderTextField}
                    type="text"
                    disabled={!isAdmin}/>

                <Field
                    name="phone"
                    hintText={<FormattedMessage
                        id="phone"
                        defaultMessage="Phone"/>}
                    component={RenderTextField}
                    type="mobile"
                    disabled={!isAdmin}/>

                <Field
                    name="userToken"
                    hintText={<FormattedMessage
                        id="userToken"
                        defaultMessage="ECR Token"/>}
                    component={RenderTextField}
                    type="text"
                    disabled={true}/>

                <div className={classes.buttonsWrapper}>
                    <Button
                        customColor="red"
                        icon={<Delete/>}
                        label={
                            <React.Fragment>
                                <FormattedMessage
                                    id="remove"
                                    defaultMessage="Remove"/>
                                &nbsp;
                                <FormattedMessage
                                    id="user"
                                    defaultMessage="user"/>
                            </React.Fragment>}
                        onClick={this.handleOpenRemoveMerchant}/>
                    <Button
                        customColor="blue"
                        label={
                            <FormattedMessage
                                id="generateECR"
                                defaultMessage="Generate New ECR Token"/>}
                        onClick={this.handleGenerateNewECR.bind(this, initialValues.id, generateMerchantECR)}/>

                    <Dialog
                        onCancel={this.handleCancelRemoveMerchant}
                        title={
                            <React.Fragment>
                                <FormattedMessage
                                    id="remove"
                                    defaultMessage="Remove"/>
                                &nbsp;
                                <FormattedMessage
                                    id="user"
                                    defaultMessage="user"/>
                            </React.Fragment>
                        }
                        actions={removeMemberDialogActions}
                        open={this.state.openRemoveMerchant}
                        content={<FormattedMessage
                            id="areYouSureRemoveUserText"
                            defaultMessage="Are you sure you want to remove user?"/>}/>

                    {isAdmin && (
                        <Button
                            icon={<Save/>}
                            label={<FormattedMessage
                                id="save"
                                defaultMessage="Save"/>}
                            type="submit"
                            disabled={submitting}/>
                    )}
                </div>
            </form>
        );
    }

    handleOpenRemoveMerchant = () => {
        this.setState({openRemoveMerchant: true});
    };

    handleCancelRemoveMerchant = () => {
        this.setState({openRemoveMerchant: false});
    };

    handleConfirmRemoveMerchant = (removeMerchant) => {
        this.setState({openRemoveMerchant: false});
        removeMerchant();
    };

    handleGenerateNewECR = (id, generateMerchantECR) => {
        generateMerchantECR(id);
    };

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        generateMerchantECR,
    }, dispatch);
}

export default reduxForm({
    form: 'merchantDetailsForm',
    enableReinitialize: true,
})(connect(null, mapDispatchToProps)(withStyles(styles)(MerchantDetailsForm)));

