import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import RenderSelect from "../../../../common/components/common/RenderSelect";

const stampAmountLabel = (stampsAmount) => <FormattedMessage
    id="amountStamps"
    defaultMessage="{stampsAmount} stamps"
    values={{
        stampsAmount: stampsAmount
    }}/>;

export const stampsAmountOption = () => {
    const stampsAmountOptionArray = [];
    for (let i = 1; i <= 10; i++) {
        stampsAmountOptionArray.push({value: i, label: stampAmountLabel(i)})
    }
    return stampsAmountOptionArray;
};

class StampcardFormPage1 extends React.Component {

    static propTypes = {
        pageTitle: PropTypes.object,
        initialValues: PropTypes.object,
        stampsAmount: PropTypes.number,
        handleSubmit: PropTypes.func
    };

    render() {
        const {handleSubmit, pageTitle, initialValues} = this.props;
        const number = 4;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    paragraph
                    variant="h5">
                    {pageTitle}
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="howManyStampsShouldTheCustomerCollectForReward"
                        defaultMessage="How many stamps should the customer collect for reward"/>
                    ?
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="customerReceivesRewardFor"
                        defaultMessage="Customer receives reward for"/>
                    :
                </Typography>

                <div>
                    <Field
                        name="stampsAmount"
                        component={RenderSelect}
                        defaultValue={initialValues && initialValues.stampsAmount
                            ? initialValues.stampsAmount
                            : number}>
                        {stampsAmountOption().map((option, index) => (
                            <MenuItem
                                key={index}
                                value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Field>
                </div>

                <Button
                    label={<FormattedMessage
                        id="next"
                        defaultMessage="Next"/>}
                    type="submit"/>
            </form>
        );
    }

}

export default reduxForm({
    form: "stampcardForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StampcardFormPage1);
