import React from "react";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import ReactTooltip from 'react-tooltip';

import withStyles from "@material-ui/core/styles/withStyles";
import Select from '@material-ui/core/Select'
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";
import Spinner from "../../../common/components/common/Spinner";
import Dialog from "../../../common/components/common/Dialog";
import ClubPointsActivationForm from "./ClubPointsActivationForm";
import ImageBoxRow from "../../../common/components/common/ImageBoxRow";

import {
    activateClubPointsActivation,
    deactivateClubPointsActivation,
    fetchClubPoints,
    fetchCoupons,
} from "../../actions/merchant-points-actions";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import DashedWrapper from "../../../common/components/common/DashedWrapper";
import {getFormattedDate} from "../../../common/utils/date-utils";

const styles = theme => ({
    button: {
        width: '33%'
    },
    itemWrapper: {
        display: 'flex',
        alignItems: 'baseline',
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0.5)
        }
    }
});

class ClubPoints extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        history: PropTypes.object,
        chain: PropTypes.object,
        coupons: PropTypes.array,
        clubPoints: PropTypes.object,
        app: PropTypes.object,
        fetchClubPoints: PropTypes.func,
        fetchCoupons: PropTypes.func,
        deactivateClubPointsActivation: PropTypes.func,
        activateClubPointsActivation: PropTypes.func
    };

    state = {
        openClubPointsActivation: false,
        currentDate: '',
        currentCouponsStatus: "ACTIVE"
    };

    handleOpenClubPointsActivation = () => {
        this.setState({currentDate: new Date()});
        this.setState({openClubPointsActivation: true});
    };

    handleCancelClubPointsActivation = () => {
        this.setState({openClubPointsActivation: false});
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain || prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchClubPoints, fetchCoupons} = this.props;
        if (chain && chain.id) {
            fetchClubPoints(chain.id);
            fetchCoupons(chain.id, false, null);
        }
    }

    onChangeCouponsStatus(event) {
        this.setState({currentCouponsStatus: event.target.value});
    }

    render() {
        const {classes, app, clubPoints, chain, history, coupons} = this.props;

        if (!clubPoints) {
            return <Spinner app={app}/>
        } else if (clubPoints.name === 'Non Existing') {

            return (
                <Container size={Container.SIZE_SMALL}>
                    <Paper padding>
                        <Typography
                            variant="h5"
                            paragraph>
                            <FormattedMessage
                                id="clubPoints"
                                defaultMessage="Club Points"/>
                        </Typography>

                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="rewardYourProfitableCustomers"
                                defaultMessage="Reward your profitable Customers"/>!
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="pointsAppreciatedWayToEnsureAndKeepLoyalAndProfitableCustomers"
                                defaultMessage="Points is a appreciated way to ensure and keep loyal and profitable customers."/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="easy"
                                defaultMessage="Easy"/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="1kr1Point"
                                defaultMessage="1 kr = 1 point"/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="automatic"
                                defaultMessage="Automatic"/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="couponIsAutomaticallyGenerated"
                                defaultMessage="Coupon is automatically generated"/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="profitable"
                                defaultMessage="Profitable"/>
                        </Typography>

                        <Typography variant="body1">
                            <FormattedMessage
                                id="customerIsRewardedAsYouProfit"
                                defaultMessage="Customer is rewarded as you profit"/>
                        </Typography>

                        <Button
                            label={<FormattedMessage
                                id="getStarted"
                                defaultMessage="Get started"/>}
                            onClick={() => history.push("/points/start")}/>
                    </Paper>
                </Container>
            )
        }

        const hasCoupons = coupons && coupons.length > 0;
        let hasActiveCoupons;
        let couponsWithCurrentStatus;
        if (hasCoupons) {
            const activeCoupons = coupons.filter(coupon => coupon.publishStatus === "ACTIVE");
            hasActiveCoupons = activeCoupons && activeCoupons.length > 0;

            couponsWithCurrentStatus = coupons.filter(coupon => coupon.publishStatus === this.state.currentCouponsStatus);
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <Typography
                        variant="h5"
                        paragraph>
                        <FormattedMessage
                            id="clubPoints"
                            defaultMessage="Club Points"/>
                    </Typography>

                    <div className={classes.itemWrapper}>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="assignmentAndDisplay"
                                defaultMessage="Assignment and display of points"/>:
                        </Typography>

                        {!clubPoints.active && (
                            <Typography
                                variant="body1"
                                color="error">
                                <FormattedMessage
                                    id="inactive"
                                    defaultMessage="Inactive"/>
                            </Typography>
                        )}

                        {clubPoints.active && (
                            <Typography
                                variant="body1"
                                color="primary">
                                <FormattedMessage
                                    id="active"
                                    defaultMessage="Active"/>
                            </Typography>
                        )}
                    </div>

                    {clubPoints.countedPointsFrom &&
                    <div className={classes.itemWrapper}>

                        {clubPoints.active &&
                        <React.Fragment>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="pointsAreCountedFrom"
                                    defaultMessage="Points are counted from:"/>
                            </Typography>&nbsp;{getFormattedDate(clubPoints.countedPointsFrom)}
                        </React.Fragment>
                        }

                        {!clubPoints.active &&
                        <React.Fragment>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="lastTimePointsWereCountedFrom"
                                    defaultMessage="Last time points were counted from:"/>
                            </Typography>&nbsp;{getFormattedDate(clubPoints.countedPointsFrom)}
                        </React.Fragment>
                        }

                    </div>
                    }

                    <div className={classes.itemWrapper}>
                        <Typography
                            variant="subtitle1"
                            paragraph>
                            <FormattedMessage
                                id="pointsValidFor"
                                defaultMessage={`Points valid for`}/>:
                        </Typography>

                        {clubPoints.monthValidation}

                        <Typography
                            variant="body1"
                            paragraph>
                            &nbsp;
                            <span style={{textTransform: 'lowercase'}}>
                            <FormattedMessage
                                id="months"
                                defaultMessage={`Months`}/>
                            </span>
                        </Typography>
                    </div>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="thisIsHowItsDisplayedToYourMembers"
                            defaultMessage="This is how it’s displayed to your members"/>:
                    </Typography>

                    <DashedWrapper>
                        <Typography variant="body1">
                            {clubPoints.description}
                        </Typography>
                    </DashedWrapper>

                    <Button
                        color="secondary"
                        label={<FormattedMessage
                            id="edit"
                            defaultMessage="Edit"/>}
                        onClick={() => history.push("/points/edit")}
                        className={classes.button}/>

                    {clubPoints.active && (
                        <Button
                            label={<FormattedMessage
                                id="deactivate"
                                defaultMessage="Deactivate"/>}
                            variant="outlined"
                            customColor="red"
                            onClick={this.props.deactivateClubPointsActivation.bind(this, clubPoints.id, chain.id)}/>
                    )}

                    {!clubPoints.active && (
                        <React.Fragment>
                            <Button
                                label={<FormattedMessage
                                    id="activate"
                                    defaultMessage="Activate"/>}
                                className={classes.button}
                                onClick={this.handleOpenClubPointsActivation}
                                disabled={!hasActiveCoupons}
                                data-tip
                                data-for='beforeActivateClubPoints'/>

                            {!hasActiveCoupons && (
                                <ReactTooltip
                                    id='beforeActivateClubPoints'
                                    type='info'>
                                    <FormattedMessage
                                        id="beforeActivateClubPoints"
                                        defaultMessage="You have to create your first reward before you can activate Club Points."/>
                                </ReactTooltip>
                            )}
                        </React.Fragment>
                    )}

                    <Dialog
                        onClose={this.handleCancelClubPointsActivation}
                        open={this.state.openClubPointsActivation}
                        content={
                            <ClubPointsActivationForm
                                cancelDialog={this.handleCancelClubPointsActivation}
                                cancelButton={true}
                                wasAlreadyActivated={!!clubPoints.countedPointsFrom}
                                enableReinitialize={true}
                                initialValues={{
                                    clubPoints,
                                    'countedPointsFrom': clubPoints.countedPointsFrom ? clubPoints.countedPointsFrom : this.state.currentDate
                                }}
                                onSubmit={(this.activateClubPointsActivation.bind(this, clubPoints.id, chain.id))}/>
                        }/>

                    <div>
                        <Select
                            value={this.state.currentCouponsStatus}
                            options={{"ACTIVE": "ACTIVE", "INACTIVE": "INACTIVE", "DRAFT": "DRAFT"}}
                            onChange={this.onChangeCouponsStatus.bind(this)}>
                            <MenuItem value={"ACTIVE"}>
                                <FormattedMessage
                                    id="activeRewards"
                                    defaultMessage="Active Rewards"/>
                            </MenuItem>
                            <MenuItem value={"INACTIVE"}>
                                <FormattedMessage
                                    id="inactiveRewards"
                                    defaultMessage="Inactive Rewards"/>
                            </MenuItem>
                            <MenuItem value={"DRAFT"}>
                                <FormattedMessage
                                    id="draftRewards"
                                    defaultMessage="Draft Rewards"/>
                            </MenuItem>
                        </Select>

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => history.push("/coupons/create")}
                            label={<FormattedMessage
                                id="addReward"
                                defaultMessage="Add Reward"/>}/>
                    </div>

                    {couponsWithCurrentStatus
                    && couponsWithCurrentStatus.length > 0 && (
                        <ImageBoxRow
                            options={couponsWithCurrentStatus}
                            coupon={true}
                            editable={true}
                            history={history}/>

                    )}
                </Paper>
            </Container>
        )

    }

    activateClubPointsActivation(clubPointsId, chainId, values) {
        this.props.activateClubPointsActivation(clubPointsId, chainId, values);
        this.setState({openClubPointsActivation: false});
    }
}

function mapStateToProps(state) {
    return {
        clubPoints: state.points.clubPoints,
        coupons: state.points.coupons,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchClubPoints,
        fetchCoupons,
        deactivateClubPointsActivation,
        activateClubPointsActivation
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ClubPoints));
