import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import RenderTextField from "../../../../common/components/common/RenderTextField";

class StampcardFormPage6 extends React.Component {

    static propTypes = {
        stampcard: PropTypes.object,
        isFirstStampcard: PropTypes.bool,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    render() {
        const {handleSubmit, stampcard: {internalName}, isFirstStampcard, submitting} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="provideInternalName"
                        defaultMessage="Provide an internal name for the stamp card"/>
                </Typography>

                <Field
                    name="internalName"
                    hintText={<FormattedMessage
                        id="internalName"
                        defaultMessage="Internal name"/>}
                    component={RenderTextField}/>

                <Button
                    label={isFirstStampcard
                        ? <FormattedMessage
                            id="next"
                            defaultMessage="Next"/>
                        : <FormattedMessage
                            id="save"
                            defaultMessage="Save"/>}
                    type="submit"
                    disabled={!internalName || submitting}/>
            </form>
        );
    }

}

export default reduxForm({
    form: "stampcardForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StampcardFormPage6);
