import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import RenderTextField from "../../../../common/components/common/RenderTextField";
import {stampcardTitleValidation} from "../../../../common/utils/karming-validators";

const validate = values => {
    return stampcardTitleValidation(
        {name: "subtitle", value: values.subtitle},
        56,
        3
    )
};

const styles = {
    itemTitle: {
        fontWeight: 'bold'
    }
};

class StampcardFormPage4 extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        stampcard: PropTypes.object,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {classes, handleSubmit, stampcard: {stampsAmount, monthValidation, subtitle}} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="subtitle1">
                    <React.Fragment>
                        <FormattedMessage
                            id="doYouWantToAddSubtitle"
                            defaultMessage="Do you want to add a subtitle"/>
                        ?
                    </React.Fragment>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="stampcardFormPage4.longText"
                        defaultMessage={`You can add a subtitle such as describes when the stamp card applies and any restrictions on the reward.`}/>
                </Typography>

                <Typography variant="body1">
                    <span className={classes.itemTitle}>
                        <FormattedMessage
                            id="example"
                            defaultMessage="Example"/>: </span>
                    <FormattedMessage
                        id="stampcardFormPage4.example"
                        defaultMessage="Stamp card is available when purchasing X and is valid for {monthValidation} months. When you collect {stampsAmount} stamps, we invite you to the next X."
                        values={{
                            stampsAmount: stampsAmount,
                            monthValidation: monthValidation
                        }}/>
                </Typography>

                <Field
                    name="subtitle"
                    hintText={<FormattedMessage
                        id="subtitle"
                        defaultMessage="Subtitle"/>}
                    component={RenderTextField}
                    multiline={true}
                    rows={3}
                    rowsMax={3}
                    type="text"
                    helperText={<FormattedMessage
                        id="provideMaximumCharactersPerTitleRow3"
                        defaultMessage={`Provide maximum {numberOfCharacters} characters per title row. Create an extra row by hitting “Enter”.`}
                        values={{
                            numberOfCharacters: 56
                        }}
                    />}/>

                {subtitle && (
                    <Button
                        label={<FormattedMessage
                            id="next"
                            defaultMessage="Next"/>}
                        type="submit"/>
                )}

                {!subtitle && (
                    <Button
                        variant="outlined"
                        label={<FormattedMessage
                            id="skip"
                            defaultMessage="Skip"/>}
                        type="submit"/>
                )}
            </form>
        )
    }

}

export default reduxForm({
    form: "stampcardForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    touchOnChange: true,
    validate
})(withStyles(styles)(StampcardFormPage4));
