import React from "react";
import classnames from "classnames";

import {makeStyles} from "@material-ui/styles";
import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';
import Close from "@material-ui/icons/Close";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles({
    inlineRadioGroup: {
        display: 'inline-table'
    },
    columnWrapper: {
        textAlign: 'left'
    }
});

const RenderRadioButtonsGroup = props => {
    const {input, direction, disabled, values, optionsInline, clearable, ...rest} = props;
    const classes = useStyles();

    return (
        <div className={classnames(direction === "column" && classes.columnWrapper)}>
            <RadioGroup
                {...input}
                {...rest}
                className={classnames(optionsInline && classes.inlineRadioGroup)}>
                {values.map((value, index) =>
                    <FormControlLabel
                        key={index}
                        value={value.value}
                        control={<Radio color="secondary"/>}
                        disabled={disabled}
                        label={value.label}/>)}
            </RadioGroup>

            {clearable && (
                <IconButton
                    onClick={() => {
                        input.onChange(null)
                    }}>
                    <Close/>
                </IconButton>
            )}
        </div>
    )
};

export default RenderRadioButtonsGroup
