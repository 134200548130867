import React from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';

import RenderColorPicker from "./RenderColorPicker";
import RenderFontPicker from "./RenderFontPicker";

import FontSize from "../../../common/utils/FontSize";
import {DEFAULT_FONT_FAMILIES, DEFAULT_FONT_SIZE} from "../../../common/constants";
import {colorBlack} from "../../../common/utils/color-const";

const defaultValues = {
    text: '',
    textColor: colorBlack,
    textSize: DEFAULT_FONT_SIZE,
    textFontFamily: DEFAULT_FONT_FAMILIES[0]
};

const styles = {
    textItem: {
        margin: 0,
        width: '100%'
    },
    textField: {
        width: '100%'
    }
};

class FormattedTextBuilder extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        value: PropTypes.shape({
            text: PropTypes.string,
            textColor: PropTypes.string,
            textSize: PropTypes.instanceOf(FontSize),
            textFontFamily: PropTypes.string,
        }),
        onChange: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = this.parseValues(this.props.value);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps) {
            this.setState(this.parseValues(nextProps.value));
        }
    }

    parseValues(values) {
        const parsed = Object.assign(defaultValues, values);
        return {
            text: parsed.text,
            textColor: parsed.textColor,
            textSize: parsed.textSize,
            textFontFamily: parsed.textFontFamily
        }
    }

    onColorChanged(color) {
        this.setState({
            textColor: color
        });

        const outputState = {
            text: this.state.text,
            textColor: color,
            textSize: this.state.textSize,
            textFontFamily: this.state.textFontFamily,
        };
        this.notifyChanged(outputState);
    }

    onFontChanged(font) {
        this.setState({
            textFontFamily: font.fontFamily,
            textSize: font.fontSize,
        });

        const outputState = {
            text: this.state.text,
            textColor: this.state.textColor,
            textSize: font.fontSize,
            textFontFamily: font.fontFamily,
        };
        this.notifyChanged(outputState);
    }

    onTextChanged(event) {
        this.setState({
            text: event.target.value
        });

        const outputState = {
            text: event.target.value,
            textColor: this.state.textColor,
            textSize: this.state.textSize,
            textFontFamily: this.state.textFontFamily,
        };
        this.notifyChanged(outputState);
    }

    notifyChanged(value) {
        const {onChange} = this.props;
        if (onChange) {
            onChange(value)
        }
    }

    render() {
        const {classes} = this.props;

        return (
            <table className={classes.textItem}>
                <tbody>
                <tr valign="middle">
                    <td>
                        <RenderColorPicker
                            color={this.state.textColor}
                            onChange={this.onColorChanged.bind(this)}/>
                    </td>

                    <td>
                        <RenderFontPicker
                            font={{
                                fontFamily: this.state.textFontFamily,
                                fontSize: this.state.textSize,
                            }}
                            onChange={this.onFontChanged.bind(this)}/>
                    </td>

                    <td className={classes.textField}>
                        <TextField
                            fullWidth
                            className={classes.textField}
                            value={this.state.text}
                            type='text'
                            onChange={this.onTextChanged.bind(this)}/>
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }

}

export default withStyles(styles)(FormattedTextBuilder);
