import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {Field, reduxForm} from "redux-form";

import withStyles from "@material-ui/core/styles/withStyles";
import Search from '@material-ui/icons/Search';

import RenderDatePicker from "../../../../common/components/common/form/RenderDatePicker";
import Button from "../../../../common/components/common/Button";

const styles = theme => ({
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1)
        }
    },
});

class SmsUsageGrid extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        pristine: PropTypes.bool,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func
    };

    render() {
        const {pristine, classes, submitting, handleSubmit} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        clearable
                        disableKeyboard
                        name="dateFrom"
                        component={RenderDatePicker}
                        hintText={
                            <React.Fragment>
                                <FormattedMessage
                                    id="from"
                                    defaultMessage="From">
                                </FormattedMessage>
                            </React.Fragment>
                        }/>

                    <Field
                        clearable
                        disableKeyboard
                        name="dateTo"
                        component={RenderDatePicker}
                        endOfDay={true}
                        hintText={
                            <React.Fragment>
                                <FormattedMessage
                                    id="to"
                                    defaultMessage="To">
                                </FormattedMessage>
                            </React.Fragment>
                        }/>
                </div>

                <Button
                    label={<FormattedMessage
                        id="find"
                        defaultMessage="Find"/>}
                    icon={<Search/>}
                    type="submit"
                    disabled={pristine || submitting}/>
            </form>
        )
    }

}

export default reduxForm({form: 'smsHistoryForm'})(withStyles(styles)(SmsUsageGrid))
