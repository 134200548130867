import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import MerchantOnboardingForm from "./MerchantOnboardingVerificationForm";
import Spinner from "../../../common/components/common/Spinner";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {fetchCompanyWithoutAuth} from "../../../common/actions/public-actions";
import {fetchUserByConfirmationId, onboardingVerification} from "../../../common/actions/onboarding-actions";

class MerchantOnboardingVerification extends React.Component {

    static propTypes = {
        profile: PropTypes.object,
        company: PropTypes.object,
        app: PropTypes.object,
        auth: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        fetchCompanyWithoutAuth: PropTypes.func,
        fetchUserByConfirmationId: PropTypes.func,
        onboardingVerification: PropTypes.func
    };

    componentDidMount() {
        const {auth, history} = this.props;
        if (auth && auth.authenticated && history) {
            history.push(`/`);
        }

        this.props.fetchCompanyWithoutAuth(this.props.match.params.companyId);
        this.props.fetchUserByConfirmationId(this.props.match.params.id);
    }

    createAndRedirectToLogin(email, history, values) { // FIXME
        this.props.onboardingVerification(this.props.match.params.id, {...values, 'username': email}, history);
    }

    render() {
        const {profile, company, app, history} = this.props;

        if (app && app.status.isError) {
            history.push("/");
            return null;
        }

        if (!profile || !company) {
            return <Spinner app={app}/>
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <MerchantOnboardingForm
                        onSubmit={this.createAndRedirectToLogin.bind(this, profile.email, history)}
                        profile={profile}
                        company={company}/>
                </Paper>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        app: state.app,
        profile: state.user.profile,
        company: state.company.company
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        onboardingVerification,
        fetchUserByConfirmationId,
        fetchCompanyWithoutAuth,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MerchantOnboardingVerification);
