import React from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import {AmountDifference} from "./AmountDifference";

const useStyles = makeStyles(theme => ({
    textBold: {
        fontWeight: 'bold'
    },
    textSpaced: {
        marginLeft: theme.spacing(0.3),
        "& > span": {
            marginLeft: theme.spacing(0.3)
        }
    },
    textGreen: {
        color: theme.palette.green.dark
    }
}));

export const StampcardUsageList = props => {
    const {usages, purchaseIndex} = props;
    const classes = useStyles();
    const totalStampsUsages = usages && usages.reduce((previousPoints, current) =>
        previousPoints + current.allStampsAmount, 0);

    return (
        <React.Fragment>
            <Typography
                variant="subtitle2"
                className={classes.textBold}>
                <FormattedMessage
                    id="stampcard"
                    defaultMessage="Stamp card"/>: {totalStampsUsages}
                <span className={classes.textSpaced}>
                    <FormattedMessage
                        id="stamps"
                        defaultMessage="stamps"/>
                </span>
            </Typography>
            {usages
                .sort((a, b) => b.id - a.id)
                .map((stampcard, stampcardIndex) => (
                        <Typography
                            key={stampcardIndex}
                            variant="subtitle2">
                            {stampcard.stampcardTitle} (id: {stampcard.stampcardId})
                            <span className={classnames(classes.textBold, classes.textSpaced)}>
                                {stampcard.allStampsAmount}
                                <span className={classes.textSpaced}>
                                <FormattedMessage
                                    id="stamps"
                                    defaultMessage="stamps"/>
                            </span>
                        </span>

                            {purchaseIndex !== 0 && (
                                <AmountDifference
                                    current={stampcard.addedStamps}
                                    previous={stampcard.previousStampsAmount}/>
                            )}
                        </Typography>
                    )
                )}
        </React.Fragment>
    );

};

StampcardUsageList.propTypes = {
    usages: PropTypes.array.isRequired
};
