import React from "react";
import {formValueSelector, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import TitleWithBackButton from "../../../../common/components/TitleWithBackButton";

import StampcardFormPage1 from "./StampcardFormPage1";
import StampcardFormPage2 from "./StampcardFormPage2";
import StampcardFormPage3 from "./StampcardFormPage3";
import StampcardFormPage4 from "./StampcardFormPage4";
import StampcardFormPage6 from "./StampcardFormPage6";
import StampcardFormPage5 from "./StampcardFormPage5";
import StampcardFormPage7 from "./StampcardFormPage7";
import StampcardFormPage8 from "./StampcardFormPage8";

import {setFormValue} from "../../../actions/merchant-forms-actions";
import {
    STAMPCARD_HEADER_FONT_SIZE,
    STAMPCARD_HEADER_FONT_SIZE_BIGGER,
    STAMPCARD_HEADER_FONT_SIZE_BIGGEST
} from "../../../../common/components/common/stampcard/StampcardUtils";

export const STAMPCARD_FORM_NAME = 'stampcardForm';

class StampcardForm extends React.Component {

    static propTypes = {
        stampcard: PropTypes.object,
        chain: PropTypes.object,
        pageTitle: PropTypes.object,
        initialValues: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        isFirstStampcard: PropTypes.bool,
        editMode: PropTypes.bool,
        onSubmit: PropTypes.func,
        setFormValue: PropTypes.func
    };

    static defaultProps = {
        pageTitle: <FormattedMessage id="stampcard" defaultMessage="Stamp card"/>
    };

    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.state = {
            page: props.editMode === 'design' ? 3 : 1,
            isTemplate: false
        }
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
    }

    previousPage() {
        this.setState({page: this.state.page - 1})
    }

    firstPage() {
        this.setState({page: 1})
    }

    setIsTemplate(isTemplate) {
        this.setState({isTemplate: isTemplate})
    }

    render() {

        const {isUpdate, onSubmit, initialValues, pageTitle, stampcard, isFirstStampcard, editMode} = this.props;

        const {page} = this.state;

        return (
            <div>
                {page !== 1 && (
                    <TitleWithBackButton
                        title={pageTitle}
                        onBackClick={!(page === 3 && editMode) && this.previousPage}/>
                )}

                {page === 1 && (
                    <StampcardFormPage1
                        pageTitle={pageTitle}
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        onSubmit={this.nextPage}
                        stampsAmount={stampcard.stampsAmount}/>
                )}

                {page === 2 && (
                    <StampcardFormPage2
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        monthValidation={stampcard.monthValidation}
                        onSubmit={this.nextPage}/>
                )}

                {page === 3 && (
                    <StampcardFormPage3
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        onSubmit={this.nextPage}
                        stampcard={stampcard}/>
                )}

                {page === 4 && (
                    <StampcardFormPage4
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        onSubmit={this.nextPage}
                        stampcard={stampcard}/>
                )}

                {page === 5 && (
                    <StampcardFormPage5
                        initialValues={isUpdate ?
                            initialValues
                            : {
                                ...initialValues,
                                titleSize: stampcard.title.length > 20
                                    ? STAMPCARD_HEADER_FONT_SIZE
                                    : stampcard.title.length > 12
                                        ? STAMPCARD_HEADER_FONT_SIZE_BIGGER
                                        : STAMPCARD_HEADER_FONT_SIZE_BIGGEST,
                            }

                        }
                        isUpdate={isUpdate}
                        onSubmit={editMode ? onSubmit : this.nextPage}
                        stampcard={stampcard}
                    />)}

                {page === 6 && (
                    <StampcardFormPage6
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        isFirstStampcard={isFirstStampcard}
                        onSubmit={isFirstStampcard ? this.nextPage : onSubmit}
                        stampcard={stampcard}/>
                )}

                {page === 7 && (
                    <StampcardFormPage7
                        previousPage={this.previousPage}
                        nextPage={this.nextPage}
                        setIsTemplate={this.setIsTemplate.bind(this)}
                        generateTemplate={this.generateTemplate.bind(this)}
                        onSubmit={onSubmit}/>
                )}

                {page === 8 && (
                    <StampcardFormPage8
                        previousPage={this.previousPage}
                        generateTemplate={this.generateTemplate.bind(this)}
                        isTemplate={this.state.isTemplate}
                        onSubmit={onSubmit}/>
                )}
            </div>
        )
    }

    generateTemplate() {
        const chain = this.props.chain;
        this.props.setFormValue("stampcardForm", "termsTitle", "Stämpelkort");
        this.props.setFormValue("stampcardForm", "termsContent", `Som innehavare av stämpelkort kan du samla stämplar i samband med dina köp hos ${chain.commercialName}.\n\nVilka köp som är giltiga att generera en stämpel kan vara avgränsat och framgår av stämpelkortet.\n\nAv stämpelkortet framgår vad som erhålls efter att visst antal stämplar uppnåtts.\n\nStämpelkortets giltighetstid specificeras på stämpelkortet. Giltighetstiden anger hur långt tid från datum att stämpel erhölls som stämpeln är giltig.\n\nFör att erhålla ett fullt stämpelkort krävs att alltså att samtliga angivna antal stämplar erhållits inom giltighetstiden.\n\nFör erhålla vad som framgår enligt stämpelkortet behöver detta begäras av kund inom giltighetstiden för det första stämpeln.\n\nGiltigt medlemsnummer skall uppges och köpet skall registreras som ett stämpelkortsköp i samband med betalning för att stämpel ska registreras.\n\nStämplarna som du har tjänat in är personliga och kan inte överföras på en annan person eller medlem.\n\nDe stämplar du har erhållit vid köp dras från ditt konto om du returnerar köpet.\n\nFelaktigt registrerade stämplar har rätt att återkallas likväl som eventuella förmåner som tillhandahållits till följd av felaktigt registrerade stämplar.\n\nReglerna för stämpelkort kan komma att förändras, vid var tid gällande klippkortsvillkor redovisas på ${chain.url} eller vår hemsida.`
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('stampcardForm');
    const {stampsAmount, monthValidation, title, subtitle, image, internalName, hideTitle, hideSubtitle} = selector(
        state, 'stampsAmount', 'monthValidation', 'title', 'subtitle', 'image', 'internalName', 'hideTitle',
        'hideSubtitle');

    return {
        stampcard: {
            stampsAmount,
            monthValidation,
            title,
            subtitle,
            image,
            internalName,
            hideTitle,
            hideSubtitle
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default reduxForm({
    form: "stampcardForm"
})(connect(mapStateToProps, mapDispatchToProps)(StampcardForm))
