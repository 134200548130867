import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import Spinner from "../../../../common/components/common/Spinner";
import TermsHome from "./common/TermsHome";
import {defaultPrivacyPolicy, fetchPrivacyPolicy, updatePrivacyPolicy} from "../../../actions/merchant-terms-actions";

class PrivacyPolicyHome extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        history: PropTypes.object,
        chain: PropTypes.object,
        terms: PropTypes.object,
        defaultPrivacyPolicy: PropTypes.func,
        fetchPrivacyPolicy: PropTypes.func,
        updatePrivacyPolicy: PropTypes.func
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain ||
            prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchPrivacyPolicy} = this.props;
        if (chain && chain.id) {
            fetchPrivacyPolicy(chain.id);
        }
    }

    render() {
        const {chain, app, terms, updatePrivacyPolicy, defaultPrivacyPolicy} = this.props;

        if (!chain || !chain.id) {
            return <Spinner app={app}/>
        }

        return (
            <TermsHome
                termsPublished={chain.privacyPolicyPublished}
                app={app}
                user={app}
                history={app}
                title={<FormattedMessage
                    id="privacyPolicy"
                    defaultMessage="Privacy Policy"/>}
                chainId={chain.id}
                terms={terms}
                updateTerms={updatePrivacyPolicy.bind(this, chain.id)}
                introInfo={this.renderNoTerms()}
                fetchDefaultTerms={defaultPrivacyPolicy.bind(this, chain.id)}/>
        )
    }

    renderNoTerms() {
        return (
            <React.Fragment>
                <Typography
                    variant="h5"
                    paragraph>
                    <FormattedMessage
                        id="privacyPolicy"
                        defaultMessage="Privacy Policy"/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="privacy-policy-home.privacyPolicyFirstPartText"
                        defaultMessage="Before you invite members, you need to formulate your Privacy policy."/>
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage
                        id="privacy-policy-home.privacyPolicySecondPartText"
                        defaultMessage="The Privacy policy explains for a member enrolling for your program the purpose of you handling their personal information, and how you do so in a secure manner."/>
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage
                        id="privacy-policy-home.privacyPolicyThirdPartText"
                        defaultMessage="Either you formulate your own, or start from the ready-made example based on your data and the functionality of the service."/>
                </Typography>
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    return {
        terms: state.terms.privacyPolicy
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchPrivacyPolicy, updatePrivacyPolicy, defaultPrivacyPolicy}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyHome)
