import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import queryString from 'query-string';
import PropTypes from "prop-types";

import BillingDetailsForm from "./BillingDetailsForm";
import Spinner from "../../../../common/components/common/Spinner";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {fetchBillingDetails, updateBillingDetails} from "../../../actions/merchant-billing-actions";
import {registerCard, removeCard, update3Dsecure} from "../../../actions/merchant-actions";

import {BILLING_DETAILS_URL} from "../../../../common/config/karming-links";

class BillingDetails extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        billingDetails: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object,
        fetchBillingDetails: PropTypes.func,
        update3Dsecure: PropTypes.func,
        updateBillingDetails: PropTypes.func,
        registerCard: PropTypes.func,
        removeCard: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchBillingDetails();
        if (this.props.location.search) {
            const params = queryString.parse(this.props.location.search);
            this.props.update3Dsecure(params, this.props.history, '/billings/details')
        }
    }

    state = {
        isCardLoading: false
    };

    static getDerivedStateFromProps(nextProps) {
        const {apiResult, fetchBillingDetails} = nextProps;

        if (apiResult && apiResult.message && apiResult.message === "REGISTER_CARD_SUCCESS") {
            fetchBillingDetails();
            return ({
                isCardLoading: false,
            });
        }

        return null;
    }

    handleSetLoading() {
        this.setState({isCardLoading: true})
    }

    render() {
        const {app, billingDetails, registerCard, removeCard, user} = this.props;
        const {isCardLoading} = this.state;

        if (!billingDetails || !user || isCardLoading || this.props.location.search) {
            return <Spinner app={app}/>
        }

        const company = user.company;

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <BillingDetailsForm
                        enableReinitialize={true}
                        billingDetails={this.props.billingDetails}
                        registerCard={registerCard}
                        redirectUrl={BILLING_DETAILS_URL}
                        removeCard={removeCard}
                        setLoading={this.handleSetLoading.bind(this)}
                        initialValues={this.prepareInitialValues(billingDetails, company.name)}
                        onSubmit={this.createAndRedirectToHome.bind(this)}>
                    </BillingDetailsForm>
                </Paper>
            </Container>
        )
    }

    createAndRedirectToHome(values) {
        this.props.updateBillingDetails(values);
    }

    prepareInitialValues(billingDetails, companyName) {
        return {
            ...billingDetails,
            "city": billingDetails.billingAddress.city,
            "street": billingDetails.billingAddress.street,
            "zipCode": billingDetails.billingAddress.zipCode,
            "name": companyName,
        }
    }

}

function mapStateToProps(state) {
    return {
        user: state.user.profile,
        billingDetails: state.merchant.billingDetails,
        apiResult: state.app.status
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchBillingDetails,
        updateBillingDetails,
        registerCard,
        removeCard,
        update3Dsecure
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetails);
