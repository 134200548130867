import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import DateRange from "@material-ui/icons/DateRange";
import InsertChart from "@material-ui/icons/InsertChart";
import AttachMoney from "@material-ui/icons/AttachMoney";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Send from "@material-ui/icons/Send";
import Typography from "@material-ui/core/Typography";

import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import DateFilters from "./DateFilters";
import TitleWithBackButton from "../../../common/components/TitleWithBackButton";
import SumFilters from "./SumFilters";
import QuantityFilters from "./QuantityFilters";
import TasteFilters from "./TasteFilters";
import SendoutFilterForm from "./SendoutFilterForm";
import {getDefaultStartAndEndDateForSendoutHistory} from "../../../common/utils/date-utils";

import {filterMembersForCampaign} from "../../actions/merchant-actions";

const Filters = {
    DATE_FILTERS: "DATE_FILTERS",
    SUM_FILTERS: "SUM_FILTERS",
    QUANTITY_FILTERS: "QUANTITY_FILTERS",
    TASTE_FILTERS: "TASTE_FILTERS",
    SENDOUT_FILTERS: "SENDOUT_FILTERS",
};

const styles = theme => ({
    icon: {
        color: theme.palette.secondary.main,
        fontSize: 40
    }
});

class SmartSelectionGrid extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        currentSmartSelection: PropTypes.object,
        chain: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object,
        sendoutHistory: PropTypes.array,
        campaigns: PropTypes.array,
        categories: PropTypes.array,
        campaignId: PropTypes.string,
        isClubPointActive: PropTypes.bool,
        sendout: PropTypes.bool,
        maxPointsBalance: PropTypes.number,
        handleCloseSmartSelection: PropTypes.func,
        filterMembersForCampaign: PropTypes.func
    };

    state = {
        currentFilter: null,
    };

    onFilterClick(filter) {
        this.setState({currentFilter: filter});
    }

    moveBack() {
        if (this.state.currentFilter === null) {
            this.props.history.push(`/campaigns/recipients/${this.props.match.params.id}`)
        }
        this.setState({currentFilter: null});
    }

    render() {
        const {
            currentSmartSelection, campaignId, handleCloseSmartSelection, isClubPointActive, chain, maxPointsBalance,
            sendoutHistory, sendout, classes
        } = this.props;

        const SmartFilters = [
            {
                label: <FormattedMessage id="date" defaultMessage="Date"/>,
                icon: <DateRange className={classes.icon}/>,
                value: Filters.DATE_FILTERS
            },
            {
                label: <FormattedMessage id="sum" defaultMessage="Sum"/>,
                icon: <AttachMoney className={classes.icon}/>,
                value: Filters.SUM_FILTERS
            },
            {
                label: <FormattedMessage id="quantity" defaultMessage="Quantity"/>,
                icon: <InsertChart className={classes.icon}/>,
                value: Filters.QUANTITY_FILTERS
            },
            {
                label: <FormattedMessage id="taste" defaultMessage="Taste"/>,
                icon: <ThumbUp className={classes.icon}/>,
                value: Filters.TASTE_FILTERS
            },
            {
                label: <FormattedMessage id="sendout" defaultMessage="Sendout"/>,
                icon: <Send className={classes.icon}/>,
                value: Filters.SENDOUT_FILTERS
            }
        ];

        return (
            <React.Fragment>
                {!this.state.currentFilter && (
                    <div>
                        <TitleWithBackButton
                            title={<FormattedMessage
                                id="smartSelection"
                                defaultMessage="Smart Selection"/>}
                            onBackClick={handleCloseSmartSelection}/>

                        <Typography
                            variant="body1"
                            paragraph>
                            <FormattedMessage
                                id="chooseWhichTypeOfSelectionYouWantToMake"
                                defaultMessage="Choose which type of selection you want to make"/>:
                        </Typography>

                        <ImageBoxRow
                            options={sendout
                                ? SmartFilters
                                : SmartFilters.filter(filter => filter.value !== Filters.SENDOUT_FILTERS)}
                            filter={true}
                            onFilterClick={this.onFilterClick.bind(this)}
                            squareShape={true}/>
                    </div>
                )}

                {this.renderFilter(
                    currentSmartSelection,
                    campaignId,
                    handleCloseSmartSelection,
                    isClubPointActive,
                    chain,
                    maxPointsBalance,
                    sendoutHistory)}
            </React.Fragment>
        );
    }

    renderFilter(currentSmartSelection, campaignId, handleCloseSmartSelection, isClubPointActive, chain, maxPointsBalance, sendoutHistory) {

        const selectMembersInfo = (
            <React.Fragment>
                <FormattedMessage
                    id="selectMemberWho"
                    defaultMessage="Select members who"/>:
            </React.Fragment>
        );

        switch (this.state.currentFilter) {
            case Filters.DATE_FILTERS:
                return (
                    <DateFilters
                        moveBack={this.moveBack.bind(this)}
                        selectMembersInfo={selectMembersInfo}
                        handleSubmit={this.onSubmit.bind(
                            this,
                            currentSmartSelection,
                            campaignId,
                            handleCloseSmartSelection)}/>);
            case Filters.SUM_FILTERS:
                return (
                    <SumFilters
                        isClubPointActive={isClubPointActive}
                        maxPointsBalance={maxPointsBalance} chain={chain}
                        moveBack={this.moveBack.bind(this)}
                        selectMembersInfo={selectMembersInfo}
                        handleSubmit={this.onSubmit.bind(
                            this,
                            currentSmartSelection,
                            campaignId,
                            handleCloseSmartSelection)}/>
                );
            case Filters.QUANTITY_FILTERS:
                return (
                    <QuantityFilters
                        moveBack={this.moveBack.bind(this)}
                        chain={chain}
                        selectMembersInfo={selectMembersInfo}
                        handleSubmit={this.onSubmit.bind(
                            this,
                            currentSmartSelection,
                            campaignId,
                            handleCloseSmartSelection)}/>
                );
            case Filters.TASTE_FILTERS:
                return (
                    <TasteFilters
                        categories={this.props.categories}
                        campaigns={this.props.campaigns}
                        moveBack={this.moveBack.bind(this)}
                        selectMembersInfo={selectMembersInfo}
                        handleSubmit={this.onSubmit.bind(
                            this,
                            currentSmartSelection,
                            campaignId,
                            handleCloseSmartSelection)}/>
                );
            case Filters.SENDOUT_FILTERS:
                return (
                    <SendoutFilterForm
                        smartSelection={currentSmartSelection}
                        categories={this.props.categories}
                        campaigns={this.props.campaigns}
                        sendoutHistory={sendoutHistory}
                        moveBack={this.moveBack.bind(this)}
                        selectMembersInfo={selectMembersInfo}
                        initialValues={getDefaultStartAndEndDateForSendoutHistory()}
                        onSubmit={this.onSubmit.bind(
                            this,
                            currentSmartSelection,
                            campaignId,
                            handleCloseSmartSelection)}/>
                );
        }
    }

    onSubmit(currentSmartSelection, campaignId, handleCloseSmartSelection, values) {
        delete values.onePurchaseAmount;
        delete values.purchasesTotalAmount;
        delete values.pointsBalance;
        delete values.purchaseQuantity;
        delete values.day;
        delete values.month;
        delete values.year;
        delete values.startDate;
        delete values.endDate;
        this.props.filterMembersForCampaign(currentSmartSelection, campaignId, this.props.sendout, values);
        handleCloseSmartSelection()
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({filterMembersForCampaign}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(SmartSelectionGrid));
