import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import {required, RequiredInfo} from "../../../common/components/common/form/FormUtils";
import Button from "../../../common/components/common/Button";
import RenderTextField from "../../../common/components/common/RenderTextField";
import {UNSIGNED_INTEGER_FORMAT_CONFIG} from "../../utils/formats";

class ClubPointsFormPage1 extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        handleSubmit: PropTypes.func
    };

    render() {
        const {handleSubmit, title} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {title}
                </Typography>

                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="HowLongTimeDoesTheCustomerHaveToCollectPoints"
                        defaultMessage="How long time does the customer have to collect points"/>?
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="TheCustomersPointsIsValidFor"
                        defaultMessage="The customers’ points is valid for"/>:
                </Typography>

                <Field
                    name="monthValidation"
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="months"
                                defaultMessage="Months"/>
                            *
                        </React.Fragment>
                    }
                    helperText={
                        <FormattedMessage
                            id="provideNumberOfMonths"
                            defaultMessage="Provide number of months"/>
                    }
                    component={RenderTextField}
                    type="number"
                    formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}
                    validate={required}/>

                <Button
                    label={<FormattedMessage
                        id="next"
                        defaultMessage="Next"/>}
                    type="submit"/>
                <RequiredInfo/>
            </form>
        );
    }

}

export default reduxForm({
    form: "clubPointsForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(ClubPointsFormPage1);
