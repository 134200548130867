import React from "react";
import {Field, FieldArray, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";
import Undo from '@material-ui/icons/Undo';
import Publish from '@material-ui/icons/Publish';
import SaveAlt from '@material-ui/icons/SaveAlt';

import RenderTextField from "../../../../../common/components/common/RenderTextField";
import Button from "../../../../../common/components/common/Button";
import renderEditParagraph from "./RenderEditParagraph";
import Container from "../../../../../common/components/common/Container";
import Paper from "../../../../../common/components/common/Paper";
import {required} from "../../../../../common/components/common/form/FormUtils";

class TermsForm extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        termsPublished: PropTypes.bool,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        reset: PropTypes.func
    };

    render() {
        const {handleSubmit, title, reset, termsPublished} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Container>
                    <Paper padding>
                        <Typography
                            paragraph
                            variant="h5">
                            {title}
                        </Typography>
                        <Button
                            icon={<Undo/>}
                            label={<FormattedMessage
                                id="undo"
                                defaultMessage="Undo"/>}
                            color="secondary"
                            onClick={reset}/>

                        {this.renderSavePublishButton(termsPublished)}

                        <Field
                            name="title"
                            type="text"
                            component={RenderTextField}
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="title"
                                        defaultMessage="Title"/>
                                    &nbsp;*
                                </React.Fragment>
                            }
                            validate={[required]}/>

                        <FieldArray name="paragraphs" component={renderEditParagraph}/>

                        {this.renderSavePublishButton(termsPublished)}
                    </Paper>
                </Container>
            </form>
        )
    }

    renderSavePublishButton(termsPublished) {
        return (
            <Button
                label={
                    termsPublished
                        ? <FormattedMessage
                            id="publish"
                            defaultMessage="Publish"/>
                        : <FormattedMessage
                            id="save"
                            defaultMessage="Save"/>
                }
                icon={termsPublished ? <Publish/> : <SaveAlt/>}
                type="submit"/>
        )
    }

}

export default reduxForm({
    form: 'termsForm'
})(TermsForm)
