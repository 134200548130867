import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Cake from "@material-ui/icons/Cake";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Typography from "@material-ui/core/Typography";

import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import DatePurchaseFilterForm from "./DatePurchaseFilterForm";
import TitleWithBackButton from "../../../common/components/TitleWithBackButton";
import DateBecameMemberFilterForm from "./DateBecameMemberFilterForm";
import DateBornFilterForm from "./DateBornFilterForm";

const Filters = {
    PURCHASE_FILTER: "PURCHASE_FILTER",
    AGE_FILTER: "AGE_FILTER",
    BECAME_MEMBER_FILTER: "BECAME_MEMBER_FILTER",
};

const styles = theme => ({
    icon: {
        color: theme.palette.secondary.main,
        fontSize: 40
    }
});

class DateFilters extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        selectMembersInfo: PropTypes.object,
        moveBack: PropTypes.func,
        handleSubmit: PropTypes.func
    };

    state = {
        currentFilter: null,
    };

    onFilterClick(filter) {
        this.setState({currentFilter: filter});
    }

    moveBack(moveBack) {
        if (this.state.currentFilter === null) {
            moveBack();
        }
        this.setState({currentFilter: null});
    }

    render() {
        const {classes, moveBack, handleSubmit, selectMembersInfo} = this.props;

        const DateFiltersOptions = [
            {
                label: <FormattedMessage id="purchaseCapital" defaultMessage="Purchase"/>,
                icon: <ShoppingCart className={classes.icon}/>,
                value: Filters.PURCHASE_FILTER
            },
            {
                label: <FormattedMessage id="birthday/age" defaultMessage="Birthday/Age"/>,
                icon: <Cake className={classes.icon}/>,
                value: Filters.AGE_FILTER
            },
            {
                label: <FormattedMessage id="becameMember" defaultMessage="Became Member"/>,
                icon: <AssignmentInd className={classes.icon}/>,
                value: Filters.BECAME_MEMBER_FILTER
            }
        ];

        return (
            <React.Fragment>
                <TitleWithBackButton
                    title={<FormattedMessage
                        id="smartSelection"
                        defaultMessage="Smart Selection"/>}
                    onBackClick={this.moveBack.bind(this, moveBack)}/>

                {!this.state.currentFilter && (
                    <React.Fragment>
                        <Typography
                            variant="body1"
                            paragraph>
                            <FormattedMessage
                                id="chooseWhichDatesYouWantToUse"
                                defaultMessage="Choose which dates you want to use as a selection:"/>:
                        </Typography>

                        <ImageBoxRow
                            options={DateFiltersOptions}
                            onFilterClick={this.onFilterClick.bind(this)}
                            filter
                            squareShape/>
                    </React.Fragment>
                )}

                {this.renderFilter(handleSubmit, selectMembersInfo)}
            </React.Fragment>
        );
    }

    renderFilter(handleSubmit, selectMembersInfo) {
        switch (this.state.currentFilter) {
            case Filters.PURCHASE_FILTER:
                return (
                    <DatePurchaseFilterForm
                        selectMembersInfo={selectMembersInfo}
                        onSubmit={handleSubmit}/>
                );
            case Filters.AGE_FILTER:
                return (
                    <DateBornFilterForm
                        selectMembersInfo={selectMembersInfo}
                        onSubmit={handleSubmit}/>);
            case Filters.BECAME_MEMBER_FILTER:
                return (
                    <DateBecameMemberFilterForm
                        selectMembersInfo={selectMembersInfo}
                        onSubmit={handleSubmit}/>
                );
        }
    }

}

export default withStyles(styles)(DateFilters);
