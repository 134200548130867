import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import SidebarMenu from "./SidebarMenu";
import AppBarMenu from "./AppBarMenu";

import {chooseCurrentChain, chooseCurrentPoS} from "../../actions/merchant-chain-actions";
import RenderOnMobile from "../../../common/components/common/RenderOnMobile";

class MerchantAppMenu extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        auth: PropTypes.object,
        chains: PropTypes.object,
        history: PropTypes.object,
        currentPoS: PropTypes.object,
        logout: PropTypes.func,
        setSidebarVisibility: PropTypes.func,
        setAccountMenuVisibility: PropTypes.func,
        setStartGuideVisibility: PropTypes.func,
        chooseCurrentChain: PropTypes.func,
        chooseCurrentPoS: PropTypes.func
    };

    render() {
        const {
            user, chains, app, auth, currentPoS, logout, setStartGuideVisibility, setSidebarVisibility,
            setAccountMenuVisibility, chooseCurrentChain, chooseCurrentPoS
        } = this.props;

        return (
            <React.Fragment>
                <AppBarMenu
                    user={user}
                    chains={chains}
                    app={app}
                    auth={auth}
                    logout={logout}
                    currentPoS={currentPoS}
                    setSidebarVisibility={setSidebarVisibility}
                    setStartGuideVisibility={setStartGuideVisibility}
                    setAccountMenuVisibility={setAccountMenuVisibility}/>

                <RenderOnMobile>
                    {isMobile => (
                        <SidebarMenu
                            app={app}
                            user={user}
                            chains={chains}
                            auth={auth}
                            currentPoS={currentPoS}
                            onItemClicked={isMobile ? this.toggleSidebarItemClicked.bind(this) : undefined}
                            chooseCurrentChain={chooseCurrentChain}
                            chooseCurrentPoS={chooseCurrentPoS}
                            setSidebarVisibility={setSidebarVisibility}/>
                    )}
                </RenderOnMobile>
            </React.Fragment>
        );
    }

    toggleSidebarItemClicked() {
        const {setStartGuideVisibility, setSidebarVisibility, setAccountMenuVisibility, app} = this.props;
        setSidebarVisibility(!app.sidebarOpen);
        app.sidebarOpen && setStartGuideVisibility(false);
        app.accountMenuOpen && setAccountMenuVisibility(false);
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({chooseCurrentChain, chooseCurrentPoS}, dispatch);
}

export default connect(null, mapDispatchToProps)(withRouter(MerchantAppMenu))
