import axios from "axios";
import {ROOT_URL} from "../../common/config/karming-links";
import {CREATE_CATEGORY, FETCH_CATEGORIES, REACTIVE_CATEGORY, REMOVE_CATEGORY} from "../../common/actions/types";
import {apiError, headerConfig} from "../../common/actions/utils-actions";
import {reset} from "redux-form";

const ENDPOINT_URL = `${ROOT_URL}/merchant`;

export function createCategory(values) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/categories/create`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: CREATE_CATEGORY,
                    payload: response.data
                });
                dispatch(reset('categoriesForm'))
            }).catch(response => {
            dispatch(apiError('Unable to create category', response));
        });
    }
}

export function updateCategory(categoryId, values) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/categories/${categoryId}/update`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: CREATE_CATEGORY,
                    payload: response.data
                });
                dispatch(reset('categoriesForm'));
                dispatch(fetchCategories())
            }).catch(response => {
            dispatch(apiError('Unable to update category', response));
        });
    }
}

export function removeCategory(categoryId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/categories/${categoryId}/remove`, headerConfig())
            .then(response => {
                dispatch({
                    type: REMOVE_CATEGORY,
                    payload: response.data
                });
                dispatch(fetchCategories())
            }).catch(response => {
            dispatch(apiError('Unable to remove category', response));
        });
    }
}

export function reactiveCategory(categoryId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/categories/${categoryId}/reactive`, headerConfig())
            .then(response => {
                dispatch({
                    type: REACTIVE_CATEGORY,
                    payload: response.data
                });
                dispatch(fetchCategories())
            }).catch(response => {
            dispatch(apiError('Unable to reactive category', response));
        });
    }
}

export function fetchCategories() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/categories`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CATEGORIES,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch categories', response));
            });
    }
}
