import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import ReactTooltip from 'react-tooltip';
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";
import RenderTextField from "../../../common/components/common/RenderTextField";
import EmailSendoutPreview from "./EmailSendoutPreview";
import Checkbox from "../../../common/components/common/form/RenderCheckbox";

import {campaignMediumsSelectionAndMessageValidation} from "../../../common/utils/karming-validators";

export const validate = values => {
    return campaignMediumsSelectionAndMessageValidation(
        {name: "email", value: values.email},
        {name: "sms", value: values.sms},
        {name: "smsMessage", value: values.smsMessage}
    )
};

const styles = {
    checkboxWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
};

class SendoutFormPage2 extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        smsAccount: PropTypes.object,
        campaign: PropTypes.object,
        campaigns: PropTypes.array,
        membersWithPhone: PropTypes.array,
        smsMessage: PropTypes.string,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            smsMessageLength: props.smsMessage.length
        };
    }

    render() {
        const {classes, campaign, handleSubmit, previousPage, smsAccount, membersWithPhone} = this.props;

        const disabledSms = !smsAccount || !smsAccount.credits || smsAccount.credits < membersWithPhone;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    <FormattedMessage
                        id="campaignMessage"
                        defaultMessage="Campaign Message"/>
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="sendout-form-page-2.defineTheTextForSMS"
                        defaultMessage="Define the text for your Campaign SMS send-out. Be careful no to disrupt the link to get the most value."/>
                </Typography>

                <div
                    className={classes.checkboxWrapper}
                    data-tip
                    data-for='buyCreditsWarning'>
                    <Field
                        name="sms"
                        label="SMS"
                        type="checkbox"
                        component={Checkbox}
                        disabled={disabledSms}/>
                    {this.renderSmsMessageCounter()}
                </div>

                {disabledSms && (
                    <ReactTooltip id='buyCreditsWarning' type='warning'>
                        <FormattedMessage
                            id="youHaveToBuyCreditsToUseSmsSendout"
                            defaultMessage="You have to buy credits to use sms sendout"/>
                    </ReactTooltip>
                )}

                <Field
                    name="smsMessage"
                    disabled={disabledSms}
                    hintText={<FormattedMessage
                        id="smsMessage"
                        defaultMessage="SMS message">
                    </FormattedMessage>}
                    component={RenderTextField}
                    type="text"
                    multiline={true}
                    rows={1}
                    onChange={(field, value) => {
                        this.setSmsMessageLength(value.length);
                    }}
                    rowsMax={7}/>

                <div className={classes.checkboxWrapper}>
                    <Field
                        name="email"
                        label={<FormattedMessage
                            id="email"
                            defaultMessage="Email"/>}
                        type="checkbox"
                        component={Checkbox}/>
                </div>

                <EmailSendoutPreview
                    campaign={campaign}
                    label={
                        <React.Fragment>
                            <FormattedMessage
                                id="email"
                                defaultMessage="Email"/>
                            &nbsp;
                            <FormattedMessage
                                id="preview"
                                defaultMessage="preview"/>
                        </React.Fragment>
                    }
                    disabled={false}/>

                <Button
                    label={<FormattedMessage
                        id="previous"
                        defaultMessage="Previous"/>}
                    color={'secondary'}
                    onClick={previousPage}/>

                <Button
                    label={<FormattedMessage
                        id="next"
                        defaultMessage="Next"/>}
                    type="submit"/>
            </form>
        );
    }

    renderSmsMessageCounter() {
        const smsMessageLength = this.state.smsMessageLength;
        return (
            <Typography variant="body1" color={smsMessageLength > 155 ? "error" : "inherit"}>
                {smsMessageLength}/155
            </Typography>
        )
    }

    setSmsMessageLength(length) {
        this.setState({smsMessageLength: length});
    }
}

export default reduxForm({
    form: "sendoutForm",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
})(withStyles(styles)(SendoutFormPage2))
