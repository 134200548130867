import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Add from '@material-ui/icons/Add';

import Dialog from './../../../../common/components/common/Dialog';
import Button from '../../../../common/components/common/Button';

export default class NewChainOrPosDialog extends React.Component {

    static propTypes = {
        moveToCreatePointOfSale: PropTypes.func,
        moveToAddCommercialChain: PropTypes.func
    };

    state = {
        open: false,
    };

    moveToCreatePointOfSale = () => {
        this.handleClose();
        this.props.moveToCreatePointOfSale();
    };

    moveToAddCommercialChain = () => {
        this.handleClose();
        this.props.moveToAddCommercialChain();
    };


    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const actions = [
            <Button
                key="cancel"
                label={<FormattedMessage
                    id="cancel"
                    defaultMessage="Cancel"/>}
                onClick={this.handleClose}/>,
            <Button
                key="no"
                label={<FormattedMessage
                    id="no"
                    defaultMessage="No"/>}
                onClick={this.moveToAddCommercialChain}/>,
            <Button
                key="yes"
                label={<FormattedMessage
                    id="yes"
                    defaultMessage="Yes"/>}
                onClick={this.moveToCreatePointOfSale}/>
        ];

        return (
            <React.Fragment>
                <Button
                    icon={<Add/>}
                    label={<FormattedMessage
                        id="add"
                        defaultMessage="Add"/>}
                    onClick={this.handleOpen}/>

                <Dialog
                    onClose={this.handleClose}
                    title={<FormattedMessage
                        id="new-chain-or-pos-dialog.createNewPointOfSale"
                        defaultMessage="Create new Point Of Sale"/>}
                    actions={actions}
                    open={this.state.open}
                    content={<FormattedMessage
                        id="new-chain-or-pos-dialog.newChainOrPosDialogText"
                        defaultMessage={`Does new Point Of Sale share Membership conditions?
                    If no it will create separate Commercial Chain with own Membership conditions`}/>}/>
            </React.Fragment>
        );
    }

}
