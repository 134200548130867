import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";
import RenderTextField from "../../../common/components/common/RenderTextField";

import {required, RequiredInfo} from "../../../common/components/common/form/FormUtils";

import {renderAgreementCheckbox} from "./MerchantOnboarding";
import {passwordValidation, phoneValidation, privacyPolicyValidation} from "../../../common/utils/karming-validators";
import {KARMING_PRIVACY_POLICY_ADDRESS} from "../../../common/config/karming-links";

const validate = values => {
    return Object.assign(
        passwordValidation(
            {name: "password", value: values.password},
            {name: "confirmPassword", value: values.confirmPassword}
        ),
        phoneValidation({name: "phone", value: values.phone}),
        privacyPolicyValidation({name: "privacyPolicy", value: values.privacyPolicy}) //FIXME no if fixed
    );
};

const styles = theme => ({
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1)
        }
    },
});

class MerchantOnboardingVerificationPageOne extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        profile: PropTypes.object,
        company: PropTypes.object,
        submitting: PropTypes.bool,
        isInvitedByMerchant: PropTypes.bool,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {classes, handleSubmit, submitting, profile, company, isInvitedByMerchant} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    <FormattedMessage
                        id="completeRegistration"
                        defaultMessage="Complete registration"/>
                </Typography>

                {this.renderWelcomeMessage(company)}

                <Field
                    name="password"
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="password"
                                defaultMessage="Password"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    component={RenderTextField}
                    type="password"
                    validate={required}/>

                <Field
                    name="confirmPassword"
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="confirmPassword"
                                defaultMessage="Confirm password"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    component={RenderTextField}
                    type="password"
                    validate={required}/>

                {isInvitedByMerchant && (
                    <div className={classes.doubleFieldsWrapper}>
                        <Field
                            name="firstName"
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="firstName"
                                        defaultMessage="First name"/>
                                    &nbsp;*
                                </React.Fragment>
                            }
                            component={RenderTextField}
                            type="text"
                            validate={[required]}/>

                        <Field
                            name="lastName"
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="lastName"
                                        defaultMessage="Last name"/>
                                    &nbsp;*
                                </React.Fragment>
                            }
                            component={RenderTextField}
                            type="text"
                            validate={[required]}/>
                    </div>
                )}

                {isInvitedByMerchant && (
                    <Field
                        name="phone"
                        hintText={
                            <FormattedMessage
                                id="phone"
                                defaultMessage="Phone"/>
                        }
                        component={RenderTextField}
                        type="mobile"/>
                )}

                {this.renderPrivacyPolicyCheckboxes(profile)}

                <Button
                    label={<FormattedMessage
                        id="submit"
                        defaultMessage="Submit"/>}
                    type="submit"
                    disabled={submitting}/>

                <RequiredInfo/>
            </form>
        );
    }

    renderWelcomeMessage(company) {
        return (
            <React.Fragment>
                <Typography variant="body1">
                    <FormattedMessage
                        id="hi"
                        defaultMessage="Hi"/>
                    !&nbsp;
                </Typography>

                <Typography variant="body1">
                    <FormattedMessage
                        id="merchant-onboarding-verification-page-one.emailConfirmedText"
                        defaultMessage="Your e-mail has been confirmed!"/>
                </Typography>

                <Typography
                    variant="body1"
                    paragraph>
                    <FormattedMessage
                        id="merchant-onboarding-verification-page-one.adminPasswordText"
                        defaultMessage="Choose your password for!"/> {company.name}.
                </Typography>
            </React.Fragment>
        )
    }

    renderPrivacyPolicyCheckboxes(profile) {
        if (!profile.acceptTermsDate) {
            return renderAgreementCheckbox("privacyPolicy", this.privacyPolicyLabel)
        }
    }

    privacyPolicyLabel = (
        <Typography variant="caption">
            <FormattedMessage
                id="haveReadAndAgreeText"
                defaultMessage="I have read and agree to the"/>
            &nbsp;
            <a
                href={KARMING_PRIVACY_POLICY_ADDRESS}
                target="_blank"
                rel="noopener noreferrer">
                <FormattedMessage
                    id="privacyPolicy"
                    defaultMessage="Privacy Policy"/>*
            </a>
        </Typography>
    )
}

export default reduxForm({
    form: 'merchantOnboardingVerificationForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validate
})(withStyles(styles)(MerchantOnboardingVerificationPageOne));
