import React from "react";
import {formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import CouponFormPage1 from "./CouponFormPage1";
import CouponFormPage2 from "./CouponFormPage2";
import CouponFormPage3 from "./CouponFormPage3";
import CouponFormPage4 from "./CouponFormPage4";
import CouponFormPage5 from "./CouponFormPage5";
import CouponFormPage6 from "./CouponFormPage6";
import CouponFormPage7 from "./CouponFormPage7";

export const COUPON_FORM_NAME = 'couponForm';
const COUPON_FORM_LAST_PAGE = 7;

class CouponForm extends React.Component {

    static propTypes = {
        initialValues: PropTypes.object,
        title: PropTypes.object,
        coupon: PropTypes.object,
        monthValidation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        onSubmit: PropTypes.func,
        handlePublish: PropTypes.func,
        chain: PropTypes.object,
        handleDeactivate: PropTypes.func
    };

    static defaultProps = {
        title: <FormattedMessage
            id="createCoupon"
            defaultMessage="Create Coupon"/>
    };

    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.state = {
            page: (!this.props.initialValues || !this.props.initialValues.imagePath ? 1 : COUPON_FORM_LAST_PAGE)
        }
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
    }

    skipNextPage() {
        this.setState({page: this.state.page + 2})
    }

    previousPage() {
        this.setState({page: this.state.page - 1})
    }

    firstPage() {
        this.setState({page: 1})
    }

    render() {

        const {chain, isUpdate, onSubmit, coupon, initialValues, monthValidation, handlePublish, handleDeactivate, title} = this.props;

        const {page} = this.state;

        return (
            <React.Fragment>
                {page === 1 && (
                    <CouponFormPage1
                        title={title}
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        onSubmit={this.nextPage}/>
                )}

                {page === 2 && (
                    <CouponFormPage2
                        title={title}
                        previousPage={this.previousPage}
                        initialValues={initialValues}
                        onSubmit={this.nextPage}/>
                )}
                {page === 3 && (
                    <CouponFormPage3
                        title={title}
                        monthValidation={monthValidation}
                        initialValues={initialValues}
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}/>
                )}
                {page === 4 && (
                    <CouponFormPage4
                        title={title}
                        isUpdate={isUpdate}
                        monthValidation={monthValidation}
                        initialValues={initialValues}
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}/>
                )}

                {page === 5 && (
                    <CouponFormPage5
                        title={title}
                        initialValues={initialValues}
                        previousPage={this.previousPage}
                        onSubmit={chain.ecrIntegrationEnabled
                            ? this.nextPage
                            : this.skipNextPage.bind(this)}/>
                )}

                {page === 6 && (
                    <CouponFormPage6
                        title={title}
                        initialValues={initialValues}
                        previousPage={this.previousPage}
                        onSubmit={this.nextPage}/>
                )}
                {page === 7 && (
                    <CouponFormPage7
                        title={title}
                        initialValues={initialValues}
                        firstPage={this.firstPage.bind(this)}
                        handlePublish={handlePublish}
                        handleDeactivate={handleDeactivate}
                        coupon={coupon}
                        onSubmit={onSubmit}
                        history={history}
                    />
                )}
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('couponForm');
    const {name, image, description, pointsRequired, fixedValue, rewardFixedValue} = selector(state, 'name', 'image',
        'description', 'pointsRequired', 'fixedValue', 'rewardFixedValue');

    return {
        coupon: {
            name,
            image,
            description,
            pointsRequired,
            fixedValue,
            rewardFixedValue
        },

        chain: state.chains.current
    }
}

export default reduxForm({
    form: "couponForm"
})(connect(mapStateToProps, null)(CouponForm))
