import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Clear from '@material-ui/icons/Clear';
import Crop from '@material-ui/icons/Crop';

import Dialog from '../../../common/components/common/Dialog'
import Button from '../../../common/components/common/Button';

import {appTheme} from "../../../common/utils/themes";

const styles = {
    cropper: {
        touchAction: 'none'
    },
    image: {
        backgroundColor: appTheme.palette.common.white,
        maxHeight: '60vh'
    }
};

class RenderImageCropper extends React.Component {

    static propTypes = {
        initialCropping: PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            width: PropTypes.number,
            height: PropTypes.number
        }),
        sourceImage: PropTypes.string,
        imageOutputRatio: PropTypes.number,
        onImageCropped: PropTypes.func,
        cancelEnabled: PropTypes.bool,
        autoOpen: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            startCrop: this.createCropFromProperties(),
            currentCrop: this.createCropFromProperties()
        }
    }

    componentDidMount() {
        if (this.props.autoOpen) {
            this.onOpen();
        }
    }

    createCropFromProperties() {
        return {
            x: this.props.initialCropping && this.props.initialCropping.x,
            y: this.props.initialCropping && this.props.initialCropping.y,
            width: this.props.initialCropping && this.props.initialCropping.width,
            height: this.props.initialCropping && this.props.initialCropping.height,
            aspect: this.props.imageOutputRatio || 1
        };
    }

    onOpen() {
        this.setState({
            isOpen: true,
            startCrop: this.state.currentCrop
        })
    }

    onCancelChanges() {
        this.setState({
            isOpen: false,
            currentCrop: this.state.startCrop
        });
    }

    onCropChanged(crop) {
        this.setState({
            currentCrop: crop
        })
    }

    onCropAccept() {
        this.setState({
            isOpen: false
        });
        const {onImageCropped} = this.props;
        if (onImageCropped) {
            onImageCropped(this.state.currentCrop);
        }
    }

    // FIXME adjust cropping
    computeCropIfNotPresent(image) {
        if (!(this.state.currentCrop
                && this.state.currentCrop.x >= 0
                && this.state.currentCrop.y >= 0
                && this.state.currentCrop.width >= 0
                && this.state.currentCrop.height >= 0)) {
            const ratio = this.props.imageOutputRatio || 1;

            let autoSizeX, autoSizeY;
            if (image.naturalWidth === image.naturalHeight
                || image.naturalWidth >= (image.naturalHeight / ratio)) {
                autoSizeX = image.naturalHeight * ratio;
                autoSizeY = image.naturalHeight;
            } else {
                autoSizeX = image.naturalWidth;
                autoSizeY = image.naturalWidth / ratio;
            }

            const autoWidth = autoSizeX / image.naturalWidth;
            const autoHeight = autoSizeY / image.naturalHeight;
            const autoX = (1 - autoWidth) * 0.5;
            const autoY = (1 - autoHeight) * 0.5;

            const autoCrop = {
                aspect: ratio,
                x: autoX * 100,
                y: autoY * 100,
                width: autoWidth * 100,
                height: autoHeight * 100
            };

            this.setState({
                currentCrop: autoCrop
            });
        }
    }

    render() {
        const actions = [];
        if (this.props.cancelEnabled) {
            actions.push(
                <Button
                    key="undo"
                    customColor="red"
                    label={<FormattedMessage
                        id="undo"
                        defaultMessage="Undo"/>}
                    icon={<Clear/>}
                    onClick={this.onCancelChanges.bind(this)}/>
            );
        }
        actions.push(
            <Button
                key="confirm"
                customColor="green"
                label={<FormattedMessage
                    id="confirm"
                    defaultMessage="Confirm"/>}
                icon={<Crop/>}
                onClick={this.onCropAccept.bind(this)}/>);

        return (
            <React.Fragment>
                <Button
                    customColor="yellow"
                    label={<FormattedMessage
                        id="crop"
                        defaultMessage="Crop"/>}
                    icon={<Crop/>}
                    onClick={this.onOpen.bind(this)}/>

                <Dialog
                    title={<FormattedMessage
                        id="cropImage"
                        defaultMessage="Crop image"/>}
                    open={this.state.isOpen}
                    actions={actions}
                    content={
                        <ReactCrop
                            src={this.props.sourceImage}
                            crop={this.state.currentCrop}
                            imageStyle={styles.image}
                            style={styles.cropper}
                            keepSelection={true}
                            onChange={this.onCropChanged.bind(this)}
                            onImageLoaded={this.computeCropIfNotPresent.bind(this)}/>
                    }/>
            </React.Fragment>
        );
    }

}

export default RenderImageCropper;
