import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import Dropzone from "react-dropzone";

import {withStyles} from "@material-ui/core/styles";
import {GridList, GridListTile} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';

import Button from "../../../common/components/common/Button";
import Dialog from "../../../common/components/common/Dialog";

import {S3_IMAGES_URL} from "../../../common/config/karming-links";

const STAMPS = [
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_coffee_black.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_coffee_white.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_hamburger.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_pizza.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_star_black.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_star_white.png',
];

const STAMPS_PW = [
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_coffee_black_pw.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_coffee_white_pw.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_hamburger_pw.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_pizza_pw.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_star_black_pw.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp/stamp_star_white_pw.png'
];

const STAMPS_UNCOLLECTED = [
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_black_full_circle.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_circle_black.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_circle_white.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_coffee_white.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_dark_shadow.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_light_shadow.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_white_full_circle.png',
];

const STAMPS_UNCOLLECTED_PW = [
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_black_full_circle_pw.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_circle_black_pw.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_circle_white_pw.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_coffee_white_pw.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_dark_shadow_pw.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_light_shadow_pw.png',
    S3_IMAGES_URL + '/templates/Stampcard/stamp_background/bg_white_full_circle_pw.jpg'
];


const STAMPS_SET = [
    {
        preview: STAMPS_PW[0],
        image: STAMPS[0],
    },
    {
        preview: STAMPS_PW[1],
        image: STAMPS[1],
    },
    {
        preview: STAMPS_PW[2],
        image: STAMPS[2],
    }, {
        preview: STAMPS_PW[3],
        image: STAMPS[3],
    }, {
        preview: STAMPS_PW[4],
        image: STAMPS[4],
    }, {
        preview: STAMPS_PW[5],
        image: STAMPS[5],
    },

];

const STAMPS_UNCOLLECTED_SET = [
    {
        preview: STAMPS_UNCOLLECTED_PW[0],
        image: STAMPS_UNCOLLECTED[0],
    },
    {
        preview: STAMPS_UNCOLLECTED_PW[1],
        image: STAMPS_UNCOLLECTED[1],
    },
    {
        preview: STAMPS_UNCOLLECTED_PW[2],
        image: STAMPS_UNCOLLECTED[2],
    }, {
        preview: STAMPS_UNCOLLECTED_PW[3],
        image: STAMPS_UNCOLLECTED[3],
    }, {
        preview: STAMPS_UNCOLLECTED_PW[4],
        image: STAMPS_UNCOLLECTED[4],
    }, {
        preview: STAMPS_UNCOLLECTED_PW[5],
        image: STAMPS_UNCOLLECTED[5],
    }, {
        preview: STAMPS_UNCOLLECTED_PW[6],
        image: STAMPS_UNCOLLECTED[6],
    },
];

const styles = theme => ({
    uploadDropzone: {
        border: 'none'
    },
    button: {
        width: 160
    },
    buttonsWrapper: {
        width: 300,
        display: 'flex',
        margin: '0 auto'
    },
    gridList: {
        overflowY: '-webkit-paged-x',
        color: theme.palette.common.white
    }
});

class StampPicker extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        buttonLabel: PropTypes.object,
        imagePaths: PropTypes.arrayOf(PropTypes.string),
        onStampSelected: PropTypes.func,
        onStampUncollectedSelected: PropTypes.func,
        handleOpen: PropTypes.func,
        handleClose: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            stampUploadOptionOpen: false,
            stampTemplateOpen: false,
            stampBackgroundOptionOpen: false,
            stampBackgroundTemplateOpen: false,
        }
    }

    onOpenStampUploadOption(event) {
        if (event) {
            event.stopPropagation();
        }
        this.setState({
            stampUploadOptionOpen: true
        });
        this.props.handleOpen();
    }

    onCloseStampUploadOption() {
        this.setState({
            stampUploadOptionOpen: false
        });
        this.props.handleClose();
    }

    onStampSelected(imagePath) {
        this.handleCloseStampTemplate();
        if (this.props.onStampSelected) {
            this.props.onStampSelected(imagePath);
        }
    }

    onStampUncollectedSelected(imagePath) {
        this.handleCloseStampBackgroundTemplate();
        if (this.props.onStampUncollectedSelected) {
            this.props.onStampUncollectedSelected(imagePath);
        }
    }

    handleSelectStampTemplateOption() {
        this.setState({
            stampUploadOptionOpen: false,
            stampTemplateOpen: true
        })
    }

    handleCloseStampTemplate() {
        this.setState({
            stampTemplateOpen: false
        })
    }

    onOpenStampBackgroundUploadOption(event) {
        if (event) {
            event.stopPropagation();
        }
        this.setState({
            stampBackgroundUploadOptionOpen: true
        });
        this.props.handleOpen();
    }

    onCloseStampBackgroundUploadOption() {
        this.setState({
            stampBackgroundUploadOptionOpen: false
        });
        this.props.handleClose();
    }

    handleSelectStampBackgroundTemplateOption() {
        this.setState({
            stampBackgroundUploadOptionOpen: false,
            stampBackgroundTemplateOpen: true
        })
    }


    handleCloseStampBackgroundTemplate() {
        this.setState({
            stampBackgroundTemplateOpen: false
        })
    }

    render() {
        const {classes, onStampSelected, onStampUncollectedSelected} = this.props;
        const {stampUploadOptionOpen, stampTemplateOpen, stampBackgroundUploadOptionOpen, stampBackgroundTemplateOpen} = this.state;

        const dropzoneRef = React.createRef();
        const dropzoneRef2 = React.createRef();

        const stampUploadActions = [
            <Dropzone
                key="uploadImage"
                multiple={false}
                ref={dropzoneRef}
                onDrop={files => {
                    this.setState({stampUploadOptionOpen: false});
                    onStampSelected(files[0]);
                }}
                disableClick
                className={classes.uploadDropzone}>
                <Button
                    onClick={() => {
                        dropzoneRef.current.open();
                    }}
                    className={classes.button}
                    color="secondary"
                    label={<FormattedMessage
                        id="uploadYourOwnImage"
                        defaultMessage="Upload your own image"/>}/>
                <br/>
            </Dropzone>,
            <Button
                key="selectTemplate"
                className={classes.button}
                label={
                    <React.Fragment>
                        <FormattedMessage
                            id="select"
                            defaultMessage="Select"/>
                        &nbsp;
                        <FormattedMessage
                            id="template"
                            defaultMessage="template"/>
                    </React.Fragment>
                }
                onClick={this.handleSelectStampTemplateOption.bind(this)}/>
        ];


        const actionsStampTemplate = [
            <Button
                key="cancel"
                label={<FormattedMessage
                    id="cancel"
                    defaultMessage="Cancel"/>}
                onClick={this.handleCloseStampTemplate.bind(this)}
                icon={<Clear/>}
                customColor="red"/>
        ];

        const stampBackgroundUploadActions = [
            <Dropzone
                key="uploadImage"
                multiple={false}
                ref={dropzoneRef2}
                onDrop={files => {
                    this.setState({stampBackgroundUploadOptionOpen: false});
                    onStampUncollectedSelected(files[0]);
                }}
                disableClick
                className={classes.uploadDropzone}>
                <Button
                    onClick={() => {
                        dropzoneRef2.current.open();
                    }}
                    className={classes.button}
                    color="secondary"
                    label={<FormattedMessage
                        id="uploadYourOwnImage"
                        defaultMessage="Upload your own image"/>}/>
            </Dropzone>,
            <Button
                key="selectTemplate"
                className={classes.button}
                label={
                    <React.Fragment>
                        <FormattedMessage
                            id="select"
                            defaultMessage="Select"/>
                        &nbsp;
                        <FormattedMessage
                            id="template"
                            defaultMessage="template"/>
                    </React.Fragment>
                }
                onClick={this.handleSelectStampBackgroundTemplateOption.bind(this)}/>
        ];


        const actionsStampBackgroundTemplate = [
            <Button
                key="cancel"
                label='Cancel'
                customColor="red"
                onClick={this.handleCloseStampBackgroundTemplate.bind(this)}
                icon={<Clear/>}/>
        ];

        return (
            <div className={classes.buttonsWrapper}>
                <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    label={<FormattedMessage
                        id="changeStampsLook"
                        defaultMessage="Change stamps look"/>}
                    onClick={this.onOpenStampUploadOption.bind(this)}/>

                <Button
                    variant="outlined"
                    color="secondary"
                    label={<FormattedMessage
                        id="changeStampsBackground"
                        defaultMessage="Change stamps background"/>}
                    onClick={this.onOpenStampBackgroundUploadOption.bind(this)}/>

                {stampUploadOptionOpen && (
                    <Dialog
                        onClose={this.onCloseStampUploadOption.bind(this)}
                        title={<FormattedMessage
                            id="changeStampsLook"
                            defaultMessage="Change stamps look"/>}
                        content={stampUploadActions}
                        open={stampUploadOptionOpen}/>
                )}

                {stampTemplateOpen && (
                    <Dialog
                        onClose={this.handleCloseStampTemplate.bind(this)}
                        title={
                            <React.Fragment>
                                <FormattedMessage
                                    id="select"
                                    defaultMessage="Select"/>
                                &nbsp;
                                <FormattedMessage
                                    id="image"
                                    defaultMessage="image"/>
                            </React.Fragment>
                        }
                        actions={actionsStampTemplate}
                        open={stampTemplateOpen}
                        content={this.renderImageChooseGrid(false)}/>
                )}

                {stampBackgroundUploadOptionOpen && (
                    <Dialog
                        onClose={this.onCloseStampBackgroundUploadOption.bind(this)}
                        title={<FormattedMessage
                            id="changeStampsBackground"
                            defaultMessage="Change stamps background"/>}
                        content={stampBackgroundUploadActions}
                        open={stampBackgroundUploadOptionOpen}/>
                )}

                {stampBackgroundTemplateOpen && (
                    <Dialog
                        onClose={this.handleCloseStampBackgroundTemplate.bind(this)}
                        title={
                            <React.Fragment>
                                <FormattedMessage
                                    id="select"
                                    defaultMessage="Select"/>
                                &nbsp;
                                <FormattedMessage
                                    id="image"
                                    defaultMessage="image"/>
                            </React.Fragment>
                        }
                        actions={actionsStampBackgroundTemplate}
                        open={stampBackgroundTemplateOpen}
                        content={this.renderImageChooseGrid(true)}/>
                )}
            </div>
        );
    }

    renderImageChooseGrid(stampBackground) {
        const {classes} = this.props;
        const imagesCollection = stampBackground ? STAMPS_UNCOLLECTED_SET : STAMPS_SET;
        return (
            <GridList
                cellHeight={160}
                className={classes.gridList}>
                {imagesCollection.map((stamp) => (
                    <GridListTile key={stamp.image}>
                        <img
                            height="100%"
                            src={stamp.preview}
                            onClick={() => stampBackground
                                ? this.onStampUncollectedSelected(stamp.image)
                                : this.onStampSelected(stamp.image)}/>
                    </GridListTile>
                ))}
            </GridList>
        );
    }

}

export default withStyles(styles)(StampPicker)
