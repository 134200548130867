import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import LoginForm from "./LoginForm";

import {login} from "../../actions/auth-actions";
import {fetchUserDetails} from "../../actions/user-actions";

class Login extends React.Component {

    static propTypes = {
        auth: PropTypes.object,
        match: PropTypes.object,
        history: PropTypes.object,
        login: PropTypes.func,
        fetchUserDetails: PropTypes.func
    };

    redirectAfterLoginUrl() {
        if (this.props.match
            && this.props.match.params
            && this.props.match.params.subdomain) {
            return '/';
        }
        return '';
    }

    loginWithRedirect(history, redirectAfterLoginUrl, values) {
        this.props.login(values, history, redirectAfterLoginUrl);
        this.afterLogin();
    }

    componentDidMount() {
        if (this.props.auth.authenticated) {
            this.props.history.push(this.redirectAfterLoginUrl());
        }
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.auth.authenticated) {
            this.props.history.push(this.redirectAfterLoginUrl());
            this.afterLogin();
        }
    }

    afterLogin() {
        this.props.fetchUserDetails();
    }

    render() {
        return (
            <LoginForm
                onSubmit={this.loginWithRedirect.bind(this, this.props.history, this.redirectAfterLoginUrl())}
                passwordRequestUrl="/auth/password/request"/>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({login, fetchUserDetails}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

