import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import CreditCard from '@material-ui/icons/CreditCard';

import Dialog from './../../../../common/components/common/Dialog';
import CardRegistrationForm from "./CardRegistrationForm";
import Button from "../../../../common/components/common/Button";
import RenderTextField from "../../../../common/components/common/RenderTextField";

import {required} from "../../../../common/components/common/form/FormUtils";

const styles = theme => ({
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1),
        }
    },
    paymentCardWrapper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
        border: `1px solid ${theme.palette.secondary.main}`
    }
});

class BillingDetailsForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        billingDetails: PropTypes.object,
        initialValues: PropTypes.object,
        redirectUrl: PropTypes.string,
        submitting: PropTypes.bool,
        registerCard: PropTypes.func,
        setLoading: PropTypes.func,
        handleSubmit: PropTypes.func,
        removeCard: PropTypes.func
    };

    state = {
        openAddCard: false,
        openRemoveCard: false,
    };

    handleOpenAddCard = () => {
        this.setState({openAddCard: true});
    };

    handleCancelAddCard = () => {
        this.setState({openAddCard: false});
    };

    handleConfirmRemoveCard = () => {
        this.setState({openRemoveCard: false});
        this.props.removeCard(this.props.initialValues.creditCard.id);
    };

    handleOpenRemoveCard = () => {
        this.setState({openRemoveCard: true});
    };

    handleCancelRemoveCard = () => {
        this.setState({openRemoveCard: false});
    };

    render() {
        const {classes, handleSubmit, submitting, initialValues, registerCard, redirectUrl, setLoading} = this.props;

        const actionsForRemoveCard = [
            <Button
                key="cancel"
                label={<FormattedMessage
                    id="cancel"
                    defaultMessage="Cancel"/>}
                color="secondary"
                onClick={this.handleCancelRemoveCard}/>,
            <Button
                key="yes"
                label={<FormattedMessage
                    id="yes"
                    defaultMessage="Yes"/>}
                customColor="red"
                onClick={this.handleConfirmRemoveCard}/>,
        ];

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="h5">
                    <FormattedMessage
                        id="billingDetails"
                        defaultMessage="Billing details"/>
                </Typography>


                {/*FIXME get company name */}
                <Field
                    name="name"
                    component={RenderTextField}
                    type="text"
                    hintText={<FormattedMessage
                        id="companyName"
                        defaultMessage="Company Name"/>}
                    disabled/>

                <Field
                    name="att"
                    component={RenderTextField}
                    type="text"
                    hintText={<FormattedMessage
                        id="att"
                        defaultMessage="ATT"
                        description="Part of billing details"/>}/>

                <Field
                    name="street"
                    component={RenderTextField}
                    type="text"
                    hintText={<FormattedMessage
                        id="address"
                        defaultMessage="Address"/>}
                    validate={required}/>

                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        name="city"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="city"
                            defaultMessage="City"/>}
                        validate={required}/>

                    <Field
                        name="zipCode"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="zipCode"
                            defaultMessage="Zip code"/>}
                        validate={required}/>
                </div>

                <Field
                    name="email"
                    component={RenderTextField}
                    type="email"
                    hintText={<FormattedMessage
                        id="billingEmail"
                        defaultMessage="Billing email"/>}
                    validate={required}/>

                <Field
                    name="reference"
                    component={RenderTextField}
                    type="text"
                    hintText={<FormattedMessage
                        id="reference"
                        defaultMessage="Reference"/>}/>

                {!initialValues.creditCard && (
                    <div className={classes.paymentCardWrapper}>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="paymentCard"
                                defaultMessage="Payment card"/>
                        </Typography>
                        <Button
                            label={<FormattedMessage
                                id="addCard"
                                defaultMessage="Add card"/>}
                            icon={<CreditCard/>}
                            size="small"
                            onClick={this.handleOpenAddCard}/>
                    </div>
                )}

                <Dialog
                    onClose={this.handleCancelAddCard}
                    title={<FormattedMessage
                        id="registerCard"
                        defaultMessage="Register card"/>}
                    open={this.state.openAddCard}
                    content={<CardRegistrationForm
                        setLoading={setLoading}
                        redirectUrl={redirectUrl}
                        registerCard={registerCard}
                        billingDetails={this.props.billingDetails}
                        cancelDialog={this.handleCancelAddCard}
                        cancelButton={true}/>}/>

                {initialValues.creditCard && (
                    <div className={classes.paymentCardWrapper}>
                        <Typography variant="subtitle1">
                            <FormattedMessage
                                id="paymentCard"
                                defaultMessage="Payment card"/>
                        </Typography>
                        <Typography variant="body1">
                            <CreditCard/> {initialValues.creditCard.brand} •••• ••••
                            •••• {initialValues.creditCard.last4Digits}
                        </Typography>
                        <Button
                            label={<FormattedMessage
                                id="removeCard"
                                defaultMessage="Remove card"/>}
                            icon={<Delete/>}
                            customColor="red"
                            size="small"
                            onClick={this.handleOpenRemoveCard}/>
                        <Button
                            label={<FormattedMessage
                                id="changeCard"
                                defaultMessage="Change card"/>}
                            icon={<Edit/>}
                            color="secondary"
                            size="small"
                            onClick={this.handleOpenAddCard}/>

                        <Dialog
                            onClose={this.handleCancelRemoveCard}
                            title={<FormattedMessage
                                id="removeCard"
                                defaultMessage="Remove card"/>}
                            actions={actionsForRemoveCard}
                            open={this.state.openRemoveCard}
                            content={<FormattedMessage
                                id="areYouSureRemoveCardText"
                                defaultMessage="Are you sure you want to remove card?"/>}/>
                    </div>
                )}

                <Button
                    label={<FormattedMessage
                        id="update"
                        defaultMessage="Update"/>}
                    type="submit"
                    disabled={submitting}/>
            </form>
        )
    }
}

export default reduxForm({
    form: 'billingDetailsForm',
})(withStyles(styles)(BillingDetailsForm));
