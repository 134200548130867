import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Add from "@material-ui/icons/Add";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import CardMembership from "@material-ui/icons/CardMembership";
import Typography from "@material-ui/core/Typography";

import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import TitleWithBackButton from "../../../common/components/TitleWithBackButton";
import Button from "../../../common/components/common/Button";
import PurchaseAmountFilterForm from "./PurchaseAmountFilterForm";
import PointsBalanceFilterForm from "./PointsBalanceFilterForm";

const Filters = {
    PURCHASE_AMOUNT_FILTER: "PURCHASE_AMOUNT_FILTER",
    POINTS_BALANCE_FILTER: "POINTS_BALANCE_FILTER",
};

const styles = theme => ({
    icon: {
        color: theme.palette.secondary.main,
        fontSize: 40
    }
});

class SumFilters extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        chain: PropTypes.object,
        selectMembersInfo: PropTypes.object,
        maxPointsBalance: PropTypes.number,
        isClubPointActive: PropTypes.bool,
        moveBack: PropTypes.func,
        handleSubmit: PropTypes.func
    };

    state = {
        currentFilter: null,
    };

    onFilterClick(filter) {
        this.setState({currentFilter: filter});
    }

    moveBack(moveBack) {
        if (this.state.currentFilter === null) {
            moveBack();
        }
        this.setState({currentFilter: null});
    }

    render() {
        const {classes, moveBack, handleSubmit, isClubPointActive, chain, maxPointsBalance, selectMembersInfo} = this.props;

        const SumFiltersOptions = [
            {
                label: <FormattedMessage id="purchaseAmount" defaultMessage="Purchase amount"/>,
                icon: <ShoppingCart className={classes.icon}/>,
                value: Filters.PURCHASE_AMOUNT_FILTER
            },
            {
                label: <FormattedMessage id="pointsBalance" defaultMessage="Points balance"/>,
                icon: <CardMembership className={classes.icon}/>,
                value: Filters.POINTS_BALANCE_FILTER
            }
        ];

        let filterOptions = SumFiltersOptions;

        if (!isClubPointActive) {
            filterOptions = SumFiltersOptions
                .filter(filter => filter.value !== Filters.POINTS_BALANCE_FILTER)
        }

        return (
            <React.Fragment>
                <TitleWithBackButton
                    title={<FormattedMessage
                        id="smartSelection"
                        defaultMessage="Smart Selection"/>}
                    onBackClick={this.moveBack.bind(this, moveBack)}/>

                {!this.state.currentFilter && (
                    <React.Fragment>
                        <Typography
                            variant="body1"
                            paragraph>
                            <FormattedMessage
                                id="chooseWhichSumYouWantToUse"
                                defaultMessage="Choose which sum you want to use as a selection"/>:
                        </Typography>

                        <ImageBoxRow
                            options={filterOptions}
                            filter
                            onFilterClick={this.onFilterClick.bind(this)}
                            squareShape/>
                    </React.Fragment>
                )}

                {this.renderFilter(handleSubmit, chain, maxPointsBalance, selectMembersInfo)}
            </React.Fragment>
        );
    }

    renderFilter(handleSubmit, chain, maxPointsBalance, selectMembersInfo) {
        const addSmartFilterButton = (
            <Button
                icon={<Add/>}
                label={<FormattedMessage
                    id="addSmartSelection"
                    defaultMessage="Add Smart Selection"/>}
                type="submit"/>
        );

        switch (this.state.currentFilter) {
            case Filters.PURCHASE_AMOUNT_FILTER:
                return (
                    <PurchaseAmountFilterForm
                        selectMembersInfo={selectMembersInfo}
                        chain={chain}
                        onSubmit={handleSubmit}/>
                );
            case Filters.POINTS_BALANCE_FILTER:
                return (
                    <PointsBalanceFilterForm
                        button={addSmartFilterButton}
                        selectMembersInfo={selectMembersInfo}
                        maxPointsBalance={maxPointsBalance}
                        onSubmit={handleSubmit}/>
                );
        }
    }

}

export default withStyles(styles)(SumFilters);
