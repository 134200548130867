import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import Save from '@material-ui/icons/Save';
import Photo from '@material-ui/icons/Photo';

import Button from "../../../../../common/components/common/Button";
import {required, RequiredInfo} from "../../../../../common/components/common/form/FormUtils";
import RenderTextField from "../../../../../common/components/common/RenderTextField";
import RenderDropzone from "../../../../../common/components/common/form/ReactDropzone";
import {APP_ICON_FIELD_NAME, LOGO_FIELD_NAME} from "./CommercialChain";

import {
    emailValidation,
    locationPhoneValidation,
    smsSenderNameValidation,
    subdomainValidation,
    URLValidation
} from "../../../../../common/utils/karming-validators";
import RenderSelect from "../../../../../common/components/common/RenderSelect";
import {LANGUAGES, SWEDISH} from "../../../../../common/utils/enums/languages";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "../../../../../common/components/common/form/RenderCheckbox";

const validate = values => {
    return Object.assign(
        emailValidation({name: "email", value: values.email}),
        locationPhoneValidation({name: "phone", value: values.phone}),
        subdomainValidation({name: "subdomain", value: values.subdomain}),
        smsSenderNameValidation({name: "smsSenderName", value: values.smsSenderName}),
        URLValidation({name: "website", value: values.website})
    )
};

const SUBDOMAIN_FIELD_NAME = 'subdomain';

const styles = theme => ({
    subtitle: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center'
    },
    websiteWrapper: {
        position: 'relative',
        display: 'flex',
        "& > :first-child": {
            width: '50%',
            marginRight: theme.spacing(1)
        },
        "& > :last-child": {
            marginTop: theme.spacing(4.8)
        }
    },
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1)
        }
    },
    imageDropzone: {
        verticalAlign: 'center',
        [theme.breakpoints.up("md")]: {
            width: '50%',
            margin: '0 auto'
        },
        "& img": {
           objectFit: 'contain'
        }
    },
    list: {
        textAlign: 'left'
    },
    imagePreview: {
        marginTop: -218,
        marginBottom: 30,
        width: '95%',
        height: '210px !important'
    }
});

class CommercialChainForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        initialValues: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        change: PropTypes.func
    };

    UNSAFE_componentWillMount() {
        const {initialValues} = this.props;
        if (initialValues && initialValues.logoPath) {
            window.URL.revokeObjectURL(initialValues.logoPath)
        }
    }

    componentWillUnmount() {
        const {initialValues} = this.props;
        if (initialValues && initialValues.logoPath) {
            window.URL.revokeObjectURL(initialValues.logoPath)
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            isLogoVisible: true,
            isAppIconVisible: true,
        }
    }

    removeLogoFile() {
        this.setState({
            isLogoVisible: false,
        });
    }

    removeAppIconFile() {
        this.setState({
            isAppIconVisible: false,
        });
    }

    render() {
        const {classes, handleSubmit, submitting, initialValues, isUpdate} = this.props;

        const hasLogo = this.state.isLogoVisible && initialValues && initialValues.logoPath;
        const hasAppIcon = this.state.isAppIconVisible && initialValues && initialValues.appIconPath;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="h5">
                    <FormattedMessage
                        id="commercialChain"
                        defaultMessage="Commercial chain"/>
                </Typography>

                <Field
                    name="commercialName"
                    component={RenderTextField}
                    type="text"
                    onChange={(event, value) => {
                        this.props.change(SUBDOMAIN_FIELD_NAME, value.toLowerCase().replace(/[^A-Z0-9]/ig, ""))
                    }}
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="commercialName"
                                defaultMessage="Commercial name"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    validate={[required]}/>

                <Typography
                    variant="subtitle1"
                    className={classes.subtitle}>
                    <FormattedMessage
                        id="commercial-chain-form.webAddressForMembersClub"
                        defaultMessage="Web address for members club"/>
                    :
                </Typography>

                <div className={classes.websiteWrapper}>
                    <Field
                        name={SUBDOMAIN_FIELD_NAME}
                        component={RenderTextField}
                        type="text"
                        hintText={<React.Fragment>
                            <FormattedMessage
                                id="commercial-chain-form.subdomain"
                                defaultMessage="Subdomain"/>
                            &nbsp;*
                        </React.Fragment>
                        }
                        validate={[required]}/>

                    <Typography variant="body1">
                        .kundklubb.online
                    </Typography>
                </div>

                <Typography
                    variant="subtitle1"
                    className={classes.subtitle}
                    component="div">
                    <Photo/>
                    &nbsp;
                    <FormattedMessage
                        id="logo"
                        defaultMessage="Logo"/>
                    &nbsp;*
                </Typography>


                <div className={classes.imageDropzone}>
                    <Field
                        name={LOGO_FIELD_NAME}
                        component={RenderDropzone}
                        hasImage={hasLogo}
                        type="file"
                        validate={hasLogo ? '' : [required]}/>
                    {this.renderLogo(initialValues)}
                </div>

                {isUpdate &&
                <React.Fragment>
                    <Typography
                        variant="subtitle1"
                        className={classes.subtitle}
                        component="div">
                        <Photo/>
                        &nbsp;
                        <FormattedMessage
                            id="appIcon"
                            defaultMessage="App icon"/>
                        &nbsp;*
                    </Typography>

                    <div className={classes.imageDropzone}>
                        <Field
                            name={APP_ICON_FIELD_NAME}
                            component={RenderDropzone}
                            hasImage={hasAppIcon}
                            type="file"
                            validate={hasAppIcon ? '' : [required]}/>
                        {this.renderAppIcon(initialValues)}
                    </div>
                </React.Fragment>
                }

                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        name="email"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="email"
                            defaultMessage="Email"/>}/>
                    <Field
                        name="phone"
                        component={RenderTextField}
                        type="text"
                        hintText={<FormattedMessage
                            id="phone"
                            defaultMessage="Phone"/>}/>
                </div>

                <Field
                    name="smsSenderName"
                    component={RenderTextField}
                    type="text"
                    helperText={
                        <React.Fragment>
                            <FormattedMessage
                                id="smsSenderNameHint"
                                defaultMessage="3-11 characters (only a-z, A-Z, 0-9, no spaces)"/>
                        </React.Fragment>
                    }
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="smsSenderName"
                                defaultMessage="SMS sender name"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    validate={[required]}/>

                <Field
                    name="website"
                    component={RenderTextField}
                    type="text"
                    hintText={<FormattedMessage
                        id="website"
                        defaultMessage="Website"/>}/>

                <Field
                    name="ecrIntegrationEnabled"
                    label={<FormattedMessage
                        id="ecrIntegrationEnabled"
                        defaultMessage="ECR Integration Enabled"/>}
                    type="checkbox"
                    component={Checkbox}/>

                <Field
                    name="language"
                    label={<FormattedMessage
                        id="defaultMemberLanguage"
                        defaultMessage="Default member language:"/>}
                    component={RenderSelect}
                    defaultValue={initialValues && initialValues.language ? initialValues.language : SWEDISH}
                >
                    {LANGUAGES.map((option, index) => (
                        <MenuItem key={index}
                                  value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))
                    }
                </Field>

                {this.renderPointsOfSale(initialValues)}

                <Button
                    icon={<Save/>}
                    label={<FormattedMessage
                        id="save"
                        defaultMessage="Save"/>}
                    type="submit"
                    disabled={submitting}/>

                <RequiredInfo/>
            </form>
        );
    }

    renderLogo(initialValues) {
        const {classes} = this.props;
        if (this.state.isLogoVisible && initialValues && initialValues.logoPath) {
            const timestamp = new Date().getTime();
            const logoPath = initialValues.logoPath + '?' + timestamp;
            return (
                <React.Fragment>
                    <img className={classes.imagePreview} key={logoPath} src={logoPath}/>
                    <Button
                        icon={<DeleteOutlined/>}
                        label={<FormattedMessage
                            id="removeFile"
                            defaultMessage="Remove file"/>}
                        customColor="red"
                        onClick={this.removeLogoFile.bind(this)}/>
                </React.Fragment>
            )
        }
    }

    renderAppIcon(initialValues) {
        const {classes} = this.props;
        if (this.state.isAppIconVisible && initialValues && initialValues.appIconPath) {
            const timestamp = new Date().getTime();

            const logoPath = initialValues.appIconPath + '?' + timestamp;
            return (
                <React.Fragment>
                    <img className={classes.imagePreview} key={logoPath} src={logoPath}/>
                    <Button
                        icon={<DeleteOutlined/>}
                        label={<FormattedMessage
                            id="removeFile"
                            defaultMessage="Remove file"/>}
                        customColor="red"
                        onClick={this.removeAppIconFile.bind(this)}/>
                </React.Fragment>
            )
        }
    }

    renderPointsOfSale(initialValues) {
        const {classes} = this.props;

        if (initialValues && initialValues.pointsOfSale) {
            return (
                <React.Fragment>
                    <Typography
                        variant="subtitle1"
                        className={classes.subtitle}>
                        <FormattedMessage
                            id=" pointsOfSale"
                            defaultMessage=" Points of sale"/>
                    </Typography>
                    <ul className={classes.list}>
                        {initialValues.pointsOfSale.map((p) =>
                            <li key={p.id}>
                                {p.commercialName} - {p.address.street} {p.address.city} {p.address.zipCode}
                            </li>
                        )}
                    </ul>
                </React.Fragment>
            )
        }
    }

}

export default reduxForm({
    form: 'commercialChainForm',
    validate
})(withStyles(styles)(CommercialChainForm));
