import React from 'react'
import PropTypes from 'prop-types'

import MerchantOnboardingVerificationPageOne from "./MerchantOnboardingVerificationPageOne";
import MerchantOnboardingVerificationPageTwo from "./MerchantOnboardingVerificationPageTwo";


class MerchantOnboardingVerificationForm extends React.Component {

    static propTypes = {
        profile: PropTypes.object,
        company: PropTypes.object,
        app: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.state = {
            page: 1
        }
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
    }

    previousPage() {
        this.setState({page: this.state.page - 1})
    }

    render() {
        const {app, onSubmit, profile, company} = this.props;
        const {page} = this.state;

        const isInvitedByMerchant = "INVITED_BY_MERCHANT" === profile.source;
        const isInvitedByAdmin = "INVITED_BY_ADMIN" === profile.source;
        return (
            <React.Fragment>
                {page === 1 && (
                    <MerchantOnboardingVerificationPageOne
                        onSubmit={isInvitedByMerchant ? onSubmit : this.nextPage}
                        isInvitedByMerchant={isInvitedByMerchant}
                        profile={profile}
                        company={company}/>
                )}
                {page === 2 && (
                    <MerchantOnboardingVerificationPageTwo
                        previousPage={this.previousPage}
                        isInvitedByAdmin={isInvitedByAdmin}
                        app={app}
                        profile={profile}
                        company={company}
                        onSubmit={onSubmit}/>
                )}
            </React.Fragment>
        )
    }
}

MerchantOnboardingVerificationForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

export default MerchantOnboardingVerificationForm
