import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";
import CampaignForm from "./form/CampaignForm";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {fetchCampaigns, updateCampaign, updateCampaignStatus} from "../../actions/merchant-campaign-actions";
import {prepareCampaignFormData} from "../../utils/FormHelper";
import Spinner from "../../../common/components/common/Spinner";

class AttractMembers extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        chain: PropTypes.object,
        app: PropTypes.object,
        welcomeOffer: PropTypes.object,
        fetchCampaigns: PropTypes.func,
        updateCampaign: PropTypes.func,
        updateCampaignStatus: PropTypes.func
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain ||
            prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchCampaigns} = this.props;
        if (chain && chain.id) {
            fetchCampaigns(chain.id, false);
        }
    }

    render() {
        const {app, history, welcomeOffer} = this.props;

        if (welcomeOffer === undefined) {
            return <Spinner app={app}/>
        }

        if (welcomeOffer !== null) {
            return (
                <CampaignForm
                    title={<FormattedMessage
                        id="welcomeOffering"
                        defaultMessage="Welcome offering"/>}
                    onSubmit={this.onSubmit.bind(this, welcomeOffer)}
                    handlePublish={this.onSubmitPublish.bind(this, welcomeOffer)}
                    handleDeactivate={this.onSubmitDeactivate.bind(this, welcomeOffer)}
                    enableReinitialize={true}
                    isWelcomeOffer={true}
                    initialValues={welcomeOffer}
                    isUpdate={true}/>
            )
        }

        return (
            <Container>
                <Paper padding>
                    <Typography
                        paragraph
                        variant="h5">
                        <FormattedMessage
                            id="welcomeOffering"
                            defaultMessage="Welcome offering"/>
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="attract-members.toBecomeAMemberText"
                            defaultMessage="To become a member is beneficial for you and your customers!"/>
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="attract-members.makeSureAsManyAsPossibleEnrollText"
                            defaultMessage="Thus, make sure as many as possible enroll to your membership program!"/>
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="attract-members.WelcomeOfferingIsVisableText"
                            defaultMessage="An Welcome offering is visable for all vistors at your members site, and is available to claim
                        withing defined period of time for any new member that register to your membership programand
                        (t.ex. XXX på köpet)"/>
                    </Typography>

                    <Button
                        label={<FormattedMessage
                            id="activeWelcomeOffering"
                            defaultMessage="Activate Welcome offering"/>}
                        onClick={() => history.push("/campaign/welcome")}/>
                </Paper>
            </Container>
        )
    }

    onSubmit(welcomeOffer, values) {
        let body = prepareCampaignFormData(values);
        this.props.updateCampaign(welcomeOffer.id, body, false)
    }

    onSubmitPublish(welcomeOffer) {
        const shouldPopUpStartGuide = welcomeOffer.publishStatus === "DRAFT";
        this.props.updateCampaignStatus(welcomeOffer.id, "ACTIVE", false, null, false, shouldPopUpStartGuide);
    }

    onSubmitDeactivate(welcomeOffer) {
        this.props.updateCampaignStatus(welcomeOffer.id, "INACTIVE", false)
    }

}

function mapStateToProps(state) {
    return {
        welcomeOffer: state.merchant.welcomeOffer,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCampaigns, updateCampaign, updateCampaignStatus}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AttractMembers);
