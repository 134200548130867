import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

import Button from "../../../../common/components/common/Button";
import Dialog from "../../../../common/components/common/Dialog";
import ReplacedStampcardsList from "./ReplacedStampcardsList";

const useStyles = makeStyles({
    actionsWrapper: {
        justifyContent: 'flex-end'
    }
});

const DeleteReplacedStampcards = props => {
    const {
        replacedStampcards, replacedStampcardsIds, updateReplacedStampcards, removeStampcardFromList,
        stampcardUsages
    } = props;
    const classes = useStyles();
    const [pickedStampcardId, setPickedStampcardId] = React.useState(null);

    useEffect(() => {
        if (pickedStampcardId != null) {
            updateReplacedStampcards(replacedStampcardsIds);
        }
        setPickedStampcardId(null);
    }, [replacedStampcardsIds]);

    return (
        <React.Fragment>
            <Typography
                variant="subtitle1"
                align="left">
                <FormattedMessage
                    id="thisCardDoesNotShowForMembersWithBelowCardActive"
                    defaultMessage="This card does not show for members with below card active"/>:
            </Typography>

            <ReplacedStampcardsList
                stampcardUsages={stampcardUsages}
                replacedStampcards={replacedStampcards}
                replacedStampcardsIds={replacedStampcardsIds}
                onCheckboxChanged={setPickedStampcardId}/>

            <Dialog
                onClose={() => setPickedStampcardId(null)}
                actionsClassName={classes.actionsWrapper}
                open={pickedStampcardId != null}
                title={<FormattedMessage
                    id="areYouSureStampcardUncheckTitle"
                    defaultMessage="Are you sure?"/>}
                actions={[
                    <Button
                        key="cancel"
                        label={<FormattedMessage
                            id="cancel"
                            defaultMessage="Cancel"/>}
                        variant="outlined"
                        customColor="red"
                        onClick={() => setPickedStampcardId(null)}/>,
                    <Button
                        key="confirm"
                        label={<FormattedMessage
                            id="confirm"
                            defaultMessage="Confirm"/>}
                        onClick={() => removeStampcardFromList(pickedStampcardId)}/>
                ]}
                content={
                    <React.Fragment>
                        <Typography
                            variant="body1"
                            align="left"
                            paragraph>
                            <FormattedMessage
                                id="areYouSureStampcardUncheckSubtitle"
                                defaultMessage="Are you sure you want to publish this card also for members
                                        that have started to collect stamps but not completed the card ”{title}”?"
                                values={{
                                    title:
                                        <b>
                                            {replacedStampcards
                                            && pickedStampcardId
                                            && replacedStampcards.find(stampcard => stampcard.id === pickedStampcardId).title}
                                        </b>
                                }}/>
                        </Typography>

                        <Typography
                            variant="body1"
                            align="left"
                            paragraph>
                            <FormattedMessage
                                id="areYouSureStampcardUncheckInfo"
                                defaultMessage="Both cards will be available to use at the same time."/>
                        </Typography>

                        <Typography
                            variant="subtitle1"
                            align="left">
                            <FormattedMessage
                                id="areYouSureStampcardUncheckWarning"
                                defaultMessage="Warning: You can not undo this action!"/>
                        </Typography>
                    </React.Fragment>
                }/>
        </React.Fragment>
    );

};

DeleteReplacedStampcards.propTypes = {
    replacedStampcards: PropTypes.array,
    replacedStampcardsIds: PropTypes.array,
    stampcardUsages: PropTypes.array,
    updateReplacedStampcards: PropTypes.func,
    removeStampcardFromList: PropTypes.func
};

export default DeleteReplacedStampcards
