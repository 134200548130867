import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import MerchantAccessForm from "./MerchantAccessForm";
import Spinner from "../../../common/components/common/Spinner";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {merchantInviteMerchant} from "../../actions/merchant-actions";
import {fetchCommercialChains} from "../../actions/merchant-chain-actions";

class InviteMerchant extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        auth: PropTypes.object,
        user: PropTypes.object,
        app: PropTypes.object,
        chains: PropTypes.array,
        merchantInviteMerchant: PropTypes.func,
        fetchCommercialChains: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchCommercialChains();
    }

    render() {
        const {chains, auth, user} = this.props;

        if (!chains) {
            return <Spinner app={this.props.app}/>
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <Typography variant="h5">
                        <FormattedMessage
                            id="invite"
                            defaultMessage="Invite"/>
                        &nbsp;
                        <FormattedMessage
                            id="user"
                            defaultMessage="user"/>
                    </Typography>

                    <FormattedMessage
                        id="invite-merchant.inviteMerchantText"
                        defaultMessage="To invite user please provide an email."/>

                    <MerchantAccessForm
                        invite
                        enableReinitialize
                        user={user.profile}
                        userRole={auth && auth.role}
                        initialValues={{pointsOfSale: [], controlLocalAdmin: false}}
                        chains={chains}
                        onSubmit={this.props.merchantInviteMerchant}/>
                </Paper>
            </Container>
        );
    }

}

function mapStateToProps(state) {
    return {
        chains: state.chains.commercialChains,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({merchantInviteMerchant, fetchCommercialChains}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteMerchant);
