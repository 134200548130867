import React from "react";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import PasswordResetRequestForm from "./PasswordResetRequestForm";
import Container from "../common/Container";
import Paper from "../common/Paper";

import {sendPasswordResetMessage} from "../../actions/auth-actions";

class PasswordResetRequest extends React.Component {

    static propTypes = {
        sendPasswordResetMessage: PropTypes.func
    };

    render() {
        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <Typography variant="h5">
                        <FormattedMessage
                            id="forgotPassword"
                            defaultMessage="Forgot password"/>
                        ?
                    </Typography>
                    <PasswordResetRequestForm onSubmit={this.props.sendPasswordResetMessage.bind(this, "")}/>
                </Paper>
            </Container>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({sendPasswordResetMessage}, dispatch);
}

export default connect(null, mapDispatchToProps)(PasswordResetRequest);
