import React from "react";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import {emailAsLink} from "../../../common/utils/string-utils";

export default class MerchantIncidentReport extends React.Component {

    render() {
        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <Typography
                        variant="h5"
                        paragraph>
                        <FormattedMessage
                            id="incidentReport"
                            defaultMessage="Incident Report"/>
                    </Typography>

                    <FormattedMessage
                        id="merchant-incident-report.pleaseDescribe"
                        defaultMessage="Please describe an issue in email to {email}."
                        values={{
                            email: emailAsLink("support@karming.se")
                        }}/>
                </Paper>
            </Container>
        )
    }

}
