import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    textGreen: {
        color: theme.palette.green.dark
    },
    textSpaced: {
        marginLeft: theme.spacing(0.3),
        "& > span": {
            marginLeft: theme.spacing(0.3)
        }
    }
}));

export const AmountDifference = ({current, previous, isPointsAmount}) => {
    const classes = useStyles();
    const currentValue = (typeof current === 'undefined') ? 0 : current;
    const previousValue = (typeof previous === 'undefined') ? 0 : previous;

    const difference = (currentValue === 0 && previousValue === 0) ? 0 : Math.abs(currentValue - previousValue);

    if (difference > 0) {
        if (currentValue > previousValue) {
            return (
                <Typography
                    component="span"
                    variant="subtitle2"
                    color="primary"
                    className={classnames(classes.textSpaced, classes.textGreen)}
                    display="inline">
                    (+{difference}{isPointsAmount && 'p'})
                </Typography>
            )
        }
        return (
            <Typography
                component="span"
                variant="subtitle2"
                color="error"
                className={classes.textSpaced}
                display="inline">
                (-{difference}{isPointsAmount && 'p'})
            </Typography>
        )
    }
    return null
};

AmountDifference.propTypes = {
    current: PropTypes.number,
    previous: PropTypes.number,
    isPointsAmount: PropTypes.bool
};
