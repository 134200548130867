import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import CampaignForm from "./form/CampaignForm";

import {createWelcomeOfferCampaign} from "../../actions/merchant-campaign-actions";
import {prepareCampaignFormData} from "../../utils/FormHelper";

class WelcomeOffering extends React.Component {

    static propTypes = {
        chain: PropTypes.object,
        history: PropTypes.object,
        createWelcomeOfferCampaign: PropTypes.func
    };

    onSubmit(values) {
        let body = prepareCampaignFormData(values);
        body.append("welcomeOffer", true);
        this.props.createWelcomeOfferCampaign(this.props.chain.id, body, this.props.history);
    }

    render() {
        return this.renderContent();
    }

    renderContent() {
        return (
            <CampaignForm
                onSubmit={this.onSubmit.bind(this)}
                isWelcomeOffer={true}
                initialValues={{actionButtonLabel: "Läs mer"}}/>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({createWelcomeOfferCampaign}, dispatch);
}

export default connect(null, mapDispatchToProps)(WelcomeOffering);
