import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        margin: 0,
        cursor: 'pointer'
    },
    disabledButton: {
        color: theme.palette.grey.main,
        cursor: 'not-allowed'
    }
});

const StartGuideLink = ({classes, onClick, children, className, disabled}) => {
    return (
        <Link
            onClick={onClick ? onClick : undefined}
            className={classnames(classes.root, className, disabled && classes.disabledButton)}>
            <Typography variant="body1">
                {children}
            </Typography>
        </Link>
    )
};

StartGuideLink.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.object,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};

export default withStyles(styles)(StartGuideLink)
