import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Spinner from "../../../../../common/components/common/Spinner";
import NewChainOrPosDialog from "../NewChainOrPosDialog";
import {KarmingTable} from "../../../../../common/components/common/table/KarmingTable";
import Button from "../../../../../common/components/common/Button";
import Container from "../../../../../common/components/common/Container";
import Paper from "../../../../../common/components/common/Paper";
import TableTitle from "../../../../../common/components/common/table/TableTitle";

import {fetchCommercialChains} from "../../../../actions/merchant-chain-actions";
import {ROLE_MERCHANT} from "../../../../../common/components/auth/roles";
import {fetchPointsOfSale} from "../../../../actions/merchant-pos-actions";

class PointsOfSale extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        auth: PropTypes.object,
        app: PropTypes.object,
        chain: PropTypes.object,
        pointsOfSale: PropTypes.array,
        chains: PropTypes.array,
        commercialChains: PropTypes.array,
        fetchPointsOfSale: PropTypes.func,
        fetchCommercialChains: PropTypes.func
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain ||
            prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchPointsOfSale, fetchCommercialChains} = this.props;
        if (chain && chain.id) {
            fetchPointsOfSale(chain.id);
            fetchCommercialChains();
        }
    }

    render() {
        const {app, chain, pointsOfSale, commercialChains, auth} = this.props;

        if (!chain || !pointsOfSale) {
            return <Spinner app={app}/>
        }
        const role = auth.role;

        const columns = [
            {
                Header: <FormattedMessage
                    id="name"
                    defaultMessage="Name"/>,
                id: "name",
                accessor: p => p.commercialName || p.id,
            },
            {
                Header: <FormattedMessage
                    id="address"
                    defaultMessage="Address"/>,
                id: "address.street",
                accessor: p => p.address.street,
            },
            {
                Header: <FormattedMessage
                    id="city"
                    defaultMessage="City"/>,
                id: "address.city",
                accessor: p => p.address.city,
            },
            {
                Header: <FormattedMessage
                    id="zipCode"
                    defaultMessage="Zip code"/>,
                id: "address.zipCode",
                accessor: p => p.address.zipCode,
            },
            {
                Header: <FormattedMessage
                    id="email"
                    defaultMessage="Email"/>,
                id: "email",
                accessor: p => p.email,
            },
            {
                Header: <FormattedMessage
                    id="phone"
                    defaultMessage="Phone"/>,
                id: "phone",
                accessor: p => p.phone,
            }
        ];

        return (
            <Container size={Container.SIZE_HUGE}>
                <Paper>
                    <TableTitle
                        title={<FormattedMessage
                            id="pointsOfSale"
                            defaultMessage="Points of sale"/>}
                        button={role === ROLE_MERCHANT && <NewChainOrPosDialog
                            moveToCreatePointOfSale={this.moveToCreatePointOfSale.bind(this)}
                            moveToAddCommercialChain={this.moveToAddCommercialChain.bind(this)}/>
                        }/>

                    <KarmingTable
                        history={this.props.history}
                        data={pointsOfSale}
                        columns={columns}
                        showPagination={false}
                        onRowClickHistoryPush={(state, row) => {
                            return (
                                {
                                    pathname: "/locations/" + row.id
                                })
                        }}/>

                    {role === ROLE_MERCHANT && commercialChains && commercialChains.length > 0 && (
                        <Button
                            label={<FormattedMessage
                                id="chains"
                                defaultMessage="Chains"/>}
                            color="secondary"
                            onClick={this.moveToCommercialChains.bind(this)}/>
                    )}
                </Paper>
            </Container>
        );
    }

    moveToCreatePointOfSale() {
        this.props.history.push("/locations/create");
    }

    moveToAddCommercialChain() {
        this.props.history.push("/chains/create");
    }

    moveToCommercialChains() {
        this.props.history.push("/chains/");
    }

}

function mapStateToProps(state) {
    return {
        pointsOfSale: state.merchant.pointsOfSale,
        commercialChains: state.chains.commercialChains,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchPointsOfSale, fetchCommercialChains}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PointsOfSale);
