import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import RenderTextField from "../../../../common/components/common/RenderTextField";
import {RequiredInfo} from "../../../../common/components/common/form/FormUtils";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {actionButtonLabelValidation, URLValidation} from "../../../../common/utils/karming-validators";
import {setFormValue} from "../../../actions/merchant-forms-actions";

const validate = values => {
    return Object.assign(
        actionButtonLabelValidation(
            {name: "actionButtonLabel", value: values.actionButtonLabel},
            {name: "actionButtonTarget", value: values.actionButtonTarget}
        ),
        URLValidation({name: "actionButtonTarget", value: values.actionButtonTarget})
    )
};

const styles = theme => ({
    radioButtonsWrapper: {
        display: 'block'
    },
    subtitle: {
        marginTop: theme.spacing(1)
    }
});

class CampaignFormPage3 extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        title: PropTypes.object,
        campaign: PropTypes.object,
        initialValues: PropTypes.object,
        actionButtonTarget: PropTypes.object,
        actionButtonLabel: PropTypes.string,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func,
        setFormValue: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            openLink: this.props.campaign.actionButtonTarget ? "yes" : "no"
        };
    }

    handleChange = (event) => {
        this.setState({openLink: event.target.value});
        if (event.target.value === 'no') {
            this.props.setFormValue("campaignForm", "actionButtonTarget", '');
            this.props.setFormValue("campaignForm", "actionButtonLabel", 'Läs mer');
        }
    };


    render() {
        const {classes, handleSubmit, previousPage, actionButtonTarget, actionButtonLabel, title} = this.props;
        const {openLink} = this.state;

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <form onSubmit={handleSubmit} autoComplete="on">
                        <Typography
                            variant="h5"
                            paragraph>
                            {title}
                        </Typography>

                        <div>
                            <Field
                                name="description"
                                hintText={<FormattedMessage
                                    id="description"
                                    defaultMessage="Description"/>}
                                component={RenderTextField}
                                type="text"
                                multiline={true}
                                rowsMax={7}/>
                        </div>

                        <Typography
                            variant="subtitle1"
                            className={classes.subtitle}>
                            <FormattedMessage
                                id="doYouWantToAddLink"
                                defaultMessage="Do you want to add a link? (optional)"/>
                        </Typography>

                        <RadioGroup
                            className={classes.radioButtonsWrapper}
                            value={openLink}
                            onChange={this.handleChange}>
                            <FormControlLabel
                                value="yes"
                                control={<Radio/>}
                                label={<FormattedMessage
                                    id="yes"
                                    defaultMessage="Yes"/>}/>

                            <FormControlLabel
                                value="no"
                                control={<Radio/>}
                                label={<FormattedMessage
                                    id="no"
                                    defaultMessage="No"/>}/>
                        </RadioGroup>


                        {openLink === 'yes' && (
                            <div>
                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="provideWebAddress"
                                        defaultMessage="Provide a web address below to display a button (next to the Campaign) that links to this address."/>
                                </Typography>

                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="defaultButtonLabelIsReadMore"
                                        defaultMessage={`The default button label is "Read more", but you can also set your own label.`}/>
                                </Typography>

                                <Field
                                    name="actionButtonTarget"
                                    hintText={<FormattedMessage
                                        id="webAddressToLink"
                                        defaultMessage="Web address to link"/>}
                                    component={RenderTextField}
                                    type="text"/>

                                <Field
                                    name="actionButtonLabel"
                                    hintText={<FormattedMessage
                                        id="buttonLabel"
                                        defaultMessage="Button label"/>}
                                    component={RenderTextField}
                                    type="text"
                                    helperText={
                                        !actionButtonLabel
                                        && actionButtonTarget
                                        && actionButtonTarget.length > 0
                                            ? <FormattedMessage
                                                id="required"
                                                defaultMessage="Required"/>
                                            : <FormattedMessage
                                                id="actionButtonTextHint"
                                                defaultMessage="Max 24 characters"/>
                                    }/>
                            </div>
                        )}

                        <Button
                            label={<FormattedMessage
                                id="previous"
                                defaultMessage="Previous"/>}
                            color="secondary"
                            onClick={previousPage}/>

                        <Button
                            label={<FormattedMessage
                                id="next"
                                defaultMessage="Next"/>}
                            type="submit"/>

                        <RequiredInfo/>
                    </form>
                </Paper>
            </Container>
        );
    }
}

function mapStateToProps(state) {
    const selector = formValueSelector('campaignForm');
    const actionButtonTarget = selector(state, 'actionButtonTarget');
    const actionButtonLabel = selector(state, 'actionButtonLabel');
    return {
        actionButtonTarget,
        actionButtonLabel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default reduxForm({
    form: "campaignForm",
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CampaignFormPage3)))
