import React from "react";
import {render} from "react-dom";
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import reduxThunk from "redux-thunk";
import {addLocaleData, IntlProvider} from 'react-intl';
import seLocaleData from 'react-intl/locale-data/se';
import {StripeProvider} from "react-stripe-elements";
import {composeWithDevTools} from 'redux-devtools-extension';
import {CookiesProvider} from "react-cookie";

import {ThemeProvider} from "@material-ui/styles";

import reducers from "../common/reducers";
import {AUTH_USER, UNAUTH_USER} from "../common/actions/types";
import * as config from "../common/config/config";
import * as translations from '../../static/translations/index'

import MerchantApp from "./components/MerchantApp";
import {appTheme} from "../common/utils/themes";

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);

/* eslint-disable no-undef */
const store = (process.env.NODE_PROFILE === 'local') ?
    createStoreWithMiddleware(reducers, composeWithDevTools()) : createStoreWithMiddleware(reducers);
/* eslint-enable no-undef */

const token = localStorage.getItem('token');
const role = localStorage.getItem('role');

// If we have a token, consider the user to be signed in
if (token) {
    // we need to update application state
    store.dispatch({
        type: AUTH_USER,
        payload: role
    });
} else {
    store.dispatch({type: UNAUTH_USER});
}

const LOCALE = "EN";
addLocaleData(seLocaleData);
const translationsForUsersLocale = translations.se;

render(
    <CookiesProvider>
        <StripeProvider apiKey={config.stripeApiKey}>
            <ThemeProvider theme={appTheme}>
                <Provider store={store}>
                    <IntlProvider
                        locale={LOCALE}
                        messages={translationsForUsersLocale}>
                        <MerchantApp/>
                    </IntlProvider>
                </Provider>
            </ThemeProvider>
        </StripeProvider>
    </CookiesProvider>
    , document.querySelector('.container-app')
);
