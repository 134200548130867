import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {CardCVCElement, CardExpiryElement, CardNumberElement, Elements, injectStripe,} from "react-stripe-elements"

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';

import Button from "../../../../common/components/common/Button";
import {RequiredInfo} from "../../../../common/components/common/form/FormUtils";

const styles = theme => ({
    form: {
        "& .StripeElement": {
            display: 'block',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1.5),
            boxShadow: theme.shadows[1],
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.common.white
        },
        "& .StripeElement--focus": {
            boxShadow: theme.shadows[1],
            transition: 'all 150ms ease'
        },
        "& .StripeElement.PaymentRequestButton": {
            padding: 0
        }
    }
});

export default class CardRegistrationForm extends React.Component {

    static propTypes = {
        billingDetails: PropTypes.object,
        redirectUrl: PropTypes.string,
        cancelButton: PropTypes.bool,
        registerCard: PropTypes.func,
        cancelDialog: PropTypes.func,
        setLoading: PropTypes.func
    };

    render() {
        const {cancelDialog, cancelButton, registerCard, billingDetails, redirectUrl, setLoading} = this.props;
        return (
            <Elements>
                <CardForm
                    setLoading={setLoading}
                    registerCard={registerCard}
                    billingDetails={billingDetails}
                    redirectUrl={redirectUrl}
                    cancelDialog={cancelDialog}
                    cancelButton={cancelButton}/>
            </Elements>
        )
    }

}

class _CardForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardNumberError: '',
            expirationDateError: '',
            cvcError: ''
        };
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        billingDetails: PropTypes.object,
        stripe: PropTypes.object,
        redirectUrl: PropTypes.string,
        cancelButton: PropTypes.bool,
        registerCard: PropTypes.func,
        cancelDialog: PropTypes.func,
        setLoading: PropTypes.func
    };

    render() {
        const {classes, cancelDialog, cancelButton} = this.props;

        return (
            <form onSubmit={this.handleSubmit} className={classes.form}>
                <Typography variant="body1">
                    <FormattedMessage
                        id="cardNumber"
                        defaultMessage="Card number"/>*
                </Typography>

                <CardNumberElement
                    onBlur={handleBlur}
                    onChange={this.handleCardNumberChange.bind(this)}
                    onFocus={handleFocus}
                    onReady={handleReady}/>
                {this.renderCardNumberError()}

                <Typography variant="body1">
                    <FormattedMessage
                        id="expirationDate"
                        defaultMessage="Expiration date"/>*
                </Typography>

                <CardExpiryElement
                    onBlur={handleBlur}
                    onChange={this.handleExpirationDateChange.bind(this)}
                    onFocus={handleFocus}
                    onReady={handleReady}/>
                {this.renderExpirationDateError()}

                <Typography variant="body1">
                    <FormattedMessage
                        id="cvc"
                        defaultMessage="CVC"/>*
                </Typography>

                <CardCVCElement
                    onBlur={handleBlur}
                    onChange={this.handleCVCChange.bind(this)}
                    onFocus={handleFocus}
                    onReady={handleReady}/>
                {this.renderCVCError()}

                {cancelButton && (
                    <Button
                        icon={<Clear/>}
                        label={<FormattedMessage
                            id="cancel"
                            defaultMessage="Cancel"/>}
                        color="secondary"
                        onClick={cancelDialog}/>
                )}

                <Button
                    icon={<Check/>}
                    label={<FormattedMessage
                        id="registerCard"
                        defaultMessage="Register card"/>}
                    onClick={this.handleSubmit.bind(this)}/>

                <RequiredInfo/>
            </form>
        );
    }

    handleSubmit = ev => {
        const {cardNumberError, expirationDateError, cvcError} = this.state;
        const {stripe, registerCard, billingDetails, redirectUrl, setLoading} = this.props;

        ev.preventDefault();
        if (stripe && !cardNumberError && !expirationDateError && !cvcError) {
            registerCard(stripe, billingDetails, redirectUrl);
            setTimeout(() => setLoading(), 500);
            if (this.props.cancelButton) {
                this.props.cancelDialog();
            }

        } else {
            console.log("Stripe.js hasn't loaded yet.");
        }
    };

    handleCardNumberChange(change) {
        this.setState({
            cardNumberError: this.getErrorType(change)
        });
    }

    handleExpirationDateChange(change) {
        this.setState({
            expirationDateError: this.getErrorType(change)
        });
    }

    handleCVCChange(change) {
        this.setState({
            cvcError: this.getErrorType(change)
        });
    }

    getErrorType(change) {
        let errorType = '';
        if (change.empty) {
            errorType = "empty"
        } else if (change.error) {
            errorType = "error"
        }
        return errorType;
    }

    renderCardNumberError() {
        if (this.state.cardNumberError) {
            return (
                <Typography
                    color="error"
                    align="left">
                    {this.state.cardNumberError == "error"
                        ? "Invalid card number"
                        : "Required"}
                </Typography>
            )
        }
    }

    renderExpirationDateError() {
        if (this.state.expirationDateError) {
            return (
                <Typography
                    color="error"
                    align="left">
                    {this.state.expirationDateError == "error"
                        ? "Invalid Expiration date"
                        : "Required"}
                </Typography>
            )
        }
    }

    renderCVCError() {
        if (this.state.cvcError) {
            return (
                <Typography
                    color="error"
                    align="left">
                    {this.state.cvcError == "error"
                        ? "Invalid CVC"
                        : "Required"}
                </Typography>
            )
        }
    }

}

const handleBlur = () => {
};

const handleFocus = () => {
};

const handleReady = () => {
};

const CardForm = injectStripe(withStyles(styles)(_CardForm));
