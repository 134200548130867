import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import Domain from '@material-ui/icons/Domain';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Store from '@material-ui/icons/Store';
import Send from '@material-ui/icons/Send';

import Button from "../../../common/components/common/Button";
import RenderTextField from "../../../common/components/common/RenderTextField";
import RenderRadioButtonGroup from "../../../common/components/common/form/RenderRadioButtonGroup";
import RenderRadioButtonsGroup from "../../../common/components/common/form/RenderRadioButtonsGroup";
import {required} from "../../../common/components/common/form/FormUtils";

import {ROLE_LOCAL_MERCHANT, ROLE_MERCHANT} from "../../../common/components/auth/roles";
import {YES_NO_TYPES} from "../../../common/utils/enums/yes-no-const";
import {emailValidation} from "../../../common/utils/karming-validators";
import {setFormValue} from "../../actions/merchant-forms-actions";

const validate = values => {
    return emailValidation({name: "email", value: values.email});
};

export const MERCHANT_ACCESS_FORM_NAME = 'merchantAccessForm';

const styles = theme => ({
    card: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1)
    },
    list: {
        listStyleType: 'none',
        textAlign: 'left',
        paddingLeft: 0
    },
    listItem: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    icon: {
        top: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        position: 'relative'
    },
    checkbox: {
        bottom: 2,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5)
    },
    caption: {
        paddingLeft: theme.spacing(4.25),
    },
    radioButton: {
        width: '100%',
        flexDirection: 'row',
    }
});

class MerchantAccessForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        user: PropTypes.object,
        initialValues: PropTypes.object,
        chains: PropTypes.array,
        pointsOfSale: PropTypes.array,
        email: PropTypes.string,
        role: PropTypes.string,
        form: PropTypes.string,
        userRole: PropTypes.string,
        submitting: PropTypes.bool,
        invite: PropTypes.bool,
        handleSubmit: PropTypes.func,
        setFormValue: PropTypes.func
    };

    render() {
        const {classes, handleSubmit, submitting, chains, pointsOfSale, email, role, userRole, user, initialValues, invite} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Field
                    name="role"
                    component={RenderRadioButtonGroup}
                    defaultValue={invite ? "ROLE_CASHIER" : initialValues.role}
                    direction="column"
                    className={classes.radioButton}>

                    {userRole === ROLE_MERCHANT && (
                        <FormControlLabel
                            value="ROLE_MERCHANT"
                            control={<Radio/>}
                            label="Superadmin"/>
                    )}

                    {(userRole === ROLE_MERCHANT || (userRole === ROLE_LOCAL_MERCHANT && user.controlLocalAdmin)) && (
                        <FormControlLabel
                            value="ROLE_LOCAL_MERCHANT"
                            control={<Radio/>}
                            label={<FormattedMessage
                                id="localAdmin"
                                defaultMessage="Local admin"/>}/>
                    )}

                    <FormControlLabel
                        value="ROLE_CASHIER"
                        control={<Radio/>}
                        label={<FormattedMessage
                            id="cashier"
                            defaultMessage="Cashier"/>}/>

                </Field>

                {role === ROLE_LOCAL_MERCHANT && (
                    <React.Fragment>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="canInviteAndDeleteOtherLocalAdmin"
                                defaultMessage="Can invite and delete other local admin?"/>
                        </Typography>

                        <Field
                            name="controlLocalAdmin"
                            values={YES_NO_TYPES}
                            component={RenderRadioButtonsGroup}
                            normalize={value => value === 'true'}
                            optionsInline/>
                    </React.Fragment>
                )}

                <Card className={classes.card}>
                    <Typography variant="h6">
                        <FormattedMessage
                            id="pointsOfSale"
                            defaultMessage="Points of sale"/>
                    </Typography>
                    <ul className={classes.list}>
                        {chains.map((chain, index) => {
                            const isSinglePoS = chain.pointsOfSale.find(pos => pos.singlePoint === true);
                            return (
                                isSinglePoS
                                    ? chain.pointsOfSale.map((pointOfSale, index) =>
                                        <li key={index}>
                                            <Typography
                                                variant="subtitle1"
                                                component="div">
                                                <Store className={classes.icon} color="secondary"/>
                                                {pointOfSale.commercialName}
                                                <Checkbox
                                                    className={classes.checkbox}
                                                    checked={!!(pointsOfSale && pointsOfSale.includes(pointOfSale.id))}
                                                    onChange={this.togglePosCheckbox.bind(this, pointOfSale.id, pointsOfSale)}/>
                                            </Typography>

                                            <Typography variant="caption">
                                                {pointOfSale.address.street}, {pointOfSale.address.city}
                                            </Typography>
                                        </li>
                                    )
                                    : <li key={index} className={classes.listItem}>
                                        <Typography
                                            variant="subtitle1"
                                            component="div">
                                            <Domain
                                                className={classes.icon}
                                                color="secondary"/>
                                            {chain.commercialName}

                                            <ul className={classes.list}>
                                                {chain.pointsOfSale
                                                    .filter(pointOfSale => userRole === ROLE_MERCHANT
                                                        || user.pointsOfSaleIds.includes(pointOfSale.id))
                                                    .map((pointOfSale, index) =>
                                                        <li key={index}>
                                                            <Typography
                                                                variant="body1"
                                                                component="div">
                                                                <Checkbox
                                                                    className={classes.checkbox}
                                                                    checked={!!(pointsOfSale && pointsOfSale.includes(pointOfSale.id))}
                                                                    onChange={this.togglePosCheckbox.bind(this, pointOfSale.id, pointsOfSale)}/>
                                                                {pointOfSale.commercialName}
                                                            </Typography>

                                                            <Typography
                                                                variant="caption"
                                                                className={classes.caption}>
                                                                {pointOfSale.address.street}, {pointOfSale.address.city}
                                                            </Typography>
                                                        </li>
                                                    )}
                                            </ul>
                                        </Typography>
                                    </li>
                            )
                        })}
                    </ul>
                </Card>

                {invite && (
                    <Field
                        name="email"
                        hintText={
                            <FormattedMessage
                                id="email"
                                defaultMessage="Email"/>}
                        component={RenderTextField}
                        validate={[required]}/>
                )}

                <Button
                    icon={<Send/>}
                    label={
                        invite
                            ? <FormattedMessage
                                id="invite"
                                defaultMessage="Invite"/>
                            : <FormattedMessage
                                id="update"
                                defaultMessage="Update"/>}
                    type="submit"
                    disabled={submitting || (invite && (!email || (role !== ROLE_MERCHANT && (!pointsOfSale || pointsOfSale.length === 0))))}/>
            </form>
        );
    }

    togglePosCheckbox(pointOfSaleId, pointsOfSale) {
        const idAlreadyInArray = pointsOfSale.includes(pointOfSaleId);
        pointsOfSale = idAlreadyInArray
            ? pointsOfSale.filter(option => option !== pointOfSaleId)
            : [...pointsOfSale, pointOfSaleId];
        this.props.setFormValue(this.props.form, "pointsOfSale", pointsOfSale)
    }

}

const mapStateToProps = (state) => {
    const selector = formValueSelector(MERCHANT_ACCESS_FORM_NAME);
    const {pointsOfSale, email, role} = selector(state, 'pointsOfSale', 'email', 'role');
    return {
        pointsOfSale,
        email,
        role
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default reduxForm({
    form: MERCHANT_ACCESS_FORM_NAME,
    validate
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MerchantAccessForm)));
