import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {FormattedMessage} from "react-intl";
import queryString from 'query-string';

import Typography from "@material-ui/core/Typography";

import CardRegistrationForm from "../payments/CardRegistrationForm";
import SmsNumberForm from "./SmsNumberForm";
import SmsCreditsForm from "./SmsCreditsForm";
import Button from "../../../../common/components/common/Button";
import SenderSMSNameForm from "./SenderSMSNameForm";
import {RequiredInfo} from "../../../../common/components/common/form/FormUtils";
import Dialog from "../../../../common/components/common/Dialog";
import {SMS_URL} from "../../../../common/config/karming-links";
import Spinner from "../../../../common/components/common/Spinner";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {prepareFormData} from "../../../utils/FormHelper";
import {CARD_VERIFIED_COST_SEK, FREE_SMS_NUMBER, SMS_FIXED_COST_PER_MONTH_SEK} from "../../../utils/business-const";
import {nowTimestamp} from "../../../../common/utils/date-utils";

import {fetchCommercialChain, updateCommercialChain} from "../../../actions/merchant-chain-actions";
import {fetchCompany} from "../../../actions/merchant-company-actions";
import {
    activateSmsService,
    buySmsCredits,
    registerCard,
    update3Dsecure,
    updateMaxCreditsPerMonth
} from "../../../actions/merchant-actions";

const ACTIVATE_SMS_ACTION = "ACTIVATE_SMS";

class SmsService extends React.Component {

    static propTypes = {
        chain: PropTypes.object,
        location: PropTypes.object,
        history: PropTypes.object,
        app: PropTypes.object,
        company: PropTypes.object,
        fetchCommercialChain: PropTypes.func,
        update3Dsecure: PropTypes.func,
        updateCommercialChain: PropTypes.func,
        fetchCompany: PropTypes.func,
        registerCard: PropTypes.func,
        buySmsCredits: PropTypes.func,
        activateSmsService: PropTypes.func
    };

    state = {
        smsActivationInfoOpen: false,
        isCardLoading: false
    };

    componentDidMount() {
        if (this.props.chain) {
            this.props.fetchCommercialChain(this.props.chain.id)
        }
        if (this.props.location.search) {
            const params = queryString.parse(this.props.location.search);
            this.props.update3Dsecure(params, this.props.history, '/sms')
        } else {
            this.props.fetchCompany()
        }
    }

    static getDerivedStateFromProps(nextProps) {
        const {apiResult} = nextProps;

        if (apiResult
            && apiResult.requestId
            && apiResult.requestId.startsWith(ACTIVATE_SMS_ACTION)) {
            if (apiResult.success) {
                return ({
                    lastSentAction: null,
                    smsActivationInfoOpen: true
                });
            }
        }

        if (apiResult && apiResult.message && (
            apiResult.message === "REGISTER_CARD_SUCCESS"
            || apiResult.isError)) {
            nextProps.fetchCompany();
            return ({
                isCardLoading: false
            });
        }

        return null;
    }

    handleCancelSmsActivationInfo() {
        this.setState({smsActivationInfoOpen: false});
    }

    handleSetLoading() {
        this.setState({isCardLoading: true})
    }


    render() {
        const {history, app, company, chain} = this.props;
        const {isCardLoading} = this.state;

        if (!company) {
            return <Spinner app={app}/>
        }

        if (isCardLoading || this.props.location.search) {
            return (
                <React.Fragment>
                    <Spinner app={app}/>
                    <Typography
                        variant="body1"
                        paragraph
                        align="center">
                        <FormattedMessage
                            id="pleaseWaitRegistrationIsProcessed"
                            defaultMessage="Please wait, registration is processed."/>
                    </Typography>
                </React.Fragment>
            )
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <Typography
                        variant="h5"
                        paragraph>
                        <FormattedMessage
                            id="smsService"
                            defaultMessage="SMS service"/>
                    </Typography>
                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="sms-service.smsLotOfValueText"
                            defaultMessage="Using SMS to communicate to your customers brings a lot of value!"/>
                    </Typography>

                    <Typography
                        variant="body1"
                        component="div"
                        align="left">
                        <ul>
                            <li>Medlemsregistrering med mobilnummer</li>
                            <li>Kampanjutskick via SMS</li>
                            <li>Medlemsinloggning vis SMS</li>
                        </ul>
                    </Typography>

                    <Button
                        color="secondary"
                        label={<FormattedMessage
                            id="smsUsage"
                            defaultMessage="SMS Usage"/>}
                        type="submit"
                        onClick={() => history.push("/sms/usage")}/>

                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="smsServicePricing"
                            defaultMessage="SMS Service pricing"/>
                    </Typography>

                    <Typography
                        variant="body1"
                        align="left">
                        <FormattedMessage
                            id="perMonth"
                            defaultMessage={`{amount} SEK per month`}
                            values={{
                                amount: SMS_FIXED_COST_PER_MONTH_SEK
                            }}/>
                    </Typography>
                    <Typography
                        variant="body1"
                        paragraph
                        align="left">
                        1 SMS-kredit = 1 SMS = 1 SEK
                    </Typography>

                    {this.renderActionable(company, chain)}
                </Paper>
            </Container>
        );
    }

    renderActionable(company, chain) {
        if (!company.billingDetails.creditCard) {
            return (
                <React.Fragment>
                    <hr/>
                    <Typography
                        paragraph
                        align="left"
                        variant="body1">
                        <FormattedMessage
                            id="sms-service.needToHaveACreditCardText"
                            defaultMessage="To use the service, you need to have a credit card registered as payment method on your account."/>
                    </Typography>

                    <Typography
                        paragraph
                        align="left"
                        variant="body1">
                        <FormattedMessage
                            id="sms-service.whenYouActivatePart1"
                            defaultMessage="When you activate, you get"/>
                        &nbsp;
                        <b>
                            <FormattedMessage
                                id="sms-service.whenYouActivatePart2"
                                defaultMessage={`{freeSMS} freeSMS credits`}
                                values={{
                                    freeSMS: FREE_SMS_NUMBER
                                }}/>
                        </b>
                        &nbsp;
                        <FormattedMessage
                            id="sms-service.whenYouActivatePart3"
                            defaultMessage="so you can test how smoothly it is with SMS!"/>
                    </Typography>

                    <Typography
                        variant="body1"
                        align="left">
                        <FormattedMessage
                            id="sms-service.youChoose"
                            defaultMessage="You then choose if you want to load more SMS credits."/>
                    </Typography>

                    <Typography
                        variant="body1"
                        align="left">
                        <FormattedMessage
                            id="sms-service.whenYourCardIsVerified"
                            defaultMessage="When your card is verified, a temporary one is made
                                reservation of {cardVerifiedCost} SEK which will be released last after 7 days."
                            values={{
                                cardVerifiedCost: CARD_VERIFIED_COST_SEK
                            }}/>
                    </Typography>

                    <CardRegistrationForm
                        setLoading={this.handleSetLoading.bind(this)}
                        registerCard={this.props.registerCard}
                        billingDetails={this.props.company.billingDetails}
                        redirectUrl={SMS_URL}/>
                </React.Fragment>
            )
        }

        if (!company.smsAccount) {
            return (
                <React.Fragment>
                    <Typography
                        variant="subtitle1"
                        color="error">
                        <FormattedMessage
                            id="smsServiceNotActivated"
                            defaultMessage="SMS service not activated"/>
                    </Typography>

                    <SmsNumberForm
                        activateSmsService={this.props.activateSmsService.bind(this, `${ACTIVATE_SMS_ACTION}.${nowTimestamp()}`)}/>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <Dialog
                    onClose={this.handleCancelSmsActivationInfo.bind(this)}
                    open={this.state.smsActivationInfoOpen}
                    title={<FormattedMessage
                        id="congratulations"
                        defaultMessage="Congratulations!"/>}
                    content={
                        <React.Fragment>
                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="weHaveNowAddedSms"
                                    defaultMessage="We have now added {freeSMS} SMS credits to your account with absolutely free!"
                                    values={{
                                        freeSMS: FREE_SMS_NUMBER
                                    }}/>
                            </Typography>

                            <Typography variant="subtitle1">
                                <FormattedMessage
                                    id="nowYouCanInvite"
                                    defaultMessage="Now you can invite and send campaigns to your members via SMS."/>
                            </Typography>
                        </React.Fragment>
                    }
                    actions={
                        <Button
                            label={<FormattedMessage
                                id="hereWeGo"
                                defaultMessage="Here we go"/>}
                            onClick={this.handleCancelSmsActivationInfo.bind(this)}/>}/>

                <Typography
                    variant="subtitle1"
                    color="primary">
                    <FormattedMessage
                        id="smsServiceActivated"
                        defaultMessage="SMS service activated"/>
                </Typography>

                {chain && (
                    <SenderSMSNameForm
                        initialValues={chain}
                        chain={chain}
                        enableReinitialize={true}
                        onSubmit={this.updateChainSmsSenderName.bind(this, chain.id)}/>
                )}

                {/*Hide for now*/}
                {/*<SmsCreditsSettingsForm*/}
                {/*enableReinitialize={true}*/}
                {/*initialValues={{*/}
                {/*maxCreditsPerMonth: company.smsAccount.maxCreditsPerMonth,*/}
                {/*}}*/}
                {/*onSubmit={this.props.updateMaxCreditsPerMonth.bind(this)}*/}
                {/*/>*/}

                <Typography variant="body1">
                    <FormattedMessage
                        id="balanceForCredits"
                        defaultMessage="Balance: {credits} Credits"
                        values={{
                            credits: company.smsAccount.credits
                        }}/>
                </Typography>

                <SmsCreditsForm
                    initialValues={{
                        credits: 100,
                        autoCharge: company.smsAccount.autoCharge
                    }}
                    onSubmit={this.props.buySmsCredits.bind(this)}/>

                <RequiredInfo/>
            </React.Fragment>
        )
    }

    updateChainSmsSenderName(chainId, values) {
        this.props.updateCommercialChain(chainId, prepareFormData(values))
    }

}

function mapStateToProps(state) {
    return {
        chain: state.chains.current,
        company: state.company.data,
        apiResult: state.app.status,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchCompany, registerCard, activateSmsService, updateMaxCreditsPerMonth, buySmsCredits,
        fetchCommercialChain, updateCommercialChain, update3Dsecure
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SmsService);
