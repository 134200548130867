import React from "react";
import {formValueSelector, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import TitleWithBackButton from "../../../../../common/components/TitleWithBackButton";
import StampcardCouponFormPage1 from "./StampcardCouponFormPage1";
import StampcardCouponFormPage2 from "./StampcardCouponFormPage2";
import StampcardCouponFormPage3 from "./StampcardCouponFormPage3";
import StampcardCouponFormPage4 from "./StampcardCouponFormPage4";
import StampcardCouponFormPage5 from "./StampcardCouponFormPage5";

export const STAMPCARD_COUPON_FORM_NAME = 'stampcardCouponForm';
const STAMPCARD_COUPON_FORM_LAST_PAGE = 5;

class StampcardCouponForm extends React.Component {

    static propTypes = {
        initialValues: PropTypes.object,
        pageTitle: PropTypes.object,
        stampcard: PropTypes.object,
        coupon: PropTypes.object,
        chain: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        onSubmit: PropTypes.func
    };

    static defaultProps = {
        pageTitle: <FormattedMessage
            id="stampcard"
            defaultMessage="Stamp card"/>
    };

    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.state = {
            page: (!this.props.initialValues || !this.props.initialValues.reward ? 1 : STAMPCARD_COUPON_FORM_LAST_PAGE)
        }
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
    }

    skipNextPage() {
        this.setState({page: this.state.page + 2})
    }

    previousPage() {
        this.setState({page: this.state.page - 1})
    }

    firstPage() {
        this.setState({page: 1})
    }

    render() {
        const {chain, isUpdate, onSubmit, initialValues, pageTitle, stampcard, coupon} = this.props;
        const {page} = this.state;

        return (
            <React.Fragment>
                {page !== 1 && (
                    <TitleWithBackButton
                        title={pageTitle}
                        onBackClick={this.previousPage}
                    />
                )}

                {page === 1 && (
                    <StampcardCouponFormPage1
                        pageTitle={pageTitle}
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        onSubmit={this.nextPage}
                        coupon={coupon}
                        stampsAmount={stampcard.stampsAmount}
                    />
                )}

                {page === 2 && (
                    <StampcardCouponFormPage2
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        monthValidation={stampcard.monthValidation}
                        coupon={coupon}
                        stampcard={stampcard}
                        onSubmit={this.nextPage}
                    />
                )}

                {page === 3 && (
                    <StampcardCouponFormPage3
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        coupon={coupon}
                        stampcard={stampcard}
                        onSubmit={chain.ecrIntegrationEnabled
                            ? this.nextPage
                            : this.skipNextPage.bind(this)}
                    />
                )}

                {page === 4 && (
                    <StampcardCouponFormPage4
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        onSubmit={this.nextPage}
                        coupon={coupon}
                        stampcard={stampcard}
                    />
                )}

                {page === 5 && (
                    <StampcardCouponFormPage5
                        initialValues={initialValues}
                        isUpdate={isUpdate}
                        coupon={coupon}
                        onSubmit={onSubmit}
                        stampcard={stampcard}
                        firstPage={this.firstPage.bind(this)}
                    />
                )}
            </React.Fragment>
        )
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('stampcardCouponForm');
    const {dayValidation, reward, description, image, hideTitle} = selector(state, 'dayValidation', 'reward',
        'description', 'image', 'hideTitle');

    return {
        coupon: {
            dayValidation,
            reward,
            description,
            image,
            hideTitle
        },
        chain: state.chains.current
    }
}

export default reduxForm({
    form: "stampcardCouponForm"
})(connect(mapStateToProps, null)(StampcardCouponForm))
