import React from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";

import {SWEDISH} from "../../../../common/utils/enums/languages";
import {getTranslatedDate} from "../../../../common/utils/date-utils";

const useStyles = makeStyles(theme => ({
    details: {
        padding: 0,
        textAlign: 'left'
    },
    table: {
        width: '100%',
        '& th, td': {
            borderRightWidth: theme.spacing(0.5),
            borderLeftWidth: theme.spacing(0.5),
            borderRightStyle: 'solid',
            borderLeftStyle: 'solid',
            borderColor: 'transparent'
        }
    },
    pointsColumn: {
        textAlign: 'center'
    }
}));

const REVISION_TYPES = {
    ADD: {
        title: <FormattedMessage id="adjustmentPointsUpdate" defaultMessage="Adjustment"/>,
        sign: '+'
    },
    DEDUCT: {
        title: <FormattedMessage id="adjustmentPointsUpdate" defaultMessage="Adjustment"/>,
        sign: '-'
    },
    USE: {
        title: <FormattedMessage id="usedPointsUpdate" defaultMessage="Used"/>,
        sign: '-'
    },
    RETURN: {
        title: <FormattedMessage id="returnedPointsUpdate" defaultMessage="Returned"/>,
        sign: '+'
    },
    EARNED: {
        title: <FormattedMessage id="earnedPointsUpdate" defaultMessage="Earned"/>,
        sign: null
    }
};

export const PointsUpdatesHistory = props => {
    const {purchase} = props;
    const classes = useStyles();
    const language = purchase && purchase.merchant.language || SWEDISH;
    const clubPointsUpdates = getFormattedUpdates();

    return (
        <ExpansionPanelDetails classes={{root: classes.details}}>
            <table className={classes.table}>
                <tbody>
                <Typography component="tr">
                    <th>
                        <FormattedMessage
                            id="eventPointsUpdate"
                            defaultMessage="Event"/>
                    </th>
                    <th>
                        <FormattedMessage
                            id="amountPointsUpdate"
                            defaultMessage="Amount"/>
                    </th>
                    <th>
                        <FormattedMessage
                            id="whenPointsUpdate"
                            defaultMessage="When"/>
                    </th>
                </Typography>

                {clubPointsUpdates.map((update, updateIndex) => {
                    return (
                        <tr key={updateIndex}>
                            <Typography
                                variant="body1"
                                component="td">
                                {update.revisionType.title}
                            </Typography>

                            <Typography
                                variant="body1"
                                component="td"
                                className={classes.pointsColumn}>
                                {update.revisionType.sign}{update.points}p
                            </Typography>

                            <Typography
                                variant="body1"
                                component="td">
                                {getTranslatedDate(update.date, language)}
                            </Typography>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </ExpansionPanelDetails>
    );

    function getFormattedUpdates() {
        purchase.historicPurchases.sort((previousValue, nextValue) => previousValue.dateOfUpdate - nextValue.dateOfUpdate);
        purchase.historicPurchases.dateOfUpdate = purchase.historicPurchases.dateOfPurchase;
        const historicPurchasesItems = [...purchase.historicPurchases, purchase];
        const historicPurchaseClubPoints = purchase.historicPurchases.length > 0
            && historicPurchasesItems.filter((historicPurchase, historicPurchaseIndex, historicPurchaseArray) => {
                if (historicPurchaseArray[historicPurchaseIndex + 1]) {
                    return historicPurchaseArray[historicPurchaseIndex + 1].earnedPoints !== historicPurchase.earnedPoints
                }
            });

        let historicClubPoints = {
            earnedPoints: purchase.earnedPoints,
            dateOfUpdate: purchase.dateOfPurchase
        };

        if (historicPurchaseClubPoints) {
            historicClubPoints = [historicClubPoints, ...historicPurchaseClubPoints.reverse()]
        }

        let usedReturnedUpdates = [];
        let adjustedUpdates = [];

        purchase.pointsUsages.length > 0
        && purchase.pointsUsages.map((pointsUsage) => {
            if (usedReturnedUpdates && pointsUsage.usedPoints > 0) {
                usedReturnedUpdates.push({
                    revisionType: REVISION_TYPES.USE,
                    // we add '1' to ensure usedPointsDate is later than returnPointsDate;
                    date: pointsUsage.usedPointsDate + 1,
                    points: pointsUsage.usedPoints
                });
                if (pointsUsage.returnPointsDate !== null) {
                    usedReturnedUpdates.push({
                        revisionType: REVISION_TYPES.RETURN,
                        date: pointsUsage.returnPointsDate,
                        points: pointsUsage.usedPoints
                    })
                }
            }
        });

        historicClubPoints.length > 0
        && historicClubPoints.map((historicClubPoint, index, array) => {
            if (index !== 0) {
                const previousEarnedPoints = array[index - 1]
                    ? array[index - 1].earnedPoints
                    : array[0];

                const difference = array[index - 1]
                    && previousEarnedPoints
                    && Math.abs(historicClubPoint.earnedPoints - previousEarnedPoints);

                const revisionType = array[index - 1] && historicClubPoint
                && historicClubPoint.earnedPoints < previousEarnedPoints
                    ? REVISION_TYPES.ADD
                    : REVISION_TYPES.DEDUCT;

                adjustedUpdates.push({
                    revisionType: revisionType,
                    date: historicClubPoint.dateOfUpdate,
                    points: difference
                });
            }
        });

        const combinedUpdates = [...adjustedUpdates, ...usedReturnedUpdates];
        combinedUpdates.push({
            revisionType: REVISION_TYPES.EARNED,
            date: historicPurchasesItems[0].dateOfPurchase,
            points: historicPurchasesItems[0].totalAmount || 0
        });

        combinedUpdates.sort((previousValue, nextValue) => nextValue.date - previousValue.date);
        return combinedUpdates
    }
};

PointsUpdatesHistory.propTypes = {
    purchase: PropTypes.object
};
