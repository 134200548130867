import React from "react";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import StampcardForm from "./StampcardForm";
import Spinner from "../../../../common/components/common/Spinner";
import StampcardInfo from "./StampcardInfo";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {prepareStampcardFormData} from "../../../utils/FormHelper";

import {
    createStampcard,
    fetchStampcard,
    fetchStampcards,
    fetchStampcardUsages,
    publishStampcard,
    stopSharingStampcard,
    updateReplacedStampcards,
    updateStampcard,
    withdrawStampcard
} from "../../../actions/merchant-stampcard-actions";
import {updateCouponStatus} from "../../../actions/merchant-points-actions";

class Stampcard extends React.Component {

    static propTypes = {
        match: PropTypes.object,
        app: PropTypes.object,
        history: PropTypes.object,
        stampcard: PropTypes.object,
        chain: PropTypes.object,
        stampcards: PropTypes.array,
        stampcardUsages: PropTypes.array,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        pristine: PropTypes.bool,
        fetchStampcard: PropTypes.func,
        fetchStampcards: PropTypes.func,
        fetchStampcardUsages: PropTypes.func,
        publishStampcard: PropTypes.func,
        withdrawStampcard: PropTypes.func,
        stopSharingStampcard: PropTypes.func,
        updateStampcard: PropTypes.func,
        createStampcard: PropTypes.func,
        updateReplacedStampcards: PropTypes.func
    };

    state = {
        editMode: false
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    static getDerivedStateFromProps(nextProps) {
        const {apiResult} = nextProps;

        if (apiResult
            && apiResult.message
            && apiResult.message.startsWith("Stamp card")) {
            return ({
                editMode: false
            });
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain || prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {isUpdate, chain} = this.props;
        let stampcardId = this.props.match.params.id;
        if (stampcardId && chain) {
            this.props.fetchStampcard(stampcardId);
            this.props.fetchStampcards(chain.id);
            this.props.fetchStampcardUsages(stampcardId)
        }
        if (!isUpdate && chain) {
            this.props.fetchStampcards(chain.id);
        }
    }

    goToEditMode(mode) {
        this.setState({editMode: mode});
    }

    render() {
        const {app, history, stampcard, stampcards, isUpdate, chain, stampcardUsages} = this.props;
        const {editMode} = this.state;

        if (!stampcards || (isUpdate && !stampcard)) {
            return <Spinner app={app}/>
        }

        if (isUpdate && stampcard && !editMode) {
            return (
                <Container size={Container.SIZE_SMALL}>
                    <Paper padding>
                        <Typography
                            variant="h5"
                            paragraph>
                            <FormattedMessage
                                id="stampcardInfo"
                                defaultMessage="Stampcard info"/>
                        </Typography>
                        <StampcardInfo
                            stampcards={stampcards}
                            stampcard={stampcard}
                            stampcardUsages={stampcardUsages}
                            history={history}
                            goToEditMode={this.goToEditMode.bind(this)}
                            publishStampcard={this.props.publishStampcard.bind(this, stampcard.id)}
                            withdrawStampcard={this.props.withdrawStampcard.bind(this, stampcard.id)}
                            stopSharingStampcard={this.props.stopSharingStampcard.bind(this, stampcard.id)}
                            updateReplacedStampcards={this.props.updateReplacedStampcards.bind(this, stampcard.id)}/>
                    </Paper>
                </Container>
            )
        }

        const initialValueForCreation = {
            titleColor: '#ffffff',
            subtitleColor: '#ffffff',
            subtitleSize: 12
        };

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <StampcardForm
                        editMode={editMode}
                        isFirstStampcard={!isUpdate && stampcards && stampcards.length === 0}
                        initialValues={isUpdate ? stampcard : initialValueForCreation}
                        isUpdate={isUpdate}
                        chain={chain}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit.bind(this)}/>
                </Paper>
            </Container>
        )
    }

    onSubmit(values) {
        let body = prepareStampcardFormData(values);
        if (this.props.isUpdate) {
            this.props.updateStampcard(this.props.match.params.id, body)
        } else {
            this.props.createStampcard(this.props.chain.id, body, this.props.history);
        }
    }
}

function mapStateToProps(state) {
    return {
        stampcard: state.merchant.stampcard,
        stampcards: state.merchant.stampcards,
        stampcardUsages: state.merchant.stampcardUsages,
        chain: state.chains.current,
        apiResult: state.app.status
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchStampcard,
        fetchStampcards,
        fetchStampcardUsages,
        createStampcard,
        updateStampcard,
        updateCouponStatus,
        publishStampcard,
        withdrawStampcard,
        stopSharingStampcard,
        updateReplacedStampcards
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Stampcard)
