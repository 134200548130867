import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import Button from "../../../../common/components/common/Button";
import RenderTextField from "../../../../common/components/common/RenderTextField";

import {smsSenderNameValidation} from "../../../../common/utils/karming-validators";
import {required} from "../../../../common/components/common/form/FormUtils";

const validate = values => {
    return smsSenderNameValidation(
        {name: SMS_SENDER_NAME_FIELD_NAME, value: values.smsSenderName},
    )
};

const SMS_SENDER_NAME_FIELD_NAME = "smsSenderName";

class SenderSMSNameForm extends Component {

    static propTypes = {
        chain: PropTypes.object,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {handleSubmit, chain} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="subtitle1">
                    <FormattedMessage
                        id="smsSenderNameFor"
                        defaultMessage="SMS sender name for"/> {chain.commercialName}
                </Typography>

                <Field
                    name={SMS_SENDER_NAME_FIELD_NAME}
                    component={RenderTextField}
                    type="text"
                    helperText={
                        <React.Fragment>
                            <FormattedMessage
                                id="smsSenderNameHint"
                                defaultMessage="3-11 characters (only a-z, A-Z, 0-9, no spaces)"/>
                        </React.Fragment>
                    }
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="smsSenderName"
                                defaultMessage="SMS sender name"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    validate={[required]}/>

                <Button
                    label={<FormattedMessage
                        id="save"
                        defaultMessage="Save"/>}
                    type="submit"/>
            </form>
        );
    }

}

export default reduxForm({
    form: 'senderSMSNameForm',
    validate
})(SenderSMSNameForm)
