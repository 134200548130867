import React from "react";
import {Field, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import RenderDatePicker from "../../../common/components/common/form/RenderDatePicker";
import Button from "../../../common/components/common/Button";

import {required} from "../../../common/components/common/form/FormUtils";

class ClubPointsActivationForm extends React.Component {

    static propTypes = {
        submitting: PropTypes.bool,
        wasAlreadyActivated: PropTypes.bool,
        handleSubmit: PropTypes.func,
        cancelDialog: PropTypes.func
    };

    render() {
        const {handleSubmit, cancelDialog, wasAlreadyActivated} = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Typography variant="h5">
                    <FormattedMessage
                        id="justToBeSure"
                        defaultMessage="Just to be sure!"/>!
                </Typography>

                {!wasAlreadyActivated &&
                <React.Fragment>
                    <Typography variant="body1">
                        <FormattedMessage
                            id="club-points-activation.pointsIsCalculated"
                            defaultMessage="Points is calculated for purchases registered from now and onward."/>
                    </Typography>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="club-points-activation.ProvideADate"
                            defaultMessage="Provide a date if you want to include registered purchase amounts from back in time."/>
                    </Typography>
                </React.Fragment>
                }

                {wasAlreadyActivated &&

                <React.Fragment>
                    <Typography variant="body1">
                        <FormattedMessage
                            id="club-points-activation.pointsIsCalculatedFromTheSameDate"
                            defaultMessage="Points is calculated for purchases registered from registered from the same date as last time when Club Points was activated."/>
                    </Typography>
                    <br/>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="club-points-activation.provideADateAnotherTime"
                            defaultMessage="Provide a preferred date to include registered purchase amounts from another time."/>
                    </Typography>
                </React.Fragment>

                }

                <Field
                    name="countedPointsFrom"
                    component={RenderDatePicker}
                    maxDate={new Date()}
                    clearable={true}
                    validate={required}/>

                <Button
                    label={<FormattedMessage
                        id="cancel"
                        defaultMessage="Cancel"/>}
                    color="secondary"
                    onClick={cancelDialog}/>

                <Button
                    label={
                        <FormattedMessage
                            id="activate"
                            defaultMessage="Activate"/>}
                    type="submit"/>
            </form>
        );
    }

}

export default reduxForm({
    form: 'clubPointsActivationForm',
})(ClubPointsActivationForm)
