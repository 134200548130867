import React from "react";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import bindActionCreators from "redux/src/bindActionCreators";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import VolumeUp from "@material-ui/icons/VolumeUp";
import Add from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import DeleteForever from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";

import TitleWithBackButton from "../../../common/components/TitleWithBackButton";
import Button from "../../../common/components/common/Button";

import {MADE_PURCHASE, NOT_MADE_PURCHASE} from "./DatePurchaseFilterForm";
import {BECAME_MEMBER, NOT_BECAME_MEMBER} from "./DateBecameMemberFilterForm";
import {BORN, NOT_BORN} from "./DateBornFilterForm";
import {getFormattedDate} from "../../../common/utils/date-utils";
import {HAVE_POINTS, NOT_HAVE_POINTS} from "./PointsBalanceFilterForm";
import {madePurchaseCategoriesOption} from "./CategoriesFilterForm";
import {madePurchaseCampaignsOption} from "./CampaignsFilterForm";
import {months} from "../../../merchant/utils/month-const";

import {filterMembersForCampaign, publishCampaign} from "../../actions/merchant-actions";

const styles = theme => ({
    itemWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(0.5)
        }
    },
    buttonsWrapper: {
        display: 'inline-grid'
    },
    cardWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(2)
    },
    card: {
        width: '100%',
        padding: theme.spacing(1)
    },
    iconButton: {
        position: 'absolute'
    },
    deleteIcon: {
        fill: theme.palette.error.main
    }
});

class CampaignRecipients extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        currentSmartSelection: PropTypes.object,
        history: PropTypes.object,
        quantityFilter: PropTypes.array,
        tasteFilter: PropTypes.array,
        dateFilter: PropTypes.array,
        sumFilter: PropTypes.array,
        membersForCampaign: PropTypes.array,
        campaigns: PropTypes.array,
        categories: PropTypes.array,
        consumersNumber: PropTypes.number,
        publicToAll: PropTypes.bool,
        campaignId: PropTypes.string,
        publishCampaign: PropTypes.func,
        handleOpenSmartSelection: PropTypes.func,
        filterMembersForCampaign: PropTypes.func
    };

    render() {
        const {
            classes, consumersNumber, currentSmartSelection, membersForCampaign, history, dateFilter, sumFilter,
            quantityFilter, tasteFilter, handleOpenSmartSelection, campaignId, publicToAll
        } = this.props;

        return (
            <React.Fragment>
                <TitleWithBackButton
                    title={<FormattedMessage
                        id="campaign"
                        defaultMessage="Campaign"/>}
                    onBackClick={() => history.push(`/campaigns/${campaignId}`)}/>

                <Typography
                    variant="body1"
                    paragraph>
                    <FormattedMessage
                        id="campaign.forWhichMembersCampaignVisible"
                        defaultMessage="For which members shall the Campaign be visible?"/>
                </Typography>

                <div className={classes.itemWrapper}>
                    <Typography variant="subtitle1">
                        <FormattedMessage
                            id="totalAmountOfMembers"
                            defaultMessage="Total amount of members"/>:
                    </Typography>

                    <Typography variant="body1">
                        {consumersNumber}

                        &nbsp;
                        <FormattedMessage
                            id="members"
                            defaultMessage="Members"/>
                    </Typography>
                </div>

                {membersForCampaign !== undefined
                && (currentSmartSelection
                    && currentSmartSelection.name !== "Non Existing") && (
                    <React.Fragment>
                        <div className={classes.itemWrapper}>
                            <Typography
                                variant="subtitle1"
                                paragraph>
                                <FormattedMessage
                                    id="selectedMembers"
                                    defaultMessage="Selected members"/>:
                            </Typography>

                            <Typography
                                variant="body1"
                                paragraph>
                                {membersForCampaign.length}
                                &nbsp;
                                <FormattedMessage
                                    id="members"
                                    defaultMessage="Members"/>
                            </Typography>
                        </div>

                        <Typography
                            variant="body1"
                            paragraph>
                            <FormattedMessage
                                id="youHaveSelectedMembersWho"
                                defaultMessage="You have selected members who"/>:
                        </Typography>
                    </React.Fragment>
                )}

                {this.renderDateFilters(dateFilter)}
                {this.renderSumFilters(sumFilter)}
                {this.renderQuantityFilters(quantityFilter)}
                {this.renderTasteFilters(tasteFilter)}

                <div className={classes.buttonsWrapper}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        icon={<Add/>}
                        label={<FormattedMessage
                            id="addSmartSelection"
                            defaultMessage="Add Smart Selection"/>}
                        onClick={handleOpenSmartSelection}/>

                    <Button
                        icon={<VolumeUp/>}
                        label={
                            publicToAll
                                ? <FormattedMessage
                                    id="publishToAll"
                                    defaultMessage="Publish to all"/>
                                : <FormattedMessage
                                    id="publishToSelected"
                                    defaultMessage="Publish to selected"/>}
                        onClick={this.props.publishCampaign.bind(
                            this,
                            campaignId,
                            publicToAll
                                ? {publicToAll: true}
                                : currentSmartSelection)}/>
                </div>
            </React.Fragment>
        )
    }

    renderDateFilters(dateFilter) {
        if (dateFilter) {
            return (
                dateFilter.map((filter, index) =>
                    this.renderDateFilter(filter, index)
                )
            )
        }
    }

    renderSumFilters(sumFilter) {
        if (sumFilter) {
            return (
                sumFilter.map((filter, index) =>
                    this.renderSumFilter(filter, index)
                )
            )
        }
    }

    renderQuantityFilters(quantityFilter) {
        if (quantityFilter) {
            return (
                quantityFilter.map((filter, index) =>
                    this.renderQuantityFilter(filter, index)
                )
            )
        }
    }

    renderTasteFilters(tasteFilter) {
        if (tasteFilter) {
            return (
                tasteFilter.map((filter, index) =>
                    this.renderTasteFilter(filter, index)
                )
            )
        }
    }

    renderTasteFilter(filter, index) {
        const {classes} = this.props;
        const isCategoryFilter = filter.categories && filter.categories.length > 0;
        const isCampaignFilter = filter.campaigns && filter.campaigns.length > 0;

        if (isCategoryFilter) {
            const filterDescription = madePurchaseCategoriesOption
                .find(option => option.value === filter.madePurchaseCategories).label;
            const categoriesNames = this.props.categories
                .filter(category => filter.categories
                    .includes(category.id))
                .map(option => option.name);
            return (
                <div
                    className={classes.cardWrapper}
                    key={index}>
                    {this.createDeleteIcon(filter, "tasteFilter")}

                    <Card className={classes.card}>
                        <Typography variant="body1">
                            {filterDescription}:
                        </Typography>

                        <Typography variant="body1">
                            {categoriesNames.map((category, index) => (
                                <span key={index}>
                                    {index > 0 && ", "}
                                    {category}
                                </span>
                            ))}
                        </Typography>
                    </Card>
                </div>
            )
        }
        if (isCampaignFilter, index) {
            const filterDescription = madePurchaseCampaignsOption
                .find(option => option.value === filter.madePurchaseCampaigns).label;
            const campaignsNames = this.props.campaigns
                .filter(campaign => filter.campaigns
                    .includes(campaign.id))
                .map(option => option.name);

            return (
                <div
                    className={classes.cardWrapper}
                    key={index}>
                    {this.createDeleteIcon(filter, "tasteFilter")}

                    <Card className={classes.card}>
                        <Typography variant="body1">
                            {filterDescription}:
                        </Typography>

                        <Typography variant="body1">
                            {campaignsNames.map((campaign, index) => (
                                <span key={index}>
                                    {index > 0 && ", "}
                                    {campaign}
                                </span>
                            ))}
                        </Typography>
                    </Card>
                </div>
            )
        }
    }

    renderQuantityFilter(filter, index) {
        const {classes} = this.props;
        const isPurchaseQuantityFilter = this.checkIfNotNotNullOrUndefined(filter.purchaseQuantityFrom)
            && this.checkIfNotNotNullOrUndefined(filter.purchaseQuantityTo);

        let filterDescription;
        let quantityFrom;
        let quantityTo;

        if (isPurchaseQuantityFilter) {
            filterDescription = filter.madePurchases ? MADE_PURCHASE : NOT_MADE_PURCHASE;
            quantityFrom = filter.purchaseQuantityFrom;
            quantityTo = filter.purchaseQuantityTo;
        }

        return (
            <div
                className={classes.cardWrapper}
                key={index}>
                {this.createDeleteIcon(filter, "quantityFilter")}

                <Card className={classes.card}>
                    <Typography variant="body1">
                        {filterDescription}
                    </Typography>

                    <Typography variant="body1">
                        {quantityFrom && (
                            <React.Fragment>
                                <FormattedMessage
                                    id="from"
                                    defaultMessage="From"/>
                                &nbsp;

                                {quantityFrom}
                            </React.Fragment>
                        )}

                        {quantityTo && (
                            <React.Fragment>
                                &nbsp;
                                <FormattedMessage
                                    id="till"
                                    defaultMessage="till"/>
                                &nbsp;

                                {quantityTo}

                                &nbsp;
                                <FormattedMessage
                                    id="times"
                                    defaultMessage="times"/>
                            </React.Fragment>
                        )}
                    </Typography>
                </Card>
            </div>
        )
    }

    checkIfNotNotNullOrUndefined(filter) {
        return filter !== undefined && filter !== null;
    }

    renderSumFilter(filter, index) {
        const {classes} = this.props;
        const isPurchasesAmountFilter = this.checkIfNotNotNullOrUndefined(filter.purchasesAmountFrom)
            && this.checkIfNotNotNullOrUndefined(filter.purchasesAmountTo);
        const isOnePurchaseAmountFilter = this.checkIfNotNotNullOrUndefined(filter.onePurchaseAmountFrom)
            && this.checkIfNotNotNullOrUndefined(filter.onePurchaseAmountTo);
        const isPointsBalance = this.checkIfNotNotNullOrUndefined(filter.pointsBalanceFrom)
            && this.checkIfNotNotNullOrUndefined(filter.pointsBalanceTo)

        if (isPurchasesAmountFilter || isOnePurchaseAmountFilter || isPointsBalance) {
            return (
                <div
                    className={classes.cardWrapper}
                    key={index}>
                    {this.createDeleteIcon(filter, "sumFilter")}

                    <Card className={classes.card}>
                        {isPurchasesAmountFilter && this.renderPurchaseFilter(filter, true)}
                        {isOnePurchaseAmountFilter && this.renderPurchaseFilter(filter, false)}
                        {isPointsBalance && (
                            <div>
                                <Typography variant="body1">
                                    {filter.havePoints ? HAVE_POINTS : NOT_HAVE_POINTS}
                                </Typography>

                                <Typography variant="body1">
                                    <FormattedMessage
                                        id="from"
                                        defaultMessage="From"/>
                                    &nbsp;

                                    {filter.pointsBalanceFrom}

                                    &nbsp;
                                    <FormattedMessage
                                        id="till"
                                        defaultMessage="till"/>
                                    &nbsp;

                                    {filter.pointsBalanceTo}

                                    <FormattedMessage
                                        id="points" defaultMessage="points"/>
                                </Typography>
                            </div>
                        )}
                    </Card>
                </div>
            )
        }
    }

    renderPurchaseFilter(filter, isPurchasesAmount, index) {
        let filterDescription;
        let amountFrom;
        let amountTo;

        if (isPurchasesAmount) {
            filterDescription = (
                <React.Fragment>
                    {filter.madePurchase ? MADE_PURCHASE : NOT_MADE_PURCHASE}
                    &nbsp;
                    <Typography variant="body1">
                        <FormattedMessage
                            id="inTotal"
                            defaultMessage="In total"/>
                    </Typography>
                </React.Fragment>
            );
            amountFrom = filter.purchasesAmountFrom;
            amountTo = filter.purchasesAmountTo;
        } else {
            filterDescription = (
                <React.Fragment>
                    {filter.madePurchase ? MADE_PURCHASE : NOT_MADE_PURCHASE}
                    &nbsp;
                    <Typography variant="body1">
                        <FormattedMessage
                            id="atSinglePurchaseTransaction"
                            defaultMessage="At a single purchase transaction"/>
                    </Typography>
                </React.Fragment>
            );
            amountFrom = filter.onePurchaseAmountFrom;
            amountTo = filter.onePurchaseAmountTo;
        }

        return (
            <div key={index}>
                <Typography variant="body1">
                    {filterDescription}
                </Typography>

                <Typography
                    variant="body1"
                    component="div">
                    <FormattedMessage
                        id="from"
                        defaultMessage="From"/>
                    &nbsp;

                    {amountFrom} SEK

                    &nbsp;
                    <FormattedMessage
                        id="till"
                        defaultMessage="till"/>
                    &nbsp;

                    {amountTo} SEK
                </Typography>
            </div>
        )
    }

    renderDateFilter(filter, index) {
        const {classes} = this.props;
        const isPurchaseDateFilter = filter.purchaseDateFrom || filter.purchaseDateTo;
        const isBecameMemberDateFilter = filter.becameMemberDateFrom || filter.becameMemberDateTo;
        const isBornDateFilter = filter.day || filter.month || filter.year;

        let filterDescription;
        let dateFrom;
        let dateTo;

        if (isPurchaseDateFilter) {
            filterDescription = filter.purchaseInDateRange ? MADE_PURCHASE : NOT_MADE_PURCHASE;
            dateFrom = filter.purchaseDateFrom;
            dateTo = filter.purchaseDateTo;

        } else if (isBecameMemberDateFilter) {
            filterDescription = filter.becameMemberInDateRange ? BECAME_MEMBER : NOT_BECAME_MEMBER;
            dateFrom = filter.becameMemberDateFrom;
            dateTo = filter.becameMemberDateTo;
        } else if (isBornDateFilter) {
            filterDescription = filter.bornInDateRange ? BORN : NOT_BORN;
            const dayFrom = filter.bornDayFrom;
            const dayTo = filter.bornDayTo;
            const monthFrom = filter.bornMonthFrom;
            const monthTo = filter.bornMonthTo;
            const yearFrom = filter.bornYearFrom;
            const yearTo = filter.bornYearTo;

            const isDay = filter.day;
            const isMonth = filter.month;
            const isYear = filter.year;

            return (
                <div
                    className={classes.cardWrapper}
                    key={index}>
                    {this.createDeleteIcon(filter, "dateFilter")}
                    <Card className={classes.card}>
                        {filterDescription}:

                        {isDay && (
                            <Typography
                                variant="body1"
                                component="div">
                                <FormattedMessage
                                    id="from"
                                    defaultMessage="From"/>
                                &nbsp;

                                <FormattedMessage
                                    id="dayLowercase"
                                    defaultMessage="day"/>
                                &nbsp;

                                {dayFrom}

                                &nbsp;
                                <FormattedMessage
                                    id="till"
                                    defaultMessage="till"/>
                                &nbsp;

                                {dayTo}

                                &nbsp;
                                <FormattedMessage
                                    id="dayOfMonth"
                                    defaultMessage="day of month"/>
                            </Typography>
                        )}

                        {isMonth && (
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="from"
                                    defaultMessage="From"/>
                                &nbsp;

                                {months.find(m => m.value === monthFrom).key}

                                &nbsp;
                                <FormattedMessage
                                    id="till"
                                    defaultMessage="till"/>
                                &nbsp;

                                {months.find(m => m.value === monthTo).key}
                            </Typography>
                        )}

                        {isYear && (
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="from"
                                    defaultMessage="From"/>
                                &nbsp;

                                <FormattedMessage
                                    id="yearLowercase"
                                    defaultMessage="year"/>
                                &nbsp;

                                {yearFrom}

                                &nbsp;
                                <FormattedMessage
                                    id="till"
                                    defaultMessage="till"/>

                                &nbsp;

                                {yearTo}
                            </Typography>
                        )}
                    </Card>
                </div>
            )
        }

        return (
            <div
                className={classes.cardWrapper}
                key={index}>
                {this.createDeleteIcon(filter, "dateFilter")}

                <Card className={classes.card}>
                    <Typography variant="body1">
                        {filterDescription}
                    </Typography>

                    <Typography variant="body1">
                        {dateFrom && (
                            <React.Fragment>
                                <FormattedMessage
                                    id="from"
                                    defaultMessage="From"/>
                                &nbsp;

                                {getFormattedDate(dateFrom)}
                            </React.Fragment>
                        )}

                        {dateTo && (
                            <React.Fragment>
                                &nbsp;
                                <FormattedMessage
                                    id="till"
                                    defaultMessage="till"/>
                                &nbsp;

                                {getFormattedDate(dateTo)}
                            </React.Fragment>
                        )}
                    </Typography>
                </Card>
            </div>
        )
    }

    createDeleteIcon(filter, type) {
        const {classes} = this.props;
        return (
            <IconButton
                onClick={this.removeFilterFromCurrentSelection.bind(this, filter, type)}
                className={classes.iconButton}>
                <DeleteForever className={classes.deleteIcon}/>
            </IconButton>
        )
    }

    removeFilterFromCurrentSelection(filter, type) {
        if (type === "dateFilter") {
            this.props.currentSmartSelection.dateFilter = this.props.currentSmartSelection.dateFilter
                .filter(item => item !== filter)
        }
        if (type === "sumFilter") {
            this.props.currentSmartSelection.sumFilter = this.props.currentSmartSelection.sumFilter
                .filter(item => item !== filter)
        }
        if (type === "quantityFilter") {
            this.props.currentSmartSelection.quantityFilter = this.props.currentSmartSelection.quantityFilter
                .filter(item => item !== filter)
        }
        if (type === "tasteFilter") {
            this.props.currentSmartSelection.tasteFilter = this.props.currentSmartSelection.tasteFilter
                .filter(item => item !== filter)
        }

        this.props.filterMembersForCampaign(this.props.currentSmartSelection, this.props.campaignId)
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({publishCampaign, filterMembersForCampaign}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(CampaignRecipients));
