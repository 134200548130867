import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";

const styles = theme => ({
    button: {
        [theme.breakpoints.up("sm")]: {
            width: '40%'
        },
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        }
    }
});

class ClubPointsFormPage3 extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        title: PropTypes.object,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        nextPage: PropTypes.func,
        previousPage: PropTypes.func,
        setIsTemplate: PropTypes.func,
        generateTemplate: PropTypes.func
    };

    render() {
        const {classes, submitting, handleSubmit, nextPage, title, previousPage, setIsTemplate, generateTemplate} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {title}
                </Typography>

                <Typography
                    variant="subtitle1"
                    paragraph>
                    <FormattedMessage
                        id="termsForPoints"
                        defaultMessage="Terms for points"/>
                </Typography>

                <Typography
                    variant="body1"
                    paragraph>
                    <FormattedMessage
                        id="club-points.formulateYourTerms"
                        defaultMessage="Formulate your terms for points to be added to your membership terms & conditions."/>
                </Typography>

                <Typography
                    variant="body1"
                    paragraph>
                    <FormattedMessage
                        id="club-points.youCanEitherFormulate"
                        defaultMessage="You can either formulate your own, or start using the ready made template."/>
                </Typography>

                <Button
                    variant="text"
                    fullWidth
                    disabled={submitting}
                    onClick={handleSubmit}
                    label={
                        <Typography
                            variant="body1"
                            color="inherit">
                            <FormattedMessage
                                id="club-points.noThankYou"
                                defaultMessage="No thank you, the terms is already adapted for points."/>
                        </Typography>
                    }/>


                <Button
                    label={<FormattedMessage
                        id="tailorTerms"
                        defaultMessage="Tailor terms"/>}
                    color="secondary"
                    variant="outlined"
                    className={classes.button}
                    onClick={this.setNoTemplateAndGoToNextPage.bind(this, nextPage, setIsTemplate)}/>

                <Button
                    label={<FormattedMessage
                        id="generateTemplate"
                        defaultMessage="Generate template"/>}
                    className={classes.button}
                    onClick={this.generateTemplate.bind(this, nextPage, setIsTemplate, generateTemplate)}/>

                <Button
                    color='secondary'
                    label={<FormattedMessage
                        id="previous"
                        defaultMessage="Previous"/>}
                    onClick={previousPage}/>
            </form>
        );
    }

    setNoTemplateAndGoToNextPage(nextPage, setIsTemplate) {
        setIsTemplate(false);
        nextPage()
    }

    generateTemplate(nextPage, setIsTemplate, generateTemplate) {
        generateTemplate();
        setIsTemplate(true);
        nextPage()
    }

}

export default reduxForm({
    form: "clubPointsForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(withStyles(styles)(ClubPointsFormPage3))
