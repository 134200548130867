import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import ShoppingCart from "@material-ui/icons/ShoppingCart";

import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import TitleWithBackButton from "../../../common/components/TitleWithBackButton";
import Button from "../../../common/components/common/Button";
import PurchaseQuantityFilterForm from "./PurchaseQuantityFilterForm";

const Filters = {
    PURCHASES_FILTER: "PURCHASES_FILTER"
};

const styles = theme => ({
    icon: {
        color: theme.palette.secondary.main,
        fontSize: 40
    }
});

class QuantityFilters extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        chain: PropTypes.object,
        selectMembersInfo: PropTypes.object,
        moveBack: PropTypes.func,
        handleSubmit: PropTypes.func
    };

    state = {
        currentFilter: null,
    };

    onFilterClick(filter) {
        this.setState({currentFilter: filter});
    }

    moveBack(moveBack) {
        if (this.state.currentFilter === null) {
            moveBack();
        }
        this.setState({currentFilter: null});
    }

    render() {
        const {classes, moveBack, handleSubmit, chain, selectMembersInfo} = this.props;

        const QuantityFiltersOptions = [
            {
                label: <FormattedMessage id="numberOfPurchases" defaultMessage="Number of purchases"/>,
                icon: <ShoppingCart className={classes.icon}/>,
                value: Filters.PURCHASES_FILTER
            },
        ];

        return (
            <React.Fragment>
                <TitleWithBackButton
                    title={<FormattedMessage
                        id="smartSelection"
                        defaultMessage="Smart Selection"/>}
                    onBackClick={this.moveBack.bind(this, moveBack)}/>

                {!this.state.currentFilter && (
                    <React.Fragment>
                        <Typography
                            variant="body1"
                            paragraph>
                            <FormattedMessage
                                id="chooseWhichQuantityYouWantToUse"
                                defaultMessage="Choose which quantity you want to use as a selection"/>:
                        </Typography>

                        <ImageBoxRow
                            options={QuantityFiltersOptions}
                            filter
                            onFilterClick={this.onFilterClick.bind(this)}
                            squareShape/>
                    </React.Fragment>
                )}

                {this.renderFilter(handleSubmit, chain, selectMembersInfo)}
            </React.Fragment>
        );
    }

    renderFilter(handleSubmit, chain, selectMembersInfo) {
        const addSmartFilterButton = (
            <Button
                icon={<Add/>}
                label={<FormattedMessage
                    id="addSmartSelection"
                    defaultMessage="Add Smart Selection"/>}
                type="submit"/>
        );


        switch (this.state.currentFilter) {
            case Filters.PURCHASES_FILTER:
                return (
                    <PurchaseQuantityFilterForm
                        chain={chain}
                        selectMembersInfo={selectMembersInfo}
                        button={addSmartFilterButton}
                        onSubmit={handleSubmit}/>
                );
        }
    }
}

export default withStyles(styles)(QuantityFilters);
