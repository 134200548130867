import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ReactTooltip from 'react-tooltip'

import Spinner from "../../../common/components/common/Spinner";
import {KarmingTable} from "../../../common/components/common/table/KarmingTable";
import Button from "../../../common/components/common/Button";
import Container from "../../../common/components/common/Container";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "../../../common/components/common/Paper";
import TableTitle from "../../../common/components/common/table/TableTitle";
import Send from '@material-ui/icons/Send';
import Person from '@material-ui/icons/Person';
import Star from '@material-ui/icons/Star';
import Settings from '@material-ui/icons/Settings';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';

import {fetchMerchants} from "../../actions/merchant-actions";
import {fetchUserDetails} from "../../../common/actions/user-actions";
import {ROLE_CASHIER, ROLE_LOCAL_MERCHANT, ROLE_MERCHANT} from "../../../common/components/auth/roles";

const styles = {
    icon: {
        position: 'absolute',
        left: 15
    }
};

class MerchantUsers extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        profile: PropTypes.object,
        history: PropTypes.object,
        app: PropTypes.object,
        auth: PropTypes.object,
        role: PropTypes.string,
        merchants: PropTypes.array,
        fetchMerchants: PropTypes.func,
        fetchUserDetails: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchUserDetails();
        this.props.fetchMerchants();
    }

    render() {
        const {classes, app, merchants, profile, history, auth} = this.props;

        if (!merchants || !profile) {
            return <Spinner app={app}/>
        }

        const userRole = auth.role;

        const columns = [
            {
                Header: <FormattedMessage
                    id="firstName"
                    defaultMessage="First name"/>,
                id: "firstName",
                accessor: m => m.firstName,
                Cell: user => {
                    if (profile.id === user.original.id) {
                        return (
                            <div>
                                <span data-tip data-for='itIsYou'>
                                    <Person className={classes.icon}/>
                                </span>
                                &nbsp;
                                {user.value}
                                <ReactTooltip
                                    id='itIsYou'
                                    type='info'>
                                    <FormattedMessage
                                        id="itIsYou"
                                        defaultMessage="It's you"/>
                                </ReactTooltip>
                            </div>
                        )
                    } else if (ROLE_MERCHANT === user.original.authority) {
                        return (
                            <div>
                                <span data-tip data-for='superadmin'>
                                    <Star className={classes.icon}/>
                                </span>
                                &nbsp;
                                {user.value}
                                <ReactTooltip
                                    id='superadmin'
                                    type='info'>
                                    Superadmin
                                </ReactTooltip>
                            </div>
                        )
                    } else if (ROLE_LOCAL_MERCHANT === user.original.authority) {
                        return (
                            <div>
                                <span data-tip data-for='localAdmin'>
                                    <Settings className={classes.icon}/>
                                </span>
                                &nbsp;
                                {user.value}
                                <ReactTooltip
                                    id='localAdmin'
                                    type='info'>
                                    <FormattedMessage
                                        id="localAdmin"
                                        defaultMessage="Local admin"/>
                                </ReactTooltip>
                            </div>
                        )
                    } else if (ROLE_CASHIER === user.original.authority) {
                        return (
                            <div>
                                <span data-tip data-for='cashier'>
                                    <ShoppingBasket className={classes.icon}/>
                                </span>
                                &nbsp;
                                {user.value}
                                <ReactTooltip
                                    id='cashier'
                                    type='info'>
                                    <FormattedMessage
                                        id="cashier"
                                        defaultMessage="Cashier"/>
                                </ReactTooltip>
                            </div>
                        )
                    }

                }
            },
            {
                Header: <FormattedMessage
                    id="lastName"
                    defaultMessage="Last name"/>,
                id: "lastName",
                accessor: m => m.lastName,
            },
            {
                Header: <FormattedMessage
                    id="email"
                    defaultMessage="Email"/>,
                id: "email",
                accessor: m => m.email,
            },
            {
                Header: <FormattedMessage
                    id="phone"
                    defaultMessage="Phone"/>,
                id: "phone",
                accessor: m => m.phone,
            }
        ];

        return (
            <Container size={Container.SIZE_HUGE}>
                <Paper>
                    <TableTitle
                        title={<FormattedMessage
                            id="users"
                            defaultMessage="Users"/>}
                        button={
                            <Button
                                icon={<Send/>}
                                label={<FormattedMessage
                                    id="invite"
                                    defaultMessage="Invite"/>}
                                onClick={this.moveToInviteMerchant.bind(this)}/>
                        }/>

                    <KarmingTable
                        history={history}
                        data={merchants}
                        columns={columns}
                        onRowClickHistoryPush={(state, row) => {
                            if ((userRole === ROLE_MERCHANT
                                || row.authority === ROLE_CASHIER
                                || (profile.controlLocalAdmin && row.authority === ROLE_LOCAL_MERCHANT))
                                && profile.id !== row.id) {
                                return (
                                    {
                                        pathname: "/users/" + row.id,
                                        state: {merchant: row}
                                    })
                            }
                        }
                        }/>
                </Paper>
            </Container>
        );
    }

    moveToInviteMerchant() {
        this.props.history.push("/users/invite");
    }

}

function mapStateToProps(state) {
    return {
        profile: state.user.profile,
        merchants: state.merchant.merchants
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchMerchants, fetchUserDetails}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MerchantUsers));
