import React from "react";
import {getFormValues, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import LocationSearching from "@material-ui/icons/LocationSearching";
import Typography from "@material-ui/core/Typography";
import Save from '@material-ui/icons/Save';
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew';
import Edit from '@material-ui/icons/Edit';
import Publish from '@material-ui/icons/Publish';
import Send from '@material-ui/icons/Send';

import Button from "../../../../common/components/common/Button";
import CampaignPage from "../../../../common/components/common/campaign/CampaignPage";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

const styles = {
    button: {
        width: '45%'
    }
};

class CampaignFormPage6 extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        title: PropTypes.object,
        history: PropTypes.object,
        auth: PropTypes.object,
        campaign: PropTypes.object,
        initialValues: PropTypes.object,
        formValues: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        submitting: PropTypes.bool,
        isWelcomeOffer: PropTypes.bool,
        handleSubmit: PropTypes.func,
        setFormValue: PropTypes.func,
        handlePublish: PropTypes.func,
        handleDeactivate: PropTypes.func,
        firstPage: PropTypes.func
    };

    render() {
        const {
            handleSubmit, submitting, handlePublish, handleDeactivate, initialValues, firstPage, campaign, title, auth,
            formValues, history, isUpdate, isWelcomeOffer, classes
        } = this.props;

        if (!(campaign && ((campaign.banner && campaign.banner.newData) || campaign.bannerPath))) {
            campaign.bannerPath = initialValues.bannerPath
        }

        const isDirty = !initialValues
            || initialValues
            && formValues
            && formValues.banner
            && (formValues.banner.isModified
                || this.isFormChanged(formValues, initialValues));

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <form onSubmit={handleSubmit} autoComplete="on">
                        <Typography
                            variant="h5"
                            paragraph>
                            {title}
                        </Typography>

                        <CampaignPage auth={auth} campaign={campaign}/>

                        <Button
                            icon={<Edit/>}
                            label={<FormattedMessage
                                id="edit"
                                defaultMessage="Edit"/>}
                            color="secondary"
                            className={classes.button}
                            onClick={firstPage}/>

                        {isDirty && (
                            <Button
                                icon={<Save/>}
                                label={this.getSaveButtonLabel(initialValues)}
                                type="submit"
                                className={classes.button}
                                disabled={submitting}/>
                        )}

                        {!isDirty
                        && initialValues
                        && !initialValues.welcomeOffer
                        && initialValues.publishStatus === "ACTIVE" && (
                            <Button
                                icon={<Send/>}
                                className={classes.button}
                                label={<FormattedMessage
                                    id="sendout"
                                    defaultMessage="Sendout"/>}
                                onClick={this.moveToSendout.bind(this, initialValues.id, history)}
                                disabled={submitting}/>
                        )}

                        {!isDirty
                        && initialValues
                        && initialValues.publishStatus === "ACTIVE" && (
                            <Button
                                icon={<PowerSettingsNew/>}
                                label={<FormattedMessage
                                    id="deactivate"
                                    defaultMessage="Deactivate"/>}
                                className={classes.button}
                                customColor="red"
                                onClick={handleDeactivate}
                                disabled={submitting}/>
                        )}

                        {isUpdate
                        && initialValues
                        && isWelcomeOffer
                        && initialValues.publishStatus !== "ACTIVE" && (
                            <Button
                                icon={<Publish/>}
                                label={<FormattedMessage
                                    id="publish"
                                    defaultMessage="Publish"/>}
                                className={classes.button}
                                onClick={handlePublish}
                                disabled={submitting}/>
                        )}

                        {!isDirty
                        && isUpdate
                        && initialValues
                        && !isWelcomeOffer && (
                            <Button
                                icon={
                                    initialValues.publishStatus !== "ACTIVE"
                                        ? <Publish/>
                                        : <LocationSearching/>
                                }
                                label={
                                    initialValues.publishStatus !== "ACTIVE"
                                        ? <FormattedMessage
                                            id="publish"
                                            defaultMessage="Publish"/>
                                        : <FormattedMessage
                                            id="changeSelection"
                                            defaultMessage="Change Selection"/>
                                }
                                className={classes.button}
                                onClick={() => history.push(`/campaigns/recipients/${initialValues.id}`)}
                                disabled={submitting}/>
                        )}
                    </form>
                </Paper>
            </Container>
        );
    }

    moveToSendout(campaignId, history) {
        history.push("/campaigns/" + campaignId + "/sendout");
    }

    getSaveButtonLabel(initialValues) {
        return initialValues
            ? <FormattedMessage
                id="save"
                defaultMessage="Save"/>
            : <React.Fragment>
                <FormattedMessage
                    id="save"
                    defaultMessage="Save"/>
                &nbsp;
                <FormattedMessage
                    id="draft"
                    defaultMessage="draft"/>
            </React.Fragment>
    }

    isFormChanged(formValues, initialValues) {
        // FIXME ugly workaround to not compare those values
        initialValues.banner = formValues['banner'];
        return JSON.stringify(formValues) !== JSON.stringify(initialValues)
    }
}

function mapStateToProps(state) {
    return {
        formValues: getFormValues('campaignForm')(state),
    }
}

export default reduxForm({
    form: "campaignForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(connect(mapStateToProps, null)(withStyles(styles)(CampaignFormPage6)))
