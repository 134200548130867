import {
    APP_ICON_DATA_NAME,
    APP_ICON_FIELD_NAME,
    LOGO_FIELD_NAME,
    LOGO_FORM_DATA_NAME
} from "../components/account/saleslocation/chains/CommercialChain";

const OPENING_HOURS_FIELD_NAME = "openingHours";

export function prepareFormData(values, body = new FormData()) {
    for (let [key, value] of Object.entries(values)) {
        if (value instanceof FileList || value instanceof Array) {
            if (key === OPENING_HOURS_FIELD_NAME) {
                for (let i = 0; i < value.length; i++) {
                    let details = JSON.stringify(value[i]);
                    body.append(key, details);
                    if (value.length === 1) {
                        body.append(key, "");
                    }
                }
            } else if (key === LOGO_FIELD_NAME) {
                body.append(LOGO_FORM_DATA_NAME, value[0]);
            } else if (key === APP_ICON_FIELD_NAME) {
                body.append(APP_ICON_DATA_NAME, value[0]);
            }
        } else {
            body.append(key, value);
        }
    }
    return body;
}

export function prepareCampaignFormData(values, body = new FormData()) {
    for (let [key, value] of Object.entries(values)) {
        if (key === "banner" && value && value.newData) {
            body.append(key, value.newData);
        } else if (key === "couponDetails" && value) {
            body.set(key, JSON.stringify(value));
        } else if (key !== "banner" && key !== "startDate" && key !== "endDate") {
            body.append(key, value)
        } else if ((key === "startDate" || key === "endDate") && value instanceof Date) {
            body.append(key, value)
        }
    }
    return body;
}

export function prepareCouponFormData(values, body = new FormData()) {
    for (let [key, value] of Object.entries(values)) {
        if (key === "image" && value && value.newData) {
            body.append(key, value.newData);
        } else {
            body.append(key, value)
        }

        if (key === "couponDetails" && value) {
            body.set(key, JSON.stringify(value));
        }
    }
    return body;
}

export function prepareStampcardFormData(values, body = new FormData()) {
    for (let [key, value] of Object.entries(values)) {
        if (key === "image") {
            if (value && value.newData) {
                body.append(key, value.newData);
            }
        } else if (key !== "stampcardCoupon") {
            body.append(key, value)
        }

        if (key === "couponDetails" && value) {
            body.set(key, JSON.stringify(value));
        }
    }
    return body;
}
