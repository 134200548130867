import React from "react"
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import MemberPaper from "../member/MemberPaper";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import Spinner from "../../../common/components/common/Spinner";

const styles = theme => ({
    list: {
        paddingLeft: 0,
        "& > *:not(:last-child)": {
            marginBottom: theme.spacing(2)
        }
    },
    listItem: {
        listStyleType: 'none'
    }
});

class SearchedMemberList extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        consumers: PropTypes.array,
        history: PropTypes.object,
        location: PropTypes.object
    };

    render() {
        const {classes, consumers, history} = this.props;

        if (!consumers) {
            return <Spinner/>
        }

        let members = [];

        if (consumers) {
            members = consumers
        } else {
            members = this.props.location.state.consumers;
        }

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <Typography variant="h5">
                        <FormattedMessage
                            id="selectMemberToCheckout"
                            defaultMessage="Select member to checkout"/>
                    </Typography>

                    <Typography
                        variant="subtitle1"
                        color="primary">
                        {members.length}&nbsp;
                        <FormattedMessage
                            id="memberFound"
                            defaultMessage="members found"/>!
                    </Typography>

                    <ul className={classes.list}>
                        {members.map((member) => (
                            <li key={member.id} className={classes.listItem}>
                                <MemberPaper
                                    member={member}
                                    onClick={() => history.push("/purchase/register/" + member.id)}/>
                            </li>
                        ))}
                    </ul>
                </Paper>
            </Container>
        )
    }

}

function mapStateToProps(state) {
    return {
        consumers: state.merchant.consumers,
    };
}

export default connect(mapStateToProps, null)(withStyles(styles)(SearchedMemberList));
