import axios from "axios";
import {ROOT_URL} from "../../common/config/karming-links";
import {apiError, headerConfig} from "../../common/actions/utils-actions";
import {
    CREATE_COMMERCIAL_CHAIN,
    FETCH_MAX_POINTS_BALANCE,
    FETCH_MERCHANT_COMMERCIAL_CHAIN,
    FETCH_MERCHANT_COMMERCIAL_CHAINS,
    UPDATE_COMMERCIAL_CHAIN
} from "../../common/actions/types";
import {fetchPointOfSale} from "./merchant-pos-actions";
import {ROLE_MERCHANT} from "../../common/components/auth/roles";

const ENDPOINT_URL = `${ROOT_URL}/merchant`;

export function fetchMaxPointsBalance(chainId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/max/points/${chainId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_MAX_POINTS_BALANCE,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to get max points balance', response));
            });
    }
}

export function createSinglePosChain(values, history) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/poschain/create/?timestamp=${Date.now()}`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: CREATE_COMMERCIAL_CHAIN,
                    payload: response.data
                });
                dispatch(fetchCommercialChains())
                dispatch(chooseCurrentChain(response.data))

                history.push("/")
            })
            .catch(response => {
                dispatch(apiError('Unable to create single point of sale', response));
            });
    }
}

export function createCommercialChain(values, history) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/chain/create/?timestamp=${Date.now()}`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: CREATE_COMMERCIAL_CHAIN,
                    payload: response.data
                });
                dispatch(fetchCommercialChains())
                dispatch(chooseCurrentChain(response.data))

                history.push("/locations/create")
            })
            .catch(response => {
                dispatch(apiError('Unable to create commercial chain', response));
            });
    }
}

export function updateCommercialChain(chainId, values) {
    if (!chainId) {
        throw "updateCommercialChain: chainId Required";
    }

    return dispatch => {
        axios.post(`${ENDPOINT_URL}/chains/${chainId}`, values, headerConfig())
            .then(response => {
                dispatch({
                    type: UPDATE_COMMERCIAL_CHAIN,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to update commercial chain', response));
            });
    }
}

export function fetchCommercialChain(chainId, profile) {
    if (!chainId) {
        throw "fetchCommercialChain: chainId Required";
    }
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chains/${chainId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_MERCHANT_COMMERCIAL_CHAIN,
                    payload: response.data
                });
                if (profile) {
                    const availablePointsOfSale = response.data.pointsOfSale
                        .filter(pointOfSale => profile.authority === ROLE_MERCHANT
                            || profile.pointsOfSaleIds.includes(pointOfSale.id));
                    if (availablePointsOfSale.length > 0) {
                        dispatch(chooseCurrentPoS(availablePointsOfSale[0].id))
                    }
                }
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch commercial chain', response));
            });
    }
}

export function fetchCommercialChains() {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/chains`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_MERCHANT_COMMERCIAL_CHAINS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch Commercial Chains', response));
            });
    }
}

export function chooseCurrentPoS(posId, fetchChain) {
    if (!posId) {
        throw "chooseCurrentPoS: posId Required";
    }

    return dispatch => {
        localStorage.setItem('currentPoSId', posId);

        dispatch(fetchPointOfSale(posId, true, fetchChain))
    }
}

export function chooseCurrentChain(chainId, profile) {
    if (!chainId) {
        throw "chooseCurrentChain: chainId Required";
    }

    return dispatch => {
        localStorage.setItem('currentChainId', chainId);
        dispatch(fetchCommercialChain(chainId, profile))
    }
}

export function prepareMerchantChainsState() {
    let currentChainId = localStorage.getItem('currentChainId');
    let currentPoSId = localStorage.getItem('currentPoSId');

    return dispatch => {
        if (currentChainId != null) {
            dispatch(chooseCurrentChain(currentChainId))
        }
        if (currentPoSId != null) {
            dispatch(chooseCurrentPoS(currentPoSId))
        }
        axios.get(`${ENDPOINT_URL}/chains`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_MERCHANT_COMMERCIAL_CHAINS,
                    payload: response.data
                });

                if (currentChainId == null && response.data.length > 0) {
                    currentChainId = response.data[response.data.length - 1].id // SET default chain
                    dispatch(chooseCurrentChain(currentChainId))
                }
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch Commercial Chains', response));
            });
    }
}
