import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import BannerBuilder, {bannerRequired} from "../banner/BannerBuilder";

class CouponFormPage4 extends React.Component {

    static propTypes = {
        title: PropTypes.object,
        initialValues: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func,
    };

    render() {
        const {handleSubmit, previousPage, initialValues, title, isUpdate} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h5"
                    paragraph>
                    {title}
                </Typography>

                <Typography
                    variant="subtitle1"
                    component="div">
                    <React.Fragment>
                        &nbsp;
                        <FormattedMessage
                            id="banner"
                            defaultMessage="banner"/>
                        &nbsp;*
                    </React.Fragment>
                </Typography>

                <Field
                    name="image"
                    component={BannerBuilder}
                    currentImageFile={this.getBannerPath(initialValues)}
                    type="blob"
                    onNext={handleSubmit}
                    onPrevious={previousPage}
                    isUpdate={isUpdate}
                    validate={[bannerRequired]}/>
            </form>
        );
    }

    getBannerPath(coupon) {
        if (coupon) {
            return coupon.imagePath
        }
    }

}

export default reduxForm({
    form: "couponForm",
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(CouponFormPage4);
