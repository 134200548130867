import React from "react";
import PropTypes from "prop-types";

import TermsForm from "./TermsForm";

export default class TermsEdit extends React.Component {

    static propTypes = {
        terms: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        app: PropTypes.object,
        user: PropTypes.object,
        history: PropTypes.object,
        title: PropTypes.object,
        termsPublished: PropTypes.bool,
        onSubmit: PropTypes.func
    };

    render() {
        const {onSubmit, terms, title, termsPublished} = this.props;

        return (
            <TermsForm
                termsPublished={termsPublished}
                enableReinitialize={true}
                onSubmit={onSubmit}
                title={title}
                initialValues={this.prepareInitialValues(terms)}
            />
        )
    }

    prepareInitialValues(terms) {
        let initialValues = null;
        if (terms) {
            initialValues = {...initialValues, 'title': terms.title,};
            initialValues = {...initialValues, 'paragraphs': terms.termsParagraphs};
            //TODO
        }

        return initialValues;
    }

}
