import React from "react";
import {Field, FieldArray, formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import Save from '@material-ui/icons/Save';

import Button from "../../../../../common/components/common/Button";
import RenderTextField from "../../../../../common/components/common/RenderTextField";
import OpeningHoursFields from "../../../../../common/components/common/form/OpeningHoursFields";
import RenderDropzone from "../../../../../common/components/common/form/ReactDropzone";
import Checkbox from './../../../../../common/components/common/form/RenderCheckbox';

import {required, RequiredInfo} from "../../../../../common/components/common/form/FormUtils";
import {LOGO_FIELD_NAME} from "../chains/CommercialChain";
import {
    emailValidation,
    locationPhoneValidation,
    subpageValidation
} from "../../../../../common/utils/karming-validators";

const COMMERCIAL_NAME_DIFFERENT_FIELD_NAME = "commercialNameDifferent";
const EMAIL_DIFFERENT_FIELD_NAME = "emailDifferent";
const PHONE_DIFFERENT_FIELD_NAME = "phoneDifferent";

const validate = values => {
    return Object.assign(
        emailValidation({name: "email", value: values.email}),
        locationPhoneValidation({name: "phone", value: values.phone}),
        subpageValidation({name: "subpage", value: values.subpage}),
    )
};

const styles = theme => ({
    subtitle: {
        marginTop: theme.spacing(2)
    },
    doubleFieldsWrapper: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        "& > div": {
            width: '50%'
        },
        "& > :first-child": {
            marginRight: theme.spacing(1)
        }
    },
    websiteWrapper: {
        position: 'relative',
        display: 'flex',
        "& > :first-child": {
            width: '50%',
            textAlign: 'left',
            marginTop: theme.spacing(4)
        },
        "& > :last-child": {
            width: '50%',
            marginLeft: theme.spacing(1)
        }
    },
    imageDropzone: {
        verticalAlign: 'center',
        [theme.breakpoints.up("md")]: {
            width: '50%',
            margin: '0 auto'
        }
    },
    imagePreview: {
        marginTop: -218,
        marginBottom: 30,
        width: '95%',
        height: '210px !important'
    }
});

class PointOfSaleForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        chain: PropTypes.object,
        initialValues: PropTypes.object,
        submitting: PropTypes.bool,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        commercialNameDifferent: PropTypes.bool,
        setFormValue: PropTypes.func,
        handleSubmit: PropTypes.func,
        change: PropTypes.func
    };

    UNSAFE_componentWillMount() {
        const {initialValues, chain} = this.props;
        if (initialValues && initialValues.logoPath) {
            window.URL.revokeObjectURL(initialValues.logoPath)
        } else if (chain && chain.logoPath) {
            window.URL.revokeObjectURL(chain.logoPath)
        }
    }

    componentWillUnmount() {
        const {initialValues, chain} = this.props;
        if (initialValues && initialValues.logoPath) {
            window.URL.revokeObjectURL(initialValues.logoPath)
        } else if (chain && chain.logoPath) {
            window.URL.revokeObjectURL(chain.logoPath)
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            isLogoVisible: true,
        }
    }

    removeFile() {
        this.props.setFormValue("pointOfSaleForm", "removeLogo", true);
        this.setState({
            isLogoVisible: false,
        });
    }

    render() {
        const {classes, handleSubmit, submitting, initialValues, chain, isUpdate, commercialNameDifferent} = this.props;
        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="h5">
                    <FormattedMessage
                        id="pointOfSale"
                        defaultMessage="Point of Sale"/>
                </Typography>

                <Field
                    name="commercialName"
                    component={RenderTextField}
                    disabled={!isUpdate && !commercialNameDifferent}
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="commercialName"
                                defaultMessage="Commercial name"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    validate={[required]}
                    type="text"/>

                {!isUpdate && (
                    <Field
                        name={COMMERCIAL_NAME_DIFFERENT_FIELD_NAME}
                        component={Checkbox}
                        onChange={(e, value) => {
                            if (!value) {
                                this.props.change("commercialName", initialValues.commercialName);
                                this.props.change("subpage", null)
                            }
                        }}
                        label={<FormattedMessage
                            id="commercialNameDifferentThanCommercialChain"
                            defaultMessage="Different commercial name than commercial chain"/>}/>
                )}

                {((isUpdate
                    || (!isUpdate && commercialNameDifferent))
                    && !initialValues.singlePoint) && (
                    <div className={classes.websiteWrapper}>
                        <Typography variant="body1">
                            {chain.subdomain}.kundklubb.online/
                        </Typography>

                        <Field
                            name="subpage"
                            component={RenderTextField}
                            type="text"
                            hintText={<FormattedMessage
                                id="point-of-sale-form.subpage"
                                defaultMessage="Subpage"/>}/>
                    </div>
                )}

                <Typography
                    variant="subtitle1"
                    className={classes.subtitle}
                    component="div">
                    <FormattedMessage
                        id="logo"
                        defaultMessage="Logo"/>
                </Typography>

                <div className={classes.imageDropzone}>
                    <Field
                        name={LOGO_FIELD_NAME}
                        component={RenderDropzone}
                        hasImage={this.state.isLogoVisible && ((initialValues && initialValues.logoPath) || (chain && chain.logoPath))}
                        type="file"/>

                    {this.renderLogo(initialValues, chain)}
                </div>

                <Field
                    name="street"
                    component={RenderTextField}
                    type="text"
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="address"
                                defaultMessage="Address"/>
                            &nbsp;*
                        </React.Fragment>
                    }
                    validate={[required]}/>

                <div className={classes.doubleFieldsWrapper}>
                    <Field
                        name="city"
                        component={RenderTextField}
                        type="text"
                        hintText={
                            <React.Fragment>
                                <FormattedMessage
                                    id="city"
                                    defaultMessage="City"/>
                                &nbsp;*
                            </React.Fragment>
                        }
                        validate={[required]}/>

                    <Field
                        name="zipCode"
                        component={RenderTextField}
                        type="text"
                        hintText={
                            <React.Fragment>
                                <FormattedMessage
                                    id="zipCode"
                                    defaultMessage="Zip code"/>
                                &nbsp;*
                            </React.Fragment>
                        }
                        validate={[required]}/>
                </div>

                <div className={classes.doubleFieldsWrapper}>
                    <div>
                        <Field
                            name="email"
                            component={RenderTextField}
                            type="text"
                            hintText={<FormattedMessage
                                id="email"
                                defaultMessage="Email"/>}/>

                        {!isUpdate && initialValues.email && (
                            <Field
                                name={EMAIL_DIFFERENT_FIELD_NAME}
                                component={Checkbox}
                                onChange={(e, value) => {
                                    if (!value) {
                                        this.props.change("email",
                                            initialValues.email)
                                    }
                                }}
                                label={<FormattedMessage
                                    id="emailDifferentThanChain"
                                    defaultMessage="Different email than chain"/>}/>
                        )}
                    </div>

                    <div>
                        <Field
                            name="phone"
                            component={RenderTextField}
                            type="text"
                            hintText={<FormattedMessage
                                id="phone"
                                defaultMessage="Phone"/>}/>

                        {!isUpdate && initialValues.phone && (
                            <Field
                                name={PHONE_DIFFERENT_FIELD_NAME}
                                component={Checkbox}
                                onChange={(e, value) => {
                                    if (!value) {
                                        this.props.change("phone",
                                            initialValues.phone)
                                    }
                                }}
                                label={<FormattedMessage
                                    id="phoneDifferentThanChain"
                                    defaultMessage="Different phone than  chain"/>}/>
                        )}
                    </div>
                </div>

                <Typography
                    variant="subtitle1"
                    className={classes.subtitle}>
                    <FormattedMessage
                        id="openingHours"
                        defaultMessage="Opening hours"/>
                </Typography>

                <FieldArray name="openingHours" component={OpeningHoursFields}/>

                <Button
                    icon={<Save/>}
                    label={<FormattedMessage
                        id="save"
                        defaultMessage="Save"/>}
                    type="submit"
                    disabled={submitting}/>

                <RequiredInfo/>
            </form>
        );
    }

    renderLogo(initialValues, chain) {
        const {classes} = this.props;
        if (this.state.isLogoVisible && ((initialValues && initialValues.logoPath) || (chain && chain.logoPath))) {
            return (
                <React.Fragment>
                    <img className={classes.imagePreview} key={this.getLogoPath(initialValues, chain)}
                         src={this.getLogoPath(initialValues, chain)}/>
                    <Button
                        icon={<DeleteOutlined/>}
                        label={<FormattedMessage
                            id="removeFile"
                            defaultMessage="Remove file"/>}
                        customColor="red"
                        onClick={this.removeFile.bind(this)}/>
                </React.Fragment>
            )
        }
    }

    getLogoPath(initialValues, chain) {
        const timestamp = new Date().getTime();
        if (initialValues && initialValues.logoPath) {
            return initialValues.logoPath + '?' + timestamp;
        }
        return chain.logoPath + '?' + timestamp;
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('pointOfSaleForm');
    const commercialNameDifferent = selector(state, COMMERCIAL_NAME_DIFFERENT_FIELD_NAME);
    const phoneDifferent = selector(state, PHONE_DIFFERENT_FIELD_NAME);
    const emailDifferent = selector(state, EMAIL_DIFFERENT_FIELD_NAME);
    return {
        commercialNameDifferent,
        phoneDifferent,
        emailDifferent,
    }
}

export default reduxForm({
    form: 'pointOfSaleForm',
    validate
})(connect(mapStateToProps, null)(withStyles(styles)(PointOfSaleForm)))
