import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import SinglePosChainForm from "./SinglePosChainForm";
import Container from "../../../../common/components/common/Container";
import Paper from "../../../../common/components/common/Paper";

import {prepareFormData} from "../../../utils/FormHelper";
import {createSinglePosChain} from "../../../actions/merchant-chain-actions";
import {APP_ICON_TEMPLATE} from "./chains/CommercialChain";

class SinglePoSChain extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        createSinglePosChain: PropTypes.func
    };

    onSubmit(values) {
        const body = prepareFormData(values);
        this.props.createSinglePosChain(body, this.props.history)
    }

    render() {
        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <SinglePosChainForm
                        initialValues={{appIconPath: APP_ICON_TEMPLATE}}
                        onSubmit={this.onSubmit.bind(this)}/>
                </Paper>
            </Container>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({createSinglePosChain}, dispatch);
}

export default connect(null, mapDispatchToProps)(SinglePoSChain);
