import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";
import PropTypes from "prop-types";

import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import List from '@material-ui/icons/List';
import Collapse from "@material-ui/core/Collapse";

import {renderExpandableMenuIcon} from "./AccountMenu";

import {
    ACCOUNT_INCIDENT_REPORT_URL,
    CHAIN_MEMBERSHIP_PRIVACY_URL,
    DATA_PROTECTION_DPA_URL,
    DATA_PROTECTION_INTRO_URL,
    DATA_PROTECTION_RECORD_LIST_URL,
    DATA_PROTECTION_USEFUL_LINKS_URL
} from "../../../../static/utils/redirect-const";

function DataProtectionCenterSubmenu(props) {
    const {classes, onItemClicked, atLeastOneChain} = props;
    const [openSubmenu, setSubmenuOpen] = useState(false);

    const handleToggle = () => {
        setSubmenuOpen(prevOpen => !prevOpen);
    };

    return (
        <React.Fragment>
            <MenuItem
                className={openSubmenu
                    ? classes.expandedMenuItemAccount
                    : classes.menuItemAccount}
                onClick={handleToggle}>
                <List className={classes.menuItemIconMain}/>
                <Typography color="inherit">
                    <FormattedMessage
                        id="dataProtectionCenter"
                        defaultMessage="Data Protection Center"/>
                </Typography>

                {renderExpandableMenuIcon(openSubmenu)}
            </MenuItem>

            <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                <MenuItem
                    className={classes.submenuItemAccount}
                    component={Link}
                    to={DATA_PROTECTION_INTRO_URL}
                    onClick={onItemClicked}>
                    <List className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="dataProtectionIntroduction"
                            defaultMessage="Introduction to Data Protection"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classnames(
                        classes.submenuItemAccount,
                        !atLeastOneChain && classes.disabledMenuItem)}
                    component={Link}
                    to={CHAIN_MEMBERSHIP_PRIVACY_URL}
                    onClick={onItemClicked}
                    disabled={!atLeastOneChain}>
                    <List className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="privacyPolicy"
                            defaultMessage="Privacy Policy"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.submenuItemAccount}
                    component={Link}
                    to={DATA_PROTECTION_RECORD_LIST_URL}
                    onClick={onItemClicked}>
                    <List className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="recordList"
                            defaultMessage="Record List"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.submenuItemAccount}
                    component={Link}
                    to={DATA_PROTECTION_USEFUL_LINKS_URL}
                    onClick={onItemClicked}>
                    <List className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="usefulLinks"
                            defaultMessage="Useful Links"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.submenuItemAccount}
                    component={Link}
                    to={DATA_PROTECTION_DPA_URL}
                    onClick={onItemClicked}>
                    <List className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="dataProcessingAgreement"
                            defaultMessage="Data Processing Agreement"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.submenuItemAccount}
                    component={Link}
                    to={ACCOUNT_INCIDENT_REPORT_URL}
                    onClick={onItemClicked}>
                    <List className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="incidentReport"
                            defaultMessage="Incident Report"/>
                    </Typography>
                </MenuItem>

                <Divider className={classes.divider}/>
            </Collapse>
        </React.Fragment>
    )
}

DataProtectionCenterSubmenu.propTypes = {
    classes: PropTypes.object,
    onItemClicked: PropTypes.func,
    atLeastOneChain: PropTypes.bool
};

export default withRouter(DataProtectionCenterSubmenu)
