import React from "react";
import {FormattedMessage} from "react-intl";

import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import AccountCircle from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme => ({
    root: {
        position: 'absolute',
        top: 0,
        right: theme.spacing(2),
        display: 'grid',
        padding: theme.spacing(0.5),
        margin: theme.spacing(0.5),
        textTransform: 'capitalize',
        color: theme.palette.common.white,
        '& svg': {
            margin: '0 auto'
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(0.25),
            margin: theme.spacing(0.25),
        }
    },
    caption: {
        display: 'flex'
    }
})));

function AccountButton(props) {
    const classes = useStyles();
    return (
        <Button
            className={classes.root}
            {...props}>

            <AccountCircle color="inherit"/>

            <div className={classes.caption}>
                <Typography color="inherit">
                    <FormattedMessage
                        id="account"
                        defaultMessage="Account"/>
                </Typography>
                <ArrowDropDown color="inherit"/>
            </div>
        </Button>
    )
}

export default AccountButton
