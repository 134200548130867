import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import DefinedTerms from "./DefinedTerms";
import Button from "../../../../../common/components/common/Button";
import TermsEdit from "./TermsEdit";
import Container from "../../../../../common/components/common/Container";
import Paper from "../../../../../common/components/common/Paper";

export default class TermsHome extends React.Component {

    static propTypes = {
        terms: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        app: PropTypes.object,
        user: PropTypes.object,
        history: PropTypes.object,
        title: PropTypes.object,
        introInfo: PropTypes.object,
        chainId: PropTypes.number,
        termsPublished: PropTypes.bool,
        updateTerms: PropTypes.func,
        fetchDefaultTerms: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            editing: false
        };
    }

    render() {
        const {terms, app, history, title, termsPublished} = this.props;
        if (this.state.editing) {
            return (
                <TermsEdit
                    termsPublished={termsPublished}
                    terms={terms}
                    title={title}
                    onSubmit={this.onSubmit.bind(this)}/>
            )

        } else if (!terms || terms === '') {
            return this.renderNoTerms(this.props.introInfo);
        }

        return (
            <DefinedTerms
                termsPublished={termsPublished}
                app={app}
                terms={terms}
                history={history}
                onClickEdit={this.editTerms.bind(this)}
                onClickPublish={this.onSubmitPublish.bind(this)}
                fetchDefaultTerms={this.fetchDefaultTerms.bind(this)}/>
        )
    }

    renderNoTerms(introInfo) {
        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    {introInfo}

                    <Button
                        label={<FormattedMessage
                            id="customizeTerms"
                            defaultMessage="Customize terms"/>}
                        color="secondary"
                        onClick={this.editTerms.bind(this)}/>

                    <Button
                        label={<FormattedMessage
                            id="generateAutomatically"
                            defaultMessage="Generate automatically"/>}
                        onClick={this.fetchDefaultTerms.bind(this)}/>
                </Paper>
            </Container>
        )
    }

    editTerms() {
        this.setState({
            editing: true
        })
    }

    stopEditTerms() {
        this.setState({
            editing: false
        })
    }

    fetchDefaultTerms() {
        this.props.fetchDefaultTerms();
        this.editTerms()
    }

    onSubmitPublish() {
        this.props.updateTerms({published: true}, this.props.history);
        this.stopEditTerms()
    }

    onSubmit(values) {
        this.props.updateTerms(values, this.props.history);
        this.stopEditTerms()
    }

}
