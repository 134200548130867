import React from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";

import Typography from "@material-ui/core/Typography";

export default class SendoutFormPage4 extends React.Component {

    render() {
        return (
            <React.Fragment>
                <Typography
                    variant="h5"
                    paragraph>
                    <FormattedMessage
                        id="campaignSendout"
                        defaultMessage="Campaign Sendout"/>
                </Typography>

                <Typography
                    variant="body1"
                    paragraph>
                    <FormattedMessage
                        id="campaignSendout.afterSendoutMessage"
                        defaultMessage="Your campaign is now published and selected will receive SMS and/or email."/>
                </Typography>

                <Link to="/campaigns">
                    <FormattedMessage
                        id="campaigns"
                        defaultMessage="Campaigns"/>
                </Link>
            </React.Fragment>
        );
    }

}
