import axios from "axios";
import {reset, startSubmit, stopSubmit} from "redux-form";

import {
    CREATE_STAMPCARD,
    CREATE_STAMPCARD_COUPON,
    FETCH_CONSUMER_STAMPCARD_COUPON_USAGES,
    FETCH_CONSUMER_STAMPCARD_COUPONS,
    FETCH_STAMPCARD,
    FETCH_STAMPCARD_USAGES,
    FETCH_STAMPCARDS,
    UPDATE_STAMPCARD,
    UPDATE_STAMPCARD_COUPON
} from "../../common/actions/types";
import {apiError, apiSuccessMessage, headerConfig} from "../../common/actions/utils-actions";
import {ROOT_URL} from "../../common/config/karming-links";
import {STAMPCARD_FORM_NAME} from "../components/account/stampcard/StampcardForm";
import {STAMPCARD_COUPON_FORM_NAME} from "../components/account/stampcard/stampcardCoupon/StampcardCouponForm";

const ENDPOINT_URL = `${ROOT_URL}/merchant/stampcard`;

export function fetchStampcard(stampcardId) {
    if (!stampcardId) {
        throw "fetchStampcard: stampcardId Required";
    }

    return dispatch => {
        axios.get(`${ENDPOINT_URL}/${stampcardId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_STAMPCARD,
                    payload: response.data
                })
            }).catch(response => {
            dispatch(apiError('Unable to fetch stamp card', response));
        });
    }
}

export function fetchStampcards(chainId, memberId, purchaseId) {
    if (!chainId) {
        throw "fetchStampcards: chainId Required";
    }

    return dispatch => {
        axios.post(`${ENDPOINT_URL}/${chainId}`, {
            purchaseId: purchaseId,
            memberId: memberId
        }, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_STAMPCARDS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch stamp cards', response));
            });
    }
}

export function fetchStampcardUsages(stampcardId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/${stampcardId}/usages`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_STAMPCARD_USAGES,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch stamp card usages', response));
            });
    }
}

export function publishStampcard(stampcardId, replacedStampcards) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/${stampcardId}/publish`, {replacedStampcardsIds: replacedStampcards}, headerConfig())
            .then(() => {
                dispatch(apiSuccessMessage("Stamp card published"));
                dispatch(fetchStampcard(stampcardId));
            })
            .catch(response => {
                dispatch(apiError('Unable to publish stamp card', response));
            });
    }
}

export function updateReplacedStampcards(stampcardId, replacedStampcards) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/${stampcardId}/replaces/update`, {replacedStampcardsIds: replacedStampcards}, headerConfig())
            .then(() => {
                dispatch(apiSuccessMessage("Replaced stampcards updated"));
                dispatch(fetchStampcard(stampcardId));
            })
            .catch(response => {
                dispatch(apiError('Unable to update replaced stampcards', response));
            });
    }
}

export function withdrawStampcard(stampcardId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/${stampcardId}/withdraw`, headerConfig())
            .then(() => {
                dispatch(apiSuccessMessage("Stamp card withdrawn"));
                dispatch(fetchStampcard(stampcardId));
            })
            .catch(response => {
                dispatch(apiError('Unable to withdraw stamp card', response));
            });
    }
}

export function stopSharingStampcard(stampcardId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/${stampcardId}/stop`, headerConfig())
            .then(() => {
                dispatch(apiSuccessMessage("Stamp card stopped"));
                dispatch(fetchStampcard(stampcardId));
            })
            .catch(response => {
                dispatch(apiError('Unable to stop sharing stamp card', response));
            });
    }
}

export function createStampcard(chainId, values, history) {
    if (!chainId) {
        throw "createStampcard: chainId Required";
    }

    return dispatch => {
        dispatch(startSubmit(STAMPCARD_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/create/${chainId}`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(STAMPCARD_FORM_NAME));
                dispatch({
                    type: CREATE_STAMPCARD,
                    payload: response.data
                });

                history.push("/stampcards/" + response.data);
                dispatch(apiSuccessMessage("Stamp card created"));
                dispatch(reset(STAMPCARD_FORM_NAME));
            }).catch(response => {
            dispatch(stopSubmit(STAMPCARD_FORM_NAME));
            dispatch(apiError('Unable to create stamp card', response));
        });
    }
}

export function updateStampcard(stampcardId, values) {
    if (!stampcardId) {
        throw "updateStampcard: stampcardId Required";
    }
    return dispatch => {
        dispatch(startSubmit(STAMPCARD_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/${stampcardId}/update`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(STAMPCARD_FORM_NAME));
                dispatch({
                    type: UPDATE_STAMPCARD,
                    payload: response.data
                });
                dispatch(apiSuccessMessage("Stamp card updated"));
                dispatch(reset("stampcardForm"));
                dispatch(fetchStampcard(stampcardId));
            })
            .catch(response => {
                dispatch(stopSubmit(STAMPCARD_FORM_NAME));
                dispatch(apiError('Unable to update stamp card', response));
            });
    }
}

export function createStampcardCoupon(stampcardId, values, history) {

    return dispatch => {
        dispatch(startSubmit(STAMPCARD_COUPON_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/${stampcardId}/coupon`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(STAMPCARD_COUPON_FORM_NAME));
                dispatch({
                    type: CREATE_STAMPCARD_COUPON,
                    payload: response.data
                });

                history.push("/stampcards/" + stampcardId);
                dispatch(apiSuccessMessage("Stamp card coupon created"));
                dispatch(reset(STAMPCARD_COUPON_FORM_NAME));
            }).catch(response => {
            dispatch(stopSubmit(STAMPCARD_COUPON_FORM_NAME));
            dispatch(apiError('Unable to create coupon stamp card', response));
        });
    }
}

export function updateStampcardCoupon(stampcardId, values, history) {

    return dispatch => {
        dispatch(startSubmit(STAMPCARD_COUPON_FORM_NAME));
        axios.post(`${ENDPOINT_URL}/${stampcardId}/coupon/update`, values, headerConfig())
            .then(response => {
                dispatch(stopSubmit(STAMPCARD_COUPON_FORM_NAME));
                dispatch({
                    type: UPDATE_STAMPCARD_COUPON,
                    payload: response.data
                });

                history.push("/stampcards/" + stampcardId);
                dispatch(apiSuccessMessage("Stamp card coupon updated"));
                dispatch(reset(STAMPCARD_COUPON_FORM_NAME));
            }).catch(response => {
            dispatch(stopSubmit(STAMPCARD_COUPON_FORM_NAME));
            dispatch(apiError('Unable to update coupon stamp card', response));
        });
    }
}


export function fetchConsumerStampcardCoupons(consumerId) {
    return dispatch => {
        axios.get(`${ENDPOINT_URL}/coupons/consumer/${consumerId}`, headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CONSUMER_STAMPCARD_COUPONS,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch consumer stamp card coupons', response));
            });
    }
}

export function fetchConsumerStampcardCouponsUsages(consumerId, updateValidation, purchaseId) {
    return dispatch => {
        axios.post(`${ENDPOINT_URL}/coupons/consumer/usages/${consumerId}`,
            {updateValidation: updateValidation, purchaseId: purchaseId},
            headerConfig())
            .then(response => {
                dispatch({
                    type: FETCH_CONSUMER_STAMPCARD_COUPON_USAGES,
                    payload: response.data
                });
            })
            .catch(response => {
                dispatch(apiError('Unable to fetch consumer stamp card coupon usages', response));
            });
    }
}
