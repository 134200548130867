import React, {useState} from "react";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";

import {makeStyles} from "@material-ui/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Typography from "@material-ui/core/Typography";
import People from '@material-ui/icons/People';
import Store from '@material-ui/icons/Store';
import List from '@material-ui/icons/List';
import Ballot from '@material-ui/icons/Ballot';
import Phone from '@material-ui/icons/Phone';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import MySubscriptionSubmenu from "./MySubscriptionSubmenu";
import DataProtectionCenterSubmenu from "./DataProtectionCenterSubmenu";
import AccountButton from "../../../common/components/common/menu/AccountButton";

import {ROLE_CASHIER, ROLE_MERCHANT} from "../../../common/components/auth/roles";
import {
    CATEGORIES_URL,
    CHAIN_MEMBERSHIP_TERMS_URL,
    HOME_REDIRECT_URL,
    LOCATIONS_URL,
    SUPPORT_URL,
    USER_PROFILE_URL,
    USERS_URL
} from "../../../../static/utils/redirect-const";

const useStyles = makeStyles((theme => ({
    accountMenuPaper: {
        marginLeft: -theme.spacing(3)
    },
    accountSelectMenu: {
        backgroundColor: theme.palette.secondary.main,
        overflow: 'hidden',
        padding: 0,
        width: 230
    },
    accountMenuItems: {
        marginTop: 48
    },
    menuItemMain: {
        fontSize: 16,
        color: theme.palette.common.white
    },
    menuItemIconMain: {
        paddingRight: theme.spacing(1)
    },
    menuItemIconSmall: {
        fontSize: 20
    },
    menuItemAccount: {
        padding: theme.spacing(1),
        color: theme.palette.common.white,
        fontSize: 12
    },
    submenuItemAccount: {
        padding: theme.spacing(1),
        color: theme.palette.common.white,
        fontSize: 12
    },
    menuLink: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.common.white,
        "&:hover": {
            opacity: 1,
            textDecoration: 'none'
        }
    },
    disabledMenuItem: {
        opacity: 0.5
    },
    expandedMenuItemAccount: {
        padding: theme.spacing(1),
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: theme.palette.common.white,
        fontSize: 12
    },
    expandableMenuIcon: {
        fontSize: 18,
        paddingLeft: 30,
        top: -6
    },
    account: {
        paddingTop: 8
    },
    divider: {
        backgroundColor: theme.palette.common.white
    }
})));

function AccountMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const {user, chains, auth, app, isMobile, setSidebarVisibility, setAccountMenuVisibility} = props;
    const atLeastOneChain = user && user.profile && chains.commercialChains && chains.commercialChains.length > 0;
    const classes = useStyles();
    const open = Boolean(anchorEl) && app.accountMenuOpen;

    const handleToggleMenu = event => {
        anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
        setAccountMenuVisibility(!app.accountMenuOpen);
        app.accountMenuOpen && setSidebarVisibility(false)
    };

    const handleToggleDesktopMenu = event => {
        anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
        setAccountMenuVisibility(!app.accountMenuOpen);
    };

    const logout = () => {
        const {currentPoS, logout, history} = props;
        logout(currentPoS && currentPoS.id);
        history.push(HOME_REDIRECT_URL);
    };

    const onItemClicked = isMobile ? handleToggleMenu : handleToggleDesktopMenu;

    return (
        <React.Fragment>
            <AccountButton onClick={handleToggleMenu}/>

            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleToggleMenu}
                className={classes.accountMenuItems}
                classes={{paper: classes.accountMenuPaper}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                MenuListProps={{className: classes.accountSelectMenu}}>
                {auth.role !== ROLE_CASHIER && (
                    <MenuItem
                        className={classnames(classes.menuItemAccount, !atLeastOneChain && classes.disabledMenuItem)}
                        component={Link}
                        to={LOCATIONS_URL}
                        onClick={onItemClicked}
                        disabled={!atLeastOneChain}>
                        <Store className={classes.menuItemIconMain}/>
                        <Typography color="inherit">
                            <FormattedMessage
                                id="pointsOfSale"
                                defaultMessage="Points of sale"/>
                        </Typography>
                    </MenuItem>
                )}
                {auth.role === ROLE_MERCHANT && (
                    <div>
                        <MenuItem
                            className={classes.menuItemAccount}
                            component={Link}
                            to={CATEGORIES_URL}
                            onClick={onItemClicked}>
                            <Ballot className={classes.menuItemIconMain}/>
                            <Typography color="inherit">
                                <FormattedMessage
                                    id="categories"
                                    defaultMessage="Categories"/>
                            </Typography>
                        </MenuItem>

                        <DataProtectionCenterSubmenu
                            classes={classes}
                            onClose={onItemClicked}
                            atLeastOneChain={atLeastOneChain}/>

                        <MenuItem
                            className={classnames(classes.menuItemAccount, !atLeastOneChain && classes.disabledMenuItem)}
                            component={Link}
                            to={CHAIN_MEMBERSHIP_TERMS_URL}
                            onClick={onItemClicked}
                            disabled={!atLeastOneChain}>
                            <List className={classes.menuItemIconMain}/>
                            <Typography color="inherit">
                                <FormattedMessage
                                    id="membershipConditions"
                                    defaultMessage="Membership Conditions"/>
                            </Typography>
                        </MenuItem>

                        <Divider className={classes.divider}/>

                        <MySubscriptionSubmenu
                            classes={classes}
                            onClose={onItemClicked}
                            atLeastOneChain={atLeastOneChain}/>
                    </div>
                )}
                {auth.role !== ROLE_CASHIER && (
                    <MenuItem
                        className={classes.menuItemAccount}
                        component={Link}
                        to={USERS_URL}
                        onClick={onItemClicked}>
                        <People className={classes.menuItemIconMain}/>
                        <Typography color="inherit">
                            <FormattedMessage
                                id="users"
                                defaultMessage="Users"/>
                        </Typography>
                    </MenuItem>
                )}
                <MenuItem
                    className={classes.menuItemAccount}
                    component={Link}
                    to={USER_PROFILE_URL}
                    onClick={onItemClicked}>
                    <AccountCircle className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="profile"
                            defaultMessage="Profile"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.menuItemAccount}
                    component={Link}
                    to={SUPPORT_URL}
                    onClick={onItemClicked}>
                    <Phone className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="support"
                            defaultMessage="Support"/>
                    </Typography>
                </MenuItem>

                <Divider className={classes.divider}/>

                <MenuItem
                    className={classes.menuItemAccount}
                    onClick={logout.bind(this)}>
                    <ExitToApp className={classes.menuItemIconMain}/>
                    <Typography color="inherit">
                        <FormattedMessage
                            id="logout"
                            defaultMessage="Logout"/>
                    </Typography>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

AccountMenu.propTypes = {
    app: PropTypes.object,
    user: PropTypes.object,
    auth: PropTypes.object,
    chains: PropTypes.object,
    history: PropTypes.object,
    currentPoS: PropTypes.object,
    isMobile: PropTypes.bool,
    onOpen: PropTypes.func,
    logout: PropTypes.func,
    setSidebarVisibility: PropTypes.func,
    setStartGuideVisibility: PropTypes.func,
    setAccountMenuVisibility: PropTypes.func,
    chooseCurrentChain: PropTypes.func,
    chooseCurrentPoS: PropTypes.func
};

export default withRouter(AccountMenu)

export const renderExpandableMenuIcon = open => {
    const classes = useStyles();
    return (
        open
            ? <ExpandLess className={classes.expandableMenuIcon}/>
            : <ExpandMore className={classes.expandableMenuIcon}/>
    )
};
