import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Edit from '@material-ui/icons/Edit';
import Publish from '@material-ui/icons/Publish';
import Autorenew from '@material-ui/icons/Autorenew';

import Terms from '../../../../../common/components/common/Terms'
import Button from "../../../../../common/components/common/Button";
import Container from "../../../../../common/components/common/Container";
import Paper from "../../../../../common/components/common/Paper";

export default class DefinedTerms extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        history: PropTypes.object,
        company: PropTypes.object,
        terms: PropTypes.object,
        termsPublished: PropTypes.bool,
        onClickEdit: PropTypes.func,
        onClickPublish: PropTypes.func,
        fetchDefaultTerms: PropTypes.func
    };

    render() {
        const {terms, fetchDefaultTerms, onClickEdit, onClickPublish, termsPublished} = this.props;

        return (
            <Container>
                <Paper padding>
                    <Terms terms={terms}/>
                    <Button
                        icon={<Autorenew/>}
                        label={<FormattedMessage
                            id="generateAutomatically"
                            defaultMessage="Generate automatically"/>}
                        color="secondary"
                        onClick={fetchDefaultTerms}/>

                    <Button
                        icon={<Edit/>}
                        label={<FormattedMessage
                            id="edit"
                            defaultMessage="Edit"/>}
                        color="secondary"
                        onClick={onClickEdit}/>

                    {!termsPublished && (
                        <Button
                            icon={<Publish/>}
                            label={<FormattedMessage
                                id="publish"
                                defaultMessage="Publish"/>}
                            onClick={onClickPublish}/>
                    )}
                </Paper>
            </Container>
        );
    }

}
