import React from "react"
import classnames from "classnames";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import Backspace from "@material-ui/icons/Backspace";

const styles = theme => ({
    wrapper: {
        marginTop: theme.spacing(1),
        width: '100%'
    },
    buttonsWrapper: {
        "& > *": {
            width: '30%',
            padding: '5%',
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: theme.typography.h6.fontSize,
            "&:hover": {
                cursor: 'pointer'
            }
        }
    },
    buttonsRowWrapper: {
        "& > :not(:last-child)": {
            borderRight: `1px solid ${theme.palette.grey.light}`,
            borderBottom: `1px solid ${theme.palette.grey.light}`
        },
        "& > :last-child": {
            borderBottom: `1px solid ${theme.palette.grey.light}`
        }
    },
    buttonsLastRowWrapper: {
        "& > :first-child": {
            borderRight: `1px solid ${theme.palette.grey.light}`
        },
        "& > :not(:last-child):not(:first-child)": {
            borderRight: `1px solid ${theme.palette.grey.light}`
        }
    },
    icon: {
        fontSize: theme.typography.h6.fontSize
    }
});

class Numpad extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        onDeleteClick: PropTypes.func,
        on1Click: PropTypes.func,
        on2Click: PropTypes.func,
        on3Click: PropTypes.func,
        on4Click: PropTypes.func,
        on5Click: PropTypes.func,
        on6Click: PropTypes.func,
        on7Click: PropTypes.func,
        on8Click: PropTypes.func,
        on9Click: PropTypes.func,
        on0Click: PropTypes.func
    };

    render() {
        const {
            classes, on1Click, on2Click, on3Click, on4Click, on5Click, on6Click, on7Click, on8Click, on9Click,
            on0Click, onDeleteClick
        } = this.props;

        const buttonsRowClass = classnames(classes.buttonsWrapper, classes.buttonsRowWrapper);

        return (
            <table className={classes.wrapper}>
                <tbody>
                <tr className={buttonsRowClass}>
                    <th onClick={on1Click}>1</th>
                    <th onClick={on2Click}>2</th>
                    <th onClick={on3Click}>3</th>
                </tr>
                <tr className={buttonsRowClass}>
                    <th onClick={on4Click}>4</th>
                    <th onClick={on5Click}>5</th>
                    <th onClick={on6Click}>6</th>
                </tr>
                <tr className={buttonsRowClass}>
                    <th onClick={on7Click}>7</th>
                    <th onClick={on8Click}>8</th>
                    <th onClick={on9Click}>9</th>
                </tr>
                <tr className={classnames(classes.buttonsWrapper, classes.buttonsLastRowWrapper)}>
                    <th/>
                    <th onClick={on0Click}>0</th>
                    <th onClick={onDeleteClick}>
                        <Backspace className={classes.icon}/>
                    </th>
                </tr>
                </tbody>
            </table>
        )
    }

}

export default withStyles(styles)(Numpad)
