import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types"

import {
    createCampaign,
    fetchCampaign,
    updateCampaign,
    updateCampaignStatus
} from "../../actions/merchant-campaign-actions";

import {fetchCommercialChain} from "../../actions/merchant-chain-actions";

import Spinner from "../../../common/components/common/Spinner";
import {prepareCampaignFormData} from "../../utils/FormHelper";
import CampaignForm from "./form/CampaignForm";

class Campaign extends React.Component {

    static propTypes = {
        chain: PropTypes.object,
        campaign: PropTypes.object,
        auth: PropTypes.object,
        app: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object,
        fetchCampaign: PropTypes.func,
        updateCampaign: PropTypes.func,
        createCampaign: PropTypes.func,
        updateCampaignStatus: PropTypes.func,
        fetchCommercialChain: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: !!this.props.match.params.id
        };
    }

    componentDidMount() {
        let campaignId = this.props.match.params.id;
        if (campaignId) {
            this.props.fetchCampaign(campaignId);
        }
        this.props.fetchCommercialChain(this.props.chain.id)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.isLoading && nextProps && nextProps.campaign) {
            this.setState({
                isLoading: false
            });
        }
    }

    shouldComponentUpdate(nextProps) {
        return this.props && nextProps && this.props.campaign !== nextProps.campaign;
    }

    render() {
        return this.state.isLoading
            ? this.renderSpinner()
            : this.renderContent();
    }

    renderSpinner() {
        const {app} = this.props;
        return <Spinner app={app}/>
    }

    renderContent() {
        const {campaign, auth, history} = this.props;

        const isUpdate = this.props.match.params.id;

        let defaultValues = {};
        if (!isUpdate) {
            defaultValues = {...defaultValues, actionButtonLabel: "Läs mer"}
        }

        return (
            <CampaignForm
                auth={auth}
                history={history}
                keepDirtyOnReinitialize={true}
                onSubmit={this.onSubmit.bind(this)}
                handlePublish={this.onSubmitPublish.bind(this, history)}
                handleDeactivate={this.onSubmitDeactivate.bind(this)}
                enableReinitialize={true}
                initialValues={isUpdate ? campaign : defaultValues}
                isUpdate={isUpdate}/>
        );
    }

    onSubmit(values) {
        let body = prepareCampaignFormData(values);
        if (this.props.match.params.id) {
            this.props.updateCampaign(this.props.match.params.id, body, true)
        } else {
            this.props.createCampaign(this.props.chain.id, body, this.props.history);
        }
    }

    onSubmitPublish(history) {
        this.props.updateCampaignStatus(this.props.match.params.id, "ACTIVE", true, history, true)
    }

    onSubmitDeactivate() {
        this.props.updateCampaignStatus(this.props.match.params.id, "INACTIVE", true)
    }
}

function mapStateToProps(state) {
    return {
        campaign: state.merchant.campaign,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createCampaign,
        updateCampaign,
        fetchCampaign,
        updateCampaignStatus,
        fetchCommercialChain
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
