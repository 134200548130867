import React from "react";
import {FormattedMessage} from "react-intl";
import ReactTooltip from 'react-tooltip';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Button from "../../../common/components/common/Button";
import Spinner from "../../../common/components/common/Spinner";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

class ClubPointsStarter extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        chain: PropTypes.object,
        app: PropTypes.object
    };

    render() {
        const {history, chain, app} = this.props;

        if (!chain) {
            return <Spinner app={app}/>
        }

        const createClubPointsDisabled = !chain.membershipConditionsPublished;

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    <Typography
                        variant="h5"
                        paragraph>
                        <FormattedMessage
                            id="clubPoints"
                            defaultMessage="Club Points"/>
                    </Typography>

                    <Typography
                        variant="subtitle1"
                        paragraph>
                        <FormattedMessage
                            id="howSmartItIs"
                            defaultMessage="How smart it is"/>
                        :
                    </Typography>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="club-points.forEach1Krona"
                            defaultMessage="For each 1 krona your customers spend they earn 1 point which is valid time you decide."/>

                    </Typography>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="club-points.pointBalanceIsCalculated"
                            defaultMessage="Point balance is calculated and and displayed automatically in the app for your customers."/>
                    </Typography>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="club-points.customerAutomatically"
                            defaultMessage="The Customer automatically eligible to redeem the your offered rewards based in the their earned points."/>
                    </Typography>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="club-points.YouCanOffer"
                            defaultMessage="You can offer a standing reward as a classic bonus check or vary and update over time."/>
                    </Typography>

                    <Button
                        label={
                            <React.Fragment>
                                <FormattedMessage
                                    id="okLetsStart"
                                    defaultMessage="Ok, let's start"/>
                                !
                            </React.Fragment>
                        }
                        onClick={() => history.push("/points/define")}
                        disabled={createClubPointsDisabled}
                        data-tip
                        data-for='beforeCreateClubPoints'/>

                    {createClubPointsDisabled && (
                        <ReactTooltip id='beforeCreateClubPoints' type='info'>
                            <Typography color="inherit">
                                <FormattedMessage
                                    id="beforeCreatingClubPointsYouHaveToPublish"
                                    defaultMessage="Before creating Club Points you have to publish"/>
                            </Typography>
                            <FormattedMessage
                                id="seeMenu"
                                defaultMessage="See menu"/>
                            :&nbsp;
                            <FormattedMessage
                                id="account"
                                defaultMessage="Account"/>
                            &gt;
                            <FormattedMessage
                                id="membershipConditions"
                                defaultMessage="Membership Conditions"/>
                        </ReactTooltip>
                    )}
                </Paper>
            </Container>
        )
    }

}

function mapStateToProps(state) {
    return {
        chain: state.chains.current,
    };
}

export default connect(mapStateToProps, null)(ClubPointsStarter);
