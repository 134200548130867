import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import BannerBuilder, {bannerRequired} from "../../banner/BannerBuilder";

class StampcardFormPage5 extends React.Component {

    static propTypes = {
        stampcard: PropTypes.object,
        initialValues: PropTypes.object,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
        previousPage: PropTypes.func
    };

    render() {
        const {handleSubmit, previousPage, initialValues, isUpdate, submitting, stampcard: {stampsAmount, monthValidation, title, subtitle, hideSubtitle, hideTitle}} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="h6">
                    <FormattedMessage
                        id="stampcardDesign"
                        defaultMessage="Stampcard design"/>
                </Typography>

                <Field
                    name="image"
                    stampcard={true}
                    initialValues={initialValues}
                    stampPath={initialValues && initialValues.stampPath}
                    stampUncollectedPath={initialValues && initialValues.stampUncollectedPath}
                    stampsAmount={stampsAmount}
                    monthValidation={monthValidation}
                    title={title}
                    subtitle={subtitle}
                    hideTitle={hideTitle}
                    hideSubtitle={hideSubtitle}
                    component={BannerBuilder}
                    currentImageFile={this.getBannerPath(initialValues)}
                    type="blob"
                    onNext={handleSubmit}
                    onPrevious={previousPage}
                    isUpdate={isUpdate}
                    submitted={submitting}
                    validate={[bannerRequired]}/>
            </form>
        );
    }

    getBannerPath(stampcard) {
        if (stampcard) {
            return stampcard.imagePath
        }
    }

}

export default reduxForm({
    form: "stampcardForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(StampcardFormPage5);
