import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Add from '@material-ui/icons/Add';

import Button from "../../../common/components/common/Button";
import ReadCheckbox from "../../../common/components/common/ReadCheckbox";
import Spinner from "../../../common/components/common/Spinner";
import {KarmingTable} from "../../../common/components/common/table/KarmingTable";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import TableTitle from "../../../common/components/common/table/TableTitle";

import {fetchCampaigns} from "../../actions/merchant-campaign-actions";
import {getFormattedDate} from "../../../common/utils/date-utils";

class Campaigns extends React.Component {

    static propTypes = {
        history: PropTypes.object,
        campaigns: PropTypes.array,
        app: PropTypes.object,
        chain: PropTypes.object,
        fetchCampaigns: PropTypes.func
    };

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.chain ||
            prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }
    }

    fetchDataIfPossible() {
        const {chain, fetchCampaigns} = this.props;
        if (chain && chain.id) {
            fetchCampaigns(chain.id, false);
        }
    }

    moveToCreateCampaign() {
        this.props.history.push("/campaign/create");
    }

    render() {
        if (!this.props.campaigns) {
            return <Spinner app={this.props.app}/>
        }

        const columns = [
            {
                Header: <FormattedMessage
                    id="campaignName"
                    defaultMessage="Campaign name"/>,
                id: "name",
                accessor: c => c.name,
            },
            {
                Header: <FormattedMessage
                    id="oneTimeOffer"
                    defaultMessage="One-time offer"/>,
                id: "oneTime",
                accessor: c => c.oneTime,
                Cell: c => (
                    <ReadCheckbox
                        checked={c.value}
                        table={true}/>
                ),
            },
            {
                Header: <FormattedMessage
                    id="startDate"
                    defaultMessage="Start date"/>,
                id: "startDate",
                accessor: c => c.startDate && getFormattedDate(c.startDate),
            },
            {
                Header: <FormattedMessage
                    id="endDate"
                    defaultMessage="End date"/>,
                id: "endDate",
                accessor: c => c.endDate && getFormattedDate(c.endDate),
            }
        ];

        return (
            <Container size={Container.SIZE_HUGE}>
                <Paper>
                    <TableTitle
                        title={<FormattedMessage
                            id="campaigns"
                            defaultMessage="Campaigns"/>}
                        button={
                            <Button
                                icon={<Add/>}
                                label={<FormattedMessage
                                    id="create"
                                    defaultMessage="Create"/>}
                                onClick={this.moveToCreateCampaign.bind(this)}/>
                        }/>

                    <KarmingTable
                        history={this.props.history}
                        data={this.props.campaigns}
                        columns={columns}
                        onRowClickHistoryPush={(state, row) => {
                            return (
                                {
                                    pathname: "/campaigns/" + row.id
                                })
                        }}/>
                </Paper>
            </Container>
        );
    }

}

function mapStateToProps(state) {
    return {
        campaigns: state.merchant.campaigns,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({fetchCampaigns}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
