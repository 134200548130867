import React from 'react';
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1)
    },
    textBold: {
        fontWeight: 'bold'
    }
}));

export const ClubPointsMessage = props => {
    const {currentBalance, madeChanges} = props;
    const classes = useStyles();

    return (
        <Typography
            display="block"
            variant="body2"
            className={classes.root}>
            <FormattedMessage
                id="currentPointsBalanceMadeChanges"
                defaultMessage="Current points balance: {currentBalance} (made changes: {madeChanges})"
                values={{
                    currentBalance: <span className={classes.textBold}>{currentBalance}p</span>,
                    madeChanges: <span>{madeChanges}p</span>
                }}/>
        </Typography>
    );
};

ClubPointsMessage.propTypes = {
    currentBalance: PropTypes.number,
    madeChanges: PropTypes.number
};
