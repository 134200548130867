import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Add from "@material-ui/icons/Add";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import ImageBoxRow from "../../../common/components/common/ImageBoxRow";
import RenderSelect from "../../../common/components/common/RenderSelect";
import Button from "../../../common/components/common/Button";

import {getFormattedDate} from "../../../common/utils/date-utils";

export const MADE_PURCHASE_IN_ANY = {
    value: "MADE_PURCHASE_IN_ANY",
    label: <FormattedMessage
        id="usedAnyOfTheseCampaigns"
        defaultMessage="Have used any of below campaigns"/>
};

export const MADE_PURCHASE_IN_ALL = {
    value: "MADE_PURCHASE_IN_ALL",
    label: <FormattedMessage
        id="usedAllOfTheseCampaigns"
        defaultMessage="Have used all of below campaigns"/>
};

export const NOT_MADE_PURCHASE_IN_ANY = {
    value: "NOT_MADE_PURCHASE_IN_ANY",
    label: <FormattedMessage
        id="notUsedAnyOfTheseCampaigns"
        defaultMessage="Have not used any of below campaigns"/>
};

export const NOT_MADE_PURCHASE_IN_ALL = {
    value: "NOT_MADE_PURCHASE_IN_ALL",
    label: <FormattedMessage
        id="notUsedAllOfTheseCampaigns"
        defaultMessage="Have not used all of below campaigns"/>
};

export const madePurchaseCampaignsOption = [
    MADE_PURCHASE_IN_ANY,
    MADE_PURCHASE_IN_ALL,
    NOT_MADE_PURCHASE_IN_ANY,
    NOT_MADE_PURCHASE_IN_ALL
];

export const START_DATE = {
    value: "START_DATE",
    label: <FormattedMessage
        id="startDate"
        defaultMessage="Start date"/>
};

export const END_DATE = {
    value: "END_DATE",
    label: <FormattedMessage
        id="endDate"
        defaultMessage="End date"/>
};

export const USAGES = {
    value: "USAGES",
    label: <FormattedMessage
        id="numberOfUsages"
        defaultMessage="Number of usages"/>
};

export const SALE_AMOUNT = {
    value: "SALE_AMOUNT",
    label: <FormattedMessage
        id="salesAmount"
        defaultMessage="Sales amount"/>
};

export const sortOption = [START_DATE, END_DATE, USAGES, SALE_AMOUNT];

const styles = theme => ({
    selectWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(2)
        }
    }
});

class CampaignsFilterForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        selectedCampaigns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        selectMembersInfo: PropTypes.object,
        campaigns: PropTypes.array,
        handleSubmit: PropTypes.func,
        submitting: PropTypes.bool
    };

    state = {
        currentSortType: START_DATE.value
    };

    onChangeSortType(event) {
        this.setState({currentSortType: event.target.value});
    }

    render() {
        const {classes, handleSubmit, campaigns, selectedCampaigns, selectMembersInfo} = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage id="campaign" defaultMessage="Campaigns"/>
                </Typography>

                <Typography
                    variant="body1"
                    component="div">
                    {selectMembersInfo}
                </Typography>

                <Field
                    name="tasteFilter.madePurchaseCampaigns"
                    component={RenderSelect}
                    defaultValue={MADE_PURCHASE_IN_ANY.value}>

                    {madePurchaseCampaignsOption.map((option, index) => (
                        <MenuItem
                            key={index}
                            value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Field>

                <div className={classes.selectWrapper}>
                    <Typography variant="body1">
                        <FormattedMessage
                            id="sortBy"
                            defaultMessage="Sort by"/>:
                    </Typography>

                    <Select
                        value={this.state.currentSortType}
                        options={sortOption}
                        onChange={this.onChangeSortType.bind(this)}>
                        {sortOption.map((option, index) => (
                            <MenuItem
                                key={index}
                                value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </div>

                <Field
                    name="tasteFilter.campaigns"
                    component={ImageBoxRow}
                    options={this.getCampaigns(campaigns, this.state.currentSortType)}
                    campaignFilter={true}/>

                <Button
                    icon={<Add/>}
                    label={<FormattedMessage
                        id="addSmartSelection"
                        defaultMessage="Add Smart Selection"/>}
                    type="submit"
                    disabled={!selectedCampaigns || !selectedCampaigns.length > 0}/>
            </form>
        );
    }

    getCampaigns(campaigns, sortType) {
        if (campaigns) {
            return (
                campaigns
                    .sort((a, b) => {
                        if (sortType === START_DATE.value) {
                            return b.startDate - a.startDate
                        } else if (sortType === END_DATE.value) {
                            return b.endDate - a.endDate
                        } else if (sortType === USAGES.value) {
                            return b.numberOfUsages - a.numberOfUsages
                        } else if (sortType === SALE_AMOUNT.value) {
                            return b.sellTotalAmount - a.sellTotalAmount
                        }
                    })
                    .map(key =>
                        ({
                            label: key.name.charAt(0).toUpperCase() + key.name.slice(1),
                            value: key.id,
                            dateRange: getFormattedDate(key.startDate) + " - " + getFormattedDate(key.endDate),
                            path: key.bannerPath,
                            numberOfUsages: key.numberOfUsages,
                            sellTotalAmount: key.sellTotalAmount
                        })
                    )
            )
        }
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('campaignsFilterForm');
    const selectedCampaigns = selector(state, 'tasteFilter.campaigns');
    return {
        selectedCampaigns
    }
}

export default reduxForm({
    form: 'campaignsFilterForm'
})(connect(mapStateToProps, null)(withStyles(styles)(CampaignsFilterForm)))
