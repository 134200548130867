import React from "react";
import {Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from '@material-ui/core/MenuItem'
import Home from "@material-ui/icons/Home";
import CardMembership from "@material-ui/icons/CardMembership";
import CardGiftcard from "@material-ui/icons/CardGiftcard";
import Typography from "@material-ui/core/Typography";
import People from '@material-ui/icons/People';
import ShoppingCartOutlined from '@material-ui/icons/ShoppingCartOutlined';
import VolumeUp from '@material-ui/icons/VolumeUp';
import BarChart from '@material-ui/icons/BarChart';
import LocalPostOffice from '@material-ui/icons/LocalPostOffice';
import Drawer from "@material-ui/core/Drawer";

import LocationSelectField from "./LocationSelectField";
import RenderOnDesktop from "../../../common/components/common/RenderOnDesktop";
import RenderOnMobile from "../../../common/components/common/RenderOnMobile";

import {ROLE_MERCHANT} from "../../../common/components/auth/roles";
import {
    ATTRACT_URL,
    CAMPAIGNS_URL,
    HOME_REDIRECT_URL,
    MEMBERS_URL,
    POINTS_URL,
    PURCHASES_URL,
    SENDOUTS_URL,
    STAMPCARDS_URL
} from "../../../../static/utils/redirect-const";

const styles = theme => ({
    paper: {
        backgroundColor: theme.palette.secondary.main,
        width: 200
    },
    leftDrawer: {
        "& > div": {
            marginTop: 55,
            paddingTop: 15
        }
    },
    menuLeftItemsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    menuItemIconMain: {
        paddingRight: theme.spacing(1)
    },
    menuItemIconSmall: {
        fontSize: 20
    },
    menuItemMain: {
        fontSize: 16,
        color: theme.palette.common.white
    }
});

class SidebarMenu extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        app: PropTypes.object,
        user: PropTypes.object,
        auth: PropTypes.object,
        chains: PropTypes.object,
        history: PropTypes.object,
        currentPoS: PropTypes.object,
        onItemClicked: PropTypes.func,
        setSidebarVisibility: PropTypes.func,
        chooseCurrentChain: PropTypes.func,
        chooseCurrentPoS: PropTypes.func
    };

    render() {
        const {classes, auth, app} = this.props;

        if (!auth.authenticated) {
            return null
        }

        return (
            <React.Fragment>
                <RenderOnDesktop>
                    <Drawer
                        open
                        className={classes.leftDrawer}
                        classes={{paper: classes.paper}}
                        variant="persistent">
                        {this.renderItems()}
                    </Drawer>
                </RenderOnDesktop>

                <RenderOnMobile>
                    <Drawer
                        className={classes.leftDrawer}
                        classes={{paper: classes.paper}}
                        variant="temporary"
                        onClose={this.toggleSidebar.bind(this)}
                        open={app.sidebarOpen}>
                        {this.renderItems()}
                    </Drawer>
                </RenderOnMobile>
            </React.Fragment>
        )
    }

    renderItems() {
        const {classes, onItemClicked, currentPoS, auth, user, chains, chooseCurrentChain, chooseCurrentPoS} = this.props;
        const atLeastOneChain = this.hasAtLeastOneChain(user, chains);
        const role = auth.role;
        return (
            <React.Fragment>
                <LocationSelectField
                    currentPoS={currentPoS}
                    user={user}
                    chains={chains}
                    chooseCurrentChain={chooseCurrentChain}
                    chooseCurrentPoS={chooseCurrentPoS}/>

                <MenuItem
                    className={classes.menuItemMain}
                    component={Link}
                    to={HOME_REDIRECT_URL}
                    onClick={onItemClicked}>
                    <Home className={classes.menuItemIconMain}/>
                    <Typography
                        variant="body1"
                        color="inherit">
                        <FormattedMessage
                            id="home"
                            defaultMessage="Home"/>
                    </Typography>
                </MenuItem>

                <MenuItem
                    className={classes.menuItemMain}
                    component={Link}
                    to={PURCHASES_URL}
                    onClick={onItemClicked}
                    disabled={!atLeastOneChain}>
                    <ShoppingCartOutlined className={classes.menuItemIconMain}/>
                    <Typography
                        variant="body1"
                        color="inherit">
                        <FormattedMessage
                            id="purchases"
                            defaultMessage="Purchases"/>
                    </Typography>
                </MenuItem>

                {role === ROLE_MERCHANT && (
                    <React.Fragment>

                        <MenuItem
                            className={classes.menuItemMain}
                            component={Link}
                            to={MEMBERS_URL}
                            onClick={onItemClicked}
                            disabled={!atLeastOneChain}>
                            <People className={classes.menuItemIconMain}/>
                            <Typography
                                variant="body1"
                                color="inherit">
                                <FormattedMessage
                                    id="members"
                                    defaultMessage="Members"/>
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            className={classes.menuItemMain}
                            component={Link}
                            to={ATTRACT_URL}
                            onClick={onItemClicked}
                            disabled={!atLeastOneChain}>
                            <VolumeUp className={classes.menuItemIconMain}/>
                            <Typography
                                variant="body1"
                                color="inherit">
                                <FormattedMessage
                                    id="welcomeOffering"
                                    defaultMessage="Welcome offering"/>
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            className={classes.menuItemMain}
                            component={Link}
                            to={CAMPAIGNS_URL}
                            onClick={onItemClicked}
                            disabled={!atLeastOneChain}>
                            <BarChart className={classes.menuItemIconMain}/>
                            <Typography
                                variant="body1"
                                color="inherit">
                                <FormattedMessage
                                    id="campaigns"
                                    defaultMessage="Campaigns"/>
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            className={classes.menuItemMain}
                            component={Link}
                            to={POINTS_URL}
                            onClick={onItemClicked}
                            disabled={!atLeastOneChain}>
                            <CardMembership className={classes.menuItemIconMain}/>
                            <Typography
                                variant="body1"
                                color="inherit">
                                <FormattedMessage
                                    id="clubPoints"
                                    defaultMessage="Club Points"/>
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            className={classes.menuItemMain}
                            component={Link}
                            to={STAMPCARDS_URL}
                            onClick={onItemClicked}
                            disabled={!atLeastOneChain}>
                            <CardGiftcard className={classes.menuItemIconMain}/>
                            <Typography
                                variant="body1"
                                color="inherit">
                                <FormattedMessage
                                    id="stampcard"
                                    defaultMessage="Stamp card"/>
                            </Typography>
                        </MenuItem>

                        <MenuItem
                            className={classes.menuItemMain}
                            component={Link}
                            to={SENDOUTS_URL}
                            onClick={onItemClicked}
                            disabled={!atLeastOneChain}>
                            <LocalPostOffice className={classes.menuItemIconMain}/>
                            <Typography
                                variant="body1"
                                color="inherit">
                                <FormattedMessage
                                    id="sendout"
                                    defaultMessage="Sendout"/>
                            </Typography>
                        </MenuItem>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    hasAtLeastOneChain(user, chains) {
        return user && user.profile && chains.commercialChains && chains.commercialChains.length > 0;
    }

    toggleSidebar() {
        const {app, setSidebarVisibility} = this.props;
        setSidebarVisibility(!app.sidebarOpen);
    }
}

export default withRouter(withStyles(styles)(SidebarMenu))
