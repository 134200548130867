import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";

import RenderSelect from "../../../common/components/common/RenderSelect";
import RenderSlider from "../../../common/components/common/RenderSlider";
import Checkbox from "../../../common/components/common/form/RenderCheckbox";
import Button from "../../../common/components/common/Button";

import {atLeastOneOfTwoCheckboxRequired, minMaxFieldsValidation,} from "../../../common/utils/karming-validators";

export const MADE_PURCHASE = <FormattedMessage id="haveSpent" defaultMessage="Have spent"/>;
export const NOT_MADE_PURCHASE = <FormattedMessage id="haveNotSpent" defaultMessage="Have not spent"/>;

export const madePurchaseOption = {
    YES: {
        value: true,
        label: MADE_PURCHASE
    },
    NO: {
        value: false,
        label: NOT_MADE_PURCHASE
    }
};

const MIN_VALUE_PURCHASE = 0;

const validate = (values, props) => {
    return Object.assign(
        atLeastOneOfTwoCheckboxRequired(
            {name: "totalAmount", value: values.sumFilter ? values.sumFilter.totalAmount : false},
            {name: "onePurchaseAmount", value: values.sumFilter ? values.sumFilter.onePurchaseAmount : false},
            "sumFilter"),
        minMaxFieldsValidation(
            'sumFilter',
            values.sumFilter ? values.sumFilter.totalAmount : false,
            {
                name: "purchasesAmountFrom",
                value: values.sumFilter ? values.sumFilter.purchasesAmountFrom : MIN_VALUE_PURCHASE,
                min: MIN_VALUE_PURCHASE
            },
            {
                name: "purchasesAmountTo",
                value: values.sumFilter ? values.sumFilter.purchasesAmountTo : props.chain.maxPurchase,
                max: props.chain.maxPurchase
            },
            values.sumFilter ? values.sumFilter.onePurchaseAmount : false,
            {
                name: "onePurchaseAmountFrom",
                value: values.sumFilter ? values.sumFilter.onePurchaseAmountFrom : MIN_VALUE_PURCHASE,
                min: MIN_VALUE_PURCHASE
            },
            {
                name: "onePurchaseAmountTo",
                value: values.sumFilter ? values.sumFilter.onePurchaseAmountTo : props.chain.maxPurchasePerOneOpportunity,
                max: props.chain.maxPurchasePerOneOpportunity
            }
        )
    )
};

const styles = theme => ({
    checkboxField: {
        justifyContent: 'center',
        marginTop: theme.spacing(2),
        marginBottom: -theme.spacing(3.5)
    },
});

class PurchaseAmountFilterForm extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        chain: PropTypes.object,
        selectMembersInfo: PropTypes.object,
        submitting: PropTypes.bool,
        totalAmount: PropTypes.bool,
        onePurchaseAmount: PropTypes.bool,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {classes, handleSubmit, chain, selectMembersInfo, totalAmount, onePurchaseAmount} = this.props;

        const maxPurchase = chain.maxPurchase;
        const maxPurchasePerOneOpportunity = chain.maxPurchasePerOneOpportunity;

        return (
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage id="purchaseAmount" defaultMessage="Purchase amount"/>
                </Typography>

                <Typography
                    variant="body1"
                    component="div">
                    {selectMembersInfo}
                </Typography>

                <Field
                    name="sumFilter.madePurchase"
                    component={RenderSelect}
                    defaultValue={madePurchaseOption.YES.value}>
                    <MenuItem value={madePurchaseOption.YES.value}>
                        {madePurchaseOption.YES.label}
                    </MenuItem>
                </Field>

                <Field
                    className={classes.checkboxField}
                    name="sumFilter.totalAmount"
                    label={
                        <React.Fragment>
                            <FormattedMessage
                                id="inTotal"
                                defaultMessage="In total"/>:
                        </React.Fragment>
                    }
                    type="checkbox"
                    component={Checkbox}/>

                <Field
                    name="purchasesTotalAmount"
                    component={RenderSlider}
                    steps={100}
                    minFieldName="sumFilter.purchasesAmountFrom"
                    maxFieldName="sumFilter.purchasesAmountTo"
                    min={MIN_VALUE_PURCHASE}
                    max={maxPurchase}
                    defaultValue={[MIN_VALUE_PURCHASE, maxPurchase]}/>

                <Field
                    className={classes.checkboxField}
                    name="sumFilter.onePurchaseAmount"
                    label={
                        <React.Fragment>
                            <FormattedMessage
                                id="atSinglePurchaseTransaction"
                                defaultMessage="At a single purchase transaction"/>:
                        </React.Fragment>
                    }
                    type="checkbox"
                    component={Checkbox}/>

                <Field
                    name="onePurchaseAmount"
                    component={RenderSlider}
                    steps={50}
                    minFieldName="sumFilter.onePurchaseAmountFrom"
                    maxFieldName="sumFilter.onePurchaseAmountTo"
                    min={MIN_VALUE_PURCHASE}
                    max={maxPurchasePerOneOpportunity}
                    defaultValue={[MIN_VALUE_PURCHASE, maxPurchasePerOneOpportunity]}/>

                <Button
                    icon={<Add/>}
                    label={<FormattedMessage
                        id="addSmartSelection"
                        defaultMessage="Add Smart Selection"/>
                    }
                    type="submit"
                    disabled={!totalAmount && !onePurchaseAmount}/>
            </form>
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('purchaseAmountFilterForm');
    const totalAmount = selector(state, 'sumFilter.totalAmount');
    const onePurchaseAmount = selector(state, 'sumFilter.onePurchaseAmount');
    return {
        totalAmount,
        onePurchaseAmount
    }
}

export default reduxForm({
    form: 'purchaseAmountFilterForm',
    touchOnChange: true,
    validate
})(connect(mapStateToProps, null)(withStyles(styles)(PurchaseAmountFilterForm)))
