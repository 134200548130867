import React from "react";
import {FormattedMessage} from "react-intl";
import {Field} from "redux-form";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import Range from 'rc-slider/lib/Range'
import 'rc-slider/assets/index.css';

import {withStyles} from "@material-ui/core/styles";

import RenderTextField from "./RenderTextField";
import {UNSIGNED_INTEGER_FORMAT_CONFIG} from "../../../merchant/utils/formats";
import {setFormValue} from "../../../merchant/actions/merchant-forms-actions";

const styles = theme => ({
    root: {
        width: '100%',
        display: 'inline-block'
    },
    fieldsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    fieldWrapper: {
        width: '25%'
    },
    range: {
        "& .rc-slider-track": {
            backgroundColor: theme.palette.primary.main
        },
        "& .rc-slider-handle": {
            border: `solid 2px ${theme.palette.secondary.main}`,
            width: 26,
            height: 26,
            "&:active": {
                boxShadow: `0 0 5px ${theme.palette.secondary.main}`
            }
        }
    },
    rangeWrapper: {
        width: '80%',
        display: 'inherit',
        margin: theme.spacing(2)
    }
});

class RenderSlider extends React.Component {

    componentDidMount() {
        const {defaultValue, input, meta, minFieldName, maxFieldName} = this.props;
        if (defaultValue) {
            input.onChange(defaultValue);
            this.props.setFormValue(meta.form, minFieldName, defaultValue[0]);
            this.props.setFormValue(meta.form, maxFieldName, defaultValue[1])
        }
    }

    render() {
        const {classes, input, defaultValue, min, max, minFieldName, maxFieldName, ...rest} = this.props;

        const valueFrom = input.value[0];
        const valueTo = input.value[1];

        return (
            <div className={classes.root}>
                <div className={classes.fieldsWrapper}>
                    <div className={classes.fieldWrapper}>
                        <Field
                            name={minFieldName}
                            type="number"
                            formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}
                            component={RenderTextField}
                            min={min}
                            max={valueTo}
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="min"
                                        defaultMessage="Min"/>
                                    &nbsp;({min})
                                </React.Fragment>
                            }
                            onBlur={(event, value) => {
                                event.preventDefault();
                                if (value === '' || value < min) {
                                    this.props.setFormValue(this.props.meta.form, minFieldName, min);
                                    input.onChange([min, valueTo])
                                } else if (value <= valueTo) {
                                    input.onChange([value, valueTo])
                                } else if (value > valueTo) {
                                    this.props.setFormValue(this.props.meta.form, minFieldName, valueTo);
                                    input.onChange([valueTo, valueTo])
                                }
                            }}
                        />
                    </div>

                    <div className={classes.fieldWrapper}>
                        <Field
                            name={maxFieldName}
                            type="number"
                            formatConfig={UNSIGNED_INTEGER_FORMAT_CONFIG}
                            component={RenderTextField}
                            min={valueFrom}
                            max={max}
                            hintText={
                                <React.Fragment>
                                    <FormattedMessage
                                        id="max"
                                        defaultMessage="Max"/>
                                    &nbsp;({max})
                                </React.Fragment>
                            }
                            onBlur={(event, value) => {
                                event.preventDefault();
                                if (value === '' || value > max) {
                                    this.props.setFormValue(this.props.meta.form, maxFieldName, max);
                                    input.onChange([valueFrom, max])
                                } else if (value >= valueFrom) {
                                    input.onChange([valueFrom, value])
                                } else if (value < valueFrom) {
                                    this.props.setFormValue(this.props.meta.form, maxFieldName, valueFrom);
                                    input.onChange([valueFrom, valueFrom])
                                }
                            }}/>
                    </div>
                </div>

                <div className={classes.rangeWrapper}>
                    <Range
                        {...rest}
                        className={classes.range}
                        value={input.value ? input.value : undefined}
                        onChange={(value) => {
                            if (value[0] !== valueFrom) {
                                this.props.setFormValue(this.props.meta.form, minFieldName, value[0])
                            }
                            if (value[1] !== valueTo) {
                                this.props.setFormValue(this.props.meta.form, maxFieldName, value[1])
                            }
                            input.onChange(value)
                        }}
                        step={1}
                        min={min}
                        max={max}
                        defaultValue={defaultValue}/>
                </div>
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(RenderSlider));
