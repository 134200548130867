import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import {withStyles} from "@material-ui/core/styles";
import {GridList, GridListTile, GridListTileBar} from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';

import Dialog from '../../../common/components/common/Dialog'
import Button from "../../../common/components/common/Button";

import {S3_IMAGES_URL} from "../../../common/config/karming-links";

const FASHION_IMAGES = [
    S3_IMAGES_URL + '/templates/Fashion/Fashion_denim.png',
    S3_IMAGES_URL + '/templates/Fashion/Fashion_denim2.png',
    S3_IMAGES_URL + '/templates/Fashion/Fashion_eton_cirkle.png',
    S3_IMAGES_URL + '/templates/Fashion/Fashion_tshirt_blue.png',
    S3_IMAGES_URL + '/templates/Fashion/Fashion_tshirt_cirkle_bw.png',
    S3_IMAGES_URL + '/templates/Fashion/Fashion_tshirt_pink.png',
    S3_IMAGES_URL + '/templates/Fashion/Fashion_tshirt_square.png',
];

const SPORT_IMAGES = [
    S3_IMAGES_URL + '/templates/Sport/Sport_.jpg',
    S3_IMAGES_URL + '/templates/Sport/Sport_boxer_winner.jpg',
    S3_IMAGES_URL + '/templates/Sport/Sport_golf_ball.jpg',
    S3_IMAGES_URL + '/templates/Sport/Sport_golf_swing.jpg',
    S3_IMAGES_URL + '/templates/Sport/sport_skier_red_banner.jpg',
    S3_IMAGES_URL + '/templates/Sport/sport_skier.jpg',
    S3_IMAGES_URL + '/templates/Sport/sport_surfer.jpg',
    S3_IMAGES_URL + '/templates/Sport/Sport_yoga.jpg',
];

const ANIMALS_IMAGES = [
    S3_IMAGES_URL + '/templates/Animals/Animals_Cat_white_background.jpg',
    S3_IMAGES_URL + '/templates/Animals/animals_White_dog_black_background.jpg',
];

const SUMMER_IMAGES = [
    S3_IMAGES_URL + '/templates/Summer/summer_ celebration.jpg',
    S3_IMAGES_URL + '/templates/Summer/summer_celebration_gray.jpg',
    S3_IMAGES_URL + '/templates/Summer/summer.png',
    S3_IMAGES_URL + '/templates/Summer/hat_beach.png',
];

const BACKGROUND_IMAGES = [
    S3_IMAGES_URL + '/templates/Background/Background_ black backgruond.jpg',
    S3_IMAGES_URL + '/templates/Background/Background_ grey background.jpg',
    S3_IMAGES_URL + '/templates/Background/Background_ red background.jpg',
    S3_IMAGES_URL + '/templates/Background/Background_.jpg',
    S3_IMAGES_URL + '/templates/Background/Background_ Old_paper.jpg',
    S3_IMAGES_URL + '/templates/Background/Background_ white background.jpg',
    S3_IMAGES_URL + '/templates/Background/Background_ black_board.png',
];

const CHRISTMAS_IMAGES = [
    S3_IMAGES_URL + '/templates/Christmas/christmas_ball.jpg',
    S3_IMAGES_URL + '/templates/Christmas/Christmas.jpg',
];

const OTHER_IMAGES = [
    S3_IMAGES_URL + '/templates/Other/baby yellow.jpg',
    S3_IMAGES_URL + '/templates/Other/berries_cirkle.png',
    S3_IMAGES_URL + '/templates/Other/books_bwpng.png',
    S3_IMAGES_URL + '/templates/Other/books_strong_two.png',
    S3_IMAGES_URL + '/templates/Other/books_strong.png',
    S3_IMAGES_URL + '/templates/Other/books.png',
    S3_IMAGES_URL + '/templates/Other/bread_natural.png',
    S3_IMAGES_URL + '/templates/Other/cup_of_thea.png',
    S3_IMAGES_URL + '/templates/Other/Drinks light overlay.jpg',
    S3_IMAGES_URL + '/templates/Other/flowers_blackandwhite.jpg',
    S3_IMAGES_URL + '/templates/Other/Gift_black.jpg',
    S3_IMAGES_URL + '/templates/Other/Gift_box_white.jpg',
    S3_IMAGES_URL + '/templates/Other/Gift_wooden_counter.jpg',
    S3_IMAGES_URL + '/templates/Other/Gifts_wrapper.jpg',
    S3_IMAGES_URL + '/templates/Other/kid w baloon.jpg',
    S3_IMAGES_URL + '/templates/Other/shopping bags_white _banner.jpg',
    S3_IMAGES_URL + '/templates/Other/shopping bags.jpg',
    S3_IMAGES_URL + '/templates/Other/Siluette_raising_arms_white.png',
    S3_IMAGES_URL + '/templates/Other/speaker.png',
    S3_IMAGES_URL + '/templates/Other/wedding_rings.jpg',
    S3_IMAGES_URL + '/templates/Other/Woman_shopping.jpg',
    S3_IMAGES_URL + '/templates/Other/wood_cirkle.png',
];

const STAMPCARD_BACKGROUND = [
    S3_IMAGES_URL + '/templates/Stampcard/background/barber.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/barber2.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/bread.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/darkblue_premium.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/darkpink.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/food.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/food_darkblue.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/hairdresser_pink.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/hairdresser_purple.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/hamburger.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/leather.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/old_paper.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/pizza.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/purple.jpg',
    S3_IMAGES_URL + '/templates/Stampcard/background/stamp_coffee.jpg',
];

const CATEGORIES = [
    {
        name: <FormattedMessage
            id="fashion"
            defaultMessage="Fashion"/>,
        titleImage: S3_IMAGES_URL + '/templates/Fashion/Fashion_denim.png',
        images: FASHION_IMAGES
    },

    {
        name: <FormattedMessage
            id="sport"
            defaultMessage="Sport"/>,
        titleImage: S3_IMAGES_URL + '/templates/Sport/Sport_.jpg',
        images: SPORT_IMAGES
    },

    {
        name: <FormattedMessage
            id="background"
            defaultMessage="Background"/>,
        titleImage: S3_IMAGES_URL + '/templates/Background/Background_ Old_paper.jpg',
        images: BACKGROUND_IMAGES
    },

    {
        name: <FormattedMessage
            id="summer"
            defaultMessage="Summer"/>,
        titleImage: S3_IMAGES_URL + '/templates/Summer/summer.png',
        images: SUMMER_IMAGES
    },

    {
        name: <FormattedMessage
            id="christmas"
            defaultMessage="Christmas"/>,
        titleImage: S3_IMAGES_URL + '/templates/Christmas/Christmas.jpg',
        images: CHRISTMAS_IMAGES
    },

    {
        name: <FormattedMessage
            id="animals"
            defaultMessage="Animals"/>,
        titleImage: S3_IMAGES_URL + '/templates/Animals/Animals_Cat_white_background.jpg',
        images: ANIMALS_IMAGES
    },

    {
        name: <FormattedMessage
            id="render-image-picker.other"
            defaultMessage="Other"/>,
        titleImage: S3_IMAGES_URL + '/templates/Other/shopping bags.jpg',
        images: OTHER_IMAGES
    }
];

const styles = theme => ({
    selectImageWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around'
    },
    imagesWrapper: {
        overflowY: '-webkit-paged-x',
        color: theme.palette.common.white
    }
});

class RenderImagePicker extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        buttonLabel: PropTypes.object,
        buttonClass: PropTypes.string,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        imagePaths: PropTypes.arrayOf(PropTypes.string),
        isStampcard: PropTypes.bool,
        isPristine: PropTypes.bool,
        isStampcardCoupon: PropTypes.bool,
        onImageSelected: PropTypes.func,
        handleOpen: PropTypes.func,
        handleClose: PropTypes.func,
    };

    componentDidMount() {
        if (this.props.isStampcard
            && !this.props.isUpdate
            && this.props.isPristine) {
            this.onOpen()
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            templateDialogOpen: false,
            category: '',
        }
    }

    onOpen(event) {
        if (event) {
            event.stopPropagation();
        }
        this.setState({
            isOpen: true
        });
        this.props.handleOpen();
    }

    onOpenTemplate(category) {
        this.setState({
            templateDialogOpen: true,
            category: category
        });
    }

    onCloseTemplate() {
        this.setState({
            templateDialogOpen: false,
            isOpen: false
        })
    }

    onSelected(imagePath) {
        this.onClose();
        if (this.props.onImageSelected) {
            this.props.onImageSelected(imagePath);
        }
    }

    onClose() {
        this.setState({
            isOpen: false
        });
        this.props.handleClose();
    }

    render() {
        const {classes, buttonClass, buttonLabel, isStampcard, isStampcardCoupon} = this.props;

        const actions = [
            <Button
                key="cancel"
                label='Cancel'
                onClick={this.onClose.bind(this)}
                icon={<Clear/>}
                customColor="red"/>
        ];

        const buttonsForTemplateDialog = [
            <Button
                key="cancel"
                label='Cancel'
                onClick={this.onCloseTemplate.bind(this)}
                icon={<Clear/>}
                customColor="red"/>
        ];

        return (
            <React.Fragment>
                <Button
                    customColor="yellow"
                    className={buttonClass}
                    label={buttonLabel}
                    onClick={this.onOpen.bind(this)}
                    icon={<PhotoLibrary/>}/>

                {(isStampcard || isStampcardCoupon) && (
                    <Dialog
                        onClose={this.onClose.bind(this)}
                        title={
                            <React.Fragment>
                                <FormattedMessage
                                    id="select"
                                    defaultMessage="Select"/>
                                &nbsp;
                                {isStampcard && (
                                    <span style={{textTransform: 'lowercase'}}>
                                    <FormattedMessage
                                        id="stampcard"
                                        defaultMessage="Stamp card"/>
                                    </span>
                                )}
                                {isStampcardCoupon && (
                                    <span style={{textTransform: 'lowercase'}}>
                                    <FormattedMessage
                                        id="rewardCoupon"
                                        defaultMessage="Stamp card coupon"/>
                                    </span>
                                )}
                                &nbsp;
                                <span style={{textTransform: 'lowercase'}}>
                                <FormattedMessage
                                    id="background"
                                    defaultMessage="Background"/>
                                </span>
                            </React.Fragment>
                        }
                        actions={actions}
                        open={this.state.isOpen}
                        content={this.renderImageChooseGrid(true)}/>
                )}

                {!isStampcard && !isStampcardCoupon && (
                    <Dialog
                        onClose={this.onClose.bind(this)}
                        title={
                            <React.Fragment>
                                <FormattedMessage
                                    id="select"
                                    defaultMessage="Select"/>
                                &nbsp;
                                <span style={{textTransform: 'lowercase'}}>
                                <FormattedMessage
                                    id="category"
                                    defaultMessage="Category"/>
                                </span>
                            </React.Fragment>
                        }
                        actions={actions}
                        open={this.state.isOpen}
                        content={
                            <div className={classes.selectImageWrapper}>
                                {!this.state.templateDialogOpen && (
                                    <GridList
                                        cellHeight={160}
                                        className={classes.imagesWrapper}>
                                        {CATEGORIES.map((tile) => (
                                            <GridListTile
                                                key={tile.titleImage}
                                                onClick={this.onOpenTemplate.bind(this, tile.name)}>
                                                <img src={tile.titleImage}/>
                                                <GridListTileBar title={tile.name}/>
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                )}
                                {this.state.templateDialogOpen && this.renderCategory(buttonsForTemplateDialog)}
                            </div>
                        }/>
                )}
            </React.Fragment>
        );
    }

    renderCategory(actions) {
        {
            return (
                <Dialog
                    onClose={this.onCloseTemplate.bind(this)}
                    title={
                        <React.Fragment>
                            <FormattedMessage
                                id="select"
                                defaultMessage="Select"/>
                            &nbsp;
                            <FormattedMessage
                                id="image"
                                defaultMessage="image"/>
                        </React.Fragment>
                    }
                    actions={actions}
                    open={this.state.templateDialogOpen}
                    content={this.renderImageChooseGrid()}/>
            )
        }
    }

    renderImageChooseGrid(isStampcardOrStampcardCoupon) {
        const {classes} = this.props;
        const imagesForCategory = CATEGORIES.find(category => category.name === this.state.category);

        if (isStampcardOrStampcardCoupon) {
            return (
                <GridList cellHeight={160} className={classes.imagesWrapper}>
                    {STAMPCARD_BACKGROUND.map((image) => (
                        <GridListTile key={image}>
                            <img src={image} onClick={() => this.onSelected(image)}/>
                        </GridListTile>
                    ))}
                </GridList>
            );
        } else if (imagesForCategory) {
            return (
                <GridList cellHeight={160} className={classes.imagesWrapper}>
                    {imagesForCategory.images.map((image) => (
                        <GridListTile key={image}>
                            <img src={image} onClick={() => this.onSelected(image)}/>
                        </GridListTile>
                    ))}
                </GridList>
            );
        }
    }

}

export default withStyles(styles)(RenderImagePicker);
