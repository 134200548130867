import React from "react";
import {Field, formValueSelector, reduxForm} from "redux-form";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import MenuItem from "@material-ui/core/MenuItem";
import Add from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";

import RenderSelect from "../../../common/components/common/RenderSelect";
import Button from "../../../common/components/common/Button";
import RenderDatePicker, {atLeastOneDateRequired} from "../../../common/components/common/form/RenderDatePicker";
import {dateRangeNestedValidation} from "../../../common/utils/karming-validators";

export const BECAME_MEMBER = <FormattedMessage id="becameMember" defaultMessage="Became a member"/>;
export const NOT_BECAME_MEMBER = <FormattedMessage id="notBecameMember" defaultMessage="Not became a member"/>;

export const becameMemberInDateRangeOption = {
    YES: {
        value: true,
        label: BECAME_MEMBER
    },
    NO: {
        value: false,
        label: NOT_BECAME_MEMBER
    }
};

const validate = values => {
    return dateRangeNestedValidation(
        {name: "becameMemberDateFrom", value: values.dateFilter ? values.dateFilter.becameMemberDateFrom : undefined},
        {name: "becameMemberDateTo", value: values.dateFilter ? values.dateFilter.becameMemberDateTo : undefined},
        "dateFilter"
    )
};

class DateBecameMemberFilterForm extends React.Component {

    static propTypes = {
        becameMemberDateFrom: PropTypes.object,
        becameMemberDateTo: PropTypes.object,
        selectMembersInfo: PropTypes.object,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func,
    };

    render() {
        const {handleSubmit, becameMemberDateFrom, becameMemberDateTo, selectMembersInfo} = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage
                        id="startDateMembership"
                        defaultMessage="Start date membership"/>
                </Typography>

                <Typography
                    variant="body1"
                    component="div">
                    {selectMembersInfo}
                </Typography>

                <Field
                    name="dateFilter.becameMemberInDateRange"
                    component={RenderSelect}
                    defaultValue={becameMemberInDateRangeOption.YES.value}>
                    <MenuItem value={becameMemberInDateRangeOption.YES.value}>
                        {becameMemberInDateRangeOption.YES.label}
                    </MenuItem>

                    <MenuItem value={becameMemberInDateRangeOption.NO.value}>
                        {becameMemberInDateRangeOption.NO.label}
                    </MenuItem>
                </Field>

                <Field
                    name="dateFilter.becameMemberDateFrom"
                    component={RenderDatePicker}
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="after"
                                defaultMessage="After">
                            </FormattedMessage>
                        </React.Fragment>
                    }
                    validate={becameMemberDateTo ? undefined : atLeastOneDateRequired}/>

                <Field
                    name="dateFilter.becameMemberDateTo"
                    component={RenderDatePicker}
                    endOfDay={true}
                    hintText={
                        <React.Fragment>
                            <FormattedMessage
                                id="before"
                                defaultMessage="Before">
                            </FormattedMessage>
                        </React.Fragment>
                    }
                    validate={becameMemberDateFrom ? undefined : atLeastOneDateRequired}/>

                <Button
                    icon={<Add/>}
                    label={<FormattedMessage
                        id="addSmartSelection"
                        defaultMessage="Add Smart Selection"/>}
                    type="submit"
                    disabled={!becameMemberDateFrom && !becameMemberDateTo}/>
            </form>
        );
    }

}

function mapStateToProps(state) {
    const selector = formValueSelector('dateBecameMemberFilterForm');
    const becameMemberDateFrom = selector(state, 'dateFilter.becameMemberDateFrom');
    const becameMemberDateTo = selector(state, 'dateFilter.becameMemberDateTo');
    return {
        becameMemberDateFrom,
        becameMemberDateTo
    }
}

export default reduxForm({
    form: 'dateBecameMemberFilterForm',
    validate
})(connect(mapStateToProps, null)(DateBecameMemberFilterForm))
