import React from 'react'
import PropTypes from 'prop-types'
import {formValueSelector, reduxForm} from "redux-form";
import {connect} from "react-redux";

import SendoutFormPage1 from "./SendoutFormPage1";
import SendoutFormPage2 from "./SendoutFormPage2";
import SendoutFormPage3 from "./SendoutFormPage3";
import SendoutFormPage4 from "./SendoutFormPage4";


class SendoutForm extends React.Component {

    static propTypes = {
        campaign: PropTypes.object,
        selectedMembers: PropTypes.object,
        membersWithEmail: PropTypes.object,
        membersWithPhone: PropTypes.object,
        history: PropTypes.object,
        smsMessage: PropTypes.object,
        smsAccount: PropTypes.object,
        currentSmartSelection: PropTypes.object,
        categories: PropTypes.object,
        campaigns: PropTypes.object,
        sendoutHistory: PropTypes.object,
        submitting: PropTypes.bool,
        handleOpenSmartSelection: PropTypes.func,
        onSubmit: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.state = {
            page: 1,
        }
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
    }

    previousPage() {
        this.setState({page: this.state.page - 1})
    }

    render() {
        const {
            onSubmit, submitting, campaign, selectedMembers, membersWithEmail, membersWithPhone, history, smsMessage,
            smsAccount, handleOpenSmartSelection, currentSmartSelection, categories, campaigns, sendoutHistory
        } = this.props;
        const {page} = this.state;

        return (
            <React.Fragment>
                {page === 1 && (
                    <SendoutFormPage1
                        smsAccount={smsAccount}
                        handleOpenSmartSelection={handleOpenSmartSelection}
                        currentSmartSelection={currentSmartSelection}
                        campaignId={campaign.id}
                        categories={categories}
                        campaigns={campaigns}
                        sendoutHistory={sendoutHistory}
                        history={history}
                        selectedMembers={selectedMembers}
                        membersWithEmail={membersWithEmail}
                        membersWithPhone={membersWithPhone}
                        onSubmit={this.nextPage}/>
                )}
                {page === 2 && (
                    <SendoutFormPage2
                        smsAccount={smsAccount}
                        previousPage={this.previousPage}
                        membersWithPhone={membersWithPhone}
                        campaign={campaign}
                        smsMessage={smsMessage}
                        onSubmit={this.nextPage}/>
                )}
                {page === 3 && (
                    <SendoutFormPage3
                        selectedMembers={selectedMembers}
                        membersWithEmail={membersWithEmail}
                        membersWithPhone={membersWithPhone}
                        submitting={submitting}
                        previousPage={this.previousPage}
                        onSubmit={(values) => {
                            onSubmit(values);
                            this.nextPage();
                        }}/>
                )}
                {page === 4 && (
                    <SendoutFormPage4
                        submitting={submitting}
                        history={history}/>
                )}
            </React.Fragment>
        )
    }

}

SendoutForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const selector = formValueSelector('sendoutForm');
    const smsMessage = selector(state, 'smsMessage');
    return {
        smsMessage
    }
}

export default reduxForm({
    form: 'sendoutForm'
})(connect(mapStateToProps, null)(SendoutForm))
