import React from "react";
import PropTypes from "prop-types";

export function withPurchaseFormData(PurchaseFormComponent) {
    return class PurchaseForm extends React.Component {

        static propTypes = {
            consumer: PropTypes.object,
            initialValues: PropTypes.object,
            history: PropTypes.object,
            purchaseStampcardUsage: PropTypes.array,
            customerStampcardUsage: PropTypes.array,
            categoriesOptions: PropTypes.array,
            consumerStampcardCoupons: PropTypes.array,
            couponsOptions: PropTypes.array,
            campaignOptions: PropTypes.array,
            stampcards: PropTypes.array,
            consumerPoints: PropTypes.number,
            clubPointsActive: PropTypes.bool,
            clubPointsCountedPointsFrom: PropTypes.number,
            clubPointsMonthValidation: PropTypes.number,
            isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
            submitting: PropTypes.bool,
            handleSubmit: PropTypes.func,
            removeMember: PropTypes.func,
            merchantUpdateMember: PropTypes.func,
            removePurchase: PropTypes.func
        };

        constructor(props) {
            super(props);
            this.state = {
                tradedPoints: null
            }
        }

        componentDidUpdate(prevProps) {
            const {purchaseStampcardUsage, purchase, clubPointsActive} = this.props;
            const {tradedPoints} = this.state;

            if (clubPointsActive && purchase && tradedPoints == null) {
                this.setTradedPoints(purchase.tradedPoints)
            }

            if (purchaseStampcardUsage !== prevProps.purchaseStampcardUsage) {
                this.forceUpdate()
            }
        }

        addTradedPoints(pointsRequired) {
            const {tradedPoints} = this.state;
            this.setState({
                tradedPoints: tradedPoints + pointsRequired
            });
        }

        subtractTradedPoints(pointsRequired) {
            const {tradedPoints} = this.state;
            this.setState({
                tradedPoints: tradedPoints - pointsRequired
            });
        }

        setTradedPoints(points) {
            this.setState({
                tradedPoints: points
            });
        }

        render() {
            const {tradedPoints} = this.state;

            return (
                <PurchaseFormComponent
                    {...this.props}
                    tradedPoints={tradedPoints ? tradedPoints : 0}
                    addTradedPoints={this.addTradedPoints.bind(this)}
                    subtractTradedPoints={this.subtractTradedPoints.bind(this)}/>
            );
        }
    }
}
