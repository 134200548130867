import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Spinner from "../../../common/components/common/Spinner";
import SmartSelectionGrid from "./SmartSelectionGrid";
import CampaignRecipients from "./CampaignRecipients";
import Sendout from "../sendout/Sendout";
import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";

import bindActionCreators from "redux/src/bindActionCreators";
import {
    fetchConsumersNumber,
    fetchSendoutHistory,
    fetchSmartSelection,
    filterMembersForCampaign,
    getMembersForCampaign,
    setCurrentSmartSelection
} from "../../actions/merchant-actions";
import {fetchClubPoints} from "../../actions/merchant-points-actions";
import {fetchCategories} from "../../actions/merchant-category-actions";
import {fetchCampaigns} from "../../actions/merchant-campaign-actions";
import {fetchCommercialChain, fetchMaxPointsBalance} from "../../actions/merchant-chain-actions";

import {getDefaultStartAndEndDateForSendoutHistory} from "../../../common/utils/date-utils";

class SmartSelectionHome extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        currentSmartSelection: PropTypes.object,
        history: PropTypes.object,
        clubPoints: PropTypes.object,
        chain: PropTypes.object,
        match: PropTypes.object,
        smartSelection: PropTypes.object,
        sendout: PropTypes.bool,
        sendoutHistory: PropTypes.array,
        categories: PropTypes.array,
        membersForCampaign: PropTypes.array,
        campaigns: PropTypes.array,
        maxPointsBalance: PropTypes.number,
        consumersNumber: PropTypes.number,
        fetchSmartSelection: PropTypes.func,
        fetchSendoutHistory: PropTypes.func,
        fetchCommercialChain: PropTypes.func,
        fetchConsumersNumber: PropTypes.func,
        fetchMaxPointsBalance: PropTypes.func,
        fetchCategories: PropTypes.func,
        fetchCampaigns: PropTypes.func,
        getMembersForCampaign: PropTypes.func,
        fetchClubPoints: PropTypes.func,
        setCurrentSmartSelection: PropTypes.func,
        filterMembersForCampaign: PropTypes.func
    };

    state = {
        smartSelectionOpen: false,
        smartSelection: null
    };

    handleOpenSmartSelection() {
        this.setState({smartSelectionOpen: true});
    }

    handleCloseSmartSelection() {
        this.setState({smartSelectionOpen: false});
    }

    UNSAFE_componentWillMount() {
        this.props.setCurrentSmartSelection(undefined)
    }

    componentDidMount() {
        this.fetchDataIfPossible()
    }

    componentDidUpdate(prevProps) {
        const {consumersNumber, smartSelection, currentSmartSelection, match: {params: {id}}, maxPointsBalance,
            clubPoints, categories, chain, campaigns, sendout, sendoutHistory, setCurrentSmartSelection,
            filterMembersForCampaign} = this.props;
        if (!prevProps.chain || prevProps.chain.id !== this.props.chain.id) {
            this.fetchDataIfPossible()
        }

        if (consumersNumber !== undefined
            && smartSelection
            && clubPoints
            && categories
            && campaigns
            && chain
            && sendoutHistory
            && maxPointsBalance !== undefined) {
            if (!currentSmartSelection || currentSmartSelection.id !== smartSelection.id) {
                setCurrentSmartSelection(smartSelection)
            }

            if (sendout && (!currentSmartSelection
                || !currentSmartSelection.sendoutFilter
                || !currentSmartSelection.sendoutFilter.length > 0
                || !currentSmartSelection.tasteFilter
                || !currentSmartSelection.tasteFilter.length > 0
                || !currentSmartSelection.dateFilter
                || !currentSmartSelection.dateFilter.length > 0
            )) {
                const extraSendoutFilter = {
                    inactive: true,
                    campaign: Number(id)
                };
                smartSelection.sendoutFilter = !smartSelection.sendoutFilter
                    ? [extraSendoutFilter]
                    : [...smartSelection.sendoutFilter, extraSendoutFilter];

                const extraTasteFilter = {
                    campaign: Number(id)
                };
                smartSelection.tasteFilter = !smartSelection.tasteFilter
                    ? [extraTasteFilter]
                    : [...smartSelection.tasteFilter, extraTasteFilter];

                const extraDateFilter = {
                    campaign: Number(id)
                };
                smartSelection.dateFilter = !smartSelection.dateFilter
                    ? [extraDateFilter]
                    : [...smartSelection.dateFilter, extraDateFilter];

                setCurrentSmartSelection(smartSelection);
                filterMembersForCampaign(smartSelection, id, sendout);
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.smartSelection
            && nextProps
            && nextProps.smartSelection
            && this.props.smartSelection !== nextProps.smartSelection) {
            this.props.setCurrentSmartSelection(nextProps.smartSelection)
        }
    }

    fetchDataIfPossible() {
        const {
            chain, fetchSmartSelection, fetchSendoutHistory, fetchCommercialChain, fetchConsumersNumber,
            fetchMaxPointsBalance, fetchCategories, fetchCampaigns, sendout, getMembersForCampaign,
            fetchClubPoints
        } = this.props;
        const dateValues = getDefaultStartAndEndDateForSendoutHistory();

        if (chain && chain.id) {
            const chainId = chain.id;
            fetchMaxPointsBalance(chainId);
            fetchSendoutHistory(chainId, dateValues);
            fetchCommercialChain(chainId);
            fetchConsumersNumber(chainId);
            fetchClubPoints(chainId);
            fetchCampaigns(chainId, false, true);
        }
        fetchCategories();
        fetchSmartSelection(this.props.match.params.id);
        if (!sendout) {
            getMembersForCampaign(this.props.match.params.id)
        }
    }

    render() {
        const {
            app, consumersNumber, smartSelection, currentSmartSelection, membersForCampaign, history, maxPointsBalance,
            clubPoints, categories, chain, campaigns, sendout, sendoutHistory
        } = this.props;
        const {smartSelectionOpen} = this.state;

        const campaignId = this.props.match.params.id;

        if (consumersNumber === undefined
            || !smartSelection
            || !clubPoints
            || !categories
            || !campaigns
            || !chain
            || !sendoutHistory
            || maxPointsBalance === undefined) {
            return <Spinner app={app}/>
        }

        const isClubPointActive = clubPoints.active;

        // TODO ADD another filters
        const dateFilter = currentSmartSelection && currentSmartSelection.dateFilter;
        const sumFilter = currentSmartSelection && currentSmartSelection.sumFilter;
        const quantityFilter = currentSmartSelection && currentSmartSelection.quantityFilter;
        const tasteFilter = currentSmartSelection && currentSmartSelection.tasteFilter;
        const sendoutFilter = currentSmartSelection && currentSmartSelection.sendoutFilter;

        const publicToAll = !(dateFilter && dateFilter.length > 0)
            && !(sumFilter && sumFilter.length > 0)
            && !(quantityFilter && quantityFilter.length > 0)
            && !(tasteFilter && tasteFilter.length > 0)
            && !(sendoutFilter && sendoutFilter.length > 0);

        return (
            <Container size={Container.SIZE_SMALL}>
                <Paper padding>
                    {this.renderSmartSelection(
                        smartSelection,
                        currentSmartSelection,
                        membersForCampaign,
                        smartSelectionOpen,
                        campaignId,
                        history,
                        publicToAll,
                        dateFilter,
                        sumFilter,
                        quantityFilter,
                        tasteFilter,
                        consumersNumber,
                        isClubPointActive,
                        categories,
                        campaigns,
                        chain,
                        maxPointsBalance,
                        sendout,
                        sendoutHistory)}
                </Paper>
            </Container>
        )
    }

    renderSmartSelection(smartSelection, currentSmartSelection, membersForCampaign, smartSelectionOpen, campaignId,
                         history, publicToAll, dateFilter, sumFilter, quantityFilter, tasteFilter, consumersNumber,
                         isClubPointActive, categories, campaigns, chain, maxPointsBalance, sendout, sendoutHistory) {

        if (sendout && !smartSelectionOpen) {
            return (
                <Sendout
                    sendoutHistory={sendoutHistory}
                    currentSmartSelection={currentSmartSelection}
                    setCurrentSmartSelection={this.props.setCurrentSmartSelection.bind(this)}
                    membersForCampaign={membersForCampaign}
                    publicToAll={publicToAll}
                    dateFilter={dateFilter}
                    sumFilter={sumFilter}
                    quantityFilter={quantityFilter}
                    tasteFilter={tasteFilter}
                    categories={categories}
                    campaigns={campaigns}
                    history={history}
                    campaignId={campaignId}
                    handleOpenSmartSelection={this.handleOpenSmartSelection.bind(this)}/>
            )
        }

        if (smartSelectionOpen) {
            return (
                <SmartSelectionGrid
                    sendoutHistory={sendoutHistory}
                    sendout={sendout}
                    currentSmartSelection={currentSmartSelection}
                    isClubPointActive={isClubPointActive}
                    campaignId={campaignId}
                    chain={chain}
                    maxPointsBalance={maxPointsBalance}
                    categories={categories}
                    campaigns={campaigns}
                    history={history}
                    handleCloseSmartSelection={this.handleCloseSmartSelection.bind(this)}/>
            )
        }
        return (
            <CampaignRecipients
                consumersNumber={consumersNumber}
                currentSmartSelection={currentSmartSelection}
                setCurrentSmartSelection={this.props.setCurrentSmartSelection.bind(this)}
                membersForCampaign={membersForCampaign}
                publicToAll={publicToAll}
                dateFilter={dateFilter}
                sumFilter={sumFilter}
                quantityFilter={quantityFilter}
                tasteFilter={tasteFilter}
                categories={categories}
                campaigns={campaigns}
                history={history}
                campaignId={campaignId}
                handleOpenSmartSelection={this.handleOpenSmartSelection.bind(this)}/>
        )
    }

}

function mapStateToProps(state) {
    return {
        chain: state.chains.current,
        consumersNumber: state.merchant.consumersNumber,
        categories: state.merchant.categories,
        campaigns: state.merchant.campaigns,
        smartSelection: state.merchant.smartSelection,
        currentSmartSelection: state.merchant.currentSmartSelection,
        membersForCampaign: state.merchant.membersForCampaign,
        clubPoints: state.points.clubPoints,
        maxPointsBalance: state.chains.maxPointsBalance,
        sendoutHistory: state.merchant.sendoutHistory
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchConsumersNumber,
        fetchSmartSelection,
        setCurrentSmartSelection,
        getMembersForCampaign,
        fetchClubPoints,
        fetchCategories,
        fetchCampaigns,
        fetchCommercialChain,
        fetchMaxPointsBalance,
        fetchSendoutHistory,
        filterMembersForCampaign
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartSelectionHome);
