import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";

import Typography from "@material-ui/core/Typography";

import Container from "../../../common/components/common/Container";
import Paper from "../../../common/components/common/Paper";
import Spinner from "../../../common/components/common/Spinner";
import InviteMemberForm, {INVITE_MEMBER_FORM_NAME} from "./InviteMemberForm";

import {fetchCompany} from "../../actions/merchant-company-actions";
import {fetchUserDetails} from "../../../common/actions/user-actions";
import {consumerOnboarding} from "../../../common/actions/onboarding-actions";

class InviteMember extends React.Component {

    static propTypes = {
        company: PropTypes.object,
        history: PropTypes.object,
        app: PropTypes.object,
        user: PropTypes.object,
        chain: PropTypes.object,
        consumerOnboarding: PropTypes.func,
        fetchCompany: PropTypes.func,
        fetchUserDetails: PropTypes.func
    };

    componentDidMount() {
        this.props.fetchCompany();
        this.props.fetchUserDetails();
    }

    render() {
        const {chain, app, company, user} = this.props;

        if (!chain || !company | !user) {
            return <Spinner app={app}/>
        }

        const commercialChain = this.props.chain;
        const isSingleLocation = commercialChain.pointsOfSale.find(c => c.singlePoint === true);
        const currentPoSId = localStorage.getItem('currentPoSId');
        return (
            <Container size={Container.SIZE_TINY}>
                <Paper padding>
                    <Typography variant="h5">
                        <FormattedMessage
                            id="inviteMember"
                            defaultMessage="Invite member"/>
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="invite-members.inviteMemberText"
                            defaultMessage="Invite a new member with mobile number or e-mail."/>
                    </Typography>

                    <Typography variant="body1">
                        (
                        <FormattedMessage
                            id="invite-members.memberGetsLink"
                            defaultMessage="The member gets a link to complete registration"/>
                        )
                    </Typography>

                    <InviteMemberForm
                        chain={chain}
                        smsAccount={company.smsAccount}
                        commercialChain={commercialChain}
                        isSingleLocation={isSingleLocation}
                        currentPoSId={currentPoSId}
                        user={user}
                        onSubmit={this.inviteMember.bind(this, commercialChain.subdomain, isSingleLocation)}/>
                </Paper>
            </Container>
        );
    }

    inviteMember(commercialChainSubdomain, isSingleLocation, values) {
        const {chain} = this.props;
        if (isSingleLocation) {
            values.pointOfSaleId = localStorage.getItem('currentPoSId')
        }
        this.props.consumerOnboarding({
            ...values,
            'commercialChainSubdomain': commercialChainSubdomain,
            'language': chain.language,
        }, null, INVITE_MEMBER_FORM_NAME);
    }

}

function mapStateToProps(state) {
    return {
        company: state.company.data,
        user: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({consumerOnboarding, fetchCompany, fetchUserDetails}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteMember);
