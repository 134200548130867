import React from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from '@material-ui/core/MenuItem'
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";

import {ROLE_MERCHANT} from "../../../common/components/auth/roles";

const styles = theme => ({
    divider: {
        backgroundColor: theme.palette.common.white
    },
    locationSelectField: {
        borderBottom: theme.palette.common.white,
        color: theme.palette.common.white,
        textAlign: 'center',
        width: 190,
        margin: '0 auto',
        height: 30,
        "& svg": {
            fill: theme.palette.common.white
        },
        "&:before": {
            borderBottomColor: `${theme.palette.common.white} !important`
        },
        "&:after": {
            borderBottomColor: `${theme.palette.common.white} !important`
        },
        "&:hover": {
            borderBottomColor: `${theme.palette.common.white} !important`
        },
        "&:focus": {
            borderBottomColor: `${theme.palette.common.white} !important`
        },
        "&:active": {
            borderBottomColor: `${theme.palette.common.white} !important`
        }
    },
    selectMenu: {
        backgroundColor: theme.palette.secondary.main,
        padding: 0
    },
    selectMenuItem: {
        color: theme.palette.common.white,
        height: 40,
        borderBottom: `1px solid ${theme.palette.common.white}`
    },
    addressSubtitle: {
        color: theme.palette.common.white,
        fontStyle: 'italic',
        textAlign: 'center',
        wordBreak: 'break-word',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    }
});

class MainMenuItems extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        user: PropTypes.object,
        chains: PropTypes.object,
        history: PropTypes.object,
        currentPoS: PropTypes.object,
        chooseCurrentChain: PropTypes.func,
        chooseCurrentPoS: PropTypes.func,
    };

    render() {
        const {currentPoS, user, chains} = this.props;
        const isCurrentChainSinglePos = chains.current && chains.current.pointsOfSale.find(pos => pos.singlePoint === true);

        if (!chains || !chains.commercialChains) {
            return null
        }

        return (
            <React.Fragment>
                {this.renderCurrentChainButton(chains, isCurrentChainSinglePos)}
                {!isCurrentChainSinglePos && this.renderCurrentPoSButton(chains, currentPoS, user.profile)}
            </React.Fragment>
        )
    }

    renderCurrentPoSButton(chains, currentPos, user) {
        const {classes} = this.props;
        const currentChain = chains && chains.current;
        const currentPosAddress = currentChain && currentPos && currentPos.address;
        if (currentChain && currentPos && user) {
            const availablePointOfSale = currentChain.pointsOfSale
                .filter(pointOfSale => user.authority === ROLE_MERCHANT
                    || user.pointsOfSaleIds.includes(pointOfSale.id));
            return (
                <React.Fragment>
                    <Select
                        onChange={this.handleChangeCurrentPoS}
                        value={currentPos.id}
                        className={classes.locationSelectField}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            MenuListProps: {
                                className: classes.selectMenu
                            }
                        }}
                        renderValue={() => currentPos && currentPos.commercialName}>
                        {availablePointOfSale.map((pointOfSale, index) =>
                            <MenuItem
                                key={index}
                                className={classes.selectMenuItem}
                                value={pointOfSale.id}>
                                <div>
                                    {pointOfSale.commercialName}
                                    {this.renderPoSAddress(pointOfSale.address)}
                                </div>
                            </MenuItem>
                        )}
                    </Select>
                    {this.renderPoSAddress(currentPosAddress)}
                </React.Fragment>
            )
        }
    }

    renderPoSAddress(address) {
        const {classes} = this.props;
        return (
            <Typography className={classes.addressSubtitle}>
                {address.street}, {address.city}
            </Typography>
        )
    }


    renderCurrentChainButton(chains, isCurrentChainSinglePos) {
        const {classes} = this.props;
        if (chains.current) {
            return (
                <React.Fragment>
                    <Select
                        onChange={this.handleChangeCurrentChain}
                        className={classes.locationSelectField}
                        value={chains.current.id}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            MenuListProps: {
                                className: classes.selectMenu
                            }
                        }}>
                        {chains.commercialChains.map((v, index) =>
                            <MenuItem
                                key={index}
                                className={classes.selectMenuItem}
                                value={v.id}>
                                {v.commercialName}
                            </MenuItem>
                        )}
                    </Select>
                    {isCurrentChainSinglePos && this.renderPoSAddress(chains.current.pointsOfSale[0].address)}
                </React.Fragment>
            )
        }
    }

    handleChangeCurrentChain = (event) => {
        const {user: {profile}} = this.props;
        this.props.chooseCurrentChain(event.target.value, profile)
    };

    handleChangeCurrentPoS = (event) => {
        this.props.chooseCurrentPoS(event.target.value)
    };

    hasAtLeastOneChain(user, chains) {
        return user && user.profile && chains.commercialChains && chains.commercialChains.length > 0;
    }

}

export default withRouter(withStyles(styles)(MainMenuItems))
