import React from "react";
import {Field, reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import Typography from "@material-ui/core/Typography";

import RenderTextField from "../../../../common/components/common/RenderTextField";
import Button from "../../../../common/components/common/Button";
import {required} from "../../../../common/components/common/form/FormUtils";

import {setFormValue} from "../../../actions/merchant-forms-actions";

class StampcardFormPage8 extends React.Component {

    static propTypes = {
        submitting: PropTypes.bool,
        isTemplate: PropTypes.bool,
        handleSubmit: PropTypes.func,
        generateTemplate: PropTypes.func,
        previousPage: PropTypes.func,
        untouch: PropTypes.func,
        clearFields: PropTypes.func
    };

    render() {
        const {submitting, handleSubmit, previousPage, isTemplate, generateTemplate} = this.props;

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography
                    variant="h6"
                    paragraph>
                    <FormattedMessage
                        id="termsForStampCard"
                        defaultMessage="Terms for stamp card"/>
                </Typography>

                <Field
                    name="termsTitle"
                    type="text"
                    component={RenderTextField}
                    hintText={<FormattedMessage
                        id="paragraphTitle"
                        defaultMessage="Paragraph title"/>}
                    validate={required}/>

                <Field
                    name="termsContent"
                    type="text"
                    component={RenderTextField}
                    hintText={<FormattedMessage
                        id="paragraphContent"
                        defaultMessage="Paragraph content"/>}
                    multiline={true}
                    validate={required}/>

                <Button
                    color='secondary'
                    label={<FormattedMessage
                        id="previous"
                        defaultMessage="Previous"/>}
                    onClick={this.resetTermsAndMoveToPreviousPage.bind(this, previousPage)}/>

                <Button
                    label={isTemplate
                        ? <FormattedMessage
                            id="reset"
                            defaultMessage="Reset"/>
                        : <FormattedMessage
                            id="clear"
                            defaultMessage="Clear"/>}
                    onClick={isTemplate
                        ? this.resetTerms.bind(this, generateTemplate)
                        : this.clearTerms.bind(this)}
                    variant="outlined"/>

                <Button
                    label={<FormattedMessage
                        id="save"
                        defaultMessage="Save"/>}
                    type="submit"
                    disabled={submitting}/>
            </form>
        );
    }

    resetTermsAndMoveToPreviousPage(previousPage) {
        this.clearTerms();
        previousPage()
    }

    resetTerms(generateTemplate) {
        generateTemplate();
        this.props.untouch("stampcardForm", "termsTitle", "termsContent");
    }

    clearTerms() {
        this.props.clearFields("stampcardForm", false, false, "termsTitle", "termsContent");
        this.props.untouch("stampcardForm", "termsTitle", "termsContent");
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({setFormValue}, dispatch);
}

export default reduxForm({
    form: "stampcardForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(connect(null, mapDispatchToProps)(StampcardFormPage8))
