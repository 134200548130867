import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Button from "../../../../common/components/common/Button";
import Dialog from "../../../../common/components/common/Dialog";

import DeleteReplacedStampcards from "./DeleteReplacedStampcards";

import {PUBLISHED} from "../../../utils/publish-status-const";

const styles = theme => ({
    itemTitle: {
        fontWeight: 'bold'
    },
    icon: {
        fill: theme.palette.secondary.main
    },
    button: {
        width: '100%',
        marginLeft: 0,
        marginRight: 0
    },
    card: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2)
    },
    rewardWrapper: {
        position: 'relative',
        textAlign: 'left',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    checkbox: {
        padding: 0,
        position: "absolute",
        top: '50%',
        right: theme.spacing(2),
        transform: 'translateY(-50%) rotate(0deg)'
    }
});

class StampcardDistribution extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        stampcard: PropTypes.object,
        stampcardPublishStatusDescription: PropTypes.object,
        stampcards: PropTypes.array,
        stampcardUsages: PropTypes.array,
        publishStampcard: PropTypes.func,
        withdrawStampcard: PropTypes.func,
        stopSharingStampcard: PropTypes.func,
        updateReplacedStampcards: PropTypes.func
    };

    state = {
        otherOptionOpen: false,
        replacedStampcards: [],
        openWithdrawStampcard: false,
        openStopSharingStampcard: false
    };

    handleOpenWithdrawStampcard = () => {
        this.setState({openWithdrawStampcard: true});
    };

    handleCancelWithdrawStampcard = () => {
        this.setState({openWithdrawStampcard: false});
    };

    handleConfirmWithdrawStampcard = (withdrawStampcard) => {
        this.setState({openWithdrawStampcard: false});
        withdrawStampcard();
    };

    handleOpenStopSharingStampcard = () => {
        this.setState({openStopSharingStampcard: true});
    };

    handleCancelStopSharingStampcard = () => {
        this.setState({openStopSharingStampcard: false});
    };

    handleConfirmStopSharingStampcard = (withdrawStampcard) => {
        this.setState({openStopSharingStampcard: false});
        withdrawStampcard();
    };

    componentWillMount() {
        const {stampcard, stampcards} = this.props;
        const stoppedStampcards = stampcards && stampcards.filter(option =>
            option.publishStatus === "STOPPED" && option.id !== stampcard.id).map(option => option.id);
        this.setState({replacedStampcards: stoppedStampcards})
    }

    render() {
        const {
            classes, stampcard, publishStampcard, withdrawStampcard, stopSharingStampcard, stampcards,
            stampcardPublishStatusDescription, updateReplacedStampcards, stampcardUsages
        } = this.props;
        const {otherOptionOpen, replacedStampcards, openWithdrawStampcard, openStopSharingStampcard} = this.state;

        const withdrawStampcardDialogActions = [
            <Button
                key="close"
                label={<FormattedMessage
                    id="close"
                    defaultMessage="Close"/>}
                color="secondary"
                onClick={this.handleCancelWithdrawStampcard}/>,
            <Button
                key={withdrawStampcard}
                label={<FormattedMessage
                    id="withdrawStampcard"
                    defaultMessage="Withdraw stamp card"/>}
                customColor="red"
                onClick={this.handleConfirmWithdrawStampcard.bind(this, withdrawStampcard)}/>
        ];

        const stopSharingStampcardDialogActions = [
            <Button
                key="close"
                label={<FormattedMessage
                    id="close"
                    defaultMessage="Close"/>}
                color="secondary"
                onClick={this.handleCancelStopSharingStampcard}/>,
            <Button
                key={stopSharingStampcard}
                label={<FormattedMessage
                    id="stopSharingStampcard"
                    defaultMessage="Stop sharing out new cards"/>}
                customColor="red"
                onClick={this.handleConfirmStopSharingStampcard.bind(this, stopSharingStampcard)}/>
        ];

        const stoppedStampcards = stampcards
            && stampcards.filter(option => option.publishStatus === "STOPPED" && option.id !== stampcard.id);

        return (
            <div>
                <Typography variant="h6">
                    {stampcard.internalName}
                </Typography>

                <Typography
                    variant="body1"
                    align="left"
                    component="div"
                    paragraph>
                    <span className={classes.itemTitle}>
                        <FormattedMessage
                            id="stampCardStatus"
                            defaultMessage="Stamp card status"/>: </span>
                    {stampcardPublishStatusDescription}
                </Typography>

                {stampcard.publishStatus === PUBLISHED.value
                && stampcard.replacedStampcards
                && stampcard.replacedStampcards.length > 0 && (
                    <DeleteReplacedStampcards
                        stampcardUsages={stampcardUsages}
                        replacedStampcards={stampcard.replacedStampcards}
                        replacedStampcardsIds={replacedStampcards}
                        updateReplacedStampcards={updateReplacedStampcards}
                        removeStampcardFromList={this.addOrRemoveStampcardFromList.bind(this, true,
                            stampcard.replacedStampcards.map(option => option.id))}/>
                )}

                {stampcard.publishStatus === "DRAFT"
                && stoppedStampcards
                && stoppedStampcards.length > 0 && (
                    <Card className={classes.card}>
                        <Typography
                            paragraph
                            variant="body1">
                            <FormattedMessage
                                id="youHaveCardsInStatus"
                                defaultMessage={`You have cards in status "Stopped sharing out new cards".`}/>
                        </Typography>

                        <Typography
                            paragraph
                            variant="body1">
                            <FormattedMessage
                                id="membersAlreadyStartedAStopped"
                                defaultMessage="Members already started a stopped card will see this card first when
                                stopped cards are completed or fallen due."/>
                        </Typography>

                        <Typography
                            component="div"
                            color="secondary"
                            align="right"
                            variant="body1">
                            <FormattedMessage
                                id="otherOptions"
                                defaultMessage="Other options"/>

                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        otherOptionOpen: !otherOptionOpen
                                    })
                                }}>

                                {otherOptionOpen
                                    ? <ExpandLess className={classes.icon}/>
                                    : <ExpandMore className={classes.icon}/>}
                            </IconButton>
                        </Typography>

                        {otherOptionOpen && (
                            <React.Fragment>
                                <Typography
                                    paragraph
                                    variant="body1">
                                    <FormattedMessage
                                        id="membersWithValidStamps"
                                        defaultMessage="Members with valid stamps on the stamp cards you deselect will
                                        also see this stamp card at the same time."/>
                                </Typography>

                                <Typography variant="subtitle1">
                                    <FormattedMessage
                                        id="publishOnceTheseCardsFallDue"
                                        defaultMessage="Publish once these cards fall due"/>:
                                </Typography>

                                {stoppedStampcards.map((stampcard, index) => {
                                    const stampcardChecked = replacedStampcards && replacedStampcards.includes(stampcard.id);

                                    return (
                                        <Card
                                            key={index}
                                            className={classes.rewardWrapper}>
                                            <CardContent>
                                                <Typography variant="subtitle1">
                                                    {stampcard.title}
                                                </Typography>

                                                <Typography
                                                    variant="body1"
                                                    align="left">
                                                    <span className={classes.itemTitle}>
                                                        <FormattedMessage
                                                            id="reward"
                                                            defaultMessage="Reward"/>: </span>
                                                    {stampcard.stampcardCoupon.reward}
                                                </Typography>

                                                <Checkbox
                                                    checked={stampcardChecked}
                                                    onChange={this.addOrRemoveStampcardFromList.bind(this, stampcardChecked,
                                                        replacedStampcards, stampcard.id)}
                                                    className={classes.checkbox}/>
                                            </CardContent>
                                        </Card>
                                    )
                                })}
                            </React.Fragment>
                        )}
                    </Card>
                )}
                {(stampcard.publishStatus === "PUBLISHED" || stampcard.publishStatus === "STOPPED") && (
                    <React.Fragment>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}
                            label={<FormattedMessage
                                id="withdrawTheCard"
                                defaultMessage="Withdraw the card"/>}
                            onClick={this.handleOpenWithdrawStampcard}/>

                        <Dialog
                            onClose={this.handleCancelWithdrawStampcard}
                            open={openWithdrawStampcard}
                            title={<FormattedMessage
                                id="withdrawTheCard"
                                defaultMessage="Withdraw the card"/>}
                            actions={withdrawStampcardDialogActions}
                            content={
                                <React.Fragment>
                                    <Typography
                                        paragraph
                                        variant="subtitle1">
                                        <FormattedMessage
                                            id="areYouSureWithdrawStampcard"
                                            defaultMessage="Are you sure you want to withdraw this stamp card?"/>
                                    </Typography>

                                    <Typography
                                        paragraph
                                        variant="body1">
                                        <FormattedMessage
                                            id="stampcardWillBeRemoved"
                                            defaultMessage="The stamp card will be removed for all members, including
                                            those already started to collect stamps."/>
                                    </Typography>

                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="doYouRatherWantToLet"
                                            defaultMessage="Do you rather want to let already started to collect stamps
                                            get the chance to complete the card and redeem their last reward?
                                            Then select ”Stop sharing out new cards” instead."/>
                                    </Typography>
                                </React.Fragment>
                            }/>
                    </React.Fragment>
                )}

                {stampcard.publishStatus === "PUBLISHED" && (
                    <React.Fragment>
                        <Button
                            customColor="red"
                            className={classes.button}
                            label={<FormattedMessage
                                id="stopSharingOutNewCards"
                                defaultMessage="Stop sharing out new cards"/>}
                            onClick={this.handleOpenStopSharingStampcard}/>

                        <Dialog
                            onClose={this.handleCancelStopSharingStampcard}
                            open={openStopSharingStampcard}
                            title={<FormattedMessage
                                id="stopSharingStampcard"
                                defaultMessage="Stop sharing out new cards"/>}
                            actions={stopSharingStampcardDialogActions}
                            content={
                                <React.Fragment>
                                    <Typography
                                        paragraph
                                        variant="subtitle1">
                                        <FormattedMessage
                                            id="areYouSureStopSharingStampcard"
                                            defaultMessage="Are you sure you want to stop sharing out new cards?"/>
                                    </Typography>

                                    <Typography
                                        paragraph
                                        variant="body1">
                                        <FormattedMessage
                                            id="stampcardWillContinue"
                                            defaultMessage="The card will continue to be available to use by members
                                            with valid stamps until the card fall due."/>
                                    </Typography>

                                    <Typography variant="body1">
                                        <FormattedMessage
                                            id="stampcardFallDue"
                                            defaultMessage="The card fall due {monthValidation} month after collecting
                                            first stamp or when they have completed the card."
                                            values={{
                                                monthValidation: stampcard.monthValidation
                                            }}/>
                                    </Typography>
                                </React.Fragment>
                            }/>
                    </React.Fragment>
                )}

                {stampcard.publishStatus === "DRAFT" && (
                    <Button
                        label={<FormattedMessage
                            id="publishStampcard"
                            defaultMessage="Publish stamp card"/>}
                        disabled={!stampcard.stampcardCoupon}
                        className={classes.button}
                        onClick={publishStampcard.bind(this, replacedStampcards)}/>
                )}
            </div>
        )
    }

    addOrRemoveStampcardFromList(stampcardChecked, list, stampcardId) {
        this.setState({
            replacedStampcards: stampcardChecked ?
                list.filter(option => option !== stampcardId) : [...list, stampcardId]
        })
    }

}

export default withStyles(styles)(StampcardDistribution)
