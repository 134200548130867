import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";

import Spinner from "../../../../common/components/common/Spinner";

export class KarmingDataProcessingAgreement extends React.Component {

    static propTypes = {
        app: PropTypes.object,
        user: PropTypes.object,
        company: PropTypes.object,
    };

    render() {
        const {app, company} = this.props;

        if (!company) {
            return <Spinner app={app}/>
        }

        const {name, companyNumber, postalAddress} = company;

        return (
            <Typography
                variant="body1"
                align="justify"
                component="div">
                <Typography
                    variant="h5"
                    align="center">
                    PERSONUPPGIFTSBITRÄDESAVTAL
                </Typography>
                Version 2018-04-01

                <div>
                    <Typography variant="h6">
                        1. PARTER
                    </Typography>
                    <p>
                        Detta personuppgiftsbiträdesavtal (“Biträdesavtal”) mellan:
                    </p>
                    <p>
                        {name}, reg.
                        no. {companyNumber}, {postalAddress.street}, {postalAddress.zipCode}, {postalAddress.city}) (i
                        det följande “Kunden”); och
                    </p>
                    <p>
                        Karming AB, 559129-2239,med adressen MARIEBERGSGATAN 1, 112 19 Stockholm (i
                        det följande “Bolaget”),
                    </p>

                    var för sig benämnda ”Part” och tillsammans ”Parterna”.
                </div>

                <div>
                    <Typography variant="h6">
                        2. GILTIGHET
                    </Typography>
                    <p>
                        2.1 Detta Biträdesavtal träder ikraft när behörigt företrädare för Kunden accepterat villkoren
                        för
                        personuppgiftsbehandlingen i samband med beställning eller aktivering av tjänsten
                        Smartkundklubb.se.
                    </p>
                    <p>
                        2.2 Biträdesavtalet är giltigt endast om Kunden samtidigt utifrån de faktiska omständigheterna
                        är
                        personuppgiftsansvarig enligt gällande dataskyddslag.
                    </p>
                    <p>
                        2.3 Vid eventuella frågor om detta Biträdesavtal vänligen kontakta support@karming.se.
                    </p>
                </div>


                <div>
                    <Typography variant="h6">
                        3. TILLÄMPNING
                    </Typography>
                    <p>
                        3.1 Kunden och Bolaget har ingått ett avtal om tillhandahållande av digitala tjänster för
                        kundlojalitet, marknadsföring och försäljning i vilken ingår insamling och vidare behandling
                        av personuppgifter.
                    </p>
                    <p>
                        3.2 Detta Biträdesavtal har företräde framför Bolagets användarvillkor för tjänsten vid
                        tolkningen
                        av bestämmelser som rör behandling av personuppgifter.
                    </p>
                    <p>
                        3.3 Parterna är överens om att i detta Biträdesavtal reglera behandlingen av Kundens
                        personuppgifter enligt nedan.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        4. DEFINITIONER
                    </Typography>
                    <p>
                        4.1 Samtliga nedanstående begrepp ska ha följande innebörd:
                    </p>
                    <p>
                        Avtalet, avser det tjänsteavtal Kunden ingått med Bolaget till vilket Bolagets användarvillkor
                        för Tjänsten och detta Biträdesavtal utgör del av.
                    </p>
                    <p>
                        behandling, avser åtgärd eller kombination av åtgärder beträffande personuppgifter eller
                        uppsättningar av personuppgifter enligt avsnitt 6 i detta Biträdesavtal.
                    </p>
                    <p>
                        gällande dataskyddslag, avser rådsdirektiv 95/46/EG, införd i svensk rätt genom
                        personuppgiftslagen (1998:204) och personuppgiftsförordningen (1998:1191) samt den
                        allmänna dataskyddsförordningen (EU) 2016/679 (”GDPR”), med tillhörande
                        genomförandeförfattningar. I händelse av konflikt mellan ovan nämnda författningar ska
                        GDPR från och med den 25 maj 2018 ha företräde.
                    </p>
                    <p>
                        Kundens personuppgifter, avser sådana personuppgifter som behandlas av Bolaget för
                        Kundens räkning i enligt med avsnitt 6 i detta Biträdesavtal.
                    </p>
                    <p>
                        personuppgiftsansvarig, avser en fysisk eller juridisk person, offentlig myndighet,
                        organisation eller annat organ som ensamt eller tillsammans med andra bestämmer ändamålen
                        och medlen för behandling av personuppgifter.
                    </p>
                    <p>
                        personuppgiftsbiträde, avser en fysisk eller juridisk person, offentlig myndighet, organisation
                        eller annat organ som behandlar personuppgifter för den personuppgiftsansvariges räkning.
                    </p>
                    <p>
                        registrerad, avser en identifierad eller identifierbar fysisk person vars personuppgifter
                        behandlas av Bolaget som en del av Tjänsten.
                    </p>
                    <p>
                        standardavtalsvillkor, avser villkor för skydd av personuppgifter överförda till Tredje land i
                        enlighet med Europeiska kommissionens beslut C(2010)593 av den 5 februari 2010 eller
                        motsvarande villkor som ersätter dessa.
                    </p>
                    <p>
                        säkerhetsincident, avser en säkerhetsincident som leder till oavsiktlig eller olaglig
                        förstöring,
                        förlust eller ändring eller till obehörigt röjande av eller obehörig åtkomst till de
                        personuppgifter som överförts, lagrats och i övrigt behandlas.
                    </p>
                    <p>
                        Tjänsten, avser tjänsten Samrtkundklubb.se som Bolaget tillhandahåller under Avtalet.
                    </p>
                    <p>
                        Tredje land, avser ett land utanför Europeiska Ekonomiska Samarbetet eller internationell
                        organisation som av EU komissionen inte anses säkerställa en adekvat skyddsnivå enligt
                        kapitel V GDPR.
                    </p>
                    <p>
                        Underbiträde, avser sådant personuppgiftsbiträde som anlitas av Bolaget för utförande av en
                        specifik behandling på Kundens vägnar.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        5. BEHANDLING AV PERSONUPPGIFTER
                    </Typography>
                    <p>
                        5.1 Avseende behandling av personuppgifter inom ramen för detta Biträdesavtal har Parterna
                        kommit överens om att Kunden är personuppgiftsansvarig och Bolaget är
                        personuppgiftsbiträde enligt definitionen i gällande dataskyddslag.
                    </p>
                    <p>
                        5.2 Båda Parter, inklusive den eller de personer som arbetar under respektive Parts ledning, ska
                        följa tillämpliga bestämmelser för personuppgiftsansvarig och personuppgiftsbiträde i enlighet
                        med gällande dataskyddslag och svara för sina respektive rättsliga skyldigheter.
                    </p>
                    <p>
                        5.3 Bolaget får, i egenskap av Kundens personuppgiftsbiträde, endast behandla personuppgifter
                        för Kundens räkning i enlighet med dennes instruktioner. Behandlar Bolaget personuppgifter
                        för egen räkning sker detta i egenskap av personuppgiftsansvarig.
                    </p>
                    <p>
                        5.4 Kundens fullständiga instruktioner framgår av detta Biträdesavtal i vilket föremålet för
                        behandlingen, behandlingens varaktighet, art och ändamål, typen av personuppgifter och
                        kategorier av personuppgifter framgår, samt samt Kundens konfigurering och användning av
                        Tjänsten enligt Avtalet.
                    </p>
                    <p>
                        5.5 Är Bolaget enligt rättslig förpliktelse skyldig att behandla Kundens personuppgifter för
                        annat
                        ändamål eller på annat sätt än vad som framgår av Kundens instruktioner ska Bolaget
                        omedelbart informera Kunden om den rättsliga förpliktelsen, såvida inte Bolaget är förhindrad
                        enligt författning eller myndighetsbeslut.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        6. KUNDENS INSTRUKTIONER
                    </Typography>
                    <p>
                        6.1 Bolaget behandlar personuppgifter för Kundens räkning i enlighet med Kundens instruktioner.
                        Kunden ger Bolaget instruktioner genom att vid utformningen av tjänsten aktivt välja vilka
                        fält, rullistor [etc] som ska vara tillgängliga för Kundens användare.
                    </p>
                    <p>
                        6.2 Bolaget behandlar Kundens personuppgifter i syfte att tillhandahålla en digital plattform
                        för
                        kundlojalitet, marknadsföring och försäljning samt i övrigt fullgöra sina åtaganden enligt
                        Avtalet.
                    </p>
                    <p>
                        6.3 Kundens personuppgifter består av kontakt- och användaruppgifter som Kunden och
                        användare (registrerade) själva registrerat i tjänstens användargränssnitt eller i övrigt lämnat
                        ifrån sig till Bolaget.
                    </p>
                    <p>
                        6.4 Med behandling avses insamling, registrering och lagring av Kundens personuppgifter samt
                        organisering, strukturering, bearbetning, läsning och sammanförande (med andra uppgifter)
                        för analys och profilering av registrerad. Med behandling avses också ändring, justering och
                        begränsning samt, vid behandlingens upphörande, radering.
                    </p>
                    <p>
                        6.5 Om inte annat uttryckligen anges sker all behandling Kundens personuppgifter inom det
                        Europeiska Ekonomiska Samarbetet (”EES”).
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        7. DEN REGISTRERADES RÄTTIGHETERR
                    </Typography>
                    <p>
                        7.1 Bolaget ska omgående informera Kunden om Bolaget blir kontaktad av en registrerad som vill
                        tillvarata sina dataskyddsrättigheter, såsom rätten till information, rätten till tillgång,
                        rätten till
                        rättelse, rätten till radering (”rätten att bli bortglömd”), rätten till begränsning av
                        behandling
                        samt rätten till dataportabilitet.
                    </p>
                    <p>
                        7.2 Med hänsyn tagen till behandlingens art ska Bolaget, i den mån det är möjligt, genom
                        tekniska
                        och organisatoriska åtgärder assistera Kunden att fullgöra sina skyldigheter gentemot den
                        registrerade avseende dennes rättigheter enligt punkten 7.1, vilket sker genom att Bolaget, via
                        tjänstens användargränssnitt, ger Kunden full kontroll över Kundens personuppgifter. Har
                        Kunden raderat en personuppgift ska Bolaget vidta sådana åtgärder som krävs för att denna
                        inte ska kunna återskapas.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        8. KUNDENS INSTRUKTIONER
                    </Typography>
                    <p>
                        8.1 Bolaget ska genom lämpliga tekniska och organisatoriska åtgärder, i förhållande till
                        behandlingens art, omfattning, sammanhang och ändamål och risk säkerställa att skyddet av
                        Kundens personuppgifter och den registrerades fri- och rättigheter tillvaratas i samband med
                        behandlingen och tills dess denna upphört.
                    </p>
                    <p>
                        8.2 Bolaget har vid bedömningen av lämplig säkerhetsnivå särskilt tagit hänsyn till risken för
                        oavsiktlig eller olaglig förstöring, förlust eller ändring eller till obehörigt röjande av eller
                        obehörig åtkomst till Kundens personuppgifter som behandlas och därmed vidta åtgärder för
                        att vidmakthålla förmågan att säkerställa konfidentialiteten, riktigheten och tillgängligheten
                        hos Kundens personuppgifter samt motståndskraften hos systemen och den tekniska
                        infrastrukturen som används vid behandlingen.
                    </p>
                    <p>
                        8.3 Bolaget ska begränsa tillgången till Kundens personuppgifter och endast ge behörighet till
                        sådan personal som behöver ha tillgång till dessa för att fullgöra sina åtaganden enligt detta
                        Biträdesavtal. Bolaget ska tillse att endast personer med behörighet att behandla Kundens
                        personuppgifter har ingått särskild sekretessförbindelse. Vad som sägs ovan gäller dock inte
                        om Bolaget ålagts med stöd av tvingande lag att lämna ut Kundens personuppgifter till behörig
                        myndighet.
                    </p>
                    <p>
                        8.4 Kunden är fullt införstådd med och godkänner de tekniska och organisatoriska åtgärder som
                        Bolaget vidtagit och som finns beskrivna på under avsnittet ”Dataskyddscenter” på Bolagets
                        hemsida.
                    </p>

                </div>


                <div>
                    <Typography variant="h6">
                        9. SÄKERHETSINCIDENTER
                    </Typography>
                    <p>
                        Bolaget ska utan oskäligt dröjsmål från att det kommit Bolaget tillkänna, underrätta Kunden
                        om förekomsten av en säkerhetsincident. En sådan underrättelse ska innehålla information om
                        säkerhetsincidentens art och, om möjligt, antalet registrerade som berörs samt typer av
                        personuppgifter och antalet personuppgiftsposter som berörs. Underrättelsen ska även
                        innehålla en beskrivning av konsekvenserna av säkerhetsincidenten och beskriva vilka
                        åtgärder som vidtagits eller kommer att vidtas av Bolaget för att åtgärda eller, när så är
                        möjligt, mildra säkerhetsimcidentens potentiella negativa effekter.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        10. KONSEKVENSBEDÖMNINGAR OCH FÖRHANDSSAMRÅD
                    </Typography>
                    <p>
                        10.1 Bolaget ska på Kundens begäran bistå denne att fullgöra sina skyldigheter enligt gällande
                        dataskyddslag vid utförandet av konsekvensbedömningar avseende dataskydd och
                        förhandssamråd med behörig tillsynsmyndighet.
                    </p>
                    <p>
                        10.2 Kunden ska i första hand använda den information om dataskydd som Bolaget tillhandahåller
                        Kunden under avsnittet ”Dataskyddscenter” på Bolagets hemsida. All ytterligare hjälp med
                        anledning av denna punkt 10 utförs av Bolaget, på begäran av Kunden, mot skälig ersättning.
                        En sådan begäran ska göras till support@karming.se.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        11. REVISION
                    </Typography>
                    <p>
                        11.1 Bolaget ska medverka till att Kunden får del av information och dokumentation som rör
                        behandlingen av Kundens personuppgifter i den utsträckning som krävs för att Kunden ska
                        kunna fullgöra sina skyldigheter enligt gällande dataskyddslag, inbegripet inspektioner
                        (”Revision”).
                    </p>
                    <p>
                        11.2 Vid Revision ska Kunden i första hand ta del av den information som finns under avsnittet
                        ”Dataskyddscenter” på Bolagets hemsida. Skulle det visa sig att den information och
                        dokumentation som Bolaget tillhandahållit inte är tillräcklig för att Kunden ska kunna fullgöra
                        sina skyldigheter enligt gällande dataskyddslag har Kunden, eller en oberoende tredje man
                        som denne anlitar, rätt att begära ytterligare dokumentation och i sista hand, när alla andra
                        mindre ingripande åtgärder är uttömda, utföra inspektioner hos Bolaget i dennes lokaler och
                        utrustning.
                    </p>
                    <p>
                        11.3 Bolaget ska senast tre (3) veckor från Kundens begäran bereda Kunden, eller en oberoende
                        tredje man anlitad av Kunden, tillträde till Bolagets lokaler under normal arbetstid i den
                        utsträckning som är skälig utifrån ändamålet. Vardera Parten står för sina egna kostnader i
                        samband med Revision.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        12. ANLITANDET AV UNDERBITRÄDEN
                    </Typography>
                    <p>
                        12.1 I och med att Kunden ingått detta Biträdesavtal har Kunden samtyckt till att Bolaget
                        anlitar
                        Underbiträden för behandling av Kundens personuppgifter, vilka framgår under avsnittet
                        ”Dataskyddscenter” på Bolagets hemsida.
                    </p>
                    <p>
                        12.2 Av Bolaget anlitade Underbiträden har samtliga lämnat garantier att vidmakthålla lämpliga
                        tekniska och organisatoriska åtgärder på ett sådant sätt att behandlingen uppfyller kraven
                        enligt gällande dataskyddslag.
                    </p>
                    <p>
                        12.3 Bolaget har ingått avtal med Underbiträden och från och med ikraftträdandet av Förordning
                        (EU) 2016/679 samt ålagt dessa i allt väsentligt samma skyldigheter som gäller för Bolaget
                        enligt detta Biträdesavtal. Bolaget är full ansvar gentemot Kunden för Underbiträdens
                        fullgörande av dennes skyldigheter.
                    </p>
                    <p>
                        12.4 Avser Bolaget att byta ut eller lägga till ett Underbiträdet ska Bolaget senast femton (15)
                        dagar
                        innan bytet informera via e-post eller notis vid inloggning Kunden om sina avsikter så att
                        Kunden har möjlighet att invända mot en sådan förändring. Om Kunden invänder mot ett
                        utbyte av Underbiträde eller återkallar denne sitt tidigare samtycke och skriftligen motiverar
                        sin invändning eller återkallande genom att gjöra sannolikt att Bolaget anlitar ett
                        Underbiträdet
                        i strid med gällande dataskyddslag har Kunden rätt att säga upp Avtalet samt detta
                        Biträdesavtal med omedelbar verkan. En uppsägning av Avtalet på denna grund ger inte
                        Kunden rätt till återbetalning av erlagda avgifter eller ersättning för skada eller förlust. En
                        sådan invändning ska göras till support@karming.se.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        13. ÖVERFÖRING TILL TREDJE LAND
                    </Typography>
                    <p>
                        13.1 Överföring, eller annan form av tillgängliggörande av Kundens personuppgifter, till ett
                        Underbiträde i Tredje land får endast ske efter att tillräckliga skyddsåtgärder vidtagits.
                    </p>
                    <p>
                        13.2 För att Bolaget ska kunna tillhandahålla Tjänsten enligt överenskommen specifikation har
                        Kunden samtyckt till att Bolaget, får överföra eller på annant sätt tillgängliggöra Kundens
                        personuppgifter för ett Underbiträde i ett Tredje land. I förevarande fall skyddas Kundens
                        personuppgifter genom att Bolaget, för Kundens räkning, ingår ett särskilt avtal med
                        Underbiträdet baserat på Standardavtalsvillkor eller vidta andra enligt gällande dataskyddslag
                        lämpliga skyddsåtgärder.
                    </p>
                    <p>
                        13.3 Inom ramen för Standardavtalsvillkoren anses Kunden vara överförare (”data exporter”) och
                        Underbiträdet mottagare (”data importer”) av Kundens personuppgifter.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        14. ANSVAR FÖR SKADA
                    </Typography>
                    <p>
                        14.1 Har Kunden ålagts att ersätta registrerad för skada till följd av Bolagets överträdelse av
                        Biträdesavtalet eller gällande dataskyddslag ska Bolaget ersätta Kunden i den omfattning
                        Bolaget ansvarar för skadan upp till ett belopp motsvarande 12 månaders föregående betalning
                        för Tjänsten.
                    </p>
                    <p>
                        14.2 I övrigt ska användarvillkoren för Tjänsten avseende ansvar och ansvarsbegränsning gälla.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        15. AVTALSTID OCH ÅTGÄRDER VID UPPHÖRANDE
                    </Typography>
                    <p>
                        15.1 Biträdesavtalet gäller tills behandlingen av Kundens personuppgifter upphör till följd av
                        att
                        Avtalet upphör att gälla eller om Biträdesavtalet ersätts av ett annat
                        personuppgiftsbiträdesavtal.
                    </p>
                    <p>
                        15.2 Vid behandlingens upphörande ska Bolaget säkerställa att Kundens personuppgifter raderas.
                    </p>
                </div>

                <div>
                    <Typography variant="h6">
                        16. TILLÄMPLIG LAG OCH TVIST
                    </Typography>
                    <p>
                        Detta Biträdesavtal ska tolkas och tillämpas i enlighet med svensk rätt. Tvister till följd av
                        tolkningen och tillämpningen av detta Biträdesavtal ska slutligen lösas i enlighet med reglerna
                        för skiljeförfarande antagna av Stockholms handelskammares skiljedomsinstitut. Platsen för
                        skiljeförfarandet är Stockholm.
                    </p>
                </div>
            </Typography>
        )
    }

}
