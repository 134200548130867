import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Dropzone from 'react-dropzone';
import {clearFields, Field} from "redux-form";
import {Group, Image, Layer, Stage, Text} from 'react-konva';
import loadImage from 'blueimp-load-image';
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DeleteOutlined from '@material-ui/icons/DeleteOutlined';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Save from '@material-ui/icons/Save';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import Undo from '@material-ui/icons/Undo';

import Button from '../../../common/components/common/Button';
import Spinner from '../../../common/components/common/Spinner';
import FormattedTextBuilder from "./FormattedTextBuilder";
import RenderImagePicker from "./RenderImagePicker";
import RenderImageCropper from "./RenderImageCropper";
import Checkbox from "../../../common/components/common/form/RenderCheckbox";

import {addBannerChangeToForm, setFormValue} from '../../actions/merchant-forms-actions';

import {colorBlack} from "../../../common/utils/color-const";
import {
    BANNER_HEIGHT_PX,
    BANNER_MAX_OUTPUT_SIZE,
    BANNER_MAX_TEXTS_COUNT,
    BANNER_RATIO,
    BANNER_WIDTH_PX,
    DEFAULT_FONT_SIZE,
    GOLDEN_RATIO_PERCENTAGE,
    showDefaultCursor,
    showGrabbingCursor,
    showGrabCursor
} from "../../../common/constants";
import {AMAZON_S3_URL} from "../../../common/config/karming-links";
import StampPicker from "./StampPicker";
import {STAMPCARD_FORM_NAME} from "../account/stampcard/StampcardForm";
import {renderStamps, STANDARD_STAMPCARD} from "../../../common/components/common/stampcard/StampcardUtils";
import StampTextEditor from "./StampTextEditor";

const croppingAccuracy = 0.99;

const validateBannerSize = data => {
    if (data && data.size && data.size > BANNER_MAX_OUTPUT_SIZE) {
        return 'Created banner is too large in size. Please choose another background image.';
    }
};

export const bannerRequired = value => {
    if (!value
        || (!value.isUpdating && !value.newData)
        || (!value.isEditing && value.isModified && !value.newData)) {
        return 'Banner is required.';
    }
    if (value && value.isEditing) {
        return value.error || 'Du måste spara eller ångra ändringarna.';
    }
    const sizeValidation = validateBannerSize(value && value.newData);
    if (sizeValidation) {
        return sizeValidation;
    }
};

const styles = theme => ({
    builderWrapper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    builderImageWrapper: {
        maxWidth: 320,
        width: '100%',
        margin: '0 auto'
    },
    selectedImage: {
        position: 'relative',
        width: '100%',
        paddingTop: '125%'
    },
    imageContent: {
        position: 'absolute',
        left: -2,
        top: -2,
        width: '100%',
        height: '100%'
    },
    imageBorderedContent: {
        position: 'absolute !important',
        left: -2,
        top: -2,
        width: '100%',
        height: '100%',
        border: `2px dashed ${theme.palette.grey[400]}`,
        borderRadius: 5
    },
    imageAcceptedBorderedContent: {
        border: `2px dashed ${theme.palette.primary.main}`
    },
    imageRejectedBorderedContent: {
        border: `2px dashed ${theme.palette.error.main}`
    },
    dropzoneContent: {
        position: 'absolute',
        width: '100%',
        top: '50%',
        transform: 'translateY(-50%)'
    },
    selectImageButton: {
        minWidth: GOLDEN_RATIO_PERCENTAGE,
        marginTop: 10
    },
    rewardWrapper: {
        color: theme.palette.common.white,
        width: '100%',
        position: 'absolute',
        zIndex: 99,
        height: '100%'
    },
    rewardContent: {
        whiteSpace: 'pre-wrap',
        position: 'relative',
        padding: 8,
        top: '50%',
        transform: 'translateY(-40%) rotate(0deg)'
    },
    textFieldsWrapper: {
        marginTop: 6,
        marginBottom: 6,
        width: '100%'
    },
    buttonsWrapper: {
        marginTop: theme.spacing(1)
    },
    checkboxesWrapper: {
        display: 'flex',
        justifyContent: 'center'
    }
});

class BannerBuilder extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        meta: PropTypes.object,
        input: PropTypes.object,
        initialValues: PropTypes.object,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        stampPath: PropTypes.string,
        isUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        currentImageFile: PropTypes.string,
        stampUncollectedPath: PropTypes.string,
        monthValidation: PropTypes.string,
        reward: PropTypes.string,
        stampsAmount: PropTypes.number,
        stampcard: PropTypes.bool,
        isStampcardCoupon: PropTypes.bool,
        hideTitle: PropTypes.bool,
        hideSubtitle: PropTypes.bool,
        stampcardCoupon: PropTypes.bool,
        submitted: PropTypes.bool,
        onNext: PropTypes.func,
        onPrevious: PropTypes.func,
        setFormValue: PropTypes.func,
        addBannerChangeToForm: PropTypes.func
    };

    // TODO loaded template file list
    static templates = [
        '/static/images/template5.png',
        '/static/images/template6.png',
        '/static/images/template7.png',
        '/static/images/template8.png',
        '/static/images/template9.png',
        '/static/images/template10.png',
        '/static/images/template11.png',
        '/static/images/template12.png',
        '/static/images/template13.png',
        '/static/images/template14.png',
    ];

    constructor(props) {
        super(props);
        this.state = {
            canvasInfo: undefined,
            isLoading: !!this.props.currentImageFile,
            isEditing: false,
            loadedImage: null,
            loadedImageCrop: null,
            bannerTexts: [],
            stampcardTitleColor: props.initialValues && props.initialValues.titleColor,
            stampcardTitleFontSize: props.initialValues && props.initialValues.titleSize,
            stampcardSubtitleColor: props.initialValues && props.initialValues.subtitleColor,
            stampcardSubtitleFontSize: props.initialValues && props.initialValues.subtitleSize,
            errorMessage: null,
            disableDropzone: false,
            selectedStamp: undefined,
            selectedStampUncollected: undefined,
        }
    }

    handleOpenTemplateDialog() {
        this.setState({
            disableDropzone: true,
        });
    }

    handleCloseTemplateDialog() {
        this.setState({
            disableDropzone: false,
        });
    }

    componentDidMount() {
        this.loadInitialImage();
        if (this.imageContainer) {
            this.setState({
                canvasInfo: {
                    canvasWidth: BANNER_WIDTH_PX,
                    canvasHeight: BANNER_HEIGHT_PX,
                    canvasScale: {
                        x: this.imageContainer.clientWidth / BANNER_WIDTH_PX,
                        y: this.imageContainer.clientHeight / BANNER_HEIGHT_PX
                    }
                }
            })
        }
    }

    componentWillUnmount() {
        this.releaseImageIfBlob();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps
            && nextProps.meta
            && nextProps.meta.touched
            && nextProps.meta.error
            && this.state.errorMessage !== nextProps.meta.error) {
            this.setState({
                errorMessage: nextProps.meta.error
            });
        }
    }

    loadInitialImage() {
        this.releaseImageIfBlob();
        const {currentImageFile} = this.props;
        this.setState({
            isLoading: !!currentImageFile,
            isEditing: false,
            loadedImage: null,
            loadedImageCrop: null,
            bannerTexts: [],
            errorMessage: null
        });
        this.loadImage(currentImageFile, currentImageFile, false, {x: 0, y: 0, width: 100, height: 100});
    }

    loadImage(source, path, editAfter, initialCrop) {
        if (source && path) {
            this.setState({
                isLoading: true,
            });

            const image = new window.Image();
            image.key = source;

            // to enable images from s3 working
            //  https://stackoverflow.com/questions/20424279/canvas-todataurl-securityerror
            if (path.includes(AMAZON_S3_URL)) {
                const timestamp = new Date().getTime();
                image.crossOrigin = 'anonymous';
                image.src = path + '?' + timestamp;
            } else {
                image.src = path
            }
            image.onload = () => {
                this.setState({
                    isLoading: false,
                    isEditing: editAfter,
                    loadedImage: image,
                    loadedImageCrop: initialCrop
                });
            };
        }

        this.notifyForm(source && path && editAfter);
    }

    // the question is we ever go inside this if, probably revoke is done anyway because of default autorevoke
    releaseImageIfBlob() {
        if (this.state.loadedImage
            && this.state.loadedImage.source
            && this.state.loadedImage.source.preview) {
            window.URL.revokeObjectURL(this.state.loadedImage.source.preview);
        }
    }

    onImageCropChanged(crop) {
        this.setState({
            loadedImageCrop: crop
        })
    }

    onTemplateImageChosen(imagePath) {
        this.loadImage(imagePath, imagePath, true);
    }

    onBackgroundChanged(acceptedImage) {
        if (acceptedImage) {
            const that = this;
            const image = acceptedImage[0];
            loadImage.parseMetaData(image, function (data) {
                let orientation = 0;
                if (data.exif) {
                    orientation = data.exif.get('Orientation');
                }
                loadImage(
                    image,
                    function (canvas) {
                        canvas.naturalHeight = canvas.height;
                        canvas.naturalWidth = canvas.width;
                        canvas.src = canvas.toDataURL('image/png');
                        that.setState({
                            isLoading: false,
                            isEditing: true,
                            loadedImage: canvas,
                            loadedImageCrop: undefined
                        });
                    }, {
                        canvas: true,
                        orientation: orientation
                    }
                );
            })
        }
    }

    onBackgroundRemove() {
        this.releaseImageIfBlob();
        this.setState({
            loadedImage: null,
            loadedImageCrop: null
        })
    }

    onTextCreate() {
        const texts = this.state.bannerTexts;
        texts.push({
            key: Date.now(),
            text: '',
            textColor: colorBlack,
            textSize: DEFAULT_FONT_SIZE,
            textFontFamily: 'Arial',
            textPosition: {
                x: BANNER_WIDTH_PX * 0.5,
                y: BANNER_HEIGHT_PX * 0.15
            }
        });
        this.setState({
            bannerTexts: texts
        });
    }

    onStampcardFontChanged(changes) {
        this.setState({
            stampcardTitleColor: changes.titleColor,
            stampcardTitleFontSize: changes.titleSize,
            stampcardSubtitleColor: changes.subtitleColor,
            stampcardSubtitleFontSize: changes.subtitleSize
        });
    }

    onTextValueChanged(changes, index) {
        const texts = this.state.bannerTexts;
        texts[index] = Object.assign(texts[index], changes);
        this.setState({
            bannerTexts: texts
        });
    }

    onTextRemove(index) {
        const texts = this.state.bannerTexts;
        texts.splice(index, 1);
        this.setState({
            bannerTexts: texts
        });
    }

    onRecreate() {
        this.releaseImageIfBlob();
        this.setState({
            isEditing: true,
            loadedImage: null,
            loadedImageCrop: null,
            bannerTexts: []
        });
        this.notifyForm(true, null, true);
    }

    onCancelEdit() {
        this.loadInitialImage();
    }

    onBuild(onNext) {
        if (!this.stageRender) {
            this.notifyForm(false, null, true);
            this.setState({
                isEditing: false
            });
            return;
        }

        const saveStage = this.stageRender
            .getStage()
            .clone({
                width: this.state.canvasInfo.canvasWidth,
                height: this.state.canvasInfo.canvasHeight,
                scale: {x: 1, y: 1}
            });
        fetch(saveStage.toDataURL({mimeType: 'image/png', quality: 1}))
            .then(response => response.blob())
            .then(blob => {
                const sizeValidation = validateBannerSize(blob);
                const isInvalid = !!sizeValidation;
                window.URL = window.URL || window.webkitURL;
                this.notifyForm(isInvalid, blob, true);
                this.setState({
                    loadedImage: {src: window.URL.createObjectURL(new Blob([blob], {type: "image/png"}))},
                    errorMessage: sizeValidation,
                    isEditing: isInvalid
                });

                saveStage.destroy();
            })
            .then(() => {
                    if (onNext) {
                        onNext();
                    }
                }
            );
    }

    notifyForm(isEditing, data, isModified) {
        const {currentImageFile} = this.props;
        this.props.addBannerChangeToForm(
            this.props.meta.form,
            this.props.input.name,
            {
                isUpdating: !!currentImageFile,
                isEditing: !!isEditing,
                isModified: !!isModified,
                newData: data
            })
    }

    onStampSelected(image) {
        this.setState({
            selectedStamp: image.preview ? image.preview : image,
        });
        this.props.setFormValue(
            STAMPCARD_FORM_NAME,
            image instanceof Blob
                ? "stampImage"
                : "stampImagePath", image);
        clearFields(
            STAMPCARD_FORM_NAME,
            false,
            false,
            image instanceof Blob
                ? "stampImagePath"
                : "stampImage");
    }

    onStampUncollectedSelected(image) {
        this.setState({
            selectedStampUncollected: image.preview ? image.preview : image,
        });
        this.props.setFormValue(
            STAMPCARD_FORM_NAME,
            image instanceof Blob
                ? "stampUncollectedImage"
                : "stampUncollectedImagePath",
            image);
        clearFields(
            STAMPCARD_FORM_NAME,
            false,
            false,
            image instanceof Blob
                ? "stampUncollectedImagePath"
                : "stampUncollectedImage");
    }

    render() {
        const {classes, onNext, onPrevious, isUpdate, stampcard, stampcardCoupon, submitted, setFormValue, initialValues} = this.props;
        const {isEditing, loadedImage} = this.state;
        return (
            <div>
                {isEditing && loadedImage ? this.getCropAndRemoveButtons() : null}

                {stampcard && (
                    <React.Fragment>
                        <StampPicker
                            onStampSelected={this.onStampSelected.bind(this)}
                            onStampUncollectedSelected={this.onStampUncollectedSelected.bind(this)}
                            handleClose={this.handleCloseTemplateDialog.bind(this)}
                            handleOpen={this.handleOpenTemplateDialog.bind(this)}/>

                        <StampTextEditor
                            initialValues={{
                                titleColor: initialValues.titleColor,
                                titleSize: initialValues.titleSize,
                                subtitleColor: initialValues.subtitleColor,
                                subtitleSize: initialValues.subtitleSize,
                            }}
                            setFormValue={setFormValue}
                            onChange={changes => this.onStampcardFontChanged(changes)}/>
                    </React.Fragment>
                )}

                <div className={classes.builderWrapper}>
                    <div className={classes.builderImageWrapper}>
                        <div
                            className={classes.selectedImage}
                            ref={node => {
                                this.imageContainer = node
                            }}>
                            {this.renderImage()}
                        </div>
                    </div>
                    {this.renderCropWarning()}
                    {(stampcard || stampcardCoupon) && (
                        <div className={classes.checkboxesWrapper}>
                            <Field
                                name="hideTitle"
                                label={<FormattedMessage
                                    id="hideTitle"
                                    defaultMessage="Hide title"/>}
                                type="checkbox"
                                component={Checkbox}
                                noMargin/>

                            {stampcard && (
                                <Field
                                    name="hideSubtitle"
                                    label={<FormattedMessage
                                        id="hideSubtitle"
                                        defaultMessage="Hide subtitle"/>}
                                    type="checkbox"
                                    component={Checkbox}
                                    noMargin/>
                            )}
                        </div>
                    )}
                    {loadedImage && !isEditing && this.renderEditButton()}
                    {isEditing ? this.renderEditPanel(isUpdate) : null}
                    {this.renderError()}

                    <div>
                        {!stampcard && !stampcardCoupon && (
                            <Button
                                label={<FormattedMessage
                                    id="previous"
                                    defaultMessage="Previous"/>}
                                color="secondary"
                                onClick={onPrevious}/>
                        )}

                        {(!stampcard || !isUpdate || (stampcard && loadedImage && !isEditing)) &&
                        <Button
                            label={stampcard && isUpdate
                                ? <FormattedMessage
                                    id="saveNewCardDesign"
                                    defaultMessage="Save new card design"/>
                                : <FormattedMessage
                                    id="next"
                                    defaultMessage="Next"/>}
                            onClick={isUpdate ? onNext : this.onBuild.bind(this, onNext)}
                            disabled={!loadedImage || submitted}/>
                        }
                    </div>

                </div>
            </div>
        )
    }

    renderImage() {
        const {classes, stampcard, stampsAmount, monthValidation, title, subtitle, stampcardCoupon, reward} = this.props;
        if (this.state.isLoading) {
            return (
                <div className={classes.imageBorderedContent}>
                    <div className={classes.dropzoneContent}>
                        <Spinner/>
                    </div>
                </div>
            )
        } else if (this.state.loadedImage) {
            return this.state.isEditing
                ? this.renderEdit()
                : <div className={classes.imageContent}>
                    {this.renderStampcardData(stampcard, stampsAmount, monthValidation, title, subtitle, stampcardCoupon, reward)}
                    <img
                        className={classes.imageContent}
                        src={this.state.loadedImage.src}/>
                </div>
        } else {
            return this.renderDropzone();
        }
    }

    renderCropWarning() {
        if (this.state.loadedImage && this.state.loadedImageCrop) {
            const croppedWidth = this.state.loadedImageCrop.width * this.state.loadedImage.naturalWidth * 0.01;
            const croppedHeight = this.state.loadedImageCrop.height * this.state.loadedImage.naturalHeight * 0.01;
            if (BANNER_WIDTH_PX * croppingAccuracy > croppedWidth
                || BANNER_HEIGHT_PX * croppingAccuracy > croppedHeight) {
                return (
                    <React.Fragment>
                        <Typography color="error">
                            <FormattedMessage
                                id="selectedImageIsSmallerThanDesiredBannerSize"
                                defaultMessage="Selected image is smaller than desired banner size"/>.
                        </Typography>

                        <Typography color="error">
                            <FormattedMessage
                                id=" itWillResultInWorseQualityDueToUpscaling"
                                defaultMessage="It will result in worse quality due to upscaling."/>.
                        </Typography>
                    </React.Fragment>
                );
            }
        }
    }

    renderError() {
        if (this.state.errorMessage) {
            return <Typography color="error">{this.state.errorMessage}</Typography>
        }
    }

    renderDropzone() {
        const {classes, input} = this.props;
        return (
            <Dropzone
                disableClick={this.state.disableDropzone}
                accept='image/jpeg, image/png'
                className={classes.imageBorderedContent}
                rejectClassName={classes.imageRejectedBorderedContent}
                acceptClassName={classes.imageAcceptedBorderedContent}
                multiple={false}
                onDrop={this.onBackgroundChanged.bind(this)}>

                {({isDragActive, isDragReject}) => {
                    let message = (
                        <React.Fragment>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="clickToSelectTemplateOrUploadImageText"
                                    defaultMessage="Click to select an template, or upload your own image."/>
                            </Typography>

                            <Typography variant="body1">
                                <FormattedMessage
                                    id="youCanAlsoDragAndDropYourOwnImageText"
                                    defaultMessage="You can also drag and drop your own image here."/>
                            </Typography>

                            <RenderImagePicker
                                isPristine={!input || !input.value || !input.value.isEditing}
                                isStampcard={this.props.stampcard}
                                isStampcardCoupon={this.props.isStampcardCoupon}
                                isUpdate={this.props.isUpdate}
                                handleClose={this.handleCloseTemplateDialog.bind(this)}
                                handleOpen={this.handleOpenTemplateDialog.bind(this)}
                                buttonClass={classes.selectImageButton}
                                buttonLabel={
                                    <React.Fragment>
                                        <FormattedMessage
                                            id="select"
                                            defaultMessage="Select"/>
                                        &nbsp;
                                        <FormattedMessage
                                            id="template"
                                            defaultMessage="template"/>
                                    </React.Fragment>
                                }
                                onImageSelected={this.onTemplateImageChosen.bind(this)}
                                imagePaths={BannerBuilder.templates}/>

                            <Button
                                customColor="grey"
                                className={classes.selectImageButton}
                                label={<FormattedMessage
                                    id="uploadYourOwnImage"
                                    defaultMessage="Upload your own image"/>}
                                icon={<AddPhotoAlternate/>}/>

                            <Typography variant="body1">
                                <FormattedMessage
                                    id="supportedFileFormat"
                                    defaultMessage="Supported file format"/>: <i>jpg</i>, <i>png</i>.
                            </Typography>
                        </React.Fragment>
                    );

                    if (isDragReject) {
                        message = <p>This file will be rejected</p>;
                    } else if (isDragActive) {
                        message = <p>This file will be accepted</p>;
                    }

                    return (
                        <div className={classes.dropzoneContent}>
                            {message}
                        </div>
                    )
                }}
            </Dropzone>
        );
    }

    renderStampcardData(stampcard, stampsAmount, monthValidation, title, subtitle, stampcardCoupon, reward) {
        const {classes, stampPath, stampUncollectedPath, hideTitle, hideSubtitle} = this.props;
        const {selectedStamp, selectedStampUncollected, stampcardTitleColor, stampcardTitleFontSize, stampcardSubtitleColor, stampcardSubtitleFontSize} = this.state;
        const stamp = selectedStamp ? selectedStamp : stampPath ? stampPath : undefined;
        const stampUncollected = selectedStampUncollected ? selectedStampUncollected : stampUncollectedPath ? stampUncollectedPath : undefined;
        if (stampcard) {
            return (
                renderStamps(stampsAmount, title, subtitle, stamp, stampUncollected, STANDARD_STAMPCARD, undefined, hideTitle, hideSubtitle, stampcardTitleColor, stampcardTitleFontSize, stampcardSubtitleColor, stampcardSubtitleFontSize)
            )
        } else if (stampcardCoupon) {
            return (
                <div className={classes.rewardWrapper}>
                    <div className={classes.rewardContent}>
                        {!hideTitle && (
                            <Typography
                                variant="h5"
                                color="inherit"
                                component="div">
                                {reward}
                            </Typography>
                        )}
                    </div>

                </div>
            )
        }
        return null;
    }

    renderEdit() {
        const {classes, stampcard, stampcardCoupon, reward, stampsAmount, monthValidation, title, subtitle} = this.props;
        const {canvasInfo, loadedImage, loadedImageCrop} = this.state;
        if (canvasInfo && loadedImage && loadedImageCrop) {

            const imageCrop = {
                x: loadedImageCrop.x * loadedImage.naturalWidth * 0.01,
                y: loadedImageCrop.y * loadedImage.naturalHeight * 0.01,
                width: loadedImageCrop.width * loadedImage.naturalWidth * 0.01,
                height: loadedImageCrop.height * loadedImage.naturalHeight * 0.01
            };
            return (
                <div className={classes.imageContent}>
                    {this.renderStampcardData(stampcard, stampsAmount, monthValidation, title, subtitle, stampcardCoupon, reward)}
                    <Stage
                        ref={node => {
                            this.stageRender = node
                        }}
                        width={canvasInfo.canvasWidth * canvasInfo.canvasScale.x}
                        height={canvasInfo.canvasHeight * canvasInfo.canvasScale.y}
                        scale={canvasInfo.canvasScale}>
                        <Layer>
                            <Image
                                image={this.state.loadedImage}
                                width={canvasInfo.canvasWidth}
                                height={canvasInfo.canvasHeight}
                                crop={imageCrop}/>
                            {this.state.bannerTexts.map((bannerText, index) => {
                                return this.createCanvasGroup(bannerText, index);
                            })}
                        </Layer>
                    </Stage>
                </div>
            );
        } else {
            return (
                <div className={classes.imageBorderedContent}>
                    <div className={classes.dropzoneContent}>
                        <Typography>
                            <FormattedMessage
                                id="toContinueYouHaveToCropBackgroundProperly"
                                defaultMessage="To continue you have to crop background properly"/>.
                        </Typography>
                    </div>
                </div>
            )
        }
    }

    getCropAndRemoveButtons() {
        return (
            <React.Fragment>
                <RenderImageCropper
                    initialCropping={this.state.loadedImageCrop}
                    sourceImage={this.state.loadedImage.src}
                    imageOutputRatio={BANNER_RATIO}
                    onImageCropped={this.onImageCropChanged.bind(this)}
                    cancelEnabled={!!this.state.loadedImageCrop}
                    autoOpen={!this.state.loadedImageCrop}/>

                <Button
                    customColor="red"
                    icon={<DeleteOutlined/>}
                    label={this.props.stampcard
                        ? <FormattedMessage
                            id="removeBackground"
                            defaultMessage="Remove background"/>
                        : <FormattedMessage
                            id="remove"
                            defaultMessage="Remove"/>}
                    onClick={this.onBackgroundRemove.bind(this)}/>
            </React.Fragment>
        )
    }

    renderEditPanel(isUpdate) {
        const {classes, submitted, stampcard, stampcardCoupon} = this.props;
        return (
            <div>
                <div>
                    {this.state.bannerTexts.map((bannerText, index) => {
                        return (
                            <table
                                key={bannerText.key}
                                className={classes.textFieldsWrapper}>
                                <tbody>
                                <tr valign="middle">
                                    <td width="100%">
                                        <FormattedTextBuilder
                                            value={bannerText}
                                            onChange={changes => this.onTextValueChanged(changes, index)}/>
                                    </td>

                                    <td>
                                        <Button
                                            customColor="red"
                                            icon={<DeleteOutlined/>}
                                            onClick={() => this.onTextRemove(index)}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        );
                    })}

                    {this.state.loadedImage
                    && this.state.bannerTexts.length < BANNER_MAX_TEXTS_COUNT
                    && (!stampcard && !stampcardCoupon)
                        ? <Button
                            customColor="green"
                            icon={<Add/>}
                            label={<FormattedMessage
                                id="addText"
                                defaultMessage="Add text"/>}
                            onClick={this.onTextCreate.bind(this)}/>
                        : null}

                </div>

                {isUpdate && (
                    <div className={classes.buttonsWrapper}>
                        <Button
                            customColor="yellow"
                            icon={<Undo/>}
                            label={<FormattedMessage
                                id="undo"
                                defaultMessage="Undo"/>}
                            onClick={this.onCancelEdit.bind(this)}/>

                        <Button
                            disabled={submitted}
                            customColor="green"
                            icon={<Save/>}
                            label={
                                stampcard || stampcardCoupon ?
                                    <FormattedMessage
                                        id="saveNewBackground"
                                        defaultMessage="Save new background"/>
                                    :
                                    <FormattedMessage
                                        id="save"
                                        defaultMessage="Save"/>}
                            onClick={this.onBuild.bind(this)}/>
                    </div>
                )}
            </div>
        )
    }

    renderEditButton() {
        return (
            <Button
                customColor="red"
                icon={<Edit/>}
                label={<FormattedMessage
                    id="changeBackground"
                    defaultMessage="Change background"/>}
                onClick={this.onRecreate.bind(this)}/>
        )
    }

    createCanvasGroup(textInfo, index) {
        return (
            <Group
                key={textInfo.key}
                ref={node => {
                    if (node && node.children[0]) {
                        const textNode = node.children[0];
                        node.setOffset({
                            x: textNode.getWidth() * 0.5,
                            y: textNode.getHeight() * 0.5
                        });
                    }
                }}
                onmouseover={this.state.isEditing && showGrabCursor}
                onmouseout={this.state.isEditing && showDefaultCursor}
                onmousedown={this.state.isEditing && showGrabbingCursor}
                onmouseup={this.state.isEditing && showGrabCursor}
                ondragend={(event) => this.onTextValueChanged({textPosition: event.target.getPosition()}, index)}
                position={textInfo.textPosition}
                draggable={this.state.isEditing}>

                <Text
                    text={textInfo.text}
                    fill={textInfo.textColor}
                    fontSize={textInfo.textSize.value}
                    fontFamily={textInfo.textFontFamily}
                    fontStyle={'bold'}
                    stroke={'black'}
                    strokeWidth={0.5}/>

            </Group>
        );
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({addBannerChangeToForm, setFormValue}, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(BannerBuilder));
